import { Container } from "react-bootstrap";
import PurchaseOrderTable from "./fragments/Table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { Heading } from "@chakra-ui/react";
import { indexPurchaseOrders } from "../../api/PurchaseOrders";
import { indexUser } from "../../api/Users";
import { useTranslation } from "react-i18next";
import { PageHeader } from "antd";
import { SET_ACTIVE_TAB } from "../../redux/actions/main";
import { faCogs } from "@fortawesome/free-solid-svg-icons";
import usePagination from "../../hooks/usePagination";
import useForm from "../../hooks/useForm";
import FiltersComp from "./fragments/Filters";
import { indexUserBusinessNames } from "../../api/Cfdi/UserBusinessNames";
import { indexRoleBusinessNames } from "../../api/Cfdi/RoleBusinessNames";
import { useHistory } from "react-router-dom";

const PurchaseOrderProvider = () => {
    const store = useStore();
    const history = useHistory();
    const purchaseOrdersStored = useSelector((state) => state.purchase_orders);
    const { token, user, fibra, role_selected } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };
    const hookForm = useForm();
    const hookProviders = usePagination({ defaultPageSize: 100 });
    const hookPurchaseOrders = usePagination({ defaultPageSize: 10, page: 1 });
    const { page, pageSize } = hookPurchaseOrders.pagination;

    const [t] = useTranslation('global');
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [form, setForm] = useState({ segment_ids: [], business_name_ids: [] });
    const { segment_ids, business_name_ids } = form;

    // Obtener filtros existentes
    useEffect(() => {
        hookForm.setForm(purchaseOrdersStored.filters);
    }, [purchaseOrdersStored.filters]);
    // Obtener permisos del rol actual
    useEffect(() => {
        const getDefaultConfigs = async () => {
            setLoading(true);
            if (role_selected.custom_conf) {
                const fetch = await indexUserBusinessNames({ headers, user_id: user.id });
                if (Array.isArray(fetch)) {
                    const { segments, business_names } = fetch.find(element => element.role.id === role_selected.id);
                    // Almacenar los identificadores de los segmentos y razones sociales a las que se tiene acceso
                    setForm({
                        business_name_ids: business_names.map(business_name => business_name.business_name_id),
                        segment_ids: segments.map(segment => segment.segment_id)
                    });
                }
                setLoading(false);
            } else {
                const { segments, business_names } = await indexRoleBusinessNames({ headers, role_id: role_selected.id });
                // Almacenar los identificadores de los segmentos y razones sociales a las que se tiene acceso
                setForm({
                    business_name_ids: business_names.map(business_name => business_name.business_name_id),
                    segment_ids: segments.map(segment => segment.segment_id)
                });
                setLoading(false);
            }
        }
        // Obtener permisos personalizados o por default
        getDefaultConfigs();
    }, [role_selected]);

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/pruchase-order-provider' });
        const getProviders = async () => {
            hookProviders.setLoading(true);
            const response = await indexUser({ headers, fibra_id: fibra.id, role_code: 'PROVEEDOR' });
            hookProviders.setData(response.data);
            hookProviders.setTotalItems(response.totalItems);
            hookProviders.setLoading(false);
        }
        getProviders();
    }, []);

    useEffect(() => {
        const getPurchaseOrders = async () => {
            hookPurchaseOrders.setLoading(true);
            const response = await indexPurchaseOrders({ headers, fibra_id: fibra.id, page, pageSize, user_id: user.id, ...purchaseOrdersStored?.filters, ...form });
            hookPurchaseOrders.setData(response.data);
            hookPurchaseOrders.setTotalItems(response.totalItems);
            hookPurchaseOrders.setLoading(false);
        }
        if (!loading) getPurchaseOrders();
    }, [page, pageSize, purchaseOrdersStored?.filters, loading]);
    const getDisabled = (item) => item.canceled === 'CANCELADA';
    const showMain = (item) => {
        history.push(`/purchase-order-provider/${item.id}`);
    }
    return (
        <Container id='purchase-order-component' fluid>
            <PageHeader
                ghost={true}
                onBack={() => window.history.back()}
                title={<Heading>{t('purchase-order.title')}</Heading>}
                subTitle=""
                extra={
                    <FiltersComp
                        hookForm={hookForm}
                        fibra_id={fibra.id}
                        headers={headers}
                        loading={loading}
                        business_name_ids={business_name_ids}
                        segment_ids={segment_ids}
                        hookPurchaseOrders={hookPurchaseOrders}
                    />
                }
            >
            </PageHeader>
            <PurchaseOrderTable
                type='provider'
                title={t('purchase-order.modal-edit.title')}
                hookPurchaseOrders={hookPurchaseOrders}
                hookProviders={hookProviders}
                tactions={[{
                    icon: faCogs, handleClick: showMain,
                    tooltip: t('purchase-order.tooltips.show'),
                    variant: 'outline-primary', getDisabled
                }]}
            />
        </Container>
    );
}

export default PurchaseOrderProvider;