import { Fragment, useEffect, useState } from "react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { getBlobRelease, showRelease } from "../../../api/Releases";
import { indexRoleReleases } from "../../../api/RoleReleases";
import { indexRoles } from "../../../api/Roles";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import ModalComp from "../../Layouts/Modal";
import PDFViewerComp from "../../Layouts/PDFViewer";
import TabsComp from "../../Layouts/Tabs";
import FormRelease from "./FormRelease";
import Recipients from "./Recipients";
import { Skeleton } from 'antd';

const ModalView = ({ onClose, release, headers, fibra_id }) => {
    const [file, setFile] = useState(null);
    const [data, setData] = useState({});
    const [roles, setRoles] = useState([]);
    const [roleReleases, setRoleReleases] = useState([]);   // Roles a los que va dirigido el comunicado
    const [loading, setLoading] = useState(true);
    const [t] = useTranslation('global');
    const prefix = 'releases.tabs';

    useEffect(() => {
        const getReleaseData = async () => {
            try {
                setLoading(true);
                let blob_file = await getBlobRelease({ headers, release_id: release.id });
                const { data } = await indexRoles({ headers, fibra_id });
                setRoles(data);
                let response = await showRelease({ headers, release_id: release.id });
                setData(response);
                response = await indexRoleReleases({ headers, release_id: release.id });
                setRoleReleases(response.data);
                setFile(blob_file);
            } catch (error) {
                console.error(error); // Maneja el error como consideres adecuado
            } finally {
                setLoading(false);
            }
        };
        getReleaseData();
    }, [release]);

    const handleChange = () => { /* TODO document why this arrow function is empty */ }
    // Determinar que switches deben estar marcados
    const getChecked = (role_id) => {
        return roleReleases.find(roleRelease => roleRelease.role_id === role_id);
    };
    // Deshabilitar los switches por default
    const getDisabled = () => true;

    return (
        <ModalComp
            onClose={onClose}
            title={release.title}
            size="lg"
            body={
                loading ? (
                    <Skeleton active />
                ) : (
                    <Fragment>
                        <TabsComp
                            tablist={[
                                t(`${prefix}.release`),
                                t(`${prefix}.preview`),
                                t(`${prefix}.addressee`)
                            ]}
                            tabpanels={[
                                <FormRelease
                                    key='form'
                                    handleChangeFile={handleChange}
                                    handleChangeData={handleChange}
                                    data={data} />,
                                <PDFViewerComp
                                    key='view'
                                    file={file} />,
                                <Recipients
                                    key='recipients'
                                    loading={loading}
                                    roles={roles}
                                    handleChange={handleChange}
                                    getChecked={getChecked}
                                    getDisabled={getDisabled} />
                            ]}
                        />
                    </Fragment>
                )
            }
            footer={
                <Fragment>
                    <ButtonIcon icon={faArrowLeft} name={t(`${prefix}.button-close`)} variant="outline-secondary" onClick={onClose} />
                </Fragment>
            }
        />
    );
};

export default ModalView;
