import { Heading } from '@chakra-ui/react';
import { useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SET_ACTIVE_TAB } from '../../redux/actions/main';
import TabsComp from '../Layouts/Tabs';
import Security from './Security/Security';
import Providers from './tabs/Providers';
import Roles from './tabs/Roles';
import Statuses from './tabs/Statuses';
import Users from './tabs/Users';
import BusinessName from '../BulkDownload/fragments/BusinessName';

const AdminSettings = () => {
    const login = useSelector(state => state.login);
    const { token, fibra } = login;
    const headers = { Authorization: `Bearer ${token}` };
    const [t] = useTranslation('global');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/settings' });
    }, []);

    return (
        <Fragment>
            <Heading className='mb-3'>{t('settings.title')}</Heading>
            <TabsComp
                tablist={[
                    t('settings.tabs.roles'),
                    t('settings.tabs.cfdi-statuses'),
                    t('settings.tabs.users'),
                    t('settings.tabs.security'),
                    t('settings.tabs.business-names')
                ]}
                tabpanels={[
                    <Roles
                        key=''
                        headers={headers}
                        fibra={fibra}
                    />,
                    <Statuses
                        key=''
                        headers={headers}
                        fibra={fibra}
                    />,
                    <Users
                        key=''
                        headers={headers}
                        fibra_id={fibra.id}
                    />,
                    <Security
                        key=''
                    />,
                    <BusinessName
                        key=''
                        headers={headers}
                        fibra_id={fibra.id}
                    />,
                    <Providers
                        key=''
                        headers={headers}
                        fibra_id={fibra.id}
                    />
                ]}
            />
        </Fragment>
    );
};

export default AdminSettings;