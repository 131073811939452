import Fetcher from "../../libs/Petition"

const url = '/inventory_cedis';
const CREATED = 201;
const STATUS_OK = 200;

export const indexInventory = async ({ headers, page, pageSize, cedis_id }) => {
    let response = { data: [], totalItems: 0 };
    try {
        // Parámetros requeridos
        let params = { cedis_id, page, pageSize };
        const fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        console.log("🚀 ~ indexInventory ~ fetch:", fetch)
        if (fetch.status === STATUS_OK)

            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}
export const storeInventory = async ({ headers, data }) => {
    let response = false;
    try {
        const fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        console.log("🚀 ~ file: requisitions.js:63 ~ storeInventory ~ fetch:", fetch)
        if (fetch.status === CREATED)
            response = fetch.data;
    } catch (error) {
        response = false;
    }
    return response;

}
