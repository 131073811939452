import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ModalComp from '../../Layouts/Modal'
import HeadersComp from './modalDetails/Headers'
import { Container } from 'react-bootstrap'
import ButtonIcon from '../../Layouts/Forms/ButtonIcon'
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import AssignPurchaseOrders from './purchaseOrders/AssignPurchaseOrders'
import ItemsProviders from './modalDetails/ItemsProviders'
import AssignProvidersComp from './modalDetails/AssignProviders'
import usePagination from '../../../hooks/usePagination'
import useForm from '../../../hooks/useForm'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import { showRequisition, updateRequisition } from '../../../api/Budget/requisitions'
import { indexSegments } from '../../../api/Segments'
import { indexBusinessName } from '../../../api/Cfdi/BusinessNames'
import { indexUser } from '../../../api/Users'

const DetailsApproval = ({ onClose, title, requisition, type, headers, setData, fibra_id, loading, business_name_ids, segment_ids }) => {
	const [t] = useTranslation('requisition');
	const prefix = 'modal-details';
	const hookItems = usePagination({ defaultPageSize: 100 });
	const hookProviders = usePagination({ defaultPageSize: 100 });
	const hookBusinessNames = usePagination({ defaultPageSize: 50 });
	const hookSegments = usePagination({ defaultPageSize: 50 });
	const { form, handleChange } = useForm();
	// Mostrar sección para configurar ordenes de compra con las partidas seleccionadas
	const [ordersView, setOrdersView] = useState(false);
	const [orderItems, setOrderItems] = useState([]);
	// Proveedores asignados a las partidas
	const [itemProviders, setItemProviders] = useState([]);
	// Datos por default, en caso de abrir requisición existente
	const [defaultData, setDefaultData] = useState({});
	const [showSaveButton, setShowSaveButton] = useState(true);

	// Obtener los segmentos
	useEffect(() => {
		const getSegments = async () => {
			hookSegments.setLoading(true);
			const { page, pageSize } = hookSegments.pagination;
			const fetch = await indexSegments({ headers, fibra_id, page, pageSize });
			const allowedSegments = fetch.data.filter(segment => segment_ids.includes(segment.id));
			hookSegments.setData(allowedSegments);
			hookSegments.setTotalItems(fetch.totalItems);
			hookSegments.setLoading(false);
		}
		if (!loading) getSegments();
	}, [loading]);
	// Obtener razones sociales de la empresa
	useEffect(() => {
		const getBusinessNames = async () => {
			hookBusinessNames.setLoading(true);
			const { data } = await indexBusinessName({ headers, fibra_id });
			const allowedBusinessNames = data.filter(business_name => business_name_ids.includes(business_name.id));
			hookBusinessNames.setData(allowedBusinessNames);
			hookBusinessNames.setLoading(false);
		}
		if (!loading) getBusinessNames();
	}, [loading]);
	// Obtener la lista de proveedores
	useEffect(() => {
		const getProviders = async () => {
			hookProviders.setLoading(true);
			const { data } = await indexUser({ headers, fibra_id, role_code: 'PROVEEDOR' });
			hookProviders.setData(data.filter(item => item.active === 'ACTIVO'));
			hookProviders.setLoading(false);
		}
		getProviders();
	}, []);
	// Obtener datos de la requisición
	useEffect(() => {
		const getRequisition = async () => {
			const response = await showRequisition({ headers, requisition_id: requisition?.id });
			if (response?.items) {
				setDefaultData(response);
				hookItems.setData(response.items.map((item, index) => ({ ...item, index: index + 1 })) || []);
				hookItems.setLoading(false);
			}
		}
		getRequisition();
	}, []);

	// Guardar proveedores seleccionados
	const handleChangeProviders = (newValue, itemId) => {
		const providersIds = newValue.map((item) => item.value);
		setItemProviders(prevState => {
			// Verificar si ya existe un elemento con el mismo item_id
			const existingItemIndex = prevState.findIndex(itemProvider => itemProvider.item_id === itemId);

			if (existingItemIndex !== -1) {
				// Si existe, actualizar providers
				return prevState.map((itemProvider, index) => {
					if (index === existingItemIndex) {
						return { item_id: itemId, providers: providersIds };
					}
					return itemProvider;
				});
			} else {
				// Si no existe, agregar un nuevo elemento al array
				return [...prevState, { item_id: itemId, providers: providersIds }];
			}
		});
	}
	// Función del botón guardar
	const onSaveSearch = () => {
		// Ocultar botón
		setShowSaveButton(false);
		// Compras - Crear orden de compra
		if (requisition?.provider_items.length > 0) createPurchaseOrder();
		// Compras - Asignación de proveedores
		if (requisition?.provider_items.length === 0) assignProviders();
	}
	// Mostrar sección para órdenes de compra
	const createPurchaseOrder = () => setOrdersView(true);
	// [Update] Asignar proveedores a las partidas, tipo de moneda y razón social
	const assignProviders = async () => {
		if (validate()) {
			const dataPayload = {
				...form,
				itemProviders
			}
			const response = await updateRequisition({ headers, data: dataPayload, requisition_id: requisition?.id });
			const prefixSave = `${prefix}.onSave`
			if (response) {
				Swal.fire(t(`${prefixSave}.success.title`), t(`${prefixSave}.success.description`), 'success');
				const { id, provider_items, business_name_id, business_name, currency, ended_date, status } = response.requisition;
				setData(prevState => prevState.map(element => {
					if (id === element.id) {
						element.provider_items = provider_items;
						element.business_name_id = business_name_id;
						element.business_name = business_name;
						element.currency = currency;
						element.ended_date = ended_date;
						element.status = status;
					}
					return element;
				}));
				onClose();
			} else {
				setShowSaveButton(true);
				Swal.fire(t(`${prefixSave}.fail.title`), t(`${prefixSave}.fail.description`), 'warning');
			}
		} else {
			setShowSaveButton(true);
		}
	}
	// Validar que cada partida tenga al menos un proveeedor assignado
	const validateProviders = () => {
		let response = true;
		for (let item of hookItems.data) {
			if (!itemProviders.find(itemProvider => itemProvider.item_id === item.id))
				response = false;
		}
		return response;
	}
	// Validar información antes de subirla
	const validate = () => {
		const { ended_date, currency } = form;
		const prefix_v = `${prefix}.validate`;
		const showMessageAndReturnFalse = (messageKey) => {
			Swal.fire(t(`${prefix_v}.title`), t(messageKey), 'info');
			return false;
		};
		if (!validateProviders()) {
			return showMessageAndReturnFalse(`${prefix_v}.providers`);
		}
		if (!currency || currency === '-1') {
			return showMessageAndReturnFalse(`${prefix_v}.currency`);
		}
		if (!ended_date || ended_date === '') {
			return showMessageAndReturnFalse(`${prefix_v}.ended-date`);
		}

		return true;
	}
	// Deshabilitar botón para guardar
	const isSaveButtonDisabled = () => {
		const { provider_items } = requisition;
		// Si es compras y no hay elementos seleccionados para crear una orden de compra
		const noOrderItems = provider_items.length > 0 && orderItems.length === 0;
		return noOrderItems;
	}
	// Obtener el nombre del botón de acción del footer
	const getButtonName = () => {
		let name = t(`${prefix}.save`);
		if (requisition?.provider_items.length > 0) name = t(`${prefix}.create`);
		return name;
	}
	// Mostrar botón para actualizar requisición
	const showUpdateButton = () => {
		const { provider_items } = requisition;
		return provider_items.length > 0;
	}
	// Actualizar datos del header: fecha de vencimiento, tipo de moneda
	const updateHeaders = async () => {
		const response = await updateRequisition({ headers, data: form, requisition_id: requisition?.id });
		const prefixSave = `${prefix}.onSave`
		if (response) {
			Swal.fire(t(`${prefixSave}.success-update.title`), t(`${prefixSave}.success-update.description`), 'success');
			const { id, currency, ended_date } = response.requisition;
			setData(prevState => prevState.map(element => {
				if (id === element.id) {
					element.currency = currency;
					element.ended_date = ended_date;
				}
				return element;
			}));
			onClose();
		} else {
			Swal.fire(t(`${prefixSave}.fail.title`), t(`${prefixSave}.fail.description`), 'warning');
		}
	}

	return (
		<ModalComp
			title={ordersView ? t(`${prefix}.purchase-orders`) : title}
			onClose={onClose}
			size={'xl'}
			body={<Container fluid>
				{
					ordersView ?
						// Vista para asignar ordenes de compra
						<AssignPurchaseOrders
							orderItems={orderItems}
							providers={hookProviders.data}
							providerItems={defaultData.provider_items}
							items={hookItems.data}
							businessNames={hookBusinessNames.data}
							defaultData={defaultData}
							requisition={requisition}
							hookSegments={hookSegments}
						/> :
						// Requisiciones
						<>
							{/* Headers */}
							<HeadersComp
								handleChange={handleChange}
								requisition={requisition}
								form={form}
								type={type}
								hookBusinessNames={hookBusinessNames}
								hookSegments={hookSegments}
							/>
							{/* Elegir cotizaciones para crear orden de compra */}
							{
								requisition?.provider_items.length > 0 &&
								<ItemsProviders
									items={hookItems.data}
									provider_items={defaultData.provider_items}
									setOrderItems={setOrderItems}
									onClose={onClose}
								/>
							}
							{/* Asignar proveedores a las partidas */}
							{
								requisition?.provider_items.length === 0 &&
								<AssignProvidersComp
									loading={hookItems.loading}
									items={hookItems.data}
									providers={hookProviders.data}
									handleChangeProviders={handleChangeProviders}
									defaultData={defaultData}
									fibra_id={fibra_id}
								/>
							}
						</>
				}
			</Container>}
			footer={<>
				<ButtonIcon
					icon={faTimes}
					name={t(`${prefix}.close`)}
					variant='outline-secondary'
					onClick={onClose}
				/>
				{/* Botón para  actualizar fecha de vencimiento y segmento */}
				{
					showUpdateButton() &&
					<ButtonIcon
						name='Actualizar'
						icon={faSave}
						variant='outline-success'
						onClick={updateHeaders}
						tooltip='Actualizar fecha de vencimiento, tipo de moneda'
					/>
				}
				{/* Ocultar el botón cuando se clickee */}
				{
					showSaveButton &&
					<ButtonIcon
						icon={faSave}
						name={getButtonName()}
						variant='outline-primary'
						onClick={onSaveSearch}
						disabled={isSaveButtonDisabled()}
					/>
				}
			</>}
		/>
	)
}

DetailsApproval.propTypes = {
	onClose: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	requisition: PropTypes.shape({
		id: PropTypes.number.isRequired,
		provider_items: PropTypes.array.isRequired
	}).isRequired,
	type: PropTypes.string.isRequired,
	setData: PropTypes.func.isRequired,
	headers: PropTypes.string.isRequired,
	fibra_id: PropTypes.number.isRequired,
	loading: PropTypes.bool,
	business_name_ids: PropTypes.array,
	segment_ids: PropTypes.array
}

export default DetailsApproval