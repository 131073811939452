import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useStore } from "react-redux";
import { SET_ACTIVE_TAB } from "../../redux/actions/main";
import TableRents from "./fragments/Table";

const ClientContracts = () => {
    const store = useStore();
    const { user, fibra } = store.getState().login;
    const [t] = useTranslation('global');
    const prefix = 'contracts.data';
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/client-contracts' });
    }, []);

    return (
        <TableRents
            params={{ fibra_id: fibra.id, user_id: user.id }}
            theaders={[
                '#',
                t(`${prefix}.contract`),
                t(`${prefix}.local`),
                t(`${prefix}.start_date`),
                t(`${prefix}.end_date`),
                t(`${prefix}.fixed_rent`),
                t(`${prefix}.actions`)
            ]}
            keys={['id', 'name', 'local_mall', 'start_date', 'end_date', 'renta_fija']}
            currency={[{ index: 5 }]}
            moment_dates={[{ index: 3, format: 'LLLL' }, { index: 4, format: 'LLL' }]}
        />
    );
}

export default ClientContracts