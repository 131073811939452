import React, { useState } from 'react';
import PageHeaderComp from '../Layouts/PageHeader';
import ButtonIcon from '../Layouts/Forms/ButtonIcon';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import TableComp from '../Layouts/Table';
import useInventory from '../../hooks/useInventory';
import { useSelector } from 'react-redux';
import ModalComp from '../Layouts/Modal';
import ModalSalida from './ModalEntrada';
import { Tabs } from 'antd';
import TabsComp from '../Layouts/Tabs';
import TodasLasEntregas from './TodasLasEntregas';
import Inventario from './Inventario';
import ModalRegistrarSalida from './ModalRegistrarSalida';


const InventoryComp = () => {
    const [showModal, setShowModal] = useState(false); // Define un estado para controlar la visibilidad del modal

    const login = useSelector(state => state.login); // Obtiene el estado de login desde Redux
    const headers = { Authorization: `Bearer ${login.token}` }; // Configura los headers de autorización usando el token de login
    const HookInventory = useInventory({ headers, cedis_id: 10 }); // Utiliza el hook personalizado para manejar el inventario

    const registrarEntrada = () => { // Función que se ejecuta al hacer clic en el botón 'Entrada'
        console.log('registrando entrada'); // Muestra un mensaje en la consola (por ahora no hace nada más)
    };

    const handleSalidaClick = () => { // Función que se ejecuta al hacer clic en el botón 'Salida'
        setShowModal(true); // Muestra el modal estableciendo el estado showModal en true
    };

    const handleCloseModal = () => { // Función para cerrar el modal
        setShowModal(false); // Oculta el modal estableciendo el estado showModal en false
    };
    return (
        <div>
            <PageHeaderComp
                title='Inventario' // Establece el título de la cabecera de la página
                actions={[
                    <ButtonIcon name='Entrada' icon={faPlus} variant='outline-success' onClick={registrarEntrada} />, // Botón para registrar entrada
                    <ButtonIcon name='Salida' icon={faPlus} variant='outline-danger' onClick={handleSalidaClick} /> // Botón para registrar salida
                ]}
            />
            <TabsComp
                tablist={['Todas las entregas', 'Inventario']}
                tabpanels={[
                    <TodasLasEntregas />,
                    <Inventario />
                ]}
            />
            {showModal && <ModalRegistrarSalida handleCloseModal={handleCloseModal} />}
        </div>
    );
};



export default InventoryComp
