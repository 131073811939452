import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Divider, Skeleton } from 'antd'
import { Heading } from '@chakra-ui/react'
import { Col, FloatingLabel, Form, FormSelect, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { currencyTypes } from '../../../../data/currencyTypes'

const HeadersComp = (props) => {
    const {
        handleChange,
        requisition,
        form,
        type,
        hookBusinessNames,
        hookSegments
    } = props;
    const [t] = useTranslation('requisition');
    const prefix = 'modal-details';
    const [segments, setSegments] = useState([]);
    // Mostrar todos los segmentos por default
    useEffect(() => {
        if (!hookSegments.loading) {
            setSegments(hookSegments.data);
        }
    }, [hookSegments.loading]);
    // Mostrar los segmentos que pertenecen a la razón social seleccionada
    useEffect(() => {
        if (form.business_name_id) {
            const businessNameSegments = hookSegments.data.filter(segment => segment.business_name_id == form.business_name_id)
            setSegments(businessNameSegments);
        }
    }, [form.business_name_id]);

    return (
        <Fragment>
            <Divider orientation='left'>
                <Heading size='sm'>{t(`${prefix}.general-data`)}</Heading>
            </Divider>
            <Row className='mb-3'>
                <Col>
                    <FloatingLabel label={t(`${prefix}.folio`)}>
                        <Form.Control type="text" placeholder={t(`${prefix}.folio`)} name='folio' defaultValue={requisition?.folio} value={form.folio} readOnly />
                    </FloatingLabel>
                </Col>
                <Col>
                    {
                        hookBusinessNames.loading ? <Skeleton.Button active={true} size={'large'} block={true} /> :
                            <FloatingLabel label={t(`${prefix}.business-name`)}>
                                <FormSelect name='business_name_id' onChange={handleChange} defaultValue={requisition?.business_name_id}
                                    value={form?.business_name_id} disabled={type !== 'admin'} required>
                                    <option value={-1}>{t(`${prefix}.select-business-name`)}</option>
                                    {
                                        hookBusinessNames.data.map((item) =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )
                                    }
                                </FormSelect>
                            </FloatingLabel>
                    }

                </Col>
                <Col>
                    <FloatingLabel label={t(`${prefix}.currency`)}>
                        <FormSelect name='currency' onChange={handleChange} defaultValue={requisition?.currency} value={form?.currency}
                            disabled={type !== 'approval'} required>
                            <option value={-1}>{t(`${prefix}.select-currency`)}</option>
                            {
                                currencyTypes.map((item) =>
                                    <option key={item.id} value={item.code}>{item.code} - {item.name}</option>
                                )
                            }
                        </FormSelect>
                    </FloatingLabel>
                </Col>
                <Col>
                    {
                        hookSegments.loading ? <Skeleton.Button active={true} size={'large'} block={true} /> :
                            <FloatingLabel label={t(`${prefix}.segment`)}>
                                <FormSelect name='segment_id' onChange={handleChange} value={form?.segment_id || requisition?.segment_id}
                                    disabled={type !== 'admin'} required>
                                    <option value={-1}>{t(`${prefix}.select-segment`)}</option>
                                    {
                                        segments.map((item) =>
                                            <option key={item.id} value={item.id}>{item.code} - {item.name}</option>
                                        )
                                    }
                                </FormSelect>
                            </FloatingLabel>
                    }
                </Col>
                <Col>
                    <FloatingLabel label={t(`${prefix}.ended-date`)}>
                        <Form.Control type="date" name='ended_date' onChange={handleChange} defaultValue={requisition?.ended_date}
                            disabled={type == 'provider' || type == 'admin'} required />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col>
                    <FloatingLabel label={t(`${prefix}.description`)}>
                        <Form.Control as='textarea' style={{ height: '5em' }} name='description' defaultValue={requisition?.description} onChange={handleChange}
                            disabled={type == 'provider' || type === 'approval'} required />
                    </FloatingLabel>
                </Col>
            </Row>
        </Fragment>
    )
}

HeadersComp.propTypes = {
    handleChange: PropTypes.func,
    requisition: PropTypes.shape({
        id: PropTypes.number,
        folio: PropTypes.string,
        business_name_id: PropTypes.number,
        segment_id: PropTypes.number,
        currency: PropTypes.string,
        description: PropTypes.string,
        ended_date: PropTypes.string
    }),
    form: PropTypes.object,
    type: PropTypes.oneOf(['admin', 'approval', 'provider']).isRequired,
    hookSegments: PropTypes.object,
    hookBusinessNames: PropTypes.object
}

export default HeadersComp