import { Grid, GridItem } from '@chakra-ui/react'
import Echo from 'laravel-echo'
import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { indexChatRooms } from '../../../api/Chat/Room'
import Chat from './Chat'
import Rooms from './Rooms'
import Send from './Send'

import audioNot from '../../../audio/iphone-notificacion.mp3';

const _ = require('underscore');

const Main = (props) => {
    const { updateRooms } = props;
    const dispatch = useDispatch();
    const login = useSelector(state => state.login);
    const headers = { Authorization: `Bearer ${login.token}` };
    const user_id = login.user.id;
    const room_id = useSelector(state => state.chat.room_id);

    const [chatRoomSel, setChatRoomSel] = useState(null);
    const [rooms, setRooms] = useState([]);
    const [messages, setMessages] = useState([]);
    const [messagesInc, setMessagesInc] = useState([]);
    const [countMessages, setCountMessages] = useState([]);

    // Efecto que obtiene y actualiza las salas del usuario
    useEffect(() => {
        getRooms();
    }, [updateRooms]);
    // Efecto que abre la conexión al socket
    useEffect(() => {
        // Agregar instancia de Pusher
        window.Pusher = require('pusher-js');
        // Establecer la comunicación a través de Laravel Echo
        window.Echo = new Echo({
            broadcaster: process.env.REACT_APP_WS_BROADCASTER,
            key: process.env.REACT_APP_WS_KEY,
            wssHost: process.env.REACT_APP_WS_HOST,
            wsHost: process.env.REACT_APP_WS_HOST,
            wssPort: process.env.REACT_APP_WS_PORT,
            wsPort: process.env.REACT_APP_WS_PORT,
            forceTLS: process.env.REACT_APP_WS_FORCE_TLS !== 'false',
            disableStats: process.env.REACT_APP_WS_DISABLE_STATS !== 'false',
            enabledTransports: ["ws", "wss"],
            transports: ['websocket'],
            auth: {
                headers: {
                    Authorization: `Bearer ${login.token}`,
                    Accept: 'application/json',
                },
            },
            apiOriginAllow: {
                "allowCors": true,
                "allowOrigin": "https://victum-re.online",
                "allowMethods": "GET, POST",
                "allowHeaders": "Origin, Content-Type, X-Auth-Token, X-Requested-With, Accept, Authorization, X-Socket-Id"
            }
        });
    }, []);
    // Efecto que se encarga de abrir los canales para cada sala
    useEffect(() => {
        if (rooms.length > 0) {
            rooms.map((room) => {
                window.Echo.channel(`NewMessage.${room.id}`)
                    .listen('NewMessageEvent', (e) => {
                        addMessage({ ...e.chat_message });
                    })
            });
        }
        // Importante eliminar la conexión al canal, en el cleanup
        return () => {
            if (rooms.length > 0) {
                rooms.map((room) => {
                    window.Echo.leave(`NewMessage.${room.id}`)
                });
            }
        }
    }, [rooms]);
    // Efecto que se encarga de procesar la cola de mensajes entrantes
    useEffect(() => {
        processMessages();
    }, [messagesInc]);

    // Procesar mensajes entrantes que se capturan en los distintos canales del socket
    const processMessages = () => {
        if (messagesInc.length > 0) {
            // Se obtienen los mensajes de la sala actual
            let new_messages = messagesInc.filter(msg => msg.chat_room_id === room_id);
            // Mensajes que pertenecen a las demás salas
            let unseen_messages = messagesInc.filter(msg => msg.chat_room_id !== room_id);
            let unseen_count = countMessages;
            // Si existen mensajes distintos a la sala abierta
            if (unseen_messages.length > 0) {
                // Reporducir sonido si hay nuevos mensajes
                const music = new Audio(audioNot);
                music.play(); // (loop, playbackRate, pause)
                // Actualizar contador de mensajes de cada sala
                unseen_messages.map((msg) => {
                    let pos = _.findIndex(unseen_count, { id: msg.chat_room_id });
                    if (pos !== -1) unseen_count[pos].count++;
                })
                setCountMessages(unseen_count); // Actualizar estado del contador
            }
            if (new_messages.length > 0)    // Si hay mensajes de la sala abierta
                setMessages(current => [...current, ...new_messages]);

            setMessagesInc([]); // Vaciar array de mensajes entrantes
        }
    }
    // Mandar al array de mensajes entrantes todos los mensajes recibidos en los canales
    const addMessage = (new_message) => {
        setMessagesInc(current => [...current, new_message]);
    }
    // Obtener todoas las salas a las que pertenece el usuario
    const getRooms = async () => {
        let response = await indexChatRooms({ headers, user_id });
        if (response.data.length > 0) {
            let count = [];
            response.data.map((r) => {
                count = [...count, { id: r.id, count: 0 }]
            })
            setCountMessages(count); // Inicializar contador para notificaciones en 0
        }
        setRooms(response.data);
    }
    // Evento para cambio de sala
    const handleChatRoom = (chat_room_id) => {
        setChatRoomSel(chat_room_id);
        dispatch({ type: 'SET_CHAT_ROOM_ID', chat_room_id });
    }

    return (
        <Container>
            <Grid
                h='82vh'
                templateRows='repeat(10, 1fr)'      // Cantidad de filas que se manejan
                templateColumns='repeat(8, 1fr)'    // Cantidad de columnas que se manejan
                gap={2}                             // Espacio entre los GridItems
                bg='#e6ebee'
            >
                <GridItem
                    rowSpan={10}
                    colSpan={2}
                    borderColor='#dfe1e5'
                    bg='#fff'
                >
                    {/* Componente para listar las salas disponibles */}
                    <Rooms
                        rooms={rooms}
                        handleChatRoom={handleChatRoom}
                        countMessages={countMessages}
                    />
                </GridItem>
                <GridItem
                    rowSpan={9}
                    colSpan={6}
                    borderColor='#dfe1e5'
                    bg='#e6ebee'
                >
                    {/* Componente para listar los mensajes de la sala seleccionada */}
                    <Chat
                        chat_room_id={chatRoomSel}
                        messages={messages}
                        setMessages={setMessages}
                    />
                </GridItem>
                <GridItem
                    rowSpan={1}
                    colSpan={6}
                    borderColor='#dfe1e5'
                    bg='#fff'
                >
                    {/* Componente para enviar un nuevo mensaje */}
                    <Send
                        chat_room_id={chatRoomSel}
                        headers={headers}
                        user_id={user_id}
                    />
                </GridItem>
            </Grid>
        </Container>
    )
}

export default Main;