import { Result, Button } from 'antd';
import { Container } from "react-bootstrap";
import '../../styles/PageNotFound.Component.css';
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SET_ACTIVE_TAB } from '../../redux/actions/main';

const PageNotFound = () => {
    const history = useHistory();
    const [t] = useTranslation('global');
    const prefix = 'page-not-found';
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: prefix });
    }, []);

    const handleBack = () => {
        history.push('/');
    }

    return (
        <Container id='pagenotfound-component'>
            <Result
                status="404"
                title={t(`${prefix}.title`)}
                subTitle={t(`${prefix}.subtitle`)}
                extra={<Button type="primary" onClick={handleBack}>{t(`${prefix}.extra`)}</Button>}
            />
        </Container>
    );
}

export default PageNotFound;