import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Select } from '@chakra-ui/react'
import { Col, Form, Row } from 'react-bootstrap'
import FormIcon from '../../Layouts/Forms/FormIcon'
import { cfdi_use, payment_form } from '../../../data/invoiceTypes'

const InvoiceDetails = ({ details, handleDetails }) => {
    return (
        <Fragment>
            <Row className='mb-3'>
                <Col>
                    <Form.Label>Forma de pago</Form.Label>
                    <Select name='payment_form' onChange={handleDetails}>
                        {
                            payment_form.map((pf) =>
                                <option key={pf.code} value={pf.code}>{pf.code} - {pf.name}</option>
                            )
                        }
                    </Select>
                </Col>
                <Col>
                    <Form.Label>Método de pago</Form.Label>
                    <Select name='payment_method' onChange={handleDetails}>
                        <option value="PUE">PUE - Pago en Una sola Exhibición</option>
                        <option value="PPD">PPD - Pago en Parcialidades o Diferido</option>
                    </Select>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col>
                    <Form.Label>Uso de CFDI</Form.Label>
                    <Select name='use' onChange={handleDetails}>
                        {
                            cfdi_use.map((cu) =>
                                <option key={cu.code} value={cu.code}>{cu.code} - {cu.name}</option>
                            )
                        }
                    </Select>
                </Col>
            </Row>
            <Row className='mb-3'>
                <FormIcon title='Tipo de moneda' name='currency' value={details?.currency} handleChange={handleDetails} />
                <FormIcon title='ID externo [Opcional]' name='external_id' value={details?.external_id} handleChange={handleDetails} />
                <FormIcon title='Folio [Opcional]' name='folio_number' value={details?.folio_number} handleChange={handleDetails} />
                <FormIcon title='ID externo [Opcional]' name='' value={details?.series} handleChange={handleDetails} />
            </Row>
        </Fragment>
    )
}

InvoiceDetails.propTypes = {
    details: PropTypes.shape({
        currency: PropTypes.string,
        external_id: PropTypes.number,
        folio_number: PropTypes.string,
        series: PropTypes.string
    }),
    handleDetails: PropTypes.func.isRequired
}

export default InvoiceDetails