import downloadFile from "../../libs/DownloadFile";
import Fetcher, { fetcherCancelToken } from "../../libs/Petition"

const url = '/invoice_payments';
const CREATED = 201;
const STATUS_OK = 200;

export const indexInvoicePayments = async ({ headers, ...params }) => {
    let response = { data: [], totalItems: 0 };
    try {
        const fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        // console.log("🚀 ~ file: InvoicePayments.js:17 ~ indexInvoicePayments ~ fetch:", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = { data: [], totalItems: 0 };
    }

    return response;
}

export const showInvoicePayments = async ({ headers, invoice_id }) => {
    let response = [];
    try {
        const fetch = await Fetcher({
            url: `${url}/${invoice_id}`,
            method: 'GET',
            headers
        });
        // console.log("🚀 ~ file: InvoicePayments.js:35 ~ showInvoicePayments ~ fetch:", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = [];
    }

    return response;
}

export const storeInvoicePayments = async ({ headers, data }) => {
    let response = true;
    try {
        const fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        // console.log("🚀 ~ file: InvoicePayments.js:54 ~ storeInvoicePayments ~ fetch:", fetch)
        if (fetch.status !== CREATED)
            response = false;
    } catch (error) {
        response = false;
    }

    return response;
}

export const updateInvoicePayments = async ({ headers, data, invoice_id }) => {
    let response = false;
    try {
        const fetch = await Fetcher({
            url: `${url}/${invoice_id}`,
            method: 'PUT',
            data,
            headers
        });
        // console.log("🚀 ~ file: InvoicePayments.js:73 ~ updateInvoicePayments ~ fetch:", fetch)
        if (fetch.status === STATUS_OK) {
            response = fetch.data.data;
        }
        return response
    } catch (error) {
        return response
    }
}

export const destroyInvoicePayments = async ({ headers, invoice_id }) => {
    let response = true;
    try {
        const fetch = await Fetcher({
            url: `${url}/${invoice_id}`,
            method: 'DELETE',
            headers
        });
        // console.log("🚀 ~ file: InvoicePayments.js:91 ~ destroyInvoicePayments ~ fetch:", fetch)
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    }

    return response;
}

export const uploadInvoicePayments = async ({ headers, data, params }) => {
    let response = false;
    try {
        const fetch = await Fetcher({
            url: `${url}/upload`,
            method: 'POST',
            data, headers, params
        });
        // console.log("🚀 ~ file: InvoicePayments.js:109 ~ uploadInvoicePayments ~ fetch:", fetch)
        if (fetch.status === CREATED) {
            response = fetch;
        }
    } catch (error) {
        response = error;
    }

    return response;

}

export const validateInvoicePayments = async ({ headers, data }) => {
    let response = false;
    try {
        const fetch = await Fetcher({
            url: `${url}/validate`,
            method: 'POST',
            data, headers
        });
        // console.log("🚀 ~ file: InvoicePayments.js:129 ~ validateInvoicePayments ~ fetch:", fetch)
        response = fetch;
    } catch (error) {
        response = error;
    }

    return response;


}

export const getBlobInvoicePaymentsPdf = async ({ headers, invoice_payment_id }) => {
    let response = { blob: null, cancel: () => { } };
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}/download/pdf/${invoice_payment_id}`,
            method: 'GET',
            responseType: "blob",
            headers
        });
        // console.log("🚀 ~ file: InvoicePayments.js:151 ~ getBlobInvoicePaymentsPdf ~ fetch:", fetch)

        if (fetch.status === STATUS_OK) {
            response.blob = new Blob([fetch.data], {
                type: fetch.data.type
            });
            response.cancel = cancel;
        }
        return response;;
    } catch (error) {
        return response;
    }
}

export const downloadInvoicePaymentsPdf = async ({ headers, invoice_payment_id, name, toast }) => {
    try {
        await downloadFile({
            headers,
            url: `${url}/download/pdf/${invoice_payment_id}`,
            type: 'application/json',
            name,
            toast
        });
        return true;
    } catch (error) {
        return false;
    }
}

export const downloadInvoicePaymentsXml = async ({ headers, invoice_payment_id, name, toast }) => {
    try {
        await downloadFile({
            headers,
            url: `${url}/download/xml/${invoice_payment_id}`,
            type: 'application/json',
            name,
            toast
        });
        return true;
    } catch (error) {
        return false;
    }
}