import React, { useRef } from 'react'
import MainRequisition from './fragments/Main'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { Heading } from '@chakra-ui/react';

const RequisitionsProvider = () => {
	const mainTableRef = useRef(null);
	const provider_id = useSelector(state => state.login.user.id);
	const [t] = useTranslation('requisition');
	const prefix = 'table.headers';

	const tKeysHeaders = [
		{ key: '#', value: '#' },
		{ key: 'folio', value: t(`${prefix}.folio`) },
		{ key: 'business_name.name', value: t(`${prefix}.business-name`) },
		{ key: 'segment.code++segment.name', value: t(`${prefix}.segment`) },
		// { key: 'description', value: t(`${prefix}.description`) },
		{ key: 'currency', value: t(`${prefix}.currency`) },
		{
			key: 'has_unit_prices', value: t(`${prefix}.status`),
			badges: [
				{ value: t(`${prefix}.completed`), color: '#1399a7' },
				{ value: t(`${prefix}.expired`), color: '#e6890b' },
				{ value: t(`${prefix}.pending`), color: 'gray' }
			]
		},
		{ key: 'ended_date', value: t(`${prefix}.ended-date`), dateFormat: 'LLL' },
		{ key: 'created_at', value: t(`${prefix}.created_at`), dateFormat: 'LLL' },
		{ key: null, value: t(`${prefix}.actions`) }
	];

	const tMomentDates = tKeysHeaders
		.map((element, index) => ({ index, format: element.dateFormat }))
		.filter(element => element.format);

	const badges = tKeysHeaders
		.map((element, index) => ({ values: { col: element.key, values: element.badges }, index: { index } }))
		.filter(element => element.values.values);

	const tactions = [
	];

	return (
		<Container fluid>
			<Heading>{t('title-provider')}</Heading>
			<MainRequisition
				ref={mainTableRef}
				type='provider'
				params={{ provider_id }}
				tHeaders={tKeysHeaders.map(element => element.value)}
				tKeys={tKeysHeaders.filter(element => element !== null).map(element => element.key)}
				tMomentDates={tMomentDates}
				badges={badges.map(badge => badge.index)}
				badges_values={badges.map(badge => badge.values)}
				tactions={tactions}
			/>
		</Container>
	)
}

export default RequisitionsProvider