import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Badge, Text, Tabs, Tab, TabList, TabPanel, TabPanels } from "@chakra-ui/react";
import ButtonIcon from "../../../Layouts/Forms/ButtonIcon";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Info from "./Info";
import History from "./History";
import { useTranslation } from "react-i18next";

const Details = (props) => {
    const [show, setShow] = useState(true);         // Modal view: Activa por default
    const { user, onClose, headers, fibra_id } = props;
    const [t] = useTranslation('global');
    const prefix = 'repse.users.modal-details';

    // Evento para cerrar modal
    const handleClose = () => {
        onClose(); setShow(false)
    }

    return (
        <Modal show={show} onHide={handleClose} size='xl' backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <Text fontSize="xl" fontWeight="bold">
                        {user.business_name}
                        <Badge ml="1" fontSize="0.8em" colorScheme="orange">Documentación incompleta</Badge>
                    </Text>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs variant="soft-rounded" colorScheme="blue">
                    <TabList>
                        <Tab>{t(`${prefix}.tabs.info`)}</Tab>
                        <Tab>{t(`${prefix}.tabs.history`)}</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <Info
                                headers={headers}
                                user={user}
                            />
                        </TabPanel>
                        <TabPanel>
                            <History
                                headers={headers}
                                user={user}
                                fibra_id={fibra_id}
                            />
                        </TabPanel>
                    </TabPanels>
                </Tabs>

            </Modal.Body>
            <Modal.Footer>
                <ButtonIcon icon={faArrowLeft} variant='outline-secondary' name={t(`${prefix}.button-close`)} onClick={handleClose} />
            </Modal.Footer>
        </Modal>
    );
}

export default Details;