import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertIcon, useToast } from '@chakra-ui/react';
import { faArrowLeft, faCheck, faFileExport, faFileImage, faTimes } from '@fortawesome/free-solid-svg-icons';
import { updateCedisDeliveryItems } from '../../../api/Cedis/CedisDeliveryItems';
import { downloadRFCedisDeliveries, downloadRMCedisDeliveries, showCedisDeliveries, updateCedisDeliveries } from '../../../api/Cedis/Deliveries';
import { updatePurchaseOrderItem } from '../../../api/PurchaseOrderItems';
import setToast from '../../../libs/SetToast';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import ModalComp from '../../Layouts/Modal';
import TabsComp from '../../Layouts/Tabs';
import Items from './Items';
import MainData from './MainData';
import Tracking from './Tracking';
import { useSelector } from 'react-redux';
import Photos from './Photos';

const ModalView = ({ onClose, event, headers }) => {
    const toast = useToast();
    const cedis_delivery_id = event.id;
    const user_id = useSelector(state => state.login.user.id);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [itemsDelivered, setItemsDelivered] = useState([]);

    useEffect(() => {
        getEventDetails();
    }, []);

    const getEventDetails = async () => {
        const response = await showCedisDeliveries({ headers, cedis_delivery_id });
        setData(response);
        setLoading(false);
    }
    // Evento que se lanza al marcar los items que re reciben
    const handleChange = async (checked, purchase_order_item_id) => {
        if (checked)    // Si el evento es marcado el item es entregado
            setItemsDelivered([...itemsDelivered, purchase_order_item_id]);
        else    // Si se desmarca se borra de la lista de entregados
            setItemsDelivered(itemsDelivered.filter((item) => item !== purchase_order_item_id));
    }
    // Botón de confirmar entrega, sólo se toman como recibidos los elementos marcados
    const acceptDelivery = async () => {
        try {
            if (itemsDelivered.length === 0) {
                setToast({
                    title: '¡No hay partidas seleccionadas!',
                    status: 'warning',
                    toast,
                    description: 'Debe seleccionar al menos una partida para continuar con esta operación.'
                });
                return;
            }

            await updateCedisDeliveries({
                headers,
                data: { status: 'delivered', receives_user_id: user_id },
                cedis_delivery_id: event.id
            });

            for (const item of itemsDelivered) {
                updateCedisDeliveryItems({
                    headers,
                    cedis_delivery_item_id: item,
                    data: { status: 'delivered' }
                });
                const purchaseOrderItemId = data.items.find(i => i.id === item)?.purchase_order_item_id;
                if (purchaseOrderItemId) {
                    updatePurchaseOrderItem({
                        headers,
                        data: { status: 'delivered' },
                        item_id: purchaseOrderItemId
                    });
                }
            }

            for (const item of data?.items || []) {
                if (!itemsDelivered.includes(item.id)) {
                    await updateCedisDeliveryItems({
                        headers,
                        cedis_delivery_item_id: item.id,
                        data: { status: 'undelivered' }
                    });
                    await updatePurchaseOrderItem({
                        headers,
                        data: { status: 'undelivered' },
                        item_id: item.purchase_order_item_id
                    });
                }
            }

            setToast({
                title: '¡Partidas recibidas!',
                status: 'success',
                toast,
                description: 'A partir de ahora ya puede facturar las partidas que han sido recibidas.'
            });

            onClose();
        } catch (error) {
            console.error('Error accepting delivery:', error);
            setToast({
                title: 'Error',
                status: 'error',
                toast,
                description: 'Ocurrió un error al procesar la entrega. Por favor, inténtelo de nuevo.'
            });
        }
    };

    // Botón de entrega rechazad, todos los items pasan a estado "no entregado"
    const rejectDelivery = async () => {
        try {
            await updateCedisDeliveries({
                headers,
                data: { status: 'rejected', receives_user_id: user_id },
                cedis_delivery_id: event.id
            });

            for (const item of data?.items || []) {
                if (!itemsDelivered.includes(item.id)) {
                    await updateCedisDeliveryItems({
                        headers,
                        cedis_delivery_item_id: item.id,
                        data: { status: 'undelivered' }
                    });
                    await updatePurchaseOrderItem({
                        headers,
                        data: { status: 'rejected' },
                        item_id: item.purchase_order_item_id
                    });
                }
            }

            setToast({
                title: '¡Entrega rechazada!',
                status: 'error',
                toast,
                description: 'Todas las partidas que esta entrega contiene han sido rechazadas.'
            });

            onClose();
        } catch (error) {
            console.error('Error rejecting delivery:', error);
            setToast({
                title: 'Error',
                status: 'error',
                toast,
                description: 'Ocurrió un error al rechazar la entrega. Por favor, inténtelo de nuevo.'
            });
        }
    };

    // Descargar Reporte de Recepción de Mercancía
    const downloadMerchandiseReception = async () => {
        const name = event?.code_rm;
        downloadRMCedisDeliveries({ headers, cedis_delivery_id, toast, name });
    }
    // Descarga Reporte Fotográfico
    const downloadPhotographicReport = async () => {
        const name = event?.code_rf;
        downloadRFCedisDeliveries({ headers, cedis_delivery_id, toast, name });
    }

    return (
        <ModalComp
            onClose={onClose}
            size='lg'
            title='Vista general'
            body={<Fragment>
                {
                    data?.status === 'delivered' &&
                    <Alert status='success' className='mb-2'><AlertIcon /> Esta entrega ya ha sido aceptada</Alert>
                }
                {
                    data?.status === 'rejected' &&
                    <Alert status='error' className='mb-2'><AlertIcon /> Esta entrega ya ha sido rechazada</Alert>
                }
                {
                    data?.status === 'undelivered' &&
                    <Alert status='warning' className='mb-2'><AlertIcon /> Esta entrega no ha sido recibida</Alert>
                }
                <TabsComp
                    tablist={['Información general', 'Partidas', 'Evidencias', 'Seguimiento']}
                    tabpanels={[
                        <MainData
                            key='main-key'
                            loading={loading}
                            data={data} />,
                        <Items
                            key='items-key'
                            items={data?.items || []}
                            loading={loading}
                            handleChange={handleChange}
                            itemsDelivered={itemsDelivered} />,
                        <Photos
                            key='photos-key'
                            headers={headers}
                            items={data?.items || []}
                            loading={loading}
                            cedis_delivery_id={event.id}
                            syncServer={getEventDetails}
                        />,
                        <Tracking
                            key='tracking-key'
                            tracking_items={data?.tracking || []} />
                    ]}
                />
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name='Cerrar' icon={faArrowLeft} variant='outline-secondary' onClick={onClose} />
                <ButtonIcon name='Recepción de Mercancía' icon={faFileExport} variant='outline-primary' onClick={downloadMerchandiseReception} disabled={data?.status !== 'delivered'} />
                <ButtonIcon name='Reporte Fotográfico' icon={faFileImage} variant='outline-primary' onClick={downloadPhotographicReport} disabled={data?.status !== 'delivered'} />
                <ButtonIcon name='Rechazar entrega' icon={faTimes} variant='outline-danger' onClick={rejectDelivery} disabled={data?.status !== 'pending'} />
                <ButtonIcon name='Aceptar entrega' icon={faCheck} variant='outline-success' onClick={acceptDelivery} disabled={data?.status !== 'pending'} />
            </Fragment>}
        />
    );
};

ModalView.propTypes = {
    onClose: PropTypes.func.isRequired,
    event: PropTypes.shape({
        id: PropTypes.number.isRequired,
        code_rm: PropTypes.string.isRequired,
        code_rf: PropTypes.string.isRequired,
    }).isRequired,
    headers: PropTypes.string.isRequired
}

export default ModalView;
