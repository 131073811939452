import PropTypes from 'prop-types';
import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { Form, Row } from "react-bootstrap";
import { storeRole, updateRole } from "../../../api/Roles";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import FormGroupComponent from "../../Layouts/Forms/FormGroup";
import ModalComp from "../../Layouts/Modal";
import useForm from "../../../hooks/useForm";
import { useTranslation } from "react-i18next";
import { Fragment } from 'react';
import Swal from 'sweetalert2';

const ModalRoles = ({ onClose, title, role, type, headers, fibra, addElement, updateElement }) => {
    const [t] = useTranslation('settings');
    const p = 'roles.modal';
    const { form, handleChange } = useForm();

    const updateOrCreate = () => {
        if (type === 'update') {
            updateRow();
        } else {
            storeRow();
        }
    }
    // Actualizar 
    const updateRow = async () => {
        const response = await updateRole({ headers, data: { ...form, fibra_id: fibra.id }, role_id: role.id });
        if (response) {
            updateElement(response);
            onClose();
            Swal.fire(t(`${p}.sf-update.title`), t(`${p}.sf-update.msg`), 'success');
        } else {
            Swal.fire(t(`${p}.sf-fail.title`), t(`${p}.sf-fail.msg`), 'info');
        }
    }
    // Crear
    const storeRow = async () => {
        const response = await storeRole({ headers, data: { ...form, code: 'ADMINISTRADOR', fibra_id: fibra.id } });
        if (response) {
            addElement(response);
            onClose();
            Swal.fire(t(`${p}.sf-store.title`), t(`${p}.sf-store.msg`), 'success');
        } else {
            Swal.fire(t(`${p}.sf-fail.title`), t(`${p}.sf-fail.msg`), 'info');
        }
    }

    return (
        <ModalComp
            onClose={onClose}
            title={title}
            body={<Form>
                <Row className='mb-2'>
                    <FormGroupComponent
                        title={t(`${p}.name`)}
                        defaultValue={role.name}
                        name='name'
                        value={form?.name}
                        readOnly={type === 'details'}
                        handleChange={handleChange} />
                </Row>
                <Row className='mb-2'>
                    <FormGroupComponent
                        title={t(`${p}.description`)}
                        defaultValue={role.description}
                        name='description'
                        value={form?.description}
                        readOnly={type === 'details'}
                        handleChange={handleChange} />
                </Row>
            </Form>}
            footer={<Fragment>
                <ButtonIcon icon={faArrowLeft} name={t(`${p}.close`)} variant='outline-secondary' onClick={onClose} />
                {
                    type !== 'details' ?
                        <ButtonIcon icon={faSave} name={t(`${p}.save`)} onClick={updateOrCreate} /> : null
                }
            </Fragment>}
        />
    );
}

ModalRoles.propTypes = {
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    role: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    }).isRequired,
    type: PropTypes.string.isRequired,
    headers: PropTypes.shape({
        Authorization: PropTypes.string.isRequired
    }).isRequired,
    fibra: PropTypes.shape({
        id: PropTypes.number.isRequired
    }).isRequired,
    addElement: PropTypes.func.isRequired,
    updateElement: PropTypes.func.isRequired,
}

export default ModalRoles;