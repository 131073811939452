import { faTimes } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ButtonIcon from '../../Layouts/Forms/ButtonIcon'
import FormIcon from '../../Layouts/Forms/FormIcon'
import ModalComp from '../../Layouts/Modal'

const ModalLogsDetails = (props) => {
    const { onClose, logSel } = props;
    const [t] = useTranslation('global');
    const prefix = 'super-admin.logs';
    return (
        <ModalComp
            title={t(`${prefix}.modal-title`)}
            size={'md'}
            onClose={onClose}
            body={<>
                <Form>
                    <Row className='mb-2'>
                        <FormIcon title={t(`${prefix}.user-name`)} name='user_name' value={logSel?.user_name} />
                        <FormIcon title={t(`${prefix}.user-email`)} name='user_email' value={logSel?.user_email} />
                    </Row>
                    <Row className='mb-2'>
                    </Row>
                    <Row className='mb-2'>
                        <FormIcon title={t(`${prefix}.platform-name`)} name='platform_name' value={logSel?.platform_name} />
                        <FormIcon title={t(`${prefix}.platform-version`)} name='platform_version' value={logSel?.platform_version} />
                    </Row>
                    <Row className='mb-2'>
                        <FormIcon title={t(`${prefix}.device-type`)} name='device_type' value={logSel?.device_type} />
                        <FormIcon title={t(`${prefix}.device-model`)} name='device_model' value={logSel?.device_model} />
                    </Row>
                    <Row className='mb-2'>
                    </Row>
                    <Row className='mb-2'>
                        <FormIcon title={t(`${prefix}.browser-name`)} name='browser_name' value={logSel?.browser_name} />
                    </Row>
                    <Row className='mb-2'>
                        <FormIcon title={t(`${prefix}.created-at`)} name='created_at' value={logSel?.created_at} />
                    </Row>
                </Form>
            </>}
            footer={<>
                <ButtonIcon name={t(`${prefix}.modal-close`)} icon={faTimes} onClick={onClose} variant='outline-secondary' />
            </>}
        />
    )
}

export default ModalLogsDetails