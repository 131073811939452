export const stateLogin = {
    session: null,
    token: null,
    apps: null,
    roles: null,
    role_selected: null,
    modules: null,
    user: null,
    fibra: null
}

export const stateTabActive = 'home';

export const stateCookies = false;

export const stateUsers = {};

export const stateCfdiStatuses = {};

export const stateChat = {};

export const stateRequisitions = {
    data: [],
    totalItems: 0,
    page: 1,
    pageSize: 10,
    filters: {
        
    }
}

export const statePurchaseOrders = {
    data: [],
    totalItems: 0,
    page: 1,
    pageSize: 10,
    filters: {
        
    }
}