import { Fragment } from 'react'

const ProviderHome = () => {
	const dashboard = 'https://metabase.victum-re.online/public/dashboard/5df17e4b-7381-4952-b898-f5d3285ce975';

	return (
		<Fragment>
			<iframe
				src={dashboard}
				frameborder="0"
				width="100%"
				height="900"
				allowtransparency
			></iframe>
		</Fragment>
	)
}

export default ProviderHome;