import downloadFile from "../../libs/DownloadFile";
import Fetcher from "../../libs/Petition"

const url = '/requisitions';
const CREATED = 201;
const STATUS_OK = 200;

export const indexRequisitions = async ({ headers, fibra_id, provider_id, page, pageSize, ...anotherParams }) => {

    let response = { data: [], totalItems: 0 };
    const { segment_id, business_name_id, start_date, end_date, status, segment_ids, business_name_ids, status_po, status_pr } = anotherParams;

    try {
        // Parámetros requeridos
        let params = { fibra_id };
        // Parámetros opcionales
        if (page) params.page = page;
        if (pageSize) params.pageSize = pageSize;
        if (provider_id) params.provider_id = provider_id;
        if (segment_id !== '-1') params.segment_id = segment_id;
        if (business_name_id !== '-1') params.business_name_id = business_name_id;
        if (start_date) params.start_date = start_date;
        if (end_date) params.end_date = end_date;
        if (status !== '-1') params.status = status;
        if(segment_ids) params.segment_ids = segment_ids;
        if(business_name_ids) params.business_name_ids = business_name_ids;
        if (status_po !== '-1') params.status_po = status_po;
        if (status_pr !== '-1') params.status_pr = status_pr;
        const fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        // console.log("🚀 ~ file: requisitions.js:28 ~ indexRequisitions ~ fetch:", fetch)

        if (fetch.status === STATUS_OK)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}

export const showRequisition = async ({ headers, requisition_id }) => {
    let response = {};
    try {
        let fetch = await Fetcher({
            url: `${url}/${requisition_id}`,
            method: 'GET',
            headers
        });
        // console.log("🚀 ~ file: requisitions.js:46 ~ showRequisition ~ fetch:", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = {};
    }
    return response;
}

export const storeRequisition = async ({ headers, data }) => {
    let response = false;
    try {
        const fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        // console.log("🚀 ~ file: requisitions.js:63 ~ storeRequisition ~ fetch:", fetch)
        if (fetch.status === CREATED)
            response = fetch.data;
    } catch (error) {
        response = false;
    }
    return response;

}

export const updateRequisition = async ({ headers, data, requisition_id }) => {
    let response = false;
    try {
        const fetch = await Fetcher({
            url: `${url}/${requisition_id}`,
            method: 'PUT',
            data,
            headers
        });
        // console.log("🚀 ~ file: requisitions.js:85 ~ updateRequisition ~ fetch:", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = false;
    }
    return response;

}

export const destroyRequisition = async ({ headers, requisition_id }) => {
    let response = false;
    try {
        const fetch = await Fetcher({
            url: `${url}/${requisition_id}`,
            method: 'DELETE',
            headers
        });

        // console.log("🚀 ~ file: requisitions.js:106 ~ destroyRequisition ~ fetch:", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}

export const downloadRequisitionPdf = async ({ headers, requisition_id, name, toast }) => {
    try {
        await downloadFile({
            headers,
            url: `${url}/download/pdf/${requisition_id}`,
            type: 'application/json',
            name,
            toast
        });
        return true;
    } catch (error) {
        return false;
    }
}