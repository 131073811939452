import { useState, useEffect } from "react";
import { useStore } from 'react-redux';
import { Container, Table } from "react-bootstrap";
import { Badge } from "@chakra-ui/react";
import SkeletonTable from "../../Layouts/Skeletons/Table";
import NoData from "../../Layouts/NoData";
import Fetcher from "../../../libs/Petition";
import { useTranslation } from "react-i18next";

const CarParking = (props) => {
    const store = useStore();
    const [loading, setLoading] = useState(true);
    const [car_parks, setCarParks] = useState([]);
    const [t] = useTranslation('global');
    const prefix = 'malls.car-parking';

    useEffect(() => {
        let { token } = store.getState().login;
        let headers = { Authorization: `Bearer ${token}` };
        getCarParkings(headers);
    }, [props]);

    const getCarParkings = async (headers) => {
        setLoading(true);
        try {
            let response = await Fetcher({
                method: 'GET',
                url: `/car_parks`,
                params: { parking_lot_id: props.parking_lot.id },
                headers
            });
            setCarParks(response.data.data);
        } catch (error) {
            setCarParks([]);
        }
        setLoading(false);
    }

    return (
        <Container>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <td>#</td>
                        <th>{t(`${prefix}.table.code`)}</th>
                        <th>{t(`${prefix}.table.status`)}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        !loading ?
                            car_parks && car_parks.map((car_park, index) =>
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{car_park.code}</td>
                                    <td>{
                                        car_park.taken ?
                                            <Badge variant="outline" colorScheme="red">{t(`${prefix}.statuses.occupied`)}</Badge> :
                                            <Badge variant="outline" colorScheme="green">{t(`${prefix}.statuses.available`)}</Badge>
                                    }</td>
                                </tr>
                            ) : <SkeletonTable tr={5} td={3} />
                    }
                </tbody>
            </Table>
            {/* Mostrar icono de NODATA en caso de no encontrar registros */}
            {(!car_parks || car_parks.length === 0) && !loading ? <NoData /> : null}
        </Container>
    );
}

export default CarParking;