import { useTranslation } from "react-i18next";
import TableComp from "../../Layouts/Table";

const Recipients = (props) => {
    const {
        loading,
        roles,
        handleChange,
        getChecked,
        getDisabled
    } = props;

    const [t] = useTranslation('global');
    const prefix = 'releases.data';

    return (
        <TableComp
            headers={[
                '#', 
                t(`${prefix}.code`), 
                t(`${prefix}.role`),
                t(`${prefix}.send`),
            ]}
            keys={['#', 'code', 'name']}
            body={roles}
            loading={loading}
            type="switch"
            actions={{
                checkedChildren: t(`${prefix}.checked`),
                unCheckedChildren: t(`${prefix}.unchecked`),
                onChange: handleChange,
                getChecked: getChecked,
                getDisabled: getDisabled
            }}
        />
    );
}
export default Recipients;