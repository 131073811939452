import { Table } from "react-bootstrap";
import PropTypes from 'prop-types';
import ButtonIcon from "./Forms/ButtonIcon";
import SkeletonTable from "./Skeletons/Table";
import { Switch, Tag } from 'antd';
import NoData from "./NoData";
import { Fragment } from "react";
import moment from "moment";
import { getCurrency, getKmFormat, getPercentaje } from "../../libs/functions";

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` } 
 * @param body Contiene el array para llenar el body de la tabla
 * @param keys Es un arreglo que contiene los indices que se van a utilizar del body
 * @param actions Es un array de objetos, estos objetos son dinámicos ya que pueden ser atributos para distintos componentes (switch, buttons, etc)
 * @param loading Es un dato booleano que indica si ya se terminó de obtener el body, para mostrar los estados de carga
 * @param type [Opcional] Indica que tipo de componente se va a utilizar en las acciones, default buttons
 * @param loadingItems [Opcional] Cantidad de tuplas que se mostrarán en el Skeleton, default 5 
 * @param page [Opcional] Página actual para calcular el index del los elementos sin importar en qué paǵina se encuentren
 * @param badges [Opcional] Array [{index:[indice de la columna], color:[indica el nombre de la tabla que tiene el color que se utiliza para la celda actual]}] que indica 
 * @param badges_values [Opcional] Object{values: [Arreglo de valores asociados a los colores que van a adquirir], col: [key que va a tener badge]} 
 * @param currency [Opcional] Array que indica las columnas que tendrán formato de moneda
 * @param icons [Opcional] Array de objetos que indica en que valores se reemplazará por un ícono
 * @param percentage [Opcional] Arreglo de {index: 'ïndice del key sobre el que se aplicará el formato', accuracy: 'Precisión'}
 * @param km [Opcional] Arreglo de {index: 'Índice del key sobre el que se aplicará el formato'}
 * @returns Table
 */
const TableComp = (props) => {
    const {
        headers,
        body,
        keys,
        actions,
        loading,
        type,
        loadingItems,       // PageSize
        page,               // Página actual
        badges,
        badges_values,
        currency,
        icons,
        percentage,         // Índices que irán con formato de porcentaje
        km,                 // Índices que irán en formato 000+000
        moment_dates,       // Índices que irán en formato de fecha
        noActions = false
    } = props;
    // Este método sirve para renderizar el contenido de las acciones
    const renderSwitch = (item) => {
        // Se pueden agregar más componentes
        switch (type) {
            case 'switch': // Deslizar para activar o desactivar
                const {
                    checkedChildren,
                    unCheckedChildren,
                    onChange,
                    getChecked,
                    getDisabled
                } = actions;

                return <Switch
                    checkedChildren={checkedChildren || 'True'}
                    unCheckedChildren={unCheckedChildren || 'False'}
                    onChange={(checked) => onChange(checked, item.id)}
                    defaultChecked={() => getChecked(item.id)}
                    disabled={getDisabled(item.id)}
                />
            case 'custom':
                return actions.renderActions(item);
            default: // Por default recorrer array de botones
                let new_actions = actions.filter((action) => {
                    // Si trae la propiedad dinamic se va a mostrar sólo si cumple las condiciones
                    if (action.dinamic) {
                        for (const element of action.dinamic) {
                            // Se obtiene el key y value que se van a comparar con la propiedad item
                            // Ejempo: Si key:'payment_method' y value: 'PPD', se tiene que buscar que item.payment_method === 'PPD', 
                            // de lo contrario no muestra el botón
                            let { key, value } = element;
                            if (item[key] !== value)
                                return false;
                        }
                    }
                    // Se evalua que el item puede adquirir mas de un valor
                    if (action.dinamics) {
                        for (const element of action.dinamics) {
                            let { key, values } = element;
                            if (!values.includes(item[key]))
                                return false;
                        }
                    }
                    if (action.showWhenNotNull) {
                        for (const key of action.showWhenNotNull) {
                            const cellValue = getCellValue(key, item);
                            if (!cellValue) {
                                return false;
                            }
                        }
                    }

                    if (action.showWhenNull) {
                        for (const key of action.showWhenNull) {
                            const cellValue = getCellValue(key, item);
                            if (cellValue) {
                                return false;
                            }
                        }
                    }

                    return true;
                });
                return new_actions.map((action, a_index) => {
                    let disabled = false;
                    if (action.disabled) disabled = true;
                    if (action.getDisabled) disabled = action.getDisabled(item)
                    return <ButtonIcon
                        key={a_index}
                        name={action.name}
                        onClick={() => action.handleClick(item)}
                        icon={action.icon}
                        tooltip={action.tooltip}
                        variant={action.variant}
                        disabled={disabled}
                    />
                })
        }
    }

    /**
     * 
     * @param {Columna en la que se va a asignar el valor} index_col 
     * @param {Objeto que se está evaluando} item 
     * @param {Tupla que se está recorriendo} index_row 
     * @returns {Celda que se va a poner en la posición [index_col, index_row]}
     */
    const setBadge = (index_col, item, index_row) => {
        let cell_changes = true;                                    // True para ejecutar formatos de moneda y badges, false si la celda se convierte en ícono
        let pos = -1;
        let pos_c = -1;
        let pos_p = -1;
        let pos_km = -1;
        let pos_dates = -1;
        if (badges && badges.length > 0) {
            pos = badges.findIndex((element) => element.index === index_col);           // Busca si la celda tiene que estar en un badge
        }
        if (currency && currency.length > 0)
            pos_c = currency.findIndex((element) => element.index === index_col);         // Busca si la celda debe tener formato de mnoneda
        if (percentage && percentage.length > 0)
            pos_p = percentage.findIndex((element) => element.index === index_col);       // Busca si la celda debe tener formato de porcentaje
        if (km && km.length > 0)
            pos_km = km.findIndex((element) => element.index === index_col);              // Busca si la celda debe tener formato de Cadenamiento 000+000  
        if (moment_dates && moment_dates.length > 0)
            pos_dates = moment_dates.findIndex((element) => element.index === index_col); // Busca si la celda debe tener formato de fecha 
        let cell = '';
        if (keys[index_col] === '#') {                              // Si la key es #, se tomará el valor index de map
            cell = index_row + 1;
            if (page && loadingItems)                               // Si recibe como parámetros page y loadingItems podemos crear un index para cada tupla 
                cell = (page - 1) * loadingItems + cell;            // Agregamos la cantidad correspondiente al tamaño de página y página actual
        } else {
            cell = getCellValue(keys[index_col], item);                 // Se obtiene el valor de la celda
            let pos_i = -1;
            if (icons && icons.length > 0)
                pos_i = icons.findIndex((item) => item.value === cell);      // Buscamos si el contenido se tiene que reemplazar con un ícono
            if (pos_i !== -1) {                                     // Si el contenido si se reemplaza
                cell = icons[pos_i].icon;                           // Asignamos el icono que viene en el arreglo
                cell_changes = false
            }
        }
        if (cell_changes) {                                             // Ejecutar cambios de moneda y badges
            if (pos_dates !== -1) {
                cell = getMomentDate(cell, moment_dates[pos_dates].format);
            }
            if (pos_c !== -1) {                                         // Si la celda debe tener formato de moneda
                cell = getCurrency(cell);                               // Asigna el valor obtenido 
            }
            if (pos_p !== -1) {
                cell = getPercentaje(cell, percentage[pos_p].accuracy);
            }
            if (pos_km !== -1)
                cell = getKmFormat(cell);
            if (pos !== -1) {                                           // Si hay badges
                let color;                                              // Dark by default
                if (badges_values) {
                    color = getColor(item, keys[index_col]);
                    cell = getCellName(item, keys[index_col]);
                } else {
                    color = item[badges[pos].color];                    // Obtiene el color
                }
                cell = <Tag color={color}>{cell}</Tag>;                 // [Ant Design] Cambia contenido a bagde
            }
        }
        return (
            <td key={index_col}><center>{cell}</center></td>
        );
    }

    // obtiene el formato de la fecha, si falla al convertir retorna el valor de la celda
    const getMomentDate = (cell, format) => {
        try {
            // Verificar si cell es una fecha válida
            if (moment(cell).isValid()) {
                // Formatear la fecha utilizando el formato especificado
                return moment(cell).format(format);
            } else {
                // Si cell no es una fecha válida, devolver cell sin formato
                return cell;
            }
        } catch (error) {
            // Si hay un error al formatear la fecha, devolver cell sin formato
            return cell;
        }
    }

    const getCellValue = (value, item) => {
        try {
            const arrayPlus = value.includes('++') ? value.split('++') : value.split('+');
            let response = '';

            for (const string of arrayPlus) {
                let cell2 = item;

                const arrayDocs = string.split('.');
                // Usamos chaining opcional para manejar valores nulos o indefinidos
                for (const val of arrayDocs) {
                    cell2 = cell2?.[val];
                    if (cell2 === undefined || cell2 === null) {
                        cell2 = '';
                        break;
                    }
                }
                // Agregamos un espacio solo cuando está presente '++'
                response += cell2 + (value.includes('++') ? ' ' : '');
            }

            return response.trim();
        } catch (error) {
            return item[value];
        }
    };
    // Obtiene el color del badge 
    const getColor = (item, key) => {
        let color = 'purple';
        try {
            let pos_badges = -1;
            if (badges_values && badges_values.length > 0)
                pos_badges = badges_values.findIndex((item) => item.col === key);
            if (pos_badges !== -1) {
                const current_values = badges_values[pos_badges].values;
                const current = current_values.find((badge) => badge.value == getCellValue(key, item))
                color = current.color
            }
            return color;
        } catch (error) {
            return color;
        }
    }
    // Obtener el nombre si viene en el badge, de lo contrario dejar el de la tupla
    const getCellName = (item, key) => {
        let response = getCellValue(key, item);

        try {
            const pos_badges = badges_values.findIndex(badge => badge.col === key);

            if (pos_badges !== -1) {
                const value = item[badges_values[pos_badges].col];
                const pos = badges_values[pos_badges].values.findIndex(badgeValue => badgeValue.value === value);

                if (pos !== -1 && badges_values[pos_badges].values[pos].name) {
                    response = badges_values[pos_badges].values[pos].name;
                }
            }

            return response;
        } catch (error) {
            return response;
        }
    };

    return (
        <Fragment>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        {   // Recorremos el array headers y los agregamos al encabezado
                            headers.map((theader, index) =>
                                <th key={index}>{theader}</th>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {   // El componente va a mostrar el skeleton table hasta que reciba un false en el loading
                        loading ? <SkeletonTable tr={loadingItems || 5} td={headers.length} /> :
                            //Se recorre el arrar de objetos body para rellenar la tabla
                            body && body.map((item, index_row) =>
                                // Se recorre el número de encabezados para crear las columnas. Nota Se resta 1 para poner las acciones en otra columna
                                <tr key={index_row}>
                                    {
                                        Array.from({ length: noActions ? headers.length : headers.length - 1 }).map((_, index_col) =>
                                            // Se inserta el valor del item  en base a la key y su index_col 
                                            setBadge(index_col, item, index_row)
                                        )
                                    }
                                    {
                                        !noActions &&
                                        <td>{actions ? renderSwitch(item) : null}</td>
                                    }
                                </tr>
                            )
                    }
                </tbody>
            </Table>
            {/* Si ya no está cargando y el body es vacio */}
            {
                !loading && (body?.length === 0 || !body) ? <NoData /> : null
            }
        </Fragment>
    );
}

TableComp.propTypes = {
    headers: PropTypes.array,       // Título de los encabezados
    body: PropTypes.array,          // Objeto que contiene los datos del body de la tabla
    keys: PropTypes.array,          // Keys del objeto que se muestran en la tabla. Nota: poner en orden de los encabezados
    // Botones o componenetes que van en la sección de acciones
    actions: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.shape({
                dinamic: PropTypes.array,
                dinamics: PropTypes.array,
                showWhenNotNull: PropTypes.array,
                showWhenNull: PropTypes.array,
                name: PropTypes.string,
                handleClick: PropTypes.func,
                icon: PropTypes.object,
                tooltip: PropTypes.string,
                variant: PropTypes.string,
                disabled: PropTypes.bool,
            })
        ),
        PropTypes.shape({
            checkedChildren: PropTypes.func,
            unCheckedChildren: PropTypes.func,
            onChange: PropTypes.func,
            getChecked: PropTypes.func,
            getDisabled: PropTypes.func,
        })
    ]),
    loading: PropTypes.bool,        // Indica si la información se sigue obteniendo
    loadingItems: PropTypes.number, // Cantidad de tuplas que se muestran al cargar
    type: PropTypes.oneOf(['switch', 'btn_din', undefined]),         // Indica que tipo de control llevan las acciones, defalt button
    badges: PropTypes.arrayOf(      // Indica si el contenido de la celda va dentro de un badge
        PropTypes.shape({
            index: PropTypes.number.isRequired,     // indica la posición en la que pintará el badge
            color: PropTypes.string.isRequired      // indica el nombre del atributo del que se tomará el color
        })
    ),
    badges_values: PropTypes.arrayOf(
        PropTypes.shape({
            values: PropTypes.arrayOf(
                PropTypes.shape({
                    color: PropTypes.string,
                    value: PropTypes.any,
                    name: PropTypes.string
                })
            ),
            col: PropTypes.string
        })
    ),
    currency: PropTypes.arrayOf(
        PropTypes.shape({
            index: PropTypes.number.isRequired
        })
    ),
    icons: PropTypes.array,
    page: PropTypes.number,
    percentage: PropTypes.arrayOf(
        PropTypes.shape({
            index: PropTypes.number.isRequired,
            accuracy: PropTypes.number.isRequired
        })
    ),
    km: PropTypes.arrayOf(
        PropTypes.shape({
            index: PropTypes.number.isRequired,
        })
    ),
    moment_dates: PropTypes.arrayOf(
        PropTypes.shape({
            index: PropTypes.number.isRequired,
            format: PropTypes.string.isRequired
        })
    ),
    noActions: PropTypes.bool
}

export default TableComp;