import React from 'react'
import PropTypes from 'prop-types'
import ModalComp from '../../../Layouts/Modal'
import ButtonIcon from '../../../Layouts/Forms/ButtonIcon'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Descriptions, Tag } from 'antd'
import TabsComp from '../../../Layouts/Tabs'
import { getCurrency } from '../../../../libs/functions'
import { useTranslation } from 'react-i18next'

const moment = require('moment');

const ModalDetails = ({ onClose, payment }) => {
    const [t] = useTranslation('invoice_payments');
    return (
        <ModalComp
            title={payment.folio}
            onClose={onClose}
            size={'lg'}
            body={
                <TabsComp
                    tablist={[t('details.title-1'), t('details.title-2')]}
                    tabpanels={[
                        <Descriptions
                            bordered
                            key={'invoice_payments_key'}
                            column={{ lg: 4, md: 2, sm: 2, xs: 1 }}
                        >
                            <Descriptions.Item label={t('details.id')} span={2}>{payment?.id}</Descriptions.Item>
                            <Descriptions.Item label={t('details.folio')} span={2}>{payment?.folio}</Descriptions.Item>
                            <Descriptions.Item label={t('details.uuid')} span={4}>{payment?.uuid}</Descriptions.Item>
                            <Descriptions.Item label={t('details.previous_balance')} span={2}>{getCurrency(payment?.previous_balance)}</Descriptions.Item>
                            <Descriptions.Item label={t('details.amount_paid')} span={2}>{getCurrency(payment?.amount_paid)}</Descriptions.Item>
                            <Descriptions.Item label={t('details.outstanding_balance')} span={2}>{getCurrency(payment?.outstanding_balance)}</Descriptions.Item>
                            <Descriptions.Item label={t('details.payment_number')} span={2}>{payment?.payment_number}</Descriptions.Item>
                            <Descriptions.Item label={t('details.emission_date')} span={2}>{payment?.emission_date}</Descriptions.Item>
                            <Descriptions.Item label={t('details.stamp_date')} span={2}>{payment?.stamp_date}</Descriptions.Item>
                            <Descriptions.Item label={t('details.rfc_issuing')} span={2}>{payment?.rfc_issuing}</Descriptions.Item>
                            <Descriptions.Item label={t('details.name_issuing')} span={2}>{payment?.name_issuing}</Descriptions.Item>
                            <Descriptions.Item label={t('details.rfc_receptor')} span={2}>{payment?.rfc_receptor}</Descriptions.Item>
                            <Descriptions.Item label={t('details.name_receptor')} span={2}>{payment?.name_receptor}</Descriptions.Item>
                            <Descriptions.Item label={t('details.created_at')} span={2}>{moment(payment?.created_at).format('LLLL')}</Descriptions.Item>
                            <Descriptions.Item label={t('details.updated_at')} span={2}>{moment(payment?.updated_at).format('LLLL')}</Descriptions.Item>
                        </Descriptions>,
                        <Descriptions
                            bordered
                            key={'invoices_key'}
                            column={{ xl: 4, lg: 4, md: 2, sm: 2, xs: 1 }}
                        >
                            <Descriptions.Item label={t('details.invoice.id')} span={2}>{payment?.invoice?.id}</Descriptions.Item>
                            <Descriptions.Item label={t('details.invoice.folio')} span={2}>{payment?.invoice?.folio}</Descriptions.Item>
                            <Descriptions.Item label={t('details.invoice.status_cfdi')} span={4}><Tag color={payment?.invoice?.color}>{payment?.invoice?.cfdi_status}</Tag></Descriptions.Item>
                            <Descriptions.Item label={t('details.invoice.uuid')} span={4}>{payment?.invoice?.uuid}</Descriptions.Item>
                            <Descriptions.Item label={t('details.invoice.payment_method')} span={2}>{payment?.invoice?.payment_method}</Descriptions.Item>
                            <Descriptions.Item label={t('details.invoice.payment_conditions')} span={2}>{payment?.invoice?.payment_conditions}</Descriptions.Item>
                            <Descriptions.Item label={t('details.invoice.way_to_pay')} span={2}>{payment?.invoice?.way_to_pay}</Descriptions.Item>
                            <Descriptions.Item label={t('details.invoice.currency')} span={3}>{payment?.invoice?.currency}</Descriptions.Item>
                            <Descriptions.Item label={t('details.invoice.subtotal')} span={2}>{getCurrency(payment?.invoice?.subtotal)}</Descriptions.Item>
                            <Descriptions.Item label={t('details.invoice.total')} span={2}>{getCurrency(payment?.invoice?.total)}</Descriptions.Item>
                            <Descriptions.Item label={t('details.invoice.emission_date')} span={2}>{payment?.invoice?.emission_date}</Descriptions.Item>
                            <Descriptions.Item label={t('details.invoice.stamp_date')} span={2}>{payment?.invoice?.fecha_timbrado}</Descriptions.Item>
                            <Descriptions.Item label={t('details.invoice.rfc_issuing')} span={2}>{payment?.invoice?.rfc_issuing}</Descriptions.Item>
                            <Descriptions.Item label={t('details.invoice.name_issuing')} span={2}>{payment?.invoice?.nombre_emisor}</Descriptions.Item>
                            <Descriptions.Item label={t('details.invoice.rfc_receptor')} span={2}>{payment?.invoice?.rfc_receptor}</Descriptions.Item>
                            <Descriptions.Item label={t('details.invoice.name_receptor')} span={2}>{payment?.invoice?.nombre_receptor}</Descriptions.Item>
                            <Descriptions.Item label={t('details.invoice.created_at')} span={2}>{moment(payment?.invoice?.created_at).format('LLLL')}</Descriptions.Item>
                            <Descriptions.Item label={t('details.invoice.updated_at')} span={2}>{moment(payment?.invoice?.updated_at).format('LLLL')}</Descriptions.Item>
                        </Descriptions>
                    ]}
                />
            }
            footer={
                <ButtonIcon name={t('details.close')} icon={faTimes} variant={'outline-secondary'} onClick={onClose} />
            }
        />
    )
}

ModalDetails.propTypes = {
    onClose: PropTypes.func.isRequired,
    payment: PropTypes.shape({
        id: PropTypes.number.isRequired,
        folio: PropTypes.string.isRequired,
        uuid: PropTypes.string.isRequired,
        previous_balance: PropTypes.number.isRequired,
        amount_paid: PropTypes.number.isRequired,
        outstanding_balance: PropTypes.number.isRequired,
        payment_number: PropTypes.number,
        emission_date: PropTypes.string,
        stamp_date: PropTypes.string,
        rfc_issuing: PropTypes.string,
        rfc_receptor: PropTypes.string,
        name_issuing: PropTypes.string,
        name_receptor: PropTypes.string,
        created_at: PropTypes.string,
        updated_at: PropTypes.string,
        invoice: PropTypes.shape({
            id: PropTypes.number.isRequired,
            folio: PropTypes.string.isRequired,
            uuid: PropTypes.string.isRequired,
            color: PropTypes.string,
            cfdi_status: PropTypes.string,
            payment_method: PropTypes.string,
            payment_conditions: PropTypes.string,
            way_to_pay: PropTypes.string,
            currency: PropTypes.string,
            subtotal: PropTypes.number,
            total: PropTypes.number,
            fecha_timbrado: PropTypes.string,
            emission_date: PropTypes.string,
            rfc_issuing: PropTypes.string,
            nombre_emisor: PropTypes.string,
            rfc_receptor: PropTypes.string,
            nombre_receptor: PropTypes.string,
            created_at: PropTypes.string,
            updated_at: PropTypes.string,
        }).isRequired
    }).isRequired
}

export default ModalDetails