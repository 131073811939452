import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import TableComp from '../../Layouts/Table'
import PaginationComp from '../../Layouts/Pagination'
import ButtonIcon from '../../Layouts/Forms/ButtonIcon'
import { Col, Row } from 'antd'
import FormIcon from '../../Layouts/Forms/FormIcon'
import usePagination from '../../../hooks/usePagination'
import usePreview from '../../../hooks/usePreview'
import useForm from '../../../hooks/useForm'
import { useTranslation } from 'react-i18next'
import { useToast } from '@chakra-ui/react'
import { downloadPaymentOrders, getBlobPaymentOrders, indexPaymentOrders, storePaymentOrders } from '../../../api/PaymentOrders'
import Swal from 'sweetalert2'
import { getPercentaje } from '../../../libs/functions'
import { faEdit, faEye, faFileCode, faFileInvoiceDollar, faFileUpload, faPlus } from '@fortawesome/free-solid-svg-icons'
import ShowFile from './ShowFile'
import useConfirmation from '../../../hooks/useConfirm'
import { uploadPurchaseOrderVouchers } from '../../../api/PurchaseOrderVouchers'
import useLoading from '../../../hooks/useLoading'
import { hookPaginationPropType } from '../../../data/propTypes'
import UploadInvoiceModal from './UploadInvoice'
import EditPercentage from './EditPercentage'

const PaymentOrders = ({ headers, purchase_order_id, hookVouchers, isProvider, purchaseOrder }) => {
    const inputFile = useRef(null);
    const toast = useToast();
    const [t] = useTranslation('purchase_orders');
    const p = 'payment-orders-modal';
    const { confirmAction } = useConfirmation();
    const { LoadingModal, hideLoading, showLoading } = useLoading();
    const hookForm = useForm();
    const hookPreview = usePreview();
    const hookPaymentOrders = usePagination({ defaultPageSize: 10 });
    const { page, pageSize, totalItems } = hookPaymentOrders.pagination;
    const [actions, setActions] = useState([]);
    const [modalInvoice, setModalInvoice] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);

    useEffect(() => {
        const defaultActions = [
            // Descargar la orden de pago
            { icon: faFileInvoiceDollar, handleClick: downloadFile, tooltip: t(`${p}.table.download`), variant: 'outline-success' },
            // Visualizar la orden de pago
            { icon: faEye, handleClick: handleViewFile, tooltip: t(`${p}.table.view`), variant: 'outline-primary' },
            // Cargar factura - El administrador puede cargar facturas del proveedor
            {
                icon: faFileCode, handleClick: handleUploadInvoice, tooltip: t(`${p}.table.upload-invoice`), variant: 'outline-success',
                dinamics: [{ key: 'has_invoice', values: ['Sin factura'] }]
            }
        ];
        // Acciones sólo para los administradores
        if (!isProvider) {
            const adminActions = [{
                icon: faFileUpload, handleClick: uploadVoucher, tooltip: t(`${p}.table.upload`), variant: 'outline-primary',
                dinamics: [{ key: 'has_voucher', values: ['Sin comprobante'] }]
            }, {
                icon: faEdit, handleClick: editPercentage, tooltip: t(`${p}.table.edit`), variant: 'outline-success',
                dinamics: [{ key: 'has_voucher', values: ['Sin comprobante'] }]
            }];
            defaultActions.push(...adminActions);
        }
        setActions(defaultActions);
    }, [isProvider]);

    useEffect(() => {
        const getPaymentOrders = async () => {
            hookPaymentOrders.setLoading(true);
            const fetch = await indexPaymentOrders({ headers, purchase_order_id, page, pageSize });
            const dataFiltered = fetch.data.map(item => {
                const percentage = item.percentage / 100;
                return { ...item, percentage }
            })
            hookPaymentOrders.setData(dataFiltered);
            hookPaymentOrders.setTotalItems(fetch.totalItems);
            hookPaymentOrders.setLoading(true);
        }
        getPaymentOrders();
    }, [page, pageSize, hookVouchers.data]);
    // Editar el porcentaje de la orden de compra, sólo si no tiene comprobante de pago
    const editPercentage = (item) => {
        hookPaymentOrders.setDataSel(item);
        setModalEdit(true);
    }
    // Crear una nueva orden de pago
    const createPaymentOrder = async () => {
        if (validateData() && !isProvider) {
            const data = {
                ...hookForm.form,
                purchase_order_id
            }
            const fetch = await storePaymentOrders({ headers, data });
            if (fetch.res) {
                const newRecord = fetch.data?.row;
                hookPaymentOrders.addElement({ ...newRecord, percentage: newRecord.percentage / 100 })
                hookForm.setForm({ percentage: 0 })
                Swal.fire(t(`${p}.sf.success.title`), t(`${p}.sf.success.desc`), 'success');
            } else if (fetch.status === 422) {
                handleValidationErrors(fetch.data?.errors);
            } else {
                Swal.fire(t(`${p}.sf.warning.title`), t(`${p}.sf.warning.desc`), 'warning');
            }
        }
    }
    // Validar formulario de envío
    const validateData = () => {
        const { percentage } = hookForm.form;
        const isValid = percentage > 0 && percentage <= 100;
        if (!isValid) {
            Swal.fire(t(`${p}.sf.valid.title`), t(`${p}.sf.valid.desc`), 'info');
        }
        return isValid;
    }
    // Mostrar mensajes de error recibidos desde la api
    const handleValidationErrors = (errors) => {
        let errorMessageList = '';

        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                const errorMessages = errors[key];
                errorMessageList += errorMessages.map(errorMessage => `<li>${errorMessage}</li>`).join('');
            }
        }

        Swal.fire({ title: t(`${p}.sf.info.title`), html: `<ul>${errorMessageList}</ul>`, icon: 'info' });
    }
    // Obtener el porcentaje actual de la orden de compra
    const getCurrentPercentage = () => {
        return getPercentaje(hookPaymentOrders.data.reduce((total, item) => total + item.percentage, 0), 2)
    }
    // Obtener el porcentage restante de la orden de compra
    const getRemainingPercentage = () => {
        const currentPercentage = hookPaymentOrders.data.reduce((total, item) => total - item.percentage, 1);
        return getPercentaje(Math.abs(currentPercentage), 2);
    }
    // Método para descargar archivo
    const downloadFile = (item) => {
        downloadPaymentOrders({ headers, name: item.folio, payment_order_id: item.id, toast });
    }
    // Determinar si las órdenes de pago ya están completas
    const isCompleted = () => {
        const currentPercentage = hookPaymentOrders.data.reduce((total, item) => total + item.percentage, 0);
        return currentPercentage === 1;
    }
    // Método para visualizar archivo
    const handleViewFile = async (item) => {
        hookPaymentOrders.setDataSel(item);
        hookPreview.setLoading(true);
        const fetch = await getBlobPaymentOrders({ headers, payment_order_id: item.id });
        hookPreview.onOpen();
        if (fetch.blob) {
            hookPreview.setBlobFile(fetch.blob);
        }
        hookPreview.setLoading(false);
    }
    // Método de referencia para abrir gestor de archivos
    const uploadVoucher = (item) => {
        hookPaymentOrders.setDataSel(item);
        inputFile.current.click();
    }
    // Agregar comprobante de pago
    const handleUploadVoucher = (e) => {
        const file = e.target.files[0];
        const title = '¿Está seguro que desea realizar esta operación?';
        const html = `Usted está por subir el comprobante de pago ${file.name}`;
        const confirmButtonText = 'Sí, subir';

        confirmAction(title, html, confirmButtonText, async () => {
            showLoading();
            const data = new FormData();
            const { id: payment_order_id } = hookPaymentOrders.dataSel;
            data.append('purchase_order_id', purchase_order_id);
            data.append('payment_order_id', payment_order_id);
            data.append('file', file);
            const fetch = await uploadPurchaseOrderVouchers({ headers, data });
            hideLoading();
            if (fetch) {
                // Agregar comprobante de pago a la tabla
                hookVouchers.addElement(fetch.row);
                // Actualizar estado de ordenes de pago
                hookPaymentOrders.updateElement({
                    ...hookPaymentOrders.dataSel,
                    has_voucher: 'Enviado',
                    purchase_order_voucher_id: fetch?.row?.id
                });
                Swal.fire(t('vouchers.sf.title-succ'), t('vouchers.sf.desc-upl-succ'), 'success');
            } else {
                Swal.fire(t('vouchers.sf.title-fail'), t('vouchers.sf.desc-upl-fail'), 'error');
            }

        });
    }
    // Agregar factura
    const handleUploadInvoice = (item) => {
        hookPaymentOrders.setDataSel(item);
        setModalInvoice(true);
    }
    // Mostrar etiquetas en las celdas de la tabla
    const badges_values = [{
        col: 'has_voucher',
        values: [
            { value: 'Sin comprobante', color: '#e6890b' },
            { value: 'Enviado', color: '#2e7c1d' }
        ]
    }, {
        col: 'has_invoice',
        values: [
            { value: 'Sin factura', color: '#FF5733' },
            { value: 'Recibida', color: '#1399a7' }
        ]
    }];

    return (
        <>
            <Row className='mb-4'>
                <Col xs={14}>
                    <p>{t(`${p}.current`)} <strong>{getCurrentPercentage()}</strong></p>
                    <p>{t(`${p}.remaining`)} <strong>{getRemainingPercentage()}</strong></p>
                </Col>
                {/* Sólo los administradores pueden agregar Ordenes de Pago */}
                {
                    !isProvider && <>
                        <Col xs={4}>
                            <FormIcon size={'sm'} re_icon={'%'} name='percentage' value={hookForm.form?.percentage} handleChange={hookForm.handleChange} />
                        </Col>
                        <Col xs={6}>
                            <ButtonIcon name={t(`${p}.table.add`)} icon={faPlus} variant='outline-success' onClick={createPaymentOrder} tooltipDisabled disabled={isCompleted()} />
                        </Col>
                    </>
                }
            </Row>
            {/* Input para el comprobante de pago */}
            <input ref={inputFile} type="file" name="file" onChange={handleUploadVoucher} hidden />
            <TableComp
                headers={[
                    '#',
                    t(`${p}.table.folio`),
                    t(`${p}.table.percentage`),
                    t(`${p}.table.partial`),
                    t(`${p}.table.voucher`),
                    t(`${p}.table.invoice`),
                    t(`${p}.table.created-at`),
                    t(`${p}.table.last-download-at`),
                    t(`${p}.table.actions`)
                ]}
                keys={['#', 'folio', 'percentage', 'partial', 'has_voucher', 'has_invoice', 'created_at', 'last_download_at']}
                moment_dates={[{ index: 6, format: 'LLL' }, { index: 7, format: 'LLL' }]}
                percentage={[{ index: 2, accuracy: 2 }]}
                body={hookPaymentOrders.data}
                badges={[{ index: 4 }, { index: 5 }]}
                badges_values={badges_values}
                currency={[{ index: 3 }]}
                actions={actions}
            />
            <PaginationComp
                current={page}
                onChange={hookPaymentOrders.changePage}
                totalItems={totalItems}
                defaultPageSize={pageSize}
            />
            {/* Mostrar estado de carga */}
            <LoadingModal />
            {/* Vista previa del archivo */}
            {
                hookPreview.showPreview && <ShowFile
                    onClose={() => hookPreview.onClose()}
                    hookPreview={hookPreview}
                    hookPaymentOrders={hookPaymentOrders}
                />
            }
            {/* Agregar factura */}
            {
                modalInvoice && <UploadInvoiceModal
                    onClose={() => setModalInvoice(false)}
                    hookPaymentOrders={hookPaymentOrders}
                    purchaseOrder={purchaseOrder}
                />
            }
            {/* Modificar porcentage */}
            {
                modalEdit && <EditPercentage
                    headers={headers}
                    onClose={() => setModalEdit(false)}
                    hookPaymentOrders={hookPaymentOrders}
                    handleValidationErrors={handleValidationErrors}
                />
            }
        </>
    )
}

PaymentOrders.propTypes = {
    headers: PropTypes.shape({
        Authorization: PropTypes.string.isRequired
    }).isRequired,
    purchase_order_id: PropTypes.number.isRequired,
    hookVouchers: hookPaginationPropType,
    isProvider: PropTypes.bool,
    purchaseOrder: PropTypes.object
}

export default PaymentOrders