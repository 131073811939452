import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Form } from 'react-bootstrap'
import FormIcon from '../../../Layouts/Forms/FormIcon'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import ButtonIcon from '../../../Layouts/Forms/ButtonIcon'
import AutocompleteComp from '../../../Layouts/Forms/Autocomplete'
import { indexBudgetCodes } from '../../../../api/Budget/codes'
import { useSelector } from 'react-redux'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const AddItems = ({ prefix, item, changeItem, addItem, fibra_id, showErrorItem }) => {
    const [t] = useTranslation('requisition');
    const currentLanguage = i18next.language;
    const token = useSelector(prevState => prevState.login.token);
    const headers = { Authorization: `Bearer ${token}` };
    const [options, setOptions] = useState([]);
    const [next, setNext] = useState(false);

    useEffect(() => {
        const getBudgetCodes = async () => {
            const { data } = await indexBudgetCodes({ headers, fibra_id });
            setOptions(data.map((item) => {
                const { id, code, translations } = item;
                const currentTranslation = translations.find((translation) => translation.locale === currentLanguage);
                return {
                    id,
                    label: `${code} - ${currentTranslation?.name}`
                }
            }));
        }
        getBudgetCodes();
    }, []);

    const verifiqueBudgetCodes = (item) => {
        const exists = options.find(option => option.label === item.label);
        if (exists) setNext(true);
        else setNext(false);
    }

    const changeAutocomplete = (e) => {
        changeItem(e);
        verifiqueBudgetCodes({ label: e.target.value });
    }
    return (
        <>
            <Row className='mb-3'>
                <AutocompleteComp
                    title={t(`${prefix}.item-description`)}
                    name='description'
                    defaultValue={item.description}
                    placeholder={t(`${prefix}.item-description-ph`)}
                    value={item.description}
                    options={options}
                    onSelect={verifiqueBudgetCodes}
                    handleChange={changeAutocomplete}
                    error={Object.values(item).length > 0 && !next}
                    autoComplete={'off'}
                />

                <FormIcon type='text' name='detailed' title={t(`${prefix}.detail`)} defaultValue={item.detailed} value={item.detailed} handleChange={changeItem} />
                <FormIcon type='text' name='unit' title={t(`${prefix}.item-unit`)} defaultValue={item.unit} value={item.unit} handleChange={changeItem} />
                <FormIcon type='number' name='quantity' title={t(`${prefix}.item-quantity`)} defaultValue={item.quantity} value={item.quantity} handleChange={changeItem} />
                <Col className='d-flex justify-content-center align-items-center'>
                    <ButtonIcon
                        name={t(`${prefix}.button-add-item`)}
                        disabled={!next}
                        icon={faPlus}
                        variant='outline-success'
                        onClick={addItem}
                    />

                </Col>
                {
                    showErrorItem && <Form.Label className="text-warning">{t(`${prefix}.error-item`)}</Form.Label>
                }

            </Row>
        </>
    )
}

AddItems.propTypes = {
    prefix: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    changeItem: PropTypes.func.isRequired,
    addItem: PropTypes.func.isRequired,
    fibra_id: PropTypes.number.isRequired,
    showErrorItem: PropTypes.bool
}

export default AddItems