import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import DrawerComp from '../../Layouts/Drawer';
import { Box, FormLabel, Input, Select } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import usePagination from '../../../hooks/usePagination';
import { indexSegments } from '../../../api/Segments';
import { indexBusinessName } from '../../../api/Cfdi/BusinessNames';

const FiltersReqComp = ({ type, hookForm, handleClickSearch, headers, fibra_id, loading, business_name_ids, segment_ids }) => {
    const [t] = useTranslation('requisition');
    const p = 'filters';

    const { form, handleChange } = hookForm;
    const hookSegments = usePagination({});
    const hookBusinessNames = usePagination({});

    useEffect(() => {
        const getSegments = async () => {
            hookSegments.setLoading(true);
            const fetchSegments = await indexSegments({ headers, fibra_id });
            const allowedSegments = fetchSegments.data.filter(segment => segment_ids.includes(segment.id));
            hookSegments.setData(allowedSegments);
            hookSegments.setLoading(false);
        }
        // Mostrar registros sólo para los administradores
        if (type !== 'provider' && !loading) getSegments();
    }, [loading]);

    useEffect(() => {
        const getBusinessNames = async () => {
            hookBusinessNames.setLoading(true);
            const fetchBusinessNames = await indexBusinessName({ headers, fibra_id });
            const allowedBusinessNames = fetchBusinessNames.data.filter(business_name => business_name_ids.includes(business_name.id));
            hookBusinessNames.setData(allowedBusinessNames);
            hookBusinessNames.setLoading(false);
        }
        // Mostrar registros sólo para los administradores
        if (type !== 'provider' && !loading) getBusinessNames();
    }, [loading]);

    return (
        <DrawerComp title={t(`${p}.title`)} handleClickSearch={handleClickSearch} showFooter>
            {/* Segmento */}
            <Box className='mb-3'>
                <FormLabel>{t(`${p}.segment`)}</FormLabel>
                <Select onChange={handleChange} defaultValue={-1} name='segment_id' value={form?.segment_id}>
                    <option value={-1}>{t(`${p}.all`)}</option>
                    {
                        !hookSegments.loading && hookSegments.data.map(segment =>
                            <option key={segment.id} value={segment.id}>
                                {segment.code} - {segment.name}
                            </option>
                        )
                    }
                </Select>
            </Box>
            {/* Razón social */}
            <Box className='mb-3'>
                <FormLabel>{t(`${p}.business_name`)}</FormLabel>
                <Select onChange={handleChange} defaultValue={-1} name='business_name_id' value={form?.business_name_id}>
                    <option value={-1}>{t(`${p}.all`)}</option>
                    {
                        !hookBusinessNames.loading && hookBusinessNames.data.map(business_name =>
                            <option key={business_name.id} value={business_name.id}>
                                {business_name.name}
                            </option>
                        )
                    }
                </Select>
            </Box>
            {/* Estado de los precios unitarios, solo para la sección de aprobación de requisiciones */}
            {
                type !== 'provider' &&
                <Box className='mb-3'>
                    <FormLabel>{t(`${p}.status`)}</FormLabel>
                    <Select onChange={handleChange} defaultValue={-1} name='status' value={form?.status}>
                        <option value={-1}>{t(`${p}.all`)}</option>
                        <option value={'complete'}>{t(`${p}.complete`)}</option>
                        <option value={'partial'}>{t(`${p}.partial`)}</option>
                        <option value={'incomplete'}>{t(`${p}.incomplete`)}</option>
                        <option value={'unassigned'}>{t(`${p}.unassigned`)}</option>
                    </Select>
                </Box>
            }
            {/* Estado de orden de compra asignada */}
            {
                type !== 'provider' &&
                <Box className='mb-3'>
                    <FormLabel>{t(`${p}.status-po`)}</FormLabel>
                    <Select onChange={handleChange} defaultValue={-1} name='status_po' value={form?.status_po}>
                        <option value={-1}>{t(`${p}.all`)}</option>
                        <option value={'complete'}>{t(`${p}.complete`)}</option>
                        <option value={'partial'}>{t(`${p}.partial`)}</option>
                        <option value={'unassigned'}>{t(`${p}.unassigned`)}</option>
                    </Select>
                </Box>
            }
            {/* Estado de los proveedores asignados */}
            {
                type !== 'provider' &&
                <Box className='mb-3'>
                    <FormLabel>{t(`${p}.status-pr`)}</FormLabel>
                    <Select onChange={handleChange} defaultValue={-1} name='status_pr' value={form?.status_pr}>
                        <option value={-1}>{t(`${p}.all`)}</option>
                        <option value={'assigned'}>{t(`${p}.assigned`)}</option>
                        <option value={'partial'}>{t(`${p}.partial`)}</option>
                        <option value={'unassigned'}>{t(`${p}.unassigned`)}</option>
                    </Select>
                </Box>
            }
            {/* Fecha mínima */}
            <Box className='mb-3'>
                <FormLabel>{t(`${p}.start_date`)}</FormLabel>
                <Input type='date' name='start_date' value={form?.start_date} onChange={handleChange} />
            </Box>
            {/* Fecha maxima */}
            <Box className='mb-3'>
                <FormLabel>{t(`${p}.end_date`)}</FormLabel>
                <Input type='date' name='end_date' value={form?.end_date} onChange={handleChange} />
            </Box>
        </DrawerComp>
    )
}

FiltersReqComp.propTypes = {
    type: PropTypes.string.isRequired,
    hookForm: PropTypes.shape({
        form: PropTypes.shape({
            segment_id: PropTypes.number,
            business_name_id: PropTypes.number,
            start_date: PropTypes.string,
            end_date: PropTypes.string,
            status: PropTypes.string
        }).isRequired,
        handleChange: PropTypes.func.isRequired
    }).isRequired,
    handleClickSearch: PropTypes.func.isRequired,
    headers: PropTypes.shape({
        Authorization: PropTypes.string.isRequired
    }).isRequired,
    fibra_id: PropTypes.number.isRequired,
    loading: PropTypes.bool,
    business_name_ids: PropTypes.array,
    segment_ids: PropTypes.array
}

export default FiltersReqComp