import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Select } from '@chakra-ui/react';
import { Col, Form, Row } from 'react-bootstrap'
import FormIcon from '../../Layouts/Forms/FormIcon'
import { tax_system } from '../../../data/invoiceTypes';

const InvoiceCustomer = ({ customer, handleChange }) => {
    return (
        <Fragment>
            <Row className='mb-3'>
                <FormIcon title='Razón social' name='legal_name' value={customer?.legal_name} handleChange={handleChange} />
                <FormIcon title='RFC' name='tax_id' value={customer?.tax_id} handleChange={handleChange} />
            </Row>
            <Row className='mb-3'>
                <FormIcon title='Correo electrónico' name='email' value={customer?.email} handleChange={handleChange} />
                <FormIcon title='Número telefónico [Opcional]' name='phone' value={customer?.phone} handleChange={handleChange} />
            </Row>
            <Row className='mb-3'>
                <Col>
                    <Form.Label>Régimen Fiscal</Form.Label>
                    <Select name='tax_system' onChange={handleChange}>
                        {
                            tax_system.map((item) =>
                                <option key={item.code} value={item.code}>{item.code} - {item.name}</option>
                            )
                        }
                    </Select>
                </Col>
            </Row>
        </Fragment>
    )
}

InvoiceCustomer.propTypes = {
    customer: PropTypes.shape({
        legal_name: PropTypes.string,
        tax_id: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string
    }),
    handleChange: PropTypes.func
}

export default InvoiceCustomer