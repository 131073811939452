import { useEffect } from 'react'
import usePagination from './usePagination';
import { indexInventory } from '../api/Budget/inventory';

const useInventory = ({ headers, cedis_id }) => {
    const hookInventory = usePagination({ defaultPageSize: 10 });
    const { page, pageSize } = hookInventory.pagination;

    useEffect(() => {
        const getInventory = async () => {
            hookInventory.setLoading(true);
            const { data, totalItems } = await indexInventory({
                headers,
                page,
                pageSize,
                cedis_id
            });
            hookInventory.setTotalItems(totalItems)
            hookInventory.setData(data)
            hookInventory.setLoading(false);
        }
        getInventory();
    }, []);

    return hookInventory;
}

export default useInventory
