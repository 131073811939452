import { useState } from "react";
import PropTypes from 'prop-types';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Col, Row, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ButtonIcon from './Forms/ButtonIcon';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import SkeletonForm from './Skeletons/Form';
import '../../styles/PDFViewerComp.css';

const PDFViewerComp = ({ file }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [t] = useTranslation('global');
    const prefix = 'layouts.pdf-viewer';

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const changePage = (number) => {
        if (number <= numPages && number >= 1) setPageNumber(number);
    }

    return (
        <Container fluid className="pdf-container">
            <Row className="pdf-row">
                <Col className="pdf-col">
                    <Document
                        file={file}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={<SkeletonForm cols={1} rows={3} />}
                        noData={t(`${prefix}.no-data`)}
                        error={t(`${prefix}.error`)}
                    >
                        <Page pageNumber={pageNumber} width={window.innerWidth} />
                    </Document>
                </Col>
            </Row>
            <Row className="pdf-controls">
                <Col className="text-center">
                    <ButtonIcon name={t(`${prefix}.prev`)} variant='outline-primary' icon={faAngleDoubleLeft} onClick={() => changePage(pageNumber - 1)} />
                    <span className="page-info">{t(`${prefix}.page`)} {pageNumber} {t(`${prefix}.of`)} {numPages}</span>
                    <ButtonIcon name={t(`${prefix}.next`)} variant='outline-primary' icon={faAngleDoubleRight} onClick={() => changePage(pageNumber + 1)} />
                </Col>
            </Row>
        </Container>
    );
}

PDFViewerComp.propTypes = {
    file: PropTypes.object.isRequired
}

export default PDFViewerComp;