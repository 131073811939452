import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import TabsComp from '../../../Layouts/Tabs'
import Header from './Header';
import ItemList from './ItemList';
import ButtonIcon from '../../../Layouts/Forms/ButtonIcon';
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { storePurchaseOrder } from '../../../../api/PurchaseOrders';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateRequisitionItems } from '../../../../api/Budget/requisition_items';
import { storeRequisitionPurchaseOrder } from '../../../../api/Budget/requisition_purchase_orders';

const AssignPurchaseOrders = ({ orderItems, providers, providerItems, items, businessNames, requisition, hookSegments }) => {
    const [t] = useTranslation('requisition');
    const prefix = 'modal-details.assign-purchase-order';
    const p_toasts = prefix + ".toasts";
    const login = useSelector(state => state.login);
    const headers = { Authorization: `Bearer ${login.token}` };
    const fibra_id = login.fibra.id;
    const [assignedProviders, setAssignedProviders] = useState([]);
    const [forms, setForms] = useState([]);
    const [folio, setFolio] = useState('');

    useEffect(() => {
        if (orderItems.length > 0) {
            // Obtener proveedores a los que se les asignan partidas y crear una pestaña por cada uno
            const [...assignedProviders] = new Set([...orderItems.map(orderItem => orderItem.provider_id)])
            setAssignedProviders(assignedProviders);
            setForms(assignedProviders.map(assignedProvider => {
                // Obtener las partidas asignadas a cada proveedor
                const assignedProviderItems = getProviderItems(assignedProvider);
                const items = assignedProviderItems.map(assignedProviderItem => {
                    const { id, item, unit_price } = assignedProviderItem;
                    const subtotal = unit_price * item.quantity;
                    return {
                        id, item, unit_price, subtotal, total: subtotal, iva: 0, isr_withholding_price: 0, iva_withholding_price: 0
                    }
                });
                return { provider_id: assignedProvider, saved: false, data: { items } }
            }));
            setFolio(getFolio());
        }
    }, [orderItems]);
    // Obtener las partidas asignadas a un proveedor
    const getProviderItems = (assignedProvider) => {
        const assignedProviderItems = orderItems
            .filter(orderItem => orderItem.provider_id == assignedProvider)
            .map(orderItem => {
                const { id, provider_id, requisition_item_id, unit_price } = providerItems.find(providerItem =>
                    providerItem.provider_id == orderItem.provider_id && providerItem.requisition_item_id == orderItem.requisition_item_id
                )
                const item = items.find(element => element.id === requisition_item_id)
                return { id, provider_id, requisition_item_id, unit_price, item }
            })
        return assignedProviderItems;
    }
    const getTabName = (provider_id) => {
        const exists = providers.find(provider => provider.id == provider_id);
        return exists ? exists?.business_partner_provider?.business_name : t(`${prefix}.unknow`);
    }
    // Obtiene el folio de las ordenes de compra
    const getFolio = () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear() - 2000;
        return `PUO_${currentYear}_${currentDate.getTime()}`;
    }

    const savePurchaseOrder = async (folio, purchase_order, assignedProviderItems) => {
        const { provider_id, total, subtotal, currency, business_name_id, segment_id } = purchase_order;
        if (validate(provider_id)) {
            const { data } = forms.find(element => element.provider_id === provider_id);
            const items = data.items.map(element => {
                return {
                    description: element.item.description,
                    detailed: element.item.detailed,
                    quantity: element.item.quantity,
                    unit: element.item.unit,
                    unit_price: element.unit_price,
                    iva: element.iva,
                    iva_withholding: element.iva_withholding,
                    isr_withholding: element.isr_withholding
                }
            });
            const dataPayload = {
                folio: folio + '_' + provider_id,
                user_id: provider_id,
                fibra_id,
                description: data.description,
                amount: total,
                subtotal,
                currency,
                ended_date: data.ended_date,
                business_name_id,
                segment_id,
                items
            }
            const response = await storePurchaseOrder({ headers, data: dataPayload });
            if (response) {
                // Deshabilitar botón de guardar
                setForms(prevState => prevState.map((form) => {
                    if (form.provider_id === purchase_order.provider_id) {
                        form.saved = true;
                    }
                    return form;
                }));
                // Agregar provider_id a requisition_items, para identificar las partidas asignadas
                updateRequisitionItems({
                    headers, data: {
                        requisition_items_ids: assignedProviderItems.map(api => api.requisition_item_id),
                        provider_id
                    }
                });
                // Guardar la relación para identificar que órdenes de compra se han creado a través de requisiciones
                storeRequisitionPurchaseOrder({
                    headers, data: {
                        provider_id,
                        requisition_id: requisition.id,
                        purchase_order_id: response?.data?.id
                    }
                });
                Swal.fire(t(`${p_toasts}.swal-succ-cre.title`), t(`${p_toasts}.swal-succ-cre.description`), 'success');
            } else {
                Swal.fire(t(`${p_toasts}.warning-di.title`), t(`${p_toasts}.warning-di.description`), 'warning');
            }
        }
    }

    const validate = (provider_id) => {
        let isValid = true;
        const form = forms.find(element => element.provider_id === provider_id);
        if (form) {
            const { description, ended_date } = form.data;
            if (!ended_date) {
                isValid = false;
                Swal.fire(t(`${p_toasts}.validate.title`), t(`${p_toasts}.validate.ended-date`), 'info');
            }
            if (!description) {
                isValid = false;
                Swal.fire(t(`${p_toasts}.validate.title`), t(`${p_toasts}.validate.description`), 'info');
            }
        }
        return isValid;
    }

    const handleChange = (event) => {
        const { id, name, value } = event.target;
        setForms(prevState => prevState.map((form) => {
            if (form.provider_id === id) {
                form.data = { ...form.data, [name]: value }
            }
            return form;
        }));
    }

    return (
        <TabsComp
            tablist={assignedProviders.map(assignedProvider => getTabName(assignedProvider))}
            tabpanels={assignedProviders.map(assignedProvider => {
                const { business_name_id, currency, segment_id } = requisition;
                const assignedProviderItems = getProviderItems(assignedProvider);
                // Formulario de la orden de compra en curso
                const form = forms.find(form => form.provider_id == assignedProvider);
                const subtotal = form.data.items.reduce((count, providerItem) => (providerItem.unit_price * providerItem.item.quantity) + count, 0);
                const total = form.data.items.reduce((count, providerItem) => (providerItem.unit_price * providerItem.item.quantity + providerItem.iva - providerItem.iva_withholding_price - providerItem.isr_withholding_price) + count, 0);
                const iva = form.data.items.reduce((count, providerItem) => providerItem.iva + count, 0);
                const iva_withholding = form.data.items.reduce((count, providerItem) => providerItem.iva_withholding_price + count, 0);
                const isr_withholding = form.data.items.reduce((count, providerItem) => providerItem.isr_withholding_price + count, 0);
                const purchase_order = {
                    business_name_id,
                    provider_id: assignedProvider,
                    currency,
                    total,
                    subtotal,
                    segment_id,
                    iva,
                    iva_withholding,
                    isr_withholding
                }
                return <>
                    <Header
                        key={assignedProvider}
                        handleChange={handleChange}
                        providers={providers}
                        folio={folio + '_' + assignedProvider}
                        form={form}
                        purchase_order={purchase_order}
                        businessNames={businessNames}
                        hookSegments={hookSegments}
                    />
                    <ItemList
                        setForms={setForms}
                        items={form.data.items}
                        provider_id={assignedProvider}
                        disabled={form.saved}
                    />
                    <ButtonIcon
                        name={t(`${prefix}.confirm`)}
                        icon={faCheckDouble}
                        variant={'outline-success'}
                        onClick={() => savePurchaseOrder(folio, purchase_order, assignedProviderItems)}
                        disabled={form.saved}
                    />
                </>
            })}
        />
    )
}

AssignPurchaseOrders.propTypes = {
    orderItems: PropTypes.array.isRequired,
    providers: PropTypes.array.isRequired,
    providerItems: PropTypes.array.isRequired,
    items: PropTypes.array.isRequired,
    businessNames: PropTypes.array.isRequired,
    requisition: PropTypes.shape({
        id: PropTypes.number.isRequired,
        business_name_id: PropTypes.number.isRequired,
        segment_id: PropTypes.number,
        currency: PropTypes.string.isRequired
    }).isRequired,
    hookSegments: PropTypes.object
}

export default AssignPurchaseOrders