import PropTypes from 'prop-types';
import { faDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useState } from "react";
import { useStore } from "react-redux";
import { downloadRelease } from "../../../api/Releases";
import TableComp from "../../Layouts/Table";
import PaginationComp from "../../Layouts/Pagination";
import { useToast } from "@chakra-ui/react";
import ModalView from "./ModalView";
import { useTranslation } from "react-i18next";
import PageHeaderComp from "../../Layouts/PageHeader";
import useLoading from "../../../hooks/useLoading";
import { hookPaginationPropType } from '../../../data/propTypes';

const ReleasesTable = ({ theaders, tkeys, tbadges, moment_dates, hookReleases }) => {
    const store = useStore();
    const toast = useToast();
    const { token, fibra } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };
    const [t] = useTranslation('global');
    const prefix = 'releases.tooltips';
    const hookLoading = useLoading();

    const [modal, setModal] = useState(false);
    // Descargar archivo de comunicado
    const download = async (item) => {
        hookLoading.showLoading();
        await downloadRelease({ headers, release_id: item.id, name: `${item.title}`, toast });
        hookLoading.hideLoading();
    }
    // Ver detalles del comunicado
    const viewDetails = (item) => {
        hookReleases.setDataSel(item);
        setModal(true);
    }

    return (
        <Fragment>
            <PageHeaderComp title={t('releases.title')} />

            {/* Mostrar spinner de carga si está descargando */}
            <hookLoading.LoadingModal />

            {/* Tabla en la que se listan los comunicados */}
            <TableComp
                headers={theaders}
                keys={tkeys}
                body={hookReleases.data}
                badges={tbadges}
                actions={[
                    { icon: faEye, tooltip: t(`${prefix}.view`), handleClick: viewDetails },
                    { icon: faDownload, tooltip: t(`${prefix}.download`), handleClick: download, variant: 'outline-secondary', disabled: hookLoading.isOpen }
                ]}
                moment_dates={moment_dates}
                loading={hookReleases.loading}
                loadingItems={hookReleases.pagination.pageSize}
                page={hookReleases.pagination.page}
            />

            {/* Paginación */}
            <PaginationComp
                current={hookReleases.pagination.page}
                totalItems={hookReleases.pagination.totalItems}
                onChange={hookReleases.changePage}
            />

            {/* Modal de vista previa */}
            {
                modal && <ModalView
                    onClose={() => setModal(false)}
                    release={hookReleases.dataSel}
                    headers={headers}
                    fibra_id={fibra.id}
                />
            }
        </Fragment>
    );
}

ReleasesTable.propTypes = {
    theaders: PropTypes.array.isRequired,
    tkeys: PropTypes.array.isRequired,
    tbadges: PropTypes.arrayOf(
        PropTypes.shape({
            index: PropTypes.number,
            color: PropTypes.string
        })
    ),
    moment_dates: PropTypes.arrayOf(
        PropTypes.shape({
            index: PropTypes.number,
            format: PropTypes.string
        })
    ),
    hookReleases: hookPaginationPropType,
}

export default ReleasesTable;
