import React, { useEffect } from 'react';
import ModalComp from '../Layouts/Modal';
import ButtonIcon from '../Layouts/Forms/ButtonIcon';
import TableComp from '../Layouts/Table';
import UploadComponent from './UploadComponent';
import ObservationsInput from './ObservationsInput';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button, Row, Col, Input } from 'antd';
import useForm from '../../hooks/useForm';
import { Form } from 'react-bootstrap';
import { storeInventory } from '../../api/Budget/inventory';
import Swal from 'sweetalert2';

// Componente ModalSalida para mostrar un modal con una tabla y formularios

const ModalEntrada = ({ handleCloseModal, cedis_delivery_id, headers }) => {
    const Formulario = useForm()
    useEffect(() => {

        console.log("🚀 ~ ModalEntrada ~ form:", Formulario.form, Formulario.file)
    }, [Formulario.form])
    
    // Función para manejar el clic en el botón "Guardar"
    const handleSave = async () => {
        Formulario.setLoading(true)
        const formData = new FormData();
        formData.append('cedis_delivery_id', cedis_delivery_id);
        formData.append('description', Formulario.form.description);
        formData.append('file', Formulario.file);

        try {
            // Realiza la solicitud para almacenar el inventario
            const respuesta = await storeInventory({ headers, data: formData });

            // Manejo de la respuesta
            if (respuesta) {
                // Si la respuesta es exitosa, muestra un mensaje de éxito
                Swal.fire(
                    'Éxito', // Título de la notificación de éxito
                    'Los datos se han guardado correctamente.',
                    'success'
                );
            } else {
                // Si la respuesta no es exitosa, muestra un mensaje de información
                Swal.fire(
                    'Error', // Título de la notificación de fallo
                    'No se pudieron guardar los datos. Intenta nuevamente.',
                    'error'

                );
            }
            handleCloseModal(); // Cierra el modal después de guardar, si es necesario
        } catch (error) {
            // Si ocurre un error, muestra un mensaje de error
            Swal.fire(
                'Error', // Título de la notificación de error
                'Hubo un problema al guardar los datos. Verifica la conexión y vuelve a intentarlo.', // Mensaje de la notificación de error
                'error' // Tipo de notificación de error
            );
        }
        Formulario.setLoading(false)
    };

    return (
        <ModalComp
            size={'lg'} // Tamaño del modal
            onClose={handleCloseModal} // Función para cerrar el modal
            title='Registrar entrada' // Título del modal
            body={
                <>
                    {/* Componente de tabla para mostrar los datos */}
                    <TableComp
                        headers={['#', 'Descripción', 'Cantidad actual', 'Cantidad a agregar', 'Cantidad final']} // Encabezados de la tabla
                        keys={['#', 'Descripción', 'Cantidad actual', 'Cantidad a agregar', 'Cantidad final']} // Claves de los datos de la tabla
                        body={[]} // Datos a mostrar en la tabla
                        moment_dates={[{ index: 4, format: 'LLLL' }, { index: 6, format: 'LL' }]} // Formato de fechas en la tabla
                        badges={[{ index: 5 }]} // Columna con etiquetas de estado
                        badges_values={[{ col: 'status', values: [{ color: '#2e7c1d', name: 'entregado', value: 'delivered' }, { color: '#FF5733', name: 'rechazado', value: 'rejected' }, { color: 'blue', name: 'pendiente', value: 'pending' }] }]} // Valores y colores para las etiquetas de estado
                    />
                    <Row gutter={16} style={{ marginTop: '16px' }}>
                        {/* Componente para cargar archivos */}
                        <Col span={12}>
                            <Form.Group className="mb-3" as={Col}>
                                <Form.Label>archivo</Form.Label>
                                <Form.Control type="file" name="file" onChange={Formulario.handleChange} accept="application/pdf" />
                            </Form.Group>
                        </Col>
                        {/* Componente para ingresar observaciones */}
                        <Col span={12}>
                            <Input.TextArea rows={4} placeholder="Escribe tus observaciones aquí..." onChange={Formulario.handleChange} name='description' value={Formulario.form?.description} />
                        </Col>
                    </Row>
                </>
            }
            footer={
                <>
                    {/* Botón para cerrar el modal */}
                    <ButtonIcon icon={faTimes} name="Cerrar" variant="outline-secondary" onClick={handleCloseModal} />
                    {/* Botón para guardar los cambios */}
                    <ButtonIcon icon={faSave} name="Guardar" variant="outline-success" onClick={handleSave} disabled={Formulario.loading} />
                </>
            }
        />
    );
};

export default ModalEntrada;
