import { Row, Select, Input, Col } from 'antd';
import { useTranslation } from 'react-i18next';
const { Option } = Select;

const Filters = (props) => {
    const {
        malls,
        handleChangeMall,
        handleChangePriceMinor,
        handleChangePriceMajor,
        handleChangeM2
    } = props;

    const [t] = useTranslation('global');
    const prefix = 'rents.filters';
    return (
        <Row justify='end' className='mb-2'>
            <Select name='mall_id' onChange={handleChangeMall} placeholder={t(`${prefix}.mall`)} style={{ width: 200 }}>
                <Option value={null}>{t(`${prefix}.all`)}</Option>
                {
                    malls.map((mall, index) =>
                        <Option value={mall.id}>{mall.code} - {mall.name}</Option>
                    )
                }
            </Select>
            <Select name='m2' onChange={handleChangeM2} placeholder={t(`${prefix}.m2`)} style={{ width: 180 }}>
                <Option value={null}>{t(`${prefix}.all`)}</Option>
                <Option value={5}>5 m<sup>2</sup></Option>
                <Option value={10}>10 m<sup>2</sup></Option>
                <Option value={25}>25 m<sup>2</sup></Option>
                <Option value={50}>50 m<sup>2</sup></Option>
            </Select>
            <Col>
                <Input prefix='$' type='number' placeholder={t(`${prefix}.greater-than`)} onChange={handleChangePriceMinor} />
            </Col>
            <Col>
                <Input prefix='$' type='number' placeholder={t(`${prefix}.less-than`)} onChange={handleChangePriceMajor} />
            </Col>
        </Row>
    );
}

export default Filters