import { Fragment } from "react";
import { Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormIcon from "../../Layouts/Forms/FormIcon";
import SkeletonForm from "../../Layouts/Skeletons/Form";

const UserForm = (props) => {
    const { loading, data } = props;
    const [t] = useTranslation('global');
    const p_data = 'access-control.data';

    return (
        <Fragment>
            {
                loading ? <SkeletonForm cols={3} rows={4} /> :
                    <Form>
                        <Row className='mb-3'>
                            <FormIcon title={t(`${p_data}.first-name`)} name='first_name' value={data?.first_name} />
                            <FormIcon title={t(`${p_data}.last-name`)} name='last_name' value={data?.last_name} />
                            <FormIcon title={t(`${p_data}.gender`)} name='gender' value={data?.gender} />
                        </Row>
                        <Row className='mb-3'>
                            <FormIcon title={t(`${p_data}.tax-id`)} name='tax_id' value={data?.tax_id} />
                            <FormIcon title={t(`${p_data}.phone-number`)} name='phone_number' value={data?.phone_number} />
                            <FormIcon title={t(`${p_data}.email`)} name='email' value={data?.email} />
                        </Row>
                        <Row className='mb-3'>
                            <FormIcon title={t(`${p_data}.business-name`)} name='business_name' value={data?.business_name} />
                            <FormIcon title={t(`${p_data}.alias`)} name='alias' value={data?.alias} />
                            <FormIcon title={t(`${p_data}.person-type`)} name='person_type' value={data?.person_type} />
                        </Row>
                    </Form>
            }
        </Fragment>
    );
}

export default UserForm