import Fetcher from "../libs/Petition";
import downloadFile from "../libs/DownloadFile";

const url = '/segments';
const CREATED = 201;
const STATUS_OK = 200;

export const indexSegments = async ({
    headers,
    fibra_id,
    business_name_id,
    page,
    pageSize,
    updated_at_start
}) => {

    let response = { data: [], totalItems: 0 };

    try {
        // Parámetros requeridos
        let params = { fibra_id };
        // Parámetros opcionales
        if (page) params.page = page;
        if (pageSize) params.pageSize = pageSize;
        if (updated_at_start) params.updated_at_start = updated_at_start;
        if (business_name_id) params.business_name_id = business_name_id;
        const fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        // console.log("🚀 ~ indexSegments ~ fetch:", fetch)

        if (fetch.status === STATUS_OK)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}

export const showSegment = async ({ headers, segment_id }) => {
    let response = {};
    try {
        let fetch = await Fetcher({
            url: `${url}/${segment_id}`,
            method: 'GET',
            headers
        });
        // console.log("🚀 ~ showSegment ~ fetch:", fetch)

        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = {};
    }
    return response;
}

export const storeSegment = async ({ headers, data }) => {
    let response = false;
    try {
        const fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        // console.log("🚀 ~ storeSegment ~ fetch:", fetch)

        if (fetch.status === CREATED)
            response = fetch.data;
    } catch (error) {
        response = false;
    }
    return response;

}

export const updateSegment = async ({ headers, data, segment_id }) => {
    let response = false;
    try {
        const fetch = await Fetcher({
            url: `${url}/${segment_id}`,
            method: 'PUT',
            data,
            headers
        });
        // console.log("🚀 ~ updateSegment ~ fetch:", fetch)

        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = false;
    }
    return response;

}

export const downloadSegment = ({ headers, segment_id, name, toast }) => {
    downloadFile({
        headers,
        url: `${url}/download/${segment_id}`,
        type: 'application/json',
        name,
        toast
    });
}

export const uploadSegment = async ({ headers, data, segment_id }) => {
    let response = false;
    try {
        const fetch = await Fetcher({
            url: `${url}/upload/${segment_id}`,
            method: 'POST',
            data, headers
        });
        // console.log("🚀 ~ file: Segments.js:140 ~ uploadSegment ~ fetch:", fetch)

        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
        return response;
    } catch (error) {
        return response;
    }
}