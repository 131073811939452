import { Alert, AlertIcon } from '@chakra-ui/react';
import { faArrowLeft, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { indexComplementaryPayment, storeComplementaryPayment } from '../../../../api/Cfdi/ComplementaryPayments';
import ButtonIcon from '../../../Layouts/Forms/ButtonIcon';
import ModalComp from '../../../Layouts/Modal';
import SkeletonForm from '../../../Layouts/Skeletons/Form';
import TableComp from '../../../Layouts/Table';
import CompPaymentData from './CompPaymentData';
import CompPaymentForm from './CompPaymentForm';

const CompPaymentModal = (props) => {
    const { onClose, invoice, headers, user_id } = props;
    const [complementary, setComplementary] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [successfull, setSuccessfull] = useState(false);
    const [dataResponse, setDataResponse] = useState({});
    const [wasPaid, setWasPaid] = useState(true);
    const [files, setFiles] = useState(null);
    const [t] = useTranslation('global');
    const prefix = 'mailbox.files-upload.toasts';

    useEffect(() => {
        getComplementaryPayments();
    }, []);

    const getComplementaryPayments = async () => {
        let response = await indexComplementaryPayment({ headers, invoice_id: invoice.id });
        setComplementary(response.data);
        let totalPaid = 0;
        response.data.map((comp) => {
            totalPaid += comp.amount_paid;
        })
        if (invoice.total > totalPaid)
            setWasPaid(false);
        setLoading(false);
    }
    // CARGAR FACTURAS
    const handleLoadFile = (e) => {
        let { name } = e.target;
        setFiles(prevState => ({ ...prevState, [name]: e.target.files[0] }));
    }

    const addInvoice = async () => {
        setLoadingUpload(true);
        if (files.xml && files.pdf) {
            let params = {
                user_id: user_id,
                invoice_id: invoice.id
            };
            let data = new FormData();
            data.append('xml', files.xml);
            data.append('pdf', files.pdf);
            let response = await storeComplementaryPayment({ headers, data, params });
            if (response) {
                if (response.res) {
                    setDataResponse(response.data);          // Guardar datos para mostrar información
                    setFiles(null);                     // Limpiar los documentos almacenados (PDF y XML)
                    setSuccessfull(true);               // Indicar que la carga se ha realizado de manera satisfactoria
                    Swal.fire(t(`${prefix}.success-comp.title`), t(`${prefix}.success-comp.description`), 'success');
                } else {
                    Swal.fire(t(`${prefix}.unexp.title`), response.msg, 'error');
                }
            } else {
                Swal.fire(t(`${prefix}.error-comp.title`), t(`${prefix}.error-comp.description`), 'error');
            }
        } else {
            Swal.fire(t(`${prefix}.nofile.title`), t(`${prefix}.nofile.description`), 'error');
        }
        setLoadingUpload(false);
    }
    return (
        <ModalComp
            title={t('mailbox.modal-comp.title')}
            size='lg'
            onClose={onClose}
            body={<Fragment>
                {/* Si se ha mandado un complemento de pago se muestra un skeleton de carga "Validando información" */}
                {loadingUpload && <SkeletonForm cols={3} rows={4} />}
                {/* Si no hay existe un complemento de pago cargando y tampoco se ha obtenido una respuesta del servidor */}
                {
                    !loadingUpload && !successfull && <Fragment>
                        <TableComp
                            headers={[
                                '#',
                                t('mailbox.data.uuid'),
                                t('mailbox.data.amount-paid'),
                                t('mailbox.data.previous-balance'),
                                t('mailbox.data.outstanding-balance'),
                                t('mailbox.data.stamp-date'),
                                t('mailbox.data.actions')
                            ]}
                            keys={['#', 'uuid', 'amount_paid', 'previous_balance', 'outstanding_balance', 'stamp_date']}
                            body={complementary}
                            loading={loading}
                            loadingItems={5}
                            currency={[{ index: 2 }, { index: 3 }, { index: 4 }]}
                            moment_dates={[{ index: 5, format: 'LL' }]}
                        />
                        {/* Si terminó de obtener datos y se determina que aun no ha sido pagada en su totalidad se muestra un mensaje, 
                        de lo contrario se muestra el formulario para cargar el CFDi */}
                        {
                            !loading ? !wasPaid ? <CompPaymentForm handleLoadFile={handleLoadFile} /> :
                                <Alert status='success' className='mb-2'>
                                    <AlertIcon /> {t('mailbox.modal-comp.alert')}
                                </Alert> : null
                        }
                    </Fragment>
                }
                {/* Si se mandó un documento y se obtiene una respuesta satisfactoria se muestran los datos obtenidos */}
                {
                    !loadingUpload && successfull && <CompPaymentData data={dataResponse} />
                }
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name={t('mailbox.modal-comp.button-close')} icon={faArrowLeft} onClick={onClose} variant='outline-secondary' />
                {
                    files?.xml && files?.pdf &&
                    <ButtonIcon name={t('mailbox.modal-comp.button-add')} onClick={addInvoice} icon={faFileUpload} variant='outline-success' />
                }
            </Fragment>}
        />
    )
}

export default CompPaymentModal;