import { useState } from "react"

const usePreview = () => {
    const [blobFile, setBlobFile] = useState(null);
    const [showPreview, setShowPreview] = useState(false);
    const [loading, setLoading] = useState(false);

    const onClose = () => {
        setShowPreview(false);
    }

    const onOpen = () => {
        setShowPreview(true);
    }

    return {
        blobFile,
        showPreview,
        loading,
        setBlobFile,
        setShowPreview,
        setLoading,
        onClose,
        onOpen
    }
}

export default usePreview;