import { useEffect } from "react";
import PropTypes from 'prop-types';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { destroyUserRoles, indexUserRoles, storeUserRoles } from "../../../api/UserRoles";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import ModalComp from "../../Layouts/Modal";
import TableComp from "../../Layouts/Table";
import { useTranslation } from "react-i18next";
import usePagination from "../../../hooks/usePagination";

const ModalUserRoles = ({ onClose, hidden, roles, user_id, headers }) => {
    const [t] = useTranslation('settings');
    const hookUserRoles = usePagination({ defaultPageSize: 10 });

    useEffect(() => {
        const getUserRoles = async () => {
            hookUserRoles.setLoading(true);
            const fetch = await indexUserRoles({ headers, user_id });
            hookUserRoles.setData(fetch.data);
            hookUserRoles.setLoading(false);
        }
        getUserRoles();
    }, [hidden]);

    // Evento para asignar módulo a la fibra
    const handleChange = async (checked, role_id) => {
        if (checked) { // Se agrega la relación
            await storeUserRoles({ headers, data: { user_id, role_id } });
        } else {
            // Se elimina la relación
            const pos = hookUserRoles.data.findIndex(item => item.role_id === role_id);
            if (pos !== -1) {
                // Si el registro se encuentra, se elimina
                await destroyUserRoles({ headers, user_role_id: hookUserRoles.data[pos].id });
            }
        }
    }
    // Método para obtener si el registro existe o no
    const getChecked = (role_id) => {
        return hookUserRoles.data.some(item => item.role_id === role_id);
    }
    // Método para indicar si el switch está habilitado
    const getDisabled = () => false;

    return (
        <ModalComp
            onClose={onClose}
            hidden={hidden}
            title={t('users.modal-roles.title')}
            size="md"
            body={
                <TableComp
                    headers={[
                        '#',
                        t('users.modal-roles.code'),
                        t('users.modal-roles.name'),
                        t('users.modal-roles.actions'),
                    ]}
                    keys={['#', 'code', 'name']}
                    body={roles}
                    type="switch"
                    actions={{
                        checkedChildren: t('users.modal-roles.allow'),
                        unCheckedChildren: t('users.modal-roles.deny'),
                        onChange: handleChange,
                        getChecked: getChecked,
                        getDisabled: getDisabled
                    }}
                    loading={hookUserRoles.loading}
                />
            }
            footer={
                <ButtonIcon icon={faArrowLeft} name={t('users.modal-roles.close')} onClick={onClose} variant="outline-secondary" />
            }
        />
    );
};

ModalUserRoles.propTypes = {
    onClose: PropTypes.func.isRequired,
    hidden: PropTypes.bool.isRequired,
    roles: PropTypes.array.isRequired,
    user_id: PropTypes.number.isRequired,
    headers: PropTypes.string.isRequired,
}

export default ModalUserRoles;
