import React from 'react'
import PropTypes from 'prop-types'
import TableComp from '../../Layouts/Table'
import PaginationComp from '../../Layouts/Pagination'
import { destroyPurchaseOrderVouchers, downloadPurchaseOrderVouchers, getBlobPurchaseOrderVouchers } from '../../../api/PurchaseOrderVouchers'
import { useTranslation } from 'react-i18next'
import { faEye, faFileDownload, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import usePreview from '../../../hooks/usePreview'
import ShowFile from './ShowFile'
import { hookPaginationPropType } from '../../../data/propTypes'
import { useToast } from '@chakra-ui/react'
import useLoading from '../../../hooks/useLoading'
import useConfirmation from '../../../hooks/useConfirm'
import Swal from 'sweetalert2'

const PaymentVouchers = ({ headers, hookVouchers }) => {
	const [t] = useTranslation('purchase_orders');
	const toast = useToast();
	const { confirmAction } = useConfirmation();
	const hookPreview = usePreview();
	const { LoadingModal, hideLoading, showLoading } = useLoading();

	const { page, pageSize, totalItems } = hookVouchers.pagination;

	// Método para visualizar archivo
	const handleViewFile = async (item) => {
		hookVouchers.setDataSel(item);
		hookPreview.setLoading(true);
		const fetch = await getBlobPurchaseOrderVouchers({ headers, purchase_order_voucher_id: item.id });
		hookPreview.onOpen();
		if (fetch.blob) {
			hookPreview.setBlobFile(fetch.blob);
		}
		hookPreview.setLoading(false);
	}
	// Método para descargar archivo
	const handleDownloadFile = async (item) => {
		showLoading();
		await downloadPurchaseOrderVouchers({ headers, purchase_order_voucher_id: item.id, name: item.file_name, toast });
		hideLoading();
	}
	// Eliminar comprobante de pago
	const handleDropItem = (item) => {
		const { id } = item;
		const title = t('vouchers.sf.title-confirm');
		const html = t('vouchers.sf.html-confirm');
		const confirmButtonText = t('vouchers.sf.btn-confirm');

		confirmAction(title, html, confirmButtonText, async () => {
			showLoading();
			const fetch = await destroyPurchaseOrderVouchers({ headers, purchase_order_voucher_id: id });
			if (fetch) {
				updateVouchersData(fetch.row);
				Swal.fire(t('vouchers.sf.title-succ'), t('vouchers.sf.succ-confirm'), 'success')
			} else {
				Swal.fire(t('vouchers.sf.title-fail'), t('vouchers.sf.fail-confirm'), 'error')
			}
			hideLoading();
		});
	}

	const updateVouchersData = (row) => {
		hookVouchers.setData(prevState => {
			return prevState.filter(({ id: voucherId }) => voucherId !== row.id);
		});
		hookVouchers.setTotalItems(totalItems - 1);
	};
	return (
		<>
			<TableComp
				headers={[
					t('vouchers.id'),
					t('vouchers.name'),
					t('vouchers.mime'),
					t('vouchers.created-by'),
					t('vouchers.created-at'),
					t('vouchers.folio'),
					t('vouchers.actions')
				]}
				keys={['#', 'file_name', 'mime', 'created_by.name', 'created_at', 'payment_order.folio']}
				body={hookVouchers.data}
				page={page}
				loading={hookVouchers.loading}
				loadingItems={pageSize}
				moment_dates={[{ index: 4, format: 'LLL' }]}
				actions={[
					{ icon: faEye, handleClick: handleViewFile, tooltip: t(`vouchers.tt.view`), variant: 'outline-primary' },
					{ icon: faFileDownload, handleClick: handleDownloadFile, tooltip: t(`vouchers.tt.download`), variant: 'outline-success' },
					{ icon: faTrashAlt, handleClick: handleDropItem, tooltip: t(`vouchers.tt.drop`), variant: 'outline-danger', showWhenNull: ['payment_order.invoice_id']},
				]}
			/>
			<PaginationComp
				onChange={hookVouchers.changePage}
				current={page}
				totalItems={totalItems}
				defaultPageSize={pageSize}
			/>
			{/* Vista previa del archivo */}
			{
				hookPreview.showPreview && <ShowFile
					onClose={() => hookPreview.onClose()}
					hookPreview={hookPreview}
					hookVouchers={hookVouchers}
				/>
			}
			{/* Mostrar estado de carga */}
			<LoadingModal />
		</>
	)
}

PaymentVouchers.propTypes = {
	headers: PropTypes.shape({
		Authorization: PropTypes.string.isRequired
	}).isRequired,
	hookVouchers: hookPaginationPropType
}

export default PaymentVouchers