import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import TableComp from '../../../Layouts/Table'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

const AssignedProvider = ({ items, loading, disabled, removeItem }) => {
    const [t] = useTranslation('requisition');
    const prefix_list = `modal-details.items-list`;

    return (
        <TableComp
            headers={[
                '#',
                t(`${prefix_list}.description`),
                t(`${prefix_list}.detailed`),
                t(`${prefix_list}.unit`),
                t(`${prefix_list}.quantity`),
                t(`${prefix_list}.actions`)
            ]}
            keys={['#', 'description', 'detailed', 'unit', 'quantity']}
            body={items}
            actions={[{
                icon: faTrashAlt, variant: 'outline-danger', tooltip: t(`${prefix_list}.remove-item`),
                handleClick: removeItem, disabled
            }]}
            loading={loading}
        />
    )
}

AssignedProvider.propTypes = {
    items: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    removeItem: PropTypes.func.isRequired
}

export default AssignedProvider