import { faShare } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { indexUser } from '../../../api/Users';
import PaginationComp from '../../Layouts/Pagination';
import TableComp from '../../Layouts/Table';

const Users = (props) => {
    const { fibra_id } = props;

    const store = useStore();

    const [t] = useTranslation('global');
    const prefix = 'super-admin.users';

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, settotalItems] = useState(0);

    useEffect(() => {
        let { token } = store.getState().login;
        let headers = { Authorization: `Bearer ${token}` };
        getUsers(headers);
    }, [store]);

    const getUsers = async (headers) => {
        let response = await indexUser({ headers, fibra_id });
        setUsers(response.data);
        settotalItems(response.totalItems);
        setLoading(false);
    }

    // Evento lanzado al cambiar de página
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }

    const viewDetails = () => {

    }

    return (
        <Fragment>

            <TableComp
                headers={[
                    '#',
                    t(`${prefix}.name`),
                    t(`${prefix}.email`),
                    t(`${prefix}.client`),
                    t(`${prefix}.last-access`),
                    t(`${prefix}.created-at`),
                    t(`${prefix}.actions`),
                ]}
                keys={['#', 'first_name++last_name', 'email', 'fibra', 'last_access', 'created_at']}
                body={users}
                actions={[
                    { icon: faShare, tooltip: t(`${prefix}.tooltip-action`), variant: 'outline-success', handleClick: viewDetails }
                ]}
                loading={loading}
                loadingItems={pageSize}
                moment_dates={[{ index: 4, format: 'LLLL' }, { index: 5, format: 'LLLL' }]}
            />

            <PaginationComp current={page} totalItems={totalItems} onChange={changePage} />
        </Fragment>
    );
}

export default Users;