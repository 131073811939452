import { useState } from "react"

const useForm = () => {
    const [form, setForm] = useState({});
    const [params, setParams] = useState({});
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);

    // Método para manejar cambios en el formulario
    const handleChange = (event) => {
        const { name, value, type } = event.target;
        if (type === "file") {
            setFile(event.target.files[0]);
        } else {
            setForm({ ...form, [name]: value });
        }
    };
    // Método para limpiar el archivo seleccionado y los datos del formulario
    const clearForm = () => {
        setFile(null);
        setForm({});
    }

    // Método para borrar el archivo seleccionado
    const handleFileDelete = () => {
        setFile(null);
    };

    // datos del formulario a los parámetros para que se obtengan los usuarios filtrados
    const updateParams = () => {
        setParams(form);
    }
    return {
        form,
        params,
        file,
        loading,
        setForm,
        setParams,
        updateParams,
        clearForm,
        handleChange,
        handleFileDelete,
        setLoading
    }
}

export default useForm;