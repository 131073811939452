import Fetcher from "../libs/Petition"
import getBlobFile from "../libs/getBlobFile";

const url = '/business_partners';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param fibra_id Se utiliza el identificador de la fibra para obtener los usuarios que le corresponden
 * @param role_code [Opcional] Obtiene los registros de un role segúnsu código
 * @returns BusinessPartner::collection() Retorna una colección de objetos de tipo User
 */
export const indexBusinessPartners = async ({ headers, fibra_id, page, pageSize }) => {
    let response = { data: [], totalItems: 0 };
    try {
        let params = { fibra_id };
        if (page) params.page = page;
        if (pageSize) params.pageSize = pageSize;
        const fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        // console.log("🚀 ~ file: Users.js:27 ~ indexBusinessPartners ~ fetch:", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = { data: [], totalItems: 0 }
    }

    return response;
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param business_partner_id Identificador del usuario sobre el que se hará la consulta
 * @returns BusinessPartner Retorna un objeto tipo User
 */
export const showBusinessPartners = async ({ headers, business_partner_id }) => {
    let response = [];
    try {
        let fetch = await Fetcher({
            url: `${url}/${business_partner_id}`,
            method: 'GET',
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = [];
    }

    return response;
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storeBusinessPartners = async ({ headers, data }) => {
    let response = true;
    try {
        return await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
    } catch (error) {
        return response
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param business_partner_id Identificador del usuario que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updateBusinessPartners = async ({ headers, data, business_partner_id }) => {
    let response = { res: false, data: {} };
    try {
        const fetch = await Fetcher({
            url: `${url}/${business_partner_id}`,
            method: 'PUT',
            data,
            headers
        });
        // console.log("🚀 ~ file: Users.js:97 ~ updateBusinessPartners ~ fetch:", fetch)

        if (fetch.status === STATUS_OK) {
            response.res = true;
            response.data = fetch.data.data;
        } else if (fetch.status === 422) {
            response.data = fetch.data.errors;
        }
        return response;
    } catch (error) {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param business_partner_id Identificador del usuario que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyBusinessPartners = async ({ headers, business_partner_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${business_partner_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    }

    return response;
}

/**
 * Almacenar Constancia de situación fiscal, Opinión de cumplimiento y Estado de cuenta
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param business_partner_id Identificador del usuario que se va a eliminar
 * @returns BlobFile
 */
export const uploadBusinessPartners = async ({ headers, data }) => {
    let response = false;
    try {
        const fetch = await Fetcher({
            url: `${url}/upload`,
            method: 'POST',
            data, headers
        });
        // console.log("🚀 ~ uploadBusinessPartners ~ fetch:", fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
        return response;
    } catch (error) {
        return response;
    }
}

/**
 * Descargar Constancia de situación fiscal, Opinión de cumplimiento y Estado de cuenta
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param business_partner_id Identificador del usuario que se va a eliminar
 * @returns BlobFile
 */
export const downloadBusinessPartners = async ({ headers, business_partner_id, type }) => {
    try {
        return await getBlobFile({ headers, url: `${url}/download/file`, params: { type, business_partner_id } });
    } catch (error) {
        return null;
    }
}