import PropTypes from 'prop-types';
import { useDisclosure } from "@chakra-ui/hooks";
import { useRef, Fragment } from "react";
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerFooter,
} from "@chakra-ui/react";
import ButtonIcon from "./Forms/ButtonIcon";
import { faFilter, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const DrawerComp = ({
    title,
    children,
    showFooter,
    handleClickSearch = () => { },
    mainTooltip,
    mainName,
    mainIcon = faFilter,
    mainVariant = 'outline-secondary'
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = useRef();
    const [t] = useTranslation('global');

    return (
        <Fragment>
            <ButtonIcon
                tooltip={mainTooltip || t('layouts.drawer.btn-tt')}
                name={mainName || t('layouts.drawer.btn-name')}
                icon={mainIcon}
                variant={mainVariant}
                onClick={onOpen}
            />
            <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth='1px'>{title}</DrawerHeader>
                    <DrawerBody>
                        {children}  {/* Filtros de búsqueda */}
                    </DrawerBody>
                    {
                        showFooter &&
                        <DrawerFooter style={{ display: 'block' }}>
                            <div className="d-grid gap-2 mt-4">
                                <ButtonIcon
                                    onClick={handleClickSearch}
                                    size='lg'
                                    name={t('layouts.drawer.btn-search')}
                                    icon={faSearch}
                                    variant='outline-success'
                                />
                            </div>
                        </DrawerFooter>
                    }
                </DrawerContent>
            </Drawer>

        </Fragment >
    );
}

DrawerComp.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    showFooter: PropTypes.bool,
    handleClickSearch: PropTypes.func.isRequired,
    mainTooltip: PropTypes.string,
    mainName: PropTypes.string,
    mainIcon: PropTypes.any,
    mainVariant: PropTypes.string,

}

export default DrawerComp;