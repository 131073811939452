import { faInfo } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { indexUserSessionLogs } from '../../../api/UserSessionLogs';
import PaginationComp from '../../Layouts/Pagination';
import TableComp from '../../Layouts/Table'
import ModalLogsDetails from './ModalLogsDetails';

const UserLogs = (props) => {
    const { fibra_id } = props;

    const token = useSelector(state => state.login.token);
    const headers = { Authorization: `Bearer ${token}` };
    const [t] = useTranslation('global');
    const prefix = 'super-admin.logs';

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [totalItems, setTotalItems] = useState(0);
    const [modalDetails, setModalDetails] = useState(false);
    const [logSel, setLogSel] = useState({});

    useEffect(() => {
        getData();
    }, [page, pageSize]);

    const getData = async () => {
        setLoading(true);
        let response = await indexUserSessionLogs({ headers, page, pageSize, fibra_id });
        setData(response.data);
        setTotalItems(response.totalItems);
        setLoading(false);
    }
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }
    const showDetails = (item) => {
        setLogSel(item);
        setModalDetails(!modalDetails);
    }
    return (
        <>
            <TableComp
                headers={[
                    '#',
                    t(`${prefix}.user-name`),
                    t(`${prefix}.user-email`),
                    t(`${prefix}.device-type`),
                    t(`${prefix}.browser-name`),
                    t(`${prefix}.platform-name`),
                    t(`${prefix}.created-at`),
                    t(`${prefix}.actions`)
                ]}
                keys={['#', 'user_name', 'user_email', 'device_type', 'browser_name', 'platform_name', 'created_at']}
                body={data}
                loading={loading}
                loadingItems={pageSize}
                page={page}
                actions={[
                    { icon: faInfo, tooltip: t(`${prefix}.modal-tooltip`), handleClick: showDetails }
                ]}
                moment_dates={[{ index: 6 , format: 'LLLL'}]}
            />
            <PaginationComp totalItems={totalItems} current={page} onChange={changePage} defaultPageSize={pageSize} />
            {/* Modal para ver los detalles */}
            {
                modalDetails && <ModalLogsDetails
                    onClose={() => setModalDetails(!modalDetails)}
                    logSel={logSel}
                />
            }
        </>
    )
}

export default UserLogs