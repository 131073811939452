import { useEffect } from 'react'
import usePagination from './usePagination';
import { indexBusinessName } from '../api/Cfdi/BusinessNames';

const useBusinessNames = ({ headers, fibra_id, defaultPageSize = 100 }) => {
    const hookBusinessNames = usePagination({ defaultPageSize });
    const { page, pageSize } = hookBusinessNames.pagination;

    useEffect(() => {
        const getBusinessNames = async () => {
            hookBusinessNames.setLoading(true);
            const { data, totalItems } = await indexBusinessName({
                headers,
                page,
                pageSize,
                fibra_id
            });
            hookBusinessNames.setTotalItems(totalItems)
            hookBusinessNames.setData(data)
            hookBusinessNames.setLoading(false);
        }
        getBusinessNames();
    }, [page, pageSize, fibra_id]);

    return hookBusinessNames;
}

export default useBusinessNames
