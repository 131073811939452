export const handleValidationErrors = (errors) => {
    let errorMessageList = '';

    for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
            const errorMessages = errors[key];
            errorMessageList += errorMessages.map(errorMessage => `<li>${errorMessage}</li>`).join('');
        }
    }
    return errorMessageList;
}