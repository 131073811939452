import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/store';
import { CookiesProvider } from 'react-cookie';
import { ChakraProvider } from "@chakra-ui/react";
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import 'bootstrap/dist/css/bootstrap.css';
import { importAll } from './libs/Languages';

require('moment-timezone');

/**
 * Crear un contexto que incluye todos los archivos .json 
 * dentro del directorio ./lang y sus subdirectorios.
 */
const langContext = require.context('./lang', true, /\.json$/);

// Obtener el idioma almacenado en el almacenamiento local
const lang = localStorage.getItem('lang') || 'es';

// Inicializar i18next para las traducciones
i18next.init({
	// Configuración de interpolación para evitar problemas de seguridad
	interpolation: { escapeValue: false },

	// Idioma inicial obtenido del almacenamiento local o por defecto 'es'
	lng: lang,

	// Cargar dinámicamente todas las traducciones desde el directorio ./lang
	resources: importAll(langContext),
});

ReactDOM.render(
	<React.StrictMode>
		<CookiesProvider>
			<ChakraProvider>
				<I18nextProvider i18n={i18next}>
					<Provider store={store}>
						<App />
					</Provider>
				</I18nextProvider>
			</ChakraProvider>
		</CookiesProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();