import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import usePagination from "./usePagination";
import { indexRequisitions } from "../api/Budget/requisitions";
import { SET_REQUISITIONS } from "../redux/actions/main";
import { useTranslation } from "react-i18next";

const useRequisitions = ({ fibra_id, headers, provider_id, type, loading, business_name_ids, segment_ids, filters }) => {
    const [t] = useTranslation('requisition');
    const prefix = 'table.headers';
    const requisitionsStored = useSelector((state) => state.requisitions);
    const dispatch = useDispatch();
    const hookRequisitions = usePagination({ defaultPageSize: 10 });
    const { page, pageSize } = hookRequisitions.pagination;

    useEffect(() => {
        const { page, pageSize } = requisitionsStored;
        hookRequisitions.changePage(page, pageSize)
    }, []);

    useEffect(() => {
        const getRequisitions = async () => {
            hookRequisitions.setLoading(true);
            const { data, totalItems } = await indexRequisitions({
                fibra_id,
                headers,
                page,
                pageSize,
                provider_id,
                ...requisitionsStored?.filters,
                segment_ids,
                business_name_ids
            });
            dispatch({ type: SET_REQUISITIONS, requisitions: { totalItems, data, page, pageSize } });
            // Si es proveedor se obtiene el estado de la requisición,
            // es decir, si ya cargó precios unitarios
            if (type === 'provider') {
                hookRequisitions.setData(data.map((item) => {
                    const { ended_date, provider_items } = item;
                    const unit_prices = provider_items.filter(provider_item => provider_item.provider_id === provider_id)
                    const isCompleted = unit_prices.every(unitPrice => unitPrice.unit_price);
                    if (isCompleted) item.has_unit_prices = t(`${prefix}.completed`);
                    else {
                        const today = new Date();
                        if (new Date(ended_date) < today) item.has_unit_prices = t(`${prefix}.expired`);
                        else item.has_unit_prices = t(`${prefix}.pending`);
                    }
                    return item;
                }));
            } else {
                hookRequisitions.setData(data);
            }
            hookRequisitions.setTotalItems(totalItems)
            hookRequisitions.setLoading(false);
        }
            
        // if (requisitionsStored?.filters !== filters) {
        //     console.log("Requisiciones cargadas del store")
        //     hookRequisitions.setLoading(true);
        //     hookRequisitions.setData(requisitionsStored.data);
        //     hookRequisitions.setTotalItems(requisitionsStored.totalItems);
        //     hookRequisitions.setLoading(false);
        // } else {
        //     console.log("Requisiciones cargadas de la api")
        if(!loading) getRequisitions();
        // }
    }, [page, pageSize, requisitionsStored?.filters, loading]);

    return hookRequisitions;
}

export default useRequisitions;