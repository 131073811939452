import { Fragment } from "react";
import PropTypes from 'prop-types';
import { Form, Row } from "react-bootstrap";
import FormIcon from "../../Layouts/Forms/FormIcon";
import SkeletonForm from "../../Layouts/Skeletons/Form";

const moment = require('moment');

const MainData = ({ loading, data }) => {
    return (
        <Fragment>
            {loading ? (
                <SkeletonForm rows={4} cols={3} />
            ) : (
                <Form>
                    <Row className="mt-3">
                        <FormIcon title="Código de entrega" value={data?.code || ''} />
                        <FormIcon title="Código RM" value={data?.code_rm || ''} />
                        <FormIcon title="Código RF" value={data?.code_rf || ''} />
                    </Row>
                    <Row className="mt-3">
                        <FormIcon title="Entregar desde" value={data?.start_date ? moment(data.start_date).format('LLLL') : ''} />
                        <FormIcon title="Fecha límite" value={data?.end_date ? moment(data.end_date).format('LLLL') : ''} />
                    </Row>
                    <Row className="mt-3">
                        <FormIcon title="Programó entrega" value={data?.schedules_user || ''} />
                        <FormIcon title="Recibió entrega" value={data?.receives_user || ''} />
                    </Row>
                    <Row className="mt-3">
                        <FormIcon title="Proveedor" value={data?.provider || ''} />
                        <FormIcon title="Orden de compra" value={data?.purchase_order || ''} />
                    </Row>
                    <Row className="mt-3">
                        <FormIcon title="Fecha de creación" value={data?.created_at ? moment(data.created_at).format('LLLL') : ''} />
                        <FormIcon title="Fecha última actualización" value={data?.updated_at ? moment(data.updated_at).format('LLLL') : ''} />
                    </Row>
                </Form>
            )}
        </Fragment>
    );
};

MainData.propTypes = {
    loading: PropTypes.bool.isRequired,
    data: PropTypes.shape({
        code: PropTypes.string,
        code_rm: PropTypes.string,
        code_rf: PropTypes.string,
        start_date: PropTypes.string,
        end_date: PropTypes.string,
        schedules_user: PropTypes.string,
        receives_user: PropTypes.string,
        provider: PropTypes.string,
        purchase_order: PropTypes.string,
        created_at: PropTypes.string,
        updated_at: PropTypes.string
    }).isRequired
};

export default MainData;
