import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Container } from 'react-bootstrap'
import ModalComp from '../../Layouts/Modal'
import UnitPrice from './modalDetails/UnitPrice'
import { Alert, AlertIcon } from '@chakra-ui/react'
import HeadersComp from './modalDetails/Headers'
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import ButtonIcon from '../../Layouts/Forms/ButtonIcon'
import { useTranslation } from 'react-i18next'
import useForm from '../../../hooks/useForm'
import usePagination from '../../../hooks/usePagination'
import { indexSegments } from '../../../api/Segments'
import { indexBusinessName } from '../../../api/Cfdi/BusinessNames'
import { showRequisition } from '../../../api/Budget/requisitions'

const DetailsProvider = ({ onClose, title, requisition, type, setData, headers, fibra_id, provider_id }) => {
    const [t] = useTranslation('requisition');
    const prefix = 'modal-details';
    const unitPricesRef = useRef(null);
    const { form, handleChange } = useForm();
    const hookBusinessNames = usePagination({ defaultPageSize: 50 });
    const hookSegments = usePagination({ defaultPageSize: 50 });
    // Partidas de la orden de compra seleccionada
    const hookItems = usePagination({ defaultPageSize: 100 });
    // Datos por default, en caso de abrir requisición existente
    const [defaultData, setDefaultData] = useState({});
    // Ocultar botón al presionarlo para evitar duplicidad de información
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [currentProviderItems, setCurrentProviderItems] = useState([]);
    // Obtener los segmentos
    useEffect(() => {
        const getSegments = async () => {
            hookSegments.setLoading(true);
            const { page, pageSize } = hookSegments.pagination;
            const fetch = await indexSegments({ headers, fibra_id, page, pageSize });
            hookSegments.setData(fetch.data);
            hookSegments.setTotalItems(fetch.totalItems);
            hookSegments.setLoading(false);
        }
        getSegments();
    }, []);
    // Obtener razones sociales de la empresa
    useEffect(() => {
        const getBusinessNames = async () => {
            hookBusinessNames.setLoading(true);
            const { data } = await indexBusinessName({ headers, fibra_id });
            hookBusinessNames.setData(data);
            hookBusinessNames.setLoading(false);
        }
        getBusinessNames();
    }, []);
    // Proveedor - Verificar si el proveedor ya cargó los precios unitarios
    useEffect(() => {
        if (Array.isArray(defaultData.provider_items)) {
            setCurrentProviderItems(defaultData.provider_items.filter((item) => item.provider.id === provider_id));
        }
    }, [defaultData.provider_items]);
    // Obtener datos de la requisición
    useEffect(() => {
        const getRequisition = async () => {
            const response = await showRequisition({ headers, requisition_id: requisition?.id });
            if (response?.items) {
                setDefaultData(response);
                hookItems.setData(response.items.map((item, index) => ({ ...item, index: index + 1 })) || []);
                hookItems.setLoading(false);
            }
        }
        getRequisition();
    }, []);
    // Requisición ya expiró?
    const isRequisitionExpired = () => {
        const today = new Date();
        const requisitionEndDate = new Date(requisition?.ended_date);
        // Sumar 6 horas a la fecha actual
        requisitionEndDate.setHours(requisitionEndDate.getHours() + 6);
        return requisitionEndDate <= today;
    }
    const onSave = () => {
        unitPricesRef.current.saveUnitPrices();
    }
    // Deshabilitar botón para guardar
    const isSaveButtonDisabled = () => {
        // Si la fecha de expiración ya venció
        const isExpired = type === 'provider' && isRequisitionExpired();
        // Si el proveedor ya tiene precios unitarios
        const isCompleted = currentProviderItems.every(currentProviderItem => currentProviderItem.unit_price);
        // Verificar si las partidas fueron asignadas a una orden de compra
        const isAssigned = currentProviderItems.every(currentProviderItem => {
            const itemAssigned = hookItems.data.find(item => item.id === currentProviderItem.requisition_item_id);
            return itemAssigned && itemAssigned.provider_id !== null;
        });
        const hasUnitPrices = type === 'provider' && isCompleted && isAssigned;
        return isExpired || hasUnitPrices;
    }

    return (
        <ModalComp
            title={title}
            onClose={onClose}
            size={'xl'}
            body={<Container fluid>
                {
                    isRequisitionExpired() &&
                    <Alert status="warning"><AlertIcon />{t(`${prefix}.expired-alert`)}</Alert>
                }
                {/* Headers */}
                <HeadersComp
                    handleChange={handleChange}
                    requisition={requisition}
                    form={form}
                    type={type}
                    hookBusinessNames={hookBusinessNames}
                    hookSegments={hookSegments}
                />
                <UnitPrice
                    items={hookItems.data}
                    ref={unitPricesRef}
                    requisition_id={requisition.id}
                    providerItems={defaultData?.provider_items}
                    onClose={onClose}
                    setData={setData}
                    setShowSaveButton={setShowSaveButton}
                />
            </Container>}
            footer={<>
                <ButtonIcon
                    icon={faTimes}
                    name={t(`${prefix}.close`)}
                    variant='outline-secondary'
                    onClick={onClose}
                />
                {/* Ocultar el botón cuando se clickee */}
                {
                    showSaveButton &&
                    <ButtonIcon
                        icon={faSave}
                        name={t(`${prefix}.save`)}
                        variant='outline-primary'
                        onClick={onSave}
                        disabled={isSaveButtonDisabled()}
                    />
                }
            </>}
        />
    )
}

DetailsProvider.propTypes = {
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    requisition: PropTypes.shape({
        id: PropTypes.number.isRequired,
        ended_date: PropTypes.string.isRequired
    }).isRequired,
    type: PropTypes.string.isRequired,
    setData: PropTypes.func.isRequired,
    headers: PropTypes.string.isRequired,
    fibra_id: PropTypes.number.isRequired,
    provider_id: PropTypes.number.isRequired
}

export default DetailsProvider