import { Fragment, useEffect, useState } from "react";
import { useStore } from "react-redux";
import TableComp from "../../Layouts/Table";
import { indexRepseMovements } from "../../../api/Repse/RepseMovements";
import PaginationComp from "../../Layouts/Pagination";
import { useTranslation } from "react-i18next";

const Movements = () => {
    const store = useStore();
    const { token, fibra } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };

    const [movements, setMovements] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalItems, setTotalItems] = useState(0);            // Cantidad de registros de la consulta
    const [page, setPage] = useState(1);                        // Página actual que se está consultando
    const [pageSize, setPageSize] = useState(10);               // Cantidad de registros por página
    const [t] = useTranslation('global');

    useEffect(() => {
        getMovements();
    }, [page, pageSize]);

    const getMovements = async () => {
        let response = await indexRepseMovements({ headers, fibra_id: fibra.id, page, pageSize });
        setMovements(response.data);
        setTotalItems(response.totalItems);
        setLoading(false);
    }
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }
    const viewDetails = () => {

    }
    return (
        <Fragment>
            <TableComp
                headers={[
                    '#',
                    t('repse.movements.headers.user'),
                    t('repse.movements.headers.title'),
                    t('repse.movements.headers.description'),
                    t('repse.movements.headers.created-at'),
                    t('repse.movements.headers.actions')
                ]}
                keys={['id', 'user', 'title', 'description', 'created_at']}
                body={movements}
                loading={loading}
                loadingItems={pageSize}
                actions={[
                    { tooltip: t('repse.movements.tooltips.view-details'), handleClick: viewDetails }
                ]}
                moment_dates={[{ index: 4 , format: 'LLLL'}]}
            />
            <PaginationComp
                totalItems={totalItems}
                current={page}
                onChange={changePage}
                defaultPageSize={pageSize}
            />
        </Fragment>
    );
}

export default Movements;