import { useEffect } from 'react'
import usePagination from './usePagination';
import { indexSegments } from '../api/Segments';

const useSegments = ({ headers, fibra_id, defaultPageSize = 10, business_name_id = undefined }) => {
    const hookSegments = usePagination({ defaultPageSize });
    const { page, pageSize } = hookSegments.pagination;

    useEffect(() => {
        const getSegments = async () => {
            hookSegments.setLoading(true);
            const { data, totalItems } = await indexSegments({
                headers,
                page,
                pageSize,
                fibra_id,
                business_name_id
            });
            hookSegments.setTotalItems(totalItems)
            hookSegments.setData(data)
            hookSegments.setLoading(false);
        }
        getSegments();
    }, [page, pageSize, fibra_id, business_name_id]);

    return hookSegments;
}

export default useSegments
