import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormIcon from "../../Layouts/Forms/FormIcon";

const FormRelease = (props) => {
    const { handleChangeFile, handleChangeData, data, edit } = props;
    const [t] = useTranslation('global');
    const prefix = 'releases.data';

    return (
        <Form>
            <Row className="mb-3">
                <FormIcon title={t(`${prefix}.title`)} name="title" handleChange={handleChangeData} value={data?.title} />
                {edit ?
                    <FormIcon type="file" title={t(`${prefix}.file`)} handleChange={handleChangeFile} accept="application/pdf" />
                    :
                    <FormIcon title={t(`${prefix}.file`)} value={data?.created_at}/>
                }
            </Row>
            <Row className="mb-3">
                <Col>
                    <Form.Label>{t(`${prefix}.body`)}</Form.Label>
                    <textarea className='form-control' name='body' value={data?.body} onChange={handleChangeData}></textarea>
                </Col>
            </Row>
        </Form>
    );
}   

export default FormRelease;