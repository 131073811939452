import downloadFile from "../libs/DownloadFile";
import { fetcherCancelToken } from "../libs/Petition";

const url = '/purchase_order_vouchers';
const CREATED = 201;
const STATUS_OK = 200;

export const indexPurchaseOrderVouchers = async ({ headers, purchase_order_id, page, pageSize }) => {

    const response = { data: [], totalItems: 0 };

    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            params: { purchase_order_id, page, pageSize },
            headers
        });
        // console.log("🚀 ~ file: PurchaseOrderVouchers.js:18 ~ indexCedisPurchaseOrderVouchers ~ fetch:", fetch)

        if (fetch.status === STATUS_OK) {
            response.data = fetch.data.data;
            response.totalItems = fetch.data.totalItems;
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const updatePurchaseOrderVouchers = async ({ headers, data, purchase_order_voucher_id }) => {
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}/${purchase_order_voucher_id}`,
            method: 'PUT',
            data,
            headers
        });
        // console.log("🚀 ~ file: PurchaseOrderVouchers.js:58 ~ updateCedisPurchaseOrderVouchers ~ fetch:", fetch)

        if (fetch.status === STATUS_OK) {
            response = fetch.data;
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const destroyPurchaseOrderVouchers = async ({ headers, purchase_order_voucher_id }) => {
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}/${purchase_order_voucher_id}`,
            method: 'DELETE',
            headers
        });
        // console.log("🚀 ~ file: PurchaseOrderVouchers.js:77 ~ destroyCedisPurchaseOrderVouchers ~ fetch:", fetch)

        if (fetch.status === STATUS_OK) {
            response = fetch.data;
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const uploadPurchaseOrderVouchers = async ({ headers, data }) => {
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        // console.log("🚀 ~ file: PurchaseOrderVouchers.js:97 ~ uploadCedisPurchaseOrderVouchers ~ fetch:", fetch)

        if (fetch.status === CREATED) {
            response = fetch.data
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const getBlobPurchaseOrderVouchers = async ({ headers, purchase_order_voucher_id }) => {
    let response = { blob: null, cancel: () => { } };
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}/${purchase_order_voucher_id}`,
            method: 'GET',
            responseType: "blob",
            headers
        });
        // console.log("🚀 ~ file: PurchaseOrderVouchers.js:117 ~ getBlobCedisPurchaseOrderVouchers ~ fetch:", fetch)
        if (fetch.status === STATUS_OK) {
            response.blob = new Blob([fetch.data], {
                type: fetch.data.type
            });
            response.cancel = cancel;
        }
        return response;;
    } catch (error) {
        return response;
    }
}

export const downloadPurchaseOrderVouchers = async ({ headers, purchase_order_voucher_id, name, toast }) => {
    try {
        await downloadFile({
            headers,
            url: `${url}/${purchase_order_voucher_id}`,
            type: 'application/json',
            name,
            toast
        });
        return true;
    } catch (error) {
        return false;
    }
}