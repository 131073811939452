import React from 'react'
import PropTypes from 'prop-types'
import ModalComp from '../../Layouts/Modal'
import ButtonIcon from '../../Layouts/Forms/ButtonIcon'
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Col, Form, Row } from 'react-bootstrap'
import FormIcon from '../../Layouts/Forms/FormIcon'
import { storeSegment, updateSegment } from '../../../api/Segments'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import { Select } from '@chakra-ui/react'
import useForm from '../../../hooks/useForm'

const ModalAddComp = ({ edit, onClose, headers, fibra_id, hookSegments, hookBusinessNames }) => {
    const segment = hookSegments.dataSel;
    const { form, loading, setLoading, handleChange } = useForm()
    const [t] = useTranslation('segments');

    const clearCacheAndExit = () => {
        hookSegments.setDataSel(null);
        onClose();
    }

    const onSave = async () => {
        if (validate()) {
            setLoading(true);
            const response = await storeSegment({ headers, data: { ...form, fibra_id } });
            if (response) {
                const { row } = response;
                hookSegments.addElement({ ...row, contract_file: null });
                Swal.fire(t('sf.t-success'), t('sf.m-segment-add'), 'success');
                clearCacheAndExit();
            } else {
                Swal.fire(t('sf.t-fail'), t('sf.m-fail-server'), 'warning');
            }
            setLoading(false);
        }
    }

    const onUpdate = async () => {
        setLoading(true);
        const response = await updateSegment({ headers, data: { ...form }, segment_id: segment.id });
        if (response) {
            hookSegments.updateElement(response.row);
            Swal.fire(t('sf.t-success'), t('sf.m-segment-edit'), 'success');
            clearCacheAndExit();
        } else {
            Swal.fire(t('sf.t-fail'), t('sf.m-fail-server'), 'warning');
        }
        setLoading(false);
    }

    const validate = () => {
        const { business_name_id, code, name, budget } = form;
        if (!(code && name && budget && business_name_id)) {
            Swal.fire(t('sf.t-form'), t('sf.m-form'), 'info');
            return false;
        }
        return true;
    }
    return (
        <ModalComp
            onClose={clearCacheAndExit}
            title={t('modal.title')}
            size='md'
            body={<>
                {/* Seleccionar la razón social */}
                <Row className='mb-3'>
                    <Col>
                        <Form.Label>{t('modal.business_name')}</Form.Label>
                        <Select placeholder={t('modal.business_name_select')} name='business_name_id'
                            defaultValue={segment?.business_name_id} value={form?.business_name_id} onChange={handleChange}>
                            {
                                hookBusinessNames.data.map((business_name) =>
                                    <option key={business_name.id} value={business_name.id}>
                                        {business_name.name}
                                    </option>
                                )
                            }
                        </Select>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <FormIcon title={t('modal.code')} name='code' defaultValue={segment?.code} value={form?.code}
                        handleChange={handleChange} maxLength={50} />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title={t('modal.name')} name='name' defaultValue={segment?.name} value={form?.name}
                        handleChange={handleChange} maxLength={200} />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title={t('modal.budget')} type='number' name='budget' defaultValue={segment?.budget} value={form?.budget}
                        handleChange={handleChange} />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title={t('modal.contract-code')} name='contract_code' defaultValue={segment?.contract_code} value={form?.contract_code}
                        handleChange={handleChange} maxLength={100} />
                </Row>
            </>}
            footer={<>
                <ButtonIcon icon={faTimes} name={t('modal.close')} onClick={clearCacheAndExit} variant='outline-secondary' />
                {
                    edit ?
                        <ButtonIcon icon={faSave} name={t('modal.update')} onClick={onUpdate} variant='outline-success' disabled={loading} /> :
                        <ButtonIcon icon={faSave} name={t('modal.store')} onClick={onSave} variant='outline-success' disabled={loading} />
                }
            </>}
        />
    )
}

ModalAddComp.propTypes = {
    edit: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    headers: PropTypes.string.isRequired,
    fibra_id: PropTypes.number.isRequired,
    hookSegments: PropTypes.object.isRequired,
    hookBusinessNames: PropTypes.object.isRequired
}

export default ModalAddComp