import { useEffect } from 'react';
import usePagination from './usePagination';
import { indexRelease } from '../api/Releases';

const useReleases = ({ headers, fibra_id, role_id, user_id, active }) => {
    const hookReleases = usePagination({ defaultPageSize: 10 });
    const { page, pageSize } = hookReleases.pagination;

    useEffect(() => {
        const getReleases = async () => {
            hookReleases.setLoading(true);
            const { data, totalItems } = await indexRelease({
                headers,
                page,
                pageSize,
                fibra_id,
                role_id,
                user_id,
                active
            });
            hookReleases.setTotalItems(totalItems);
            hookReleases.setData(data);
            hookReleases.setLoading(false);
        };

        getReleases();
    }, [fibra_id, page, pageSize, role_id, user_id, active]);

    return hookReleases;
};

export default useReleases;
