import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ModalComp from '../../Layouts/Modal'
import { hookPaginationPropType } from '../../../data/propTypes';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import { faCloudUploadAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import UploadInvoice from '../fragments/Modal/UploadInvoice';
import { useTranslation } from 'react-i18next';
import useForm from '../../../hooks/useForm';
import { getCfdiStatuses } from '../../../api/Cfdi/CfdiStatuses';
import { useSelector } from 'react-redux';
import { uploadInvoice, validateInvoice } from '../../../api/Cfdi/Mailbox';
import Swal from 'sweetalert2';
import FormInvoice from '../../Mailbox/fragments/invoices/FormInvoice';
import SkeletonForm from '../../Layouts/Skeletons/Form';
import { handleValidationErrors } from '../../../libs/errors';

const UploadInvoiceModal = ({ onClose, hookPaymentOrders, purchaseOrder }) => {
    const { user_id } = purchaseOrder;
    const paymentOrder = hookPaymentOrders.dataSel;
    const [t] = useTranslation('purchase_orders');
    const p_data = 'invoices-modal';
    const { token, fibra } = useSelector(prevState => prevState.login);
    const fibra_id = fibra.id;
    const headers = { Authorization: `Bearer ${token}` };
    const { folio } = hookPaymentOrders.dataSel;
    const hookForm = useForm();
    const { pdf, xml } = hookForm.form;

    const [cfdiStatusId, setCfdiStatusId] = useState(null);
    const [invoice, setInvoice] = useState({});
    const [successfull, setSuccessfull] = useState(false);

    // Obtener los estados de CFDi de la empresa
    useEffect(() => {
        const getStatuses = async () => {
            const data = await getCfdiStatuses({ headers, fibra_id });
            const exists = data.find(item => item.code === 'inbox')
            if (exists) setCfdiStatusId(exists.id);
        }
        getStatuses();
    }, []);

    const handleLoadFile = (e) => {
        const { name } = e.target;
        hookForm.setForm(prevState => ({ ...prevState, [name]: e.target.files[0] }));
    }
    // Validar que el nombre de los dos archivos coincida
    const validateNames = () => {
        const pdf_name = pdf.name.toLowerCase().split('.pdf')[0];
        const xml_name = xml.name.toLowerCase().split('.xml')[0];
        return pdf_name === xml_name;
    }
    // Validar que los datos de la factura correspondan a la orden de compra
    const validate = async (data) => {
        let res = true;
        const fetch = await validateInvoice({ headers, data });
        if (fetch.status === 200) {
            const { currency, total, rfc_receptor } = fetch.data;
            // El tipo de moneda debe ser igual
            if (currency !== purchaseOrder.currency) {
                res = false;
            }
            const paymentOrderTotal = paymentOrder.partial;
            // El monto debe corresponder a la orden de pago
            if (parseFloat(total).toFixed(2) !== parseFloat(paymentOrderTotal).toFixed(2)) {
                res = false;
            }
            // El RFC receptor debe ser el que se asigna a la orden de compra en caso de existir
            if (purchaseOrder.business_name && rfc_receptor !== purchaseOrder.business_name.rfc) {
                res = false;
            }

            if (!res)
                Swal.fire(t(`${p_data}.toasts.wrn-valid-data.title`), t(`${p_data}.toasts.wrn-valid-data.description`), 'info');
        } else if (fetch.status === 422) {
            res = false;
            const errorMessageList = handleValidationErrors(fetch.data?.errors);
            Swal.fire({ title: t(`${p_data}.toasts.swal-err-xml.title`), html: `<ul>${errorMessageList}</ul>`, icon: 'info' });
        } else {
            res = false;
            const status = getStatusError(fetch);
            const description = t(`${p_data}.toasts.swal-err-xml.description`) + status;
            Swal.fire(t(`${p_data}.toasts.swal-err-xml.title`), description, 'error');
        }
        return res;
    }
    // Obtener código de error emitido por el Web Service del SAT
    const getStatusError = (fetch) => {
        let status = '';
        try {
            const { CodigoEstatus, Estado } = fetch.data.data.msg;
            status = `\nEstado: ${Estado}\nCódigo: ${CodigoEstatus}`;
        } catch (error) {
            return status;
        }
        return status;
    }
    const handleAddInvoice = async () => {
        hookForm.setLoading(true);
        if (xml && pdf) {
            const params = {
                fibra_id,
                user_id,
                cfdi_status_id: cfdiStatusId,
                payment_order_id: paymentOrder?.id
            };
            const data = new FormData();
            data.append('xml', xml);
            data.append('pdf', pdf);
            const validNames = validateNames();
            if (validNames) {
                const valid = await validate(data);
                if (valid) {
                    let response = await uploadInvoice({ headers, data, params });
                    if (response.status === 201) {
                        // Guardar datos para mostrar información
                        setInvoice(response.data.data);
                        // Indicar que la carga se ha realizado de manera satisfactoria
                        setSuccessfull(true);
                        // Actualizar estado de ordenes de pago
                        hookPaymentOrders.updateElement({
                            ...hookPaymentOrders.dataSel,
                            has_invoice: 'Recibida',
                            invoice_id: response.data.data?.id
                        });
                        Swal.fire(t(`${p_data}.toasts.swal-succ-add.title`), t(`${p_data}.toasts.swal-succ-add.description`), 'success');
                    } else {
                        Swal.fire(t(`${p_data}.toasts.swal-err-xml.title`), t(`${p_data}.toasts.swal-err-xml.description`), 'error');
                    }
                }
            } else {
                Swal.fire(t(`${p_data}.toasts.wrn-valid-names.title`), t(`${p_data}.toasts.wrn-valid-names.description`), 'info');
            }
        } else {
            Swal.fire(t(`${p_data}.toasts.swal-err-load.title`), t(`${p_data}.toasts.swal-err.load.description`), 'error');
        }
        hookForm.setLoading(false);
    }
    return (
        <ModalComp
            onClose={onClose}
            title={successfull ? t(`${p_data}.title-uploaded`) : t(`${p_data}.title`) + folio}
            size={'lg'}
            body={<>
                {/* Visualizar datos si la factura se carga correctamente */}
                {
                    successfull && <FormInvoice data={invoice} />
                }
                {/* Mostrar estado de carga si los datos se siguen procesando */}
                {
                    !successfull && hookForm.loading && <SkeletonForm rows={5} cols={3} />
                }
                {
                    !hookForm.loading && !successfull && <UploadInvoice
                        t={t}
                        p_data={p_data}
                        handleLoadFile={handleLoadFile}
                    />
                }
            </>}
            footer={<>
                <ButtonIcon name={t(`${p_data}.close`)} icon={faTimes} onClick={onClose} variant='outline-secondary' />
                {
                    !successfull &&
                    <ButtonIcon name={t(`${p_data}.upload`)} icon={faCloudUploadAlt} onClick={handleAddInvoice} variant='outline-success' />
                }
            </>}
        />
    )
}

UploadInvoiceModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    hookPaymentOrders: hookPaginationPropType,
    purchaseOrder: PropTypes.shape({
        user_id: PropTypes.number,
        currency: PropTypes.string,
        business_name: PropTypes.shape({
            rfc: PropTypes.string
        })
    }).isRequired
}

export default UploadInvoiceModal