import axios from "axios";

const axiosAPIInstance = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER}/api`
});


const Fetcher = async (args) => {
    let response;
    try {
        response = await axiosAPIInstance(args);
        return response;
    } catch (error) {
        if (error.response) {
            // La petición fue realizada y el servidor responde con un status code
            // Si obtiene una respuesta Unauthenticate, redirigir al login
            // console.log("Error response: ", error.response);
            if (error.response.status === 401) { // Si es una petición no autorizada
                // window.location.href = '/logout';
                // console.log("Cerrando sesión!", document, window);
            }
            response = error.response;
        } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            response = error.request;
        } else {
            // Alguna configuración de la petición ha provocado un error
            response = error.message;
        }
    } finally {
        return response;
    }
}

export const fetcherCancelToken = async (args) => {
    const source = axios.CancelToken.source();
    try {
        const response = await axiosAPIInstance({
            ...args,
            cancelToken: source.token,
        });
        return {
            fetch: response,
            cancel: () => source.cancel("Solicitud cancelada")
        };
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log("La petición fue cancelada:", error.message);
        } else {
            const { response } = error;
            if (response && response.status === 401) {
                console.log("Cerrando sesión!");
            }
            return { fetch: response || error.request || error.message, cancel: () => { } };
        }
    }
};

export default Fetcher;                 