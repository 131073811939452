import Fetcher from "../libs/Petition";

const url = '/app_requests';

const CREATED = 201;
const STATUS_OK = 200;

export const createAppRequest = async ({ headers, data }) => {
    let response = false;
    try {
        const fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        // console.log("🚀 ~ file: apps.js:17 ~ createAppRequest ~ fetch:", fetch)

        if (fetch.status === CREATED)
            response = fetch.data;
    } catch (error) {
        response = false;
    }
    return response;

}