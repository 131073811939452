import { Heading } from "@chakra-ui/layout";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { SET_ACTIVE_TAB } from "../../redux/actions/main";
import TableComp from "../Layouts/Table";

const CouponsAdmin = () => {
    const [t] = useTranslation('global');
    const prefix = 'coupons.data';
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/coupons' });
    }, []);

    return (
        <Container fluid>
            <Heading>{t('coupons.title')}</Heading>
            <TableComp
                headers={[
                    '#',
                    t(`${prefix}.subject`),
                    t(`${prefix}.type`),
                    t(`${prefix}.created-at`),
                    t(`${prefix}.actions`)
                ]}
                moment_dates={[{ index: 3, format: 'LLLL' }]}
            />
        </Container>
    );
}

export default CouponsAdmin;