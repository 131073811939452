import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { PageHeader, Select } from "antd";
import { Card, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Signup from "../../Signup/Index";
import { indexFibras } from "../../../api/AccessControlUsers";

const SignupForm = (props) => {
    const { goBack } = props;
    const [t] = useTranslation('global');
    const [fibras, setFibras] = useState([]);
    const [fibraID, setFibraID] = useState(1);

    useEffect(() => {
        const getFibras = async () => {
            const { data } = await indexFibras({});
            if (Array.isArray(data)) {
                const TICONSA = data.filter(fibra => fibra.code === 'TICONSA');
                if (Array.isArray(TICONSA) && TICONSA.length === 1) {
                    setFibras([...TICONSA]);
                    setFibraID(TICONSA[0].id);
                }
            }
        }
        getFibras();
    }, []);

    const handleChangeFibra = (value) => {
        setFibraID(value);
    }

    return (
        <Container>
            <Card id='signup-form'>
                <Card.Body>
                    <PageHeader
                        onBack={() => goBack()}
                        title={t('login.signup.title')}
                        subTitle={t('login.signup.subtitle')}
                        extra={[
                            <Select key='fibras' placeholder='Fibra' onChange={handleChangeFibra} name='type' style={{ width: 180 }} defaultValue={fibraID} value={fibraID}>
                                {
                                    fibras.map((fibra) =>
                                        <option key={fibra.id} value={fibra.id}>{fibra.name}</option>
                                    )
                                }
                            </Select>,
                        ]}
                    />
                    {/* Formulario para registrar proveedor. path: Signup/Index*/}
                    <Signup fibra_id={fibraID} goBack={goBack} />
                </Card.Body>
            </Card>
        </Container >
    );
}

SignupForm.propTypes = {
    goBack: PropTypes.func.isRequired
}

export default SignupForm