import PropTypes from 'prop-types';
import { Steps } from 'antd';

const moment = require('moment');

const { Step } = Steps;

const Tracking = ({ tracking_items }) => {
	return (
		<div style={{ maxHeight: '300px', overflowY: 'auto' }}> {/* Establece la altura máxima y agrega desplazamiento vertical */}
			<Steps progressDot current={tracking_items.length} direction="vertical">
				{
					tracking_items.reverse().map((item) =>
						<Step
							key={item.id}
							title={item.title}
							description={`${item.description}. ${moment(item.created_at).format('LLLL')}.`}
						/>
					)
				}
			</Steps>
		</div>
	);
};

Tracking.propTypes = {
	tracking_items: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			title: PropTypes.string.isRequired,
			description: PropTypes.string.isRequired,
			created_at: PropTypes.string.isRequired
		})
	)
}
export default Tracking;
