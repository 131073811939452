import { Fragment, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FormIcon from '../../Layouts/Forms/FormIcon';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import { faEye, faEyeSlash, faUserLock } from '@fortawesome/free-solid-svg-icons';
import { changePassword } from '../../../api/Authentification';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ChangePassword = () => {
    const [t] = useTranslation('global');
    const prefix = 'settings.security';
    const token = useSelector(state => state.login.token);
    const user_id = useSelector(state => state.login.user.id);
    const headers = { Authorization: `Bearer ${token}` };
    const dispatch = useDispatch();

    const [data, setData] = useState({});
    const [show, setShow] = useState({ actual: false, new: false, confirm: false });

    const validate = () => {
        const { actual_password, new_password, confirm_password } = data;

        if (!actual_password || !new_password || !confirm_password) {
            Swal.fire(t(`${prefix}.val-fields-title`), t(`${prefix}.val-fields-desc`), 'info');
            return false;
        }

        if (new_password.length < 8) {
            Swal.fire(t(`${prefix}.val-length-title`), t(`${prefix}.val-length-desc`), 'info');
            return false;
        }

        if (new_password !== confirm_password) {
            Swal.fire(t(`${prefix}.val-match-title`), t(`${prefix}.val-match-desc`), 'info');
            return false;
        }

        return true;
    }

    const send = async () => {
        if (validate()) {
            const { res } = await changePassword({ headers, data: { ...data, user_id } });
            if (res) {
                Swal.fire(
                    t(`${prefix}.done-title`),
                    t(`${prefix}.done-desc`),
                    'success'
                );
                dispatch({ type: 'CLOSE_SESSION' });
            } else {
                Swal.fire(
                    t(`${prefix}.undone-title`),
                    t(`${prefix}.undone-desc`),
                    'warning'
                );
            }
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData(prevState => ({ ...prevState, [name]: value }));
    }

    return (
        <Fragment>
            <Row className='mt-4 mb-4'>
                <FormIcon
                    type={show.actual ? 'text' : 'password'}
                    title={t(`${prefix}.actual-pass`)}
                    name='actual_password'
                    value={data?.actual_password}
                    handleChange={handleChange}
                    re_icon={<FontAwesomeIcon icon={show.actual ? faEyeSlash : faEye} />}
                    re_onClick={() => setShow(prevState => ({ ...prevState, actual: !show.actual }))}
                />
                <FormIcon
                    type={show.new ? 'text' : 'password'}
                    title={t(`${prefix}.new-pass`)}
                    name='new_password'
                    value={data?.new_password}
                    handleChange={handleChange}
                    re_icon={<FontAwesomeIcon icon={show.new ? faEyeSlash : faEye} />}
                    re_onClick={() => setShow(prevState => ({ ...prevState, new: !show.new }))}
                />
                <FormIcon
                    type={show.confirm ? 'text' : 'password'}
                    title={t(`${prefix}.confirm-pass`)}
                    name='confirm_password'
                    value={data?.confirm_password}
                    handleChange={handleChange}
                    re_icon={<FontAwesomeIcon icon={show.confirm ? faEyeSlash : faEye} />}
                    re_onClick={() => setShow(prevState => ({ ...prevState, confirm: !show.confirm }))}
                />
            </Row>
            <ButtonIcon variant='outline-success' icon={faUserLock} name={t(`${prefix}.button-pass`)} onClick={send} />
        </Fragment>
    );
};

export default ChangePassword;
