export const tax_system = [
    { code: '601', name: 'General de Ley Personas Morales' },
    { code: '603', name: 'Personas Morales con Fines no Lucrativos' },
    { code: '605', name: 'Sueldos y Salarios e Ingresos Asimilados a Salarios' },
    { code: '606', name: 'Arrendamiento' },
    { code: '608', name: 'Demás ingresos' },
    { code: '609', name: 'Consolidación' },
    { code: '610', name: 'Residentes en el Extranjero sin Establecimiento Permanente en México' },
    { code: '611', name: 'Ingresos por Dividendos (socios y accionistas)' },
    { code: '612', name: 'Personas Físicas con Actividades Empresariales y Profesionales' },
    { code: '614', name: 'Ingresos por intereses' },
    { code: '616', name: 'Sin obligaciones fiscales' },
    { code: '620', name: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos' },
    { code: '621', name: 'Incorporación Fiscal' },
    { code: '622', name: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras' },
    { code: '623', name: 'Opcional para Grupos de Sociedades' },
    { code: '624', name: 'Coordinados' },
    { code: '628', name: 'Hidrocarburos' },
    { code: '607', name: 'Régimen de Enajenación o Adquisición de Bienes' },
    { code: '629', name: 'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales' },
    { code: '630', name: 'Enajenación de acciones en bolsa de valores' },
    { code: '615', name: 'Régimen de los ingresos por obtención de premios' },
    { code: '625', name: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas' },
    { code: '626', name: 'Régimen Simplificado de Confianza' },
];

export const payment_form = [
    { code: "01", name: 'Efectivo' },
    { code: "02", name: 'Cheque nominativo' },
    { code: "03", name: 'Transferencia electrónica de fondos' },
    { code: "04", name: 'Tarjeta de crédito' },
    { code: "05", name: 'Monedero electrónico' },
    { code: "06", name: 'Dinero electrónico' },
    { code: "08", name: 'Vales de despensa' },
    { code: "12", name: 'Dación en pago' },
    { code: "13", name: 'Pago por subrogación' },
    { code: "14", name: 'Pago por consignación' },
    { code: "15", name: 'Condonación' },
    { code: "17", name: 'Compensación' },
    { code: "23", name: 'Novación' },
    { code: "24", name: 'Confusión' },
    { code: "25", name: 'Remisión de deuda' },
    { code: "26", name: 'Prescripción o caducidad' },
    { code: "27", name: 'A satisfacción del acreedor' },
    { code: "28", name: 'Tarjeta de débito' },
    { code: "29", name: 'Tarjeta de servicios' },
    { code: "30", name: 'Aplicación de anticipos' },
    { code: "31", name: 'Intermediario pagos' },
    { code: "99", name: 'Por definir' },
];

export const cfdi_use = [
    { code: 'G01', name: 'Adquisición de mercancías.' },
    { code: 'G02', name: 'Devoluciones, descuentos o bonificaciones.' },
    { code: 'G03', name: 'Gastos en general.' },
    { code: 'I01', name: 'Construcciones.' },
    { code: 'I02', name: 'Mobiliario y equipo de oficina por inversiones.' },
    { code: 'I03', name: 'Equipo de transporte.' },
    { code: 'I04', name: 'Equipo de computo y accesorios.' },
    { code: 'I05', name: 'Dados, troqueles, moldes, matrices y herramental.' },
    { code: 'I06', name: 'Comunicaciones telefónicas.' },
    { code: 'I07', name: 'Comunicaciones satelitales.' },
    { code: 'I08', name: 'Otra maquinaria y equipo.' },
    { code: 'D01', name: 'Honorarios médicos, dentales y gastos hospitalarios.' },
    { code: 'D02', name: 'Gastos médicos por incapacidad o discapacidad.' },
    { code: 'D03', name: 'Gastos funerales.' },
    { code: 'D04', name: 'Donativos.' },
    { code: 'D05', name: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).' },
    { code: 'D06', name: 'Aportaciones voluntarias al SAR.' },
    { code: 'D07', name: 'Primas por seguros de gastos médicos.' },
    { code: 'D08', name: 'Gastos de transportación escolar obligatoria.' },
    { code: 'D09', name: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.' },
    { code: 'D10', name: 'Pagos por servicios educativos (colegiaturas).' },
    { code: 'S01', name: 'Sin efectos fiscales.' },
    { code: 'CP01', name: 'Pagos' },
    { code: 'CN01', name: 'Nómina' },
];