import { fetcherCancelToken } from "../../libs/Petition"

const url = '/cedis_delivery_files';
const CREATED = 201;
const STATUS_OK = 200;

export const indexCedisDeliveryFiles = async ({ headers, cedis_delivery_id }) => {

    const response = { data: [], totalItems: 0 };

    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            params: { cedis_delivery_id },
            headers
        });
        // console.log("🚀 ~ file: DeliveryFiles.js:18 ~ indexCedisDeliveryFiles ~ fetch:", fetch)

        if (fetch.status === STATUS_OK) {
            response.data = fetch.data.data;
            response.totalItems = fetch.data.totalItems;
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const showCedisDeliveryFiles = async ({ headers, cedis_delivery_file_id }) => {
    let response = {};
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}/${cedis_delivery_file_id}`,
            method: 'GET',
            headers
        });
        // console.log("🚀 ~ file: DeliveryFiles.js:38 ~ showCedisDeliveryFiles ~ fetch:", fetch)

        if (fetch.status === STATUS_OK) {
            response = fetch.data.data;
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const updateCedisDeliveryFiles = async ({ headers, data, cedis_delivery_file_id }) => {
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}/${cedis_delivery_file_id}`,
            method: 'PUT',
            data,
            headers
        });
        // console.log("🚀 ~ file: DeliveryFiles.js:58 ~ updateCedisDeliveryFiles ~ fetch:", fetch)

        if (fetch.status === STATUS_OK) {
            response = fetch.data;
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const destroyCedisDeliveryFiles = async ({ headers, cedis_delivery_file_id }) => {
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}/${cedis_delivery_file_id}`,
            method: 'DELETE',
            headers
        });
        // console.log("🚀 ~ file: DeliveryFiles.js:77 ~ destroyCedisDeliveryFiles ~ fetch:", fetch)

        if (fetch.status === STATUS_OK) {
            response = fetch.data;
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const uploadCedisDeliveryFiles = async ({ headers, data, cedis_delivery_id }) => {
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}/upload/${cedis_delivery_id}`,
            method: 'POST',
            data,
            headers
        });
        // console.log("🚀 ~ file: DeliveryFiles.js:97 ~ uploadCedisDeliveryFiles ~ fetch:", fetch)

        if (fetch.status === CREATED) {
            response = fetch.data.data
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const getBlobCedisDeliveryFiles = async ({ headers, cedis_delivery_file_id }) => {
    let response = { blob: null, cancel: () => { } };
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}/download/${cedis_delivery_file_id}`,
            method: 'GET',
            responseType: "blob",
            headers
        });
        // console.log("🚀 ~ file: DeliveryFiles.js:117 ~ getBlobCedisDeliveryFiles ~ fetch:", fetch)
        if (fetch.status === STATUS_OK) {
            response.blob = new Blob([fetch.data], {
                type: fetch.data.type
            });
            response.cancel = cancel;
        }
        return response;;
    } catch (error) {
        return response;
    }
}