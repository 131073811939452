import { useState } from 'react';

const useGet = () => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    return {
        data,
        setData,
        loading,
        setLoading
    };
};

export default useGet;
