export const currencyTypes = [
    { id: 1, name: 'Dólar estadounidense', code: 'USD' },
    { id: 2, name: 'Peso mexicano', code: 'MXN' },
    { id: 3, name: 'Euro', code: 'EUR' },
    { id: 4, name: 'Libra esterlina:', code: 'GBP' },
    { id: 5, name: 'Yen japonés', code: 'JPY' },
    { id: 6, name: 'Franco suizo', code: 'CHF' },
    { id: 7, name: 'Dólar canadiense', code: 'CAD' },
    { id: 8, name: 'Dólar australiano', code: 'AUD' },
    { id: 9, name: 'Real brasileño', code: 'BRL' },
    { id: 10, name: 'Peso argentino', code: 'ARS' }
];