import { faBoxes } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { indexModules } from "../../../api/Modules";
import { indexRoles } from "../../../api/Roles";
import PaginationComp from "../../Layouts/Pagination";
import TableComp from "../../Layouts/Table";
import ModalRoleModules from "./ModalRoleModules";



const Roles = (props) => {
    const { fibra_id } = props;

    const [t] = useTranslation('global');
    const prefix = 'super-admin.data';

    const login = useSelector(state => state.login);
    const headers = { Authorization: `Bearer ${login.token}` };

    const [modules, setModules] = useState([]);
    const [roles, setRoles] = useState([]);
    const [role_id, setRoleID] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);

    // Modal para editar permisos. Módulos a los que tiene acceso cada rol
    const [modalView, setModalView] = useState(false);

    useEffect(() => {
        getRoles();
        getModules();
    }, []);

    const getRoles = async () => {
        let response = await indexRoles({ headers, fibra_id });
        setRoles(response.data);
        setTotalItems(response.totalItems);
        setLoading(false);
    }
    // Obtiene todos los módulos disponibles
    const getModules = async () => {
        let response = await indexModules({ headers });
        setModules(response.data);
    }

    // Evento lanzado al cambiar de página
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }

    const operOrClose = () => setModalView(!modalView);

    const editPermissions = (item) => {
        setRoleID(item.id);
        operOrClose();
    }

    return (
        <Fragment>
            <TableComp
                headers={[
                    '#',
                    t(`${prefix}.code`),
                    t(`${prefix}.name`),
                    t(`${prefix}.created-at`),
                    t(`${prefix}.actions`)
                ]}
                keys={['#', 'code', 'name', 'created_at']}
                body={roles}
                loading={loading}
                loadingItems={pageSize}
                actions={[
                    { icon: faBoxes, tooltip: t(`${prefix}.module-assign`), handleClick: editPermissions, variant: 'outline-success' }
                ]}
                moment_dates={[{ index: 3 , format: 'LLLL'}]}
            />
            <PaginationComp
                current={page}
                totalItems={totalItems}
                onChange={changePage}
            />
            {/* Mostar modal para asignar permisos */}
            {
                modalView &&
                <ModalRoleModules
                    onClose={operOrClose}
                    role_id={role_id}
                    modules={modules}
                    headers={headers}
                />
            }
        </Fragment>
    );
}

export default Roles