import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import { Divider, Tag } from 'antd'
import { Heading } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { storeRequisitionProviderItems } from '../../../../api/Budget/requisition_item_providers'
import Swal from 'sweetalert2'
import UnitPriceTable from './UnitPriceTable'
import { useTranslation } from 'react-i18next'

const UnitPrice = forwardRef(({ items, loading, requisition_id, providerItems, onClose, setData, setShowSaveButton }, ref) => {
    const [t] = useTranslation('requisition');
    const prefix = 'modal-details.unit-prices';
    const login = useSelector(state => state.login);
    const headers = { Authorization: `Bearer ${login.token}`, 'Content-Type': 'multipart/form-data' };
    const provider_id = login.user.id;
    // Encabezados de la tabla
    const p_headers = prefix + ".headers";
    const tHeaders = ['#', t(`${p_headers}.description`), t(`${p_headers}.detailed`), t(`${p_headers}.unit`), t(`${p_headers}.quantity`),
        t(`${p_headers}.unit-price`), t(`${p_headers}.total`), t(`${p_headers}.file`)];
    // Lista de precios unitarios
    const [unitPrices, setUnitPrices] = useState([]);
    // Lista de archivos
    const [files, setFiles] = useState([]);
    const [currentProviderItems, setCurrentProviderItems] = useState([]);

    useEffect(() => {
        if (items.length > 0) {
            const providerItems = items.filter((item) => item.providers.find(provider => provider.id === provider_id))
            setUnitPrices(Array(providerItems.length).fill(0));
            setFiles(Array(providerItems.length).fill(null));
        }
    }, [items])
    // Obtener precios unitarios cargados por el proveedor actual
    useEffect(() => {
        if (Array.isArray(providerItems)) {
            setCurrentProviderItems(providerItems.filter((item) => item.provider.id === provider_id));
        }
    }, [providerItems]);
    // Si el proveedor ya ha cargado precios unitarios se muestran 
    useEffect(() => {
        if (currentProviderItems.length > 0) {
            const providerItems = items.filter((item) => item.providers.find(provider => provider.id === provider_id))
            setUnitPrices(providerItems.map((item) => {
                const exists = currentProviderItems.find(element => element.requisition_item_id === item.id);
                if (exists) return exists.unit_price;
                return 0;
            }));
        }
    }, [currentProviderItems]);
    // Manejador para la inserción de precios
    const handleChange = (event, index) => {
        const { value } = event.target;
        setUnitPrices(prevState => prevState.map((unitPrice, indexUnitPrice) => {
            if (indexUnitPrice === index) return value;
            return unitPrice
        }));
    }
    // Manejador de inserción de archivos
    const handleChangeFiles = (event, index) => {
        const file = event.target.files[0];
        setFiles(prevState => prevState.map((element, indexFile) => {
            if (indexFile === index) return file;
            return element
        }));
    }
    // Cargar los precios unitarios
    const saveUnitPrices = async () => {
        const formData = new FormData();
        formData.append('requisition_id', requisition_id);
        formData.append('provider_id', provider_id);
        const providerItems = items.filter((item) => item.providers.find(provider => provider.id === provider_id))
        for (let index = 0; index < providerItems.length; index++) {
            const item = providerItems[index];
            // El proveedor actual tiene asignada la partida?
            const actualProvider = item.providers.find(provider => provider.id === provider_id);
            if (actualProvider) {
                formData.append(`providerItems[${index}][requisition_item_id]`, item.id);
                // formData.append(`providerItems[${index}][provider_id]`, provider_id);
                // Agregar el precio unitario si no es 0
                if (unitPrices[index])
                    formData.append(`providerItems[${index}][unit_price]`, unitPrices[index]);
                // Agregar archivo solo si está presente
                if (files[index])
                    formData.append(`providerItems[${index}][file]`, files[index]);
            }
        }
        const response = await storeRequisitionProviderItems({ headers, data: formData });
        const p_save = prefix + ".save"
        if (response) {
            Swal.fire(t(`${p_save}.success.title`), t(`${p_save}.success.description`), 'success');
            // Cambiar estatus de la requisición
            setData(prevState => prevState.map((requisition) => {
                if (requisition.id === requisition_id) {
                    requisition.has_unit_prices = t(`table.headers.completed`)
                }
                return requisition;
            }));
            onClose();
        } else {
            setShowSaveButton(true);
            Swal.fire(t(`${p_save}.fail.title`), t(`${p_save}.fail.description`), 'info');
        }
    }
    // Obtener valores por default
    const getDefaultValue = (item) => {
        const exists = currentProviderItems.find((element) => element.requisition_item_id === item.id && element.unit_price)
        if (exists) return exists;
        return false;
    }
    // Mostrar si se subió un archivo o no
    const getTag = (exists) => {
        if (exists.file) return <Tag color='green'>{t(`${prefix}.tag-send-file`)}</Tag>
        return <Tag>{t(`${prefix}.tag-no-file`)}</Tag>
    }
    // Asignar la función al ref proporcionado por el componente padre
    useImperativeHandle(ref, () => ({
        saveUnitPrices,
    }));

    return (
        <Fragment>
            <Divider orientation='left'>
                <Heading size='sm'>{t(`${prefix}.heading`)}</Heading>
            </Divider>
            <UnitPriceTable
                tHeaders={tHeaders}
                loading={loading}
                items={items}
                getDefaultValue={getDefaultValue}
                handleChange={handleChange}
                unitPrices={unitPrices}
                getTag={getTag}
                handleChangeFiles={handleChangeFiles}
                provider_id={provider_id}
            />
        </Fragment>
    )
});

UnitPrice.propTypes = {
    items: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    requisition_id: PropTypes.number.isRequired,
    providerItems: PropTypes.arrayOf(
        PropTypes.shape({
            provider_id: PropTypes.number.isRequired,
            unit: PropTypes.number.isRequired,
            requisition_item_id: PropTypes.number.isRequired
        })
    ),
    onClose: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired,
    setShowSaveButton: PropTypes.func.isRequired
}

export default UnitPrice