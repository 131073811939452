import Fetcher from "../../libs/Petition";

const url = '/quizz_questions';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * ESTE MÉTODO NO ES NECESARIO, YA QUE LAS PREGUNTAS SE RETORNAN EN EL ENDPOINT "QUIZZS"
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param quizz_id Identificador de la encuesta de la que se desean obtener las preguntas
 * @returns Object {"data" => QuizzQuestions::collection(),"totalItems" => Cantidad de registros existentes}
 */
// export const indexQuizzQuestions = async (props) => {
//     const {
//         headers,
//         quizz_id } = props;
//     let response = [];
//     try {
//         // Parámetros requeridos
//         let params = { quizz_id };
//         let fetch = await Fetcher({
//             url: `${url}`,
//             method: 'GET',
//             params,
//             headers
//         });
//         if (fetch.status === STATUS_OK)
//             response = fetch.data;
//     } catch (error) {
//         response = {
//             data: [],
//             totalItems: 0
//         };
//     } finally {
//         return response;
//     }
// }
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param quizz_question_id Identificador de la encuesta sobre el que se hará la consulta
 * @returns Incident Retorna un objeto tipo Incident
 */
// export const showQuizzQuestions = async ({ headers, quizz_question_id }) => {
//     let response = [];
//     try {
//         let fetch = await Fetcher({
//             url: `${url}/${quizz_question_id}`,
//             method: 'GET',
//             headers
//         });
//         if (fetch.status === STATUS_OK)
//             response = fetch.data.data;
//     } catch (error) {
//         response = {};
//     } finally {
//         return response;
//     }
// }
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storeQuizzQuestions = async ({ headers, data }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        // console.log("Fetch store quizz questions: ", fetch);
        if (fetch.status !== CREATED)
            response = fetch.data;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param quizz_question_id Identificador de la encuesta que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updateQuizzQuestions = async ({ headers, data, quizz_question_id }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}/${quizz_question_id}`,
            method: 'PUT',
            data,
            headers
        });
        if (fetch.status === STATUS_OK)
            response = true;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param quizz_question_id Identificador de la encuesta que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyQuizzQuestions = async ({ headers, quizz_question_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${quizz_question_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}