import { Fragment, useState, useEffect } from "react";
import { Form, Row } from "react-bootstrap";
import Fetcher from "../../../../libs/Petition";
import FormGroupComponent from "../../../Layouts/Forms/FormGroup";
import SkeletonForm from "../../../Layouts/Skeletons/Form";
import { Divider } from "antd";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

const Info = (props) => {
    const [provider, setProvider] = useState({});
    const [loading, setLoading] = useState(true);
    const [t] = useTranslation('global');
    const prefix = ['repse.users.form'];
    const { user, headers } = props;

    useEffect(() => {
        getDataProvider();
    }, []);

    const getDataProvider = async () => {
        try {
            let response = await Fetcher({
                url: `/repse/users/${user.id}`, method: 'GET', headers
            });
            if (response.status === 200) {
                setProvider(response.data.data);
            }
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }

    return (
        <Fragment>
            {
                loading ?
                    <SkeletonForm rows={5} cols={3} /> :
                    <Form>
                        <Divider orientation="left"><strong>{t(`${prefix[0]}.title-1`)}</strong></Divider>
                        <Row className="mb-3">
                            <FormGroupComponent type='text' title={t(`${prefix[0]}.identifier`)} value={provider?.business_partner?.identifier} readOnly />
                            <FormGroupComponent type='text' title={t(`${prefix[0]}.business-name`)} value={provider?.business_partner?.business_name} readOnly />
                            <FormGroupComponent type='text' title={t(`${prefix[0]}.tax-id`)} value={provider?.business_partner?.rfc} readOnly />
                        </Row>
                        <Row className='mb-3'>
                            <FormGroupComponent type='text' title={t(`${prefix[0]}.alias`)} value={provider?.business_partner?.alias} readOnly />
                            <FormGroupComponent type='text' title={t(`${prefix[0]}.person-type`)} value={provider?.business_partner?.person_type} readOnly />
                            <FormGroupComponent type='text' title={t(`${prefix[0]}.partner-type`)} value={provider?.business_partner?.partner_type} readOnly />
                        </Row>
                        <Divider orientation="left"><strong>{t(`${prefix[0]}.title-2`)}</strong></Divider>
                        <Row className='mb-3'>
                            <FormGroupComponent type='text' title={t(`${prefix[0]}.first-name`)} value={provider?.first_name} readOnly />
                            <FormGroupComponent type='text' title={t(`${prefix[0]}.last-name`)} value={provider?.last_name} readOnly />
                            <FormGroupComponent type='text' title={t(`${prefix[0]}.email`)} value={provider?.email} readOnly />
                        </Row>
                        <Row className='mb-3'>
                            <FormGroupComponent type='text' title={t(`${prefix[0]}.nickname`)} value={provider?.nickname} readOnly />
                            <FormGroupComponent type='text' title={t(`${prefix[0]}.phone-number`)} value={provider?.phone_number} readOnly />
                            <FormGroupComponent type='text' title={t(`${prefix[0]}.gender`)} value={provider?.gender} readOnly />
                        </Row>
                    </Form>
            }
        </Fragment>
    );
}

Info.propTypes = {
    users: PropTypes.object,
    headers: PropTypes.string
}

export default Info;