import Fetcher from "../../libs/Petition"

const url = '/cedis_users';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param cedis_id Obtiene los usuarios asignados a un cedis [cedis_id o user_id]
 * @param user_id Obtiene los cedis a los que tiene acceso un usuario [cedis_id o user_id]
 * @param page [Opcional] Se utiliza para conocer la página en la que se encuentra
 * @param pageSize [Opcional] Se utiliza para especificar el tamaño de la página, es decir, la cantidad de registros que se obtendrán.
 * @returns Object {"data" => ComplaintSuggestionBox::collection(),"totalItems" => Cantidad de registros existentes}
 */
export const indexCedisUsers = async (props) => {
    const {
        headers,
        cedis_id,
        user_id,
        page,
        pageSize } = props;

    let response = { data: [], totalItems: 0 };

    try {
        // Parámetros requeridos
        let params = {};
        // Parámetros opcionales
        if (user_id) params.user_id = user_id;
        if (cedis_id) params.cedis_id = cedis_id;
        if (page) params.page = page;
        if (pageSize) params.pageSize = pageSize;
        const fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        // console.log("🚀 ~ file: CedisUsers.js:40 ~ indexCedisUsers ~ fetch:", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = {
            data: [],
            totalItems: 0
        };
    }

    return response;
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param cedis_user_id Identificador de la queja o sugerencia sobre el que se hará la consulta
 * @returns Module Retorna un objeto tipo Module
 */
export const showCedisUsers = async ({ headers, cedis_user_id }) => {
    let response = [];
    try {
        let fetch = await Fetcher({
            url: `${url}/${cedis_user_id}`,
            method: 'GET',
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = {};
    }

    return response;
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storeCedisUsers = async ({ headers, data }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        // console.log("Fetch store cedis user: ", fetch);
        if (fetch.status === CREATED)
            response = fetch.data;
    } catch (error) {
        response = false;
    }

    return response;
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param cedis_user_id Identificador de la queja o sugerencia que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updateCedisUsers = async ({ headers, data, cedis_user_id }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}/${cedis_user_id}`,
            method: 'PUT',
            data,
            headers
        });
        if (fetch.status === STATUS_OK)
            response = true;
        // console.log("Fetch update cedis: ", fetch);
    } catch (error) {
        response = false;
    }

    return response;
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param cedis_user_id Identificador de la queja o sugerencia que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyCedisUsers = async ({ headers, cedis_user_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${cedis_user_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    }

    return response;
}