import PropTypes from 'prop-types';
import TabsComp from "../../Layouts/Tabs";
import Invoices from "./invoices/Invoices";
import InvoicePayments from "./invoice_payments/InvoicePayments";
import { Container } from "react-bootstrap";

const Mailbox = (props) => {
    const {
        is_admin,
        hookInvoices,
        tactions,
        filters,
        setFilters,
        setShowTab
    } = props;

    const changeTab = (tab) => {
        if (setShowTab) setShowTab(tab === 0);
    }

    return (
        <Container fluid>
            <TabsComp
                tablist={['Facturas', 'Pagos']}
                tabpanels={[
                    <Invoices
                        key='invoices-tab-key'
                        hookInvoices={hookInvoices}
                        setFilters={setFilters}
                        filters={filters}
                        tactions={tactions}
                        is_admin={is_admin}
                    />,
                    <InvoicePayments
                        key='invoice-payments-tab-key'
                        is_admin={is_admin}
                    />
                ]}
                onChange={changeTab}
            />

        </Container>
    );
}

Mailbox.propTypes = {
    is_admin: PropTypes.bool,
    hookInvoices: PropTypes.shape({
        data: PropTypes.array.isRequired,
        dataSel: PropTypes.object.isRequired,
        pagination: PropTypes.shape({
            page: PropTypes.number.isRequired,
            pageSize: PropTypes.number.isRequired,
            totalItems: PropTypes.number.isRequired
        }),
        loading: PropTypes.bool.isRequired,
        setDataSel: PropTypes.func.isRequired,
        changePage: PropTypes.func.isRequired
    }).isRequired,
    tactions: PropTypes.array.isRequired,
    filters: PropTypes,
    setFilters: PropTypes.func.isRequired,
    setShowTab: PropTypes.func
}

export default Mailbox;