import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import CarouselComponent from '../../Layouts/Carousel'
import useConfirmation from '../../../hooks/useConfirm'
import { Container } from 'react-bootstrap'
import Swal from 'sweetalert2'
import moment from 'moment';
import { destroyCedisDeliveryFiles, getBlobCedisDeliveryFiles, indexCedisDeliveryFiles, uploadCedisDeliveryFiles } from '../../../api/Cedis/DeliveryFiles'

const Photos = ({ headers, items, cedis_delivery_id, syncServer }) => {
    const { confirmAction } = useConfirmation();
    const [loading, setLoading] = useState(true);
    const [loadingUpl, setLoadingUpl] = useState(false);
    const [loadingDel, setLoadingDel] = useState(false);
    const [pictures, setPictures] = useState([]);
    const [filesUpl, setFilesUpl] = useState([]);
    const [count, setCount] = useState(0);
    const [currentCount, setCurrentCount] = useState(0);

    useEffect(() => {
        let cancelLoop = false;
        let cancelTokens = [];

        const getEvidences = async () => {
            setPictures([]);
            setCount(0);
            setCurrentCount(0);
            const fetch = await indexCedisDeliveryFiles({ headers, cedis_delivery_id });
            setLoading(true);
            if (fetch.data.length > 0) {
                setCount(fetch.data.length);
                for (const file of fetch.data) {
                    if (cancelLoop) break;
                    const { blob, cancel } = await getBlobCedisDeliveryFiles({ headers, cedis_delivery_file_id: file.id });
                    cancelTokens.push({ cancel });
                    if (blob) {
                        const { id, created_at } = file;
                        let blob_file = URL.createObjectURL(blob);
                        setPictures(pictures => [...pictures, {
                            id,
                            image: blob_file,
                            created_at: `Evidencia capturada ${moment(created_at).format('LLLL')}`
                        }]);
                        setCurrentCount(currentCount => currentCount + 1);  // Contador del archivo obtenido
                        setLoading(false);
                    }
                }
            }
            setLoading(false);
        }

        getEvidences();

        return () => {
            cancelLoop = true;  // cancelar la petición del resto de las fotografías
            cancelTokens.forEach((token) => token.cancel()); // cancelar cada uno de los tokens creados
        }
    }, []);

    const deleteFile = async (item, index) => {
        const { id } = item;
        const title = '¿Está seguro que desea realizar esta operación?';
        const html = 'Al aceptar esta operación se eliminará el archivo de la base de datos. ¡Esta operación es irreversible!.';
        const confirmButtonText = 'Sí, eliminarlo';

        confirmAction(title, html, confirmButtonText, async () => {
            setLoadingDel(true);
            const response = await destroyCedisDeliveryFiles({ headers, cedis_delivery_file_id: id });
            if (response) {
                setPictures(prevState => prevState.filter(picture => picture.id !== id));
                setCount(prevState => prevState - 1);
                setCurrentCount(prevState => prevState - 1);
                Swal.fire('¡Operación exitosa!', 'El archivo ha sido eliminado.', 'success')
                syncServer();
            } else
                Swal.fire('¡Operación fallida!', 'Ha ocurrido un error al tratar de eliminar el archivo.', 'error')
            setLoadingDel(false);
        });
    }
    // Método para subir el archivo
    const handleUploadClick = async () => {
        if (filesUpl.length === 0) {
            Swal.fire('¡Sin archivos!', 'Necesita elegir un archivo.', 'warning');
            return;
        }
        setLoadingUpl(true);
        let count = 0;
        for (const iterator of filesUpl) {
            const data = new FormData();
            data.append('file', iterator);
            const fetchUpload = await uploadCedisDeliveryFiles({ headers, cedis_delivery_id, data });
            if (fetchUpload) {
                count++;
                const reader = new FileReader();
                // Asigna una función de callback para cuando se complete la lectura del archivo
                reader.onload = (e) => {
                    // Obtiene la URL de la vista previa
                    const image = e.target.result;
                    // Actualiza las fotografías del carousel con las fotografías obtenidas localmente
                    setPictures(prevState => [...prevState, { id: fetchUpload.id, image, created_at: moment().format('LLLL') }]);
                    setCount(prevState => prevState + 1);
                    setCurrentCount(prevState => prevState + 1);
                };
                // Lee el contenido del archivo como una URL
                reader.readAsDataURL(iterator);
                syncServer();
            }
        }
        switch (count) {
            case 0:
                Swal.fire('¡Operación fallida!', 'Hubo un error a tratar de agregar los archivos', 'error');
                break;
            case count < filesUpl.length:
                Swal.fire('¡Operación incompleta!', `Sólo ${count} archivos pudieron ser cargados.`, 'warning');
                break;
            default:
                Swal.fire('¡Operación exitosa!', `Los ${count} archivos fueron agregados al servidor`, 'success');
                break;
        }
        setLoadingUpl(false);
    };

    const handleLoadFile = (event) => {
        const files = event.target.files;
        setFilesUpl(files);
    }

    return (
        <Container>
            {
                count > 0 &&
                <p>Obteniendo {currentCount}/{count} fotografía{count === 1 ? '' : 's'}...</p>
            }
            <CarouselComponent
                evidences={pictures}
                loading={loading}
                enableDel={true}
                enableUpl={true}
                handleLoadFile={handleLoadFile}
                handleUploadClick={handleUploadClick}
                handleDeleteThumbnail={deleteFile}
                loadingDel={loadingDel}
                loadingUpl={loadingUpl}
            />
        </Container>
    )
}

Photos.propTypes = {
    headers: PropTypes.shape({
        Authorization: PropTypes.string.isRequired
    }).isRequired,
    items: PropTypes.array.isRequired,
    cedis_delivery_id: PropTypes.number.isRequired,
    syncServer: PropTypes.func.isRequired
}

export default Photos