import { Select } from '@chakra-ui/react'
import PageHeaderComp from '../Layouts/PageHeader'
import useBusinessNames from '../../hooks/useBusinessNames'
import { useSelector } from 'react-redux';
import useForm from '../../hooks/useForm';
import useSegments from '../../hooks/useSegments';
import TableComp from '../Layouts/Table';
import PaginationComp from '../Layouts/Pagination';
import DetailsModal from './Details';
import { faCalendar, faHistory } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import HistoryModal from './History';

const FinancialPlanning = () => {
	const login = useSelector(state => state.login);
	const headers = { Authorization: `Bearer ${login.token}` };
	const fibra_id = login.fibra.id;

	const [showDetailsModal, setShowDetailsModal] = useState(false);
	const [showHistoryModal, setShowHistoryModal] = useState(false);

	const hookForm = useForm();
	const hookBusinessNames = useBusinessNames({ headers, fibra_id });
	const hookSegments = useSegments({ headers, fibra_id, business_name_id: hookForm.form?.business_name_id });

	// Método para abrir el modal de planeación mensual
	const openDetailsModal = (item) => {
		hookSegments.setDataSel(item);
		setShowDetailsModal(true);
	}
	// Método para abrir el modal de planeación mensual
	const openHistoryModal = (item) => {
		hookSegments.setDataSel(item);
		setShowHistoryModal(true);
	}

	return (
		<>
			{/* Encabezado del módulo de planificación financiera */}
			<PageHeaderComp
				title='Planeación Financiera'
				actions={[
					<Select name='business_name_id' onChange={hookForm.handleChange} value={hookForm.form?.business_name_id}>
						{
							hookBusinessNames.data.map((item) =>
								<option key={item.id} value={item.id}>{item.name} | {item.rfc}</option>
							)
						}
					</Select>
				]}
			/>
			{/* Tabla para mostrar los segmentos de la razón social seleccionada */}
			<TableComp
				headers={['#', 'Código', 'Nombre', 'Código de contrato', 'Presupuesto', 'Creado en', 'Acciones']}
				keys={['#', 'code', 'name', 'contract_code', 'budget', 'created_at']}
				body={hookSegments.data}
				page={hookSegments.pagination.page}
				loadingItems={hookSegments.pagination.pageSize}
				loading={hookSegments.loading}
				currency={[{ index: 4 }]}
				moment_dates={[{ index: 5, format: 'LL' }]}
				actions={[
					{ icon: faCalendar, variant: 'primary', tooltip: 'Ver planificación mensual', handleClick: openDetailsModal },
					{ icon: faHistory, variant: 'outline-primary', tooltip: 'Ver historial', handleClick: openHistoryModal }
				]}
			/>
			{/* Componente de paginación de los segmentos */}
			<PaginationComp
				current={hookSegments.pagination.page}
				totalItems={hookSegments.pagination.totalItems}
				onChange={hookSegments.changePage}
				defaultPageSize={hookSegments.pagination.pageSize}
			/>
			{/* Modal para visualizar la planificación mensual */}
			<DetailsModal
				headers={headers}
				fibra_id={fibra_id}
				hidden={!showDetailsModal}
				onClose={() => setShowDetailsModal(false)}
				business_name_id={hookForm.form?.business_name_id}
				segment_id={hookSegments.dataSel?.id}
			/>
			{/* Modal para visualizar el historial de gastos de cada segmento */}
			<HistoryModal
				headers={headers}
				fibra_id={fibra_id}
				hidden={!showHistoryModal}
				onClose={() => setShowHistoryModal(false)}
				business_name_id={hookForm.form?.business_name_id}
				segment={hookSegments.dataSel}
			/>
		</>
	)
}

export default FinancialPlanning
