import { Fragment } from "react";
import PropTypes from 'prop-types';
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const FormFilesUpload = (props) => {
    const { handleLoadFile} = props;
    const [t] = useTranslation('global');
    const prefix = 'mailbox.form-complementary-payment';
    return (
        <Fragment>
            <Form.Group className="mb-3">
                <Form.Label>{t(`${prefix}.load-xml`)}</Form.Label>
                <Form.Control type="file" name="xml" onChange={handleLoadFile} accept="text/xml" required />
                <Form.Control.Feedback type='invalid'>{t(`${prefix}.load-xml-feedback`)}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>{t(`${prefix}.load-pdf`)}</Form.Label>
                <Form.Control type="file" name="pdf" onChange={handleLoadFile} accept="application/pdf" required />
                <Form.Control.Feedback type='invalid'>{t(`${prefix}.load-pdf-feedback`)}</Form.Control.Feedback>
            </Form.Group>
        </Fragment>
    );
}

FormFilesUpload.propTypes = {
    handleChange: PropTypes.func,
}

export default FormFilesUpload;