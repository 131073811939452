import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from "react";
import { showIncident } from "../../../api/Incidents/Incidents";
import Fetcher from "../../../libs/Petition";
import CarouselComponent from "../../Layouts/Carousel";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import ModalComp from "../../Layouts/Modal";
import TabsComp from "../../Layouts/Tabs";
import FormComponent from "./Form";
import MapComponet from '../../Layouts/Map';
import SkeletonForm from "../../Layouts/Skeletons/Form";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ModalIncident = (props) => {
    const { onClose, incident_id, headers, fibra_id } = props;
    const [incident, setIncident] = useState([]);
    const [evidences, setEvidences] = useState([]);
    const [loading, setLoading] = useState(true);
    const [ubications, setUbicactions] = useState([]);

    const [t] = useTranslation('global');
    const prefix = 'incidents.modal-details';

    useEffect(() => {
        getIncident();
    }, []);

    const getIncident = async () => {
        setLoading(true);
        let response = await showIncident({ headers, fibra_id, incident_id });
        let { latitude, user, code, longitude } = response;
        setIncident(response);
        setUbicactions([{
            title: code,
            name: user,
            position: { latitude, longitude }
        }]);
        getIncidentEvidences(response.evidences);

    }

    const getIncidentEvidences = async (_evidences) => {

        try {
            _evidences.map(async (item) => {
                await Fetcher({
                    url: `incident_evidences/download/${item.id}`,
                    method: 'GET',
                    responseType: 'blob',
                    headers
                }).then(response => {
                    let image = new Blob([response.data], { type: response.data.type });
                    let url = window.URL || window.webkitURL;
                    image = url.createObjectURL(image);
                    setEvidences(prevState => [...prevState, image]);
                });
            });
        } catch (error) {
            setEvidences([]);
        }
        setLoading(false);
    }

    return (
        <ModalComp
            size='lg'
            onClose={onClose}
            title={incident.code}
            body={
                loading ? <Form><SkeletonForm cols={3} rows={3} /></Form> :
                    <TabsComp
                        tablist={[
                            t(`${prefix}.tabs.general`),
                            t(`${prefix}.tabs.evidences`),
                            t(`${prefix}.tabs.ubication`)
                        ]}
                        tabpanels={[
                            <FormComponent incident={incident} loading={loading} />,
                            <CarouselComponent evidences={evidences} loading={loading} />,
                            <MapComponet ubications={ubications} loading={loading} />
                        ]}
                    />
            }
            footer={<Fragment >
                <ButtonIcon icon={faArrowLeft} name={t(`${prefix}.button-close`)} onClick={onClose} variant='outline-secondary' />
            </Fragment>}
        />
    );
}

export default ModalIncident;