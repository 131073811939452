import { useState, useEffect, Fragment } from "react";
import { useStore } from 'react-redux';
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Col, Button, Row } from "react-bootstrap";
import NoData from "../../Layouts/NoData";
import SkeletonCards from "../../Layouts/Skeletons/Cards";
import Fetcher from "../../../libs/Petition";

const Lots = (props) => {
    const store = useStore();
    const [parking_lots, setParkingLots] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let { token } = store.getState().login;
        let headers = { Authorization: `Bearer ${token}` };
        getParkingLots(headers);
    }, [props])

    const getParkingLots = async (headers) => {
        try {
            let response = await Fetcher({
                method: 'GET',
                url: `/parking_lots`,
                params: { mall_id: props.mall_id },
                headers
            });
            setParkingLots(response.data.data);
        } catch (error) {
            setParkingLots([]);
        }
        setLoading(false);
    }
    return (
        <Fragment>
            {!loading ?
                <Row xs={1} md={2} className="g-4 mt-2">
                    {
                        parking_lots.map((lot, index) => (
                            <Col key={index}>
                                <Card onClick={() => props.handleChange(lot)}>
                                    <Card.Body>
                                        <Card.Title>{lot.code}</Card.Title>
                                        <Card.Text>{lot.name}</Card.Text>
                                        <div id='footer-options'>
                                            <Button variant='outline-success'><FontAwesomeIcon icon={faEdit} /></Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                </Row> : <SkeletonCards items={8} cols={2} />
            }
            {/* Si ya no está obteniendo registros y la variable es vacia mostrar ícono de no datos */}
            {(!parking_lots || parking_lots.length === 0) && !loading ? <NoData /> : null}
        </Fragment>
    );
}

export default Lots;