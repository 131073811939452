import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from "react";
import { indexLocalMall } from "../../../api/Malls/LocalMalls";
import PaginationComp from "../../Layouts/Pagination";
import TableComp from "../../Layouts/Table";
import Details from "../modals/Details";
import Info from "../modals/Info";
import { indexMall } from "../../../api/Malls/Malls";
import Filters from "./Filters";
import { useTranslation } from "react-i18next";

const TableRents = (props) => {
    const { headers, fibra_id, user_id, update } = props;

    const [malls, setMalls] = useState([]);
    const [local_malls, setLocalMalls] = useState([]);      // Colección de locales disponibles
    const [local_id, setLocalID] = useState(null);          // Local seleccionado
    const [totalItems, setTotalItems] = useState(0);        // Cantidad de registros de la consulta
    const [page, setPage] = useState(1);                    // Página actual que se está consultando
    const [pageSize, setPageSize] = useState(10);           // Cantidad de registros por página
    const [loading, setLoading] = useState(true);           // indica si se están obteniendo registros
    const [modalView, setModalView] = useState(false);      // Modal de detalles del local
    const [modalInfo, setModalInfo] = useState(false);

    const [mall_id, setMallID] = useState(null);
    const [price_minor, setPriceMinor] = useState(null);
    const [price_major, setPriceMajor] = useState(null);
    const [m2, setM2] = useState(null);
    const [t] = useTranslation('global');
    const prefix = 'rents.data';

    useEffect(() => {
        getMalls();
    }, []);

    useEffect(() => {
        getRents();
    }, [page, pageSize, mall_id, m2, price_major, price_minor]);

    const getMalls = async () => {
        let response = await indexMall({ headers, fibra_id });
        setMalls(response);
    }
    const getRents = async () => {
        setLoading(true);
        let response = await indexLocalMall({ headers, fibra_id, page, pageSize, available: true, mall_id, m2, price_minor, price_major });
        // console.log("response local malls: ", response);
        setLocalMalls(response.data);
        setTotalItems(response.totalItems);
        setLoading(false);
    }

    // Evento lanzado al cambiar de página
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }

    const openOrCloseModalView = () => { setModalView(!modalView); };
    const openOrCloseModalInfo = () => { setModalInfo(!modalInfo); };

    const viewDetails = (item) => {
        setLocalID(item.id);
        openOrCloseModalView();
    }

    const info = (item) => {
        setLocalID(item.id);
        openOrCloseModalInfo();
    }

    const handleChangeMall = (value) => { setMallID(value); };
    const handleChangeM2 = (value) => { setM2(value); };
    const handleChangePriceMinor = (e) => { setPriceMinor(e.target.value); };
    const handleChangePriceMajor = (e) => { setPriceMajor(e.target.value); };

    return (
        <Fragment>
            <Filters
                handleChangeMall={handleChangeMall}
                handleChangeM2={handleChangeM2}
                handleChangePriceMinor={handleChangePriceMinor}
                handleChangePriceMajor={handleChangePriceMajor}
                malls={malls}
            />
            <TableComp
                headers={[
                    '#',
                    t(`${prefix}.code`),
                    t(`${prefix}.local`),
                    t(`${prefix}.flat`),
                    t(`${prefix}.m2`),
                    t(`${prefix}.price`),
                    t(`${prefix}.status`),
                    t(`${prefix}.actions`)
                ]}
                keys={['id', 'code', 'name', 'flat', 'm2', 'price_total', 'taken']}
                body={local_malls}
                loading={loading}
                loadingItems={pageSize}
                currency={[{ index: 5 }]}
                actions={[
                    // { icon: faInfoCircle, handleClick: info, tooltip: 'Solicitar información', variant: 'outline-secondary' },
                    { icon: faEye, handleClick: viewDetails, tooltip: t('rents.filters.view-details') },
                ]}
                badges={[{ index: 6, color: 'color' }]}
            />
            {/* Paginación de los locals disponibles */}
            <PaginationComp totalItems={totalItems} onChange={changePage} current={page} />
            {/* Modal para ver el detalle del local disponible */}
            {
                modalView &&
                <Details
                    local_mall_id={local_id}
                    fibra_id={fibra_id}
                    user_id={user_id}
                    headers={headers}
                    onClose={openOrCloseModalView}
                    update={update}
                />
            }
            {/* Modal para solicitar información sobre el local disponible */}
            {
                modalInfo &&
                <Info
                    local_id={local_id}
                    onClose={openOrCloseModalInfo}
                />
            }
        </Fragment>
    );
}

export default TableRents