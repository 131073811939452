import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SET_ACTIVE_TAB } from "../../redux/actions/main";
import ReleasesTable from "./fragments/Table";
import useReleases from '../../hooks/useReleases'; // Importamos el hook

const ReleasesProvider = () => {
    const login = useSelector(state => state.login);
    const role_selected = login.role_selected;
    const headers = { Authorization: `Bearer ${login.token}` };
    const fibra_id = login.fibra.id;

    const [t] = useTranslation('global');
    const prefix = 'releases.data';
    const dispatch = useDispatch();

    // Usamos el hook para obtener los comunicados filtrados por el rol del proveedor
    const hookReleases = useReleases({ headers, fibra_id, role_id: role_selected.id, active: 1 });

    // Establecemos la pestaña activa una vez que el componente se monte
    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/releases/provider' });
    }, [dispatch]);

    return (
        <ReleasesTable
            theaders={[
                '#',
                t(`${prefix}.title`),
                t(`${prefix}.status`),
                t(`${prefix}.created_at`),
                t(`${prefix}.actions`),
            ]}
            tkeys={['#', 'title', 'active', 'created_at']}
            tbadges={[{ index: 2, color: 'color_active' }]}
            hookReleases={hookReleases}
            moment_dates={[{ index: 3, format: 'LLLL' }]}
        />
    );
}

export default ReleasesProvider;
