import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import PDFViewerComp from './PDFViewer';
import CarouselComponent from './Carousel';
import { Container } from 'react-bootstrap';
import ButtonIcon from './Forms/ButtonIcon';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const ViewImageOrPDF = ({ file, loading }) => {
    const [t] = useTranslation('layouts');
    const [evidences, setEvidences] = useState([]);
    const [unsupportedFile, setUnsupportedFile] = useState(false);
    const [urlUnsupportedFile, setUrlUnsupportedFile] = useState(null);

    useEffect(() => {
        if (file.type.startsWith('image/')) {
            const url = window.URL || window.webkitURL;
            const image = url.createObjectURL(file);
            setUnsupportedFile(false);
            setEvidences([{ image, created_at: '' }]);
        } else if (file.type === 'application/pdf') {
            setUnsupportedFile(false);
        } else {
            const url = window.URL || window.webkitURL;
            const fileUrl = url.createObjectURL(file);
            setUrlUnsupportedFile(fileUrl);
            setUnsupportedFile(true);
        }
    }, [file]);

    const handleDownload = () => {
        if (urlUnsupportedFile) {
            const link = document.createElement('a');
            link.href = urlUnsupportedFile;
            link.setAttribute('download', 'file');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };
    return (
        <Container fluid>
            {
                file.type === 'application/pdf' &&
                <PDFViewerComp file={file} />
            }
            {
                file.type.startsWith('image/') &&
                <CarouselComponent
                    evidences={evidences}
                    loading={loading}
                    showThumbnails={false}
                    showFullscreenButton={false}
                />
            }
            {
                unsupportedFile &&
                <div style={{ textAlign: 'center' }}>
                    <p>{t('ViewImageOrPDF.p1')}</p>
                    <p>{t('ViewImageOrPDF.p2')}</p><br />
                    <ButtonIcon name={t('ViewImageOrPDF.btn-name')} icon={faDownload} variant="primary" onClick={handleDownload} />
                </div>
            }
        </Container >
    )
}

ViewImageOrPDF.propTypes = {
    file: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired
}

export default ViewImageOrPDF