import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ModalComp from '../../Layouts/Modal'
import HeadersComp from './modalDetails/Headers'
import AddItems from './modalDetails/AddItems'
import ItemsList from './modalDetails/ItemsList'
import AssignedProvider from './modalDetails/AssignedProvider'
import ButtonIcon from '../../Layouts/Forms/ButtonIcon'
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import useForm from '../../../hooks/useForm'
import usePagination from '../../../hooks/usePagination'
import { indexSegments } from '../../../api/Segments'
import { indexBusinessName } from '../../../api/Cfdi/BusinessNames'
import { showRequisition, storeRequisition, updateRequisition } from '../../../api/Budget/requisitions'
import Swal from 'sweetalert2'
import { Alert, AlertIcon, Heading } from '@chakra-ui/react'
import { Divider } from 'antd'

const DetailsAdmin = ({ onClose, title, requisition, type, headers, setData, fibra_id, addElement, loading, business_name_ids, segment_ids }) => {
	const [t] = useTranslation('requisition');
	const prefix = 'modal-details';
	const { form, handleChange } = useForm();
	const hookBusinessNames = usePagination({ defaultPageSize: 50 });
	const hookSegments = usePagination({ defaultPageSize: 50 });
	// Partidas de la orden de compra seleccionada
	const hookItems = usePagination({ defaultPageSize: 100 });

	// Datos de nueva partida para ser agregada
	const [item, setItem] = useState({});
	// Mostrar advertencia de datos incompletos la crear items
	const [showErrorItem, setShowErrorItem] = useState(false);
	// Array de items para ser eliminados 
	const [itemsDestroy, setItemsDestroy] = useState([]);
	// Ocultar botón al presionarlo para evitar duplicidad de información
	const [showSaveButton, setShowSaveButton] = useState(true);

	// Obtener los segmentos
	useEffect(() => {
		const getSegments = async () => {
			hookSegments.setLoading(true);
			const { page, pageSize } = hookSegments.pagination;
			const fetch = await indexSegments({ headers, fibra_id, page, pageSize });
			const allowedSegments = fetch.data.filter(segment => segment_ids.includes(segment.id));
			hookSegments.setData(allowedSegments);
			hookSegments.setTotalItems(fetch.totalItems);
			hookSegments.setLoading(false);
		}
		getSegments();
	}, []);
	// Obtener razones sociales de la empresa
	useEffect(() => {
		const getBusinessNames = async () => {
			hookBusinessNames.setLoading(true);
			const { data } = await indexBusinessName({ headers, fibra_id });
			const allowedBusinessNames = data.filter(business_name => business_name_ids.includes(business_name.id));
			hookBusinessNames.setData(allowedBusinessNames);
			hookBusinessNames.setLoading(false);
		}
		getBusinessNames();
	}, []);
	// Obtener datos de la requisición
	useEffect(() => {
		const getRequisition = async () => {
			const response = await showRequisition({ headers, requisition_id: requisition?.id });
			if (response?.items) {
				hookItems.setData(response.items.map((item, index) => ({ ...item, index: index + 1 })) || []);
				hookItems.setLoading(false);
			}
		}
		getRequisition();
	}, []);
	// Listener para obtener los cambios del formulario de nueva partida
	const changeItem = (e) => {
		const { name, value } = e.target;
		setItem({ ...item, [name]: value });
	}
	// Agregar nueva partida al array items y se limpian los datos en memoria
	const addItem = () => {
		let { description, quantity, unit, detailed } = item;
		if (description && quantity && unit && detailed) {
			setShowErrorItem(false);
			const newItem = { description, quantity, unit, detailed }
			hookItems.setData(prevState => [...prevState, { index: prevState.length + 1, ...newItem }]);
			setItem({ description: '', quantity: '', unit: '', detailed: '' });
		} else setShowErrorItem(true)
	}
	// Eliminar partida de la requisición
	const removeItem = (itemToRemove) => {
		// Si tiene un Id, se agrega al array de items a eliminar
		if (itemToRemove.id) setItemsDestroy([...itemsDestroy, itemToRemove.id]);
		// Se actualiza el estado, eliminando el item y actualizando los index
		hookItems.setData(prevItems =>
			prevItems
				.filter(item => item.index !== itemToRemove.index)
				.map((item, index) => ({ ...item, index: index + 1 }))
		);
	};
	// Validar información antes de subirla
	const validate = () => {
		const { business_name_id, description } = form;
		const prefix_v = `${prefix}.validate`;
		const showMessageAndReturnFalse = (messageKey) => {
			Swal.fire(t(`${prefix_v}.title`), t(messageKey), 'info');
			return false;
		};
		if (!business_name_id || business_name_id === '-1') {
			return showMessageAndReturnFalse(`${prefix_v}.business-name`);
		}
		if (!hookItems.data || hookItems.data.length === 0) {
			return showMessageAndReturnFalse(`${prefix_v}.items`);
		}

		if (!description || description === '') {
			return showMessageAndReturnFalse(`${prefix_v}.description`);
		}

		return true;
	}
	// Validar datos antes de actualizar
	const validateUpdate = () => {
		const { business_name_id, description, segment_id } = form;
		const prefix_v = `${prefix}.validate`;
		const showMessageAndReturnFalse = (messageKey) => {
			Swal.fire(t(`${prefix_v}.title`), t(messageKey), 'info');
			return false;
		};
		if (business_name_id && business_name_id === '-1') {
			return showMessageAndReturnFalse(`${prefix_v}.business-name`);
		}
		if (segment_id && segment_id === '-1') {
			return showMessageAndReturnFalse(`${prefix_v}.segment`);
		}
		if (hookItems.data.length === 0) {
			return showMessageAndReturnFalse(`${prefix_v}.items`);
		}

		if (description && description === '') {
			return showMessageAndReturnFalse(`${prefix_v}.description`);
		}

		return true;
	}
	// Buscar función del botón guardar
	const onSaveSearch = () => {
		if (requisition?.id) {
			onUpdate();
		} else {
			onSave();
		}
	}
	// Guardar la requisición
	const onSave = async () => {
		if (validate()) {
			const dataPayload = {
				fibra_id,
				...form,
				items: hookItems.data
			}
			const response = await storeRequisition({ headers, data: dataPayload });
			const prefixSave = `${prefix}.onSave`
			if (response) {
				Swal.fire(t(`${prefixSave}.success.title`), t(`${prefixSave}.success.description`), 'success');
				// Agregar el elemento creado a la tabla
				addElement(response.requisition);
				onClose();
			} else {
				setShowSaveButton(true);
				Swal.fire(t(`${prefixSave}.fail.title`), t(`${prefixSave}.fail.description`), 'warning');
			}
		} else {
			setShowSaveButton(true);
		}
	}
	const onUpdate = async () => {
		if (validateUpdate()) {
			const dataPayload = {
				...form,
				items: hookItems.data
			}
			const fetch = await updateRequisition({ headers, requisition_id: requisition?.id, data: dataPayload });
			const prefixSave = `${prefix}.onSave`
			if (fetch) {
				Swal.fire(t(`${prefixSave}.success-update.title`), t(`${prefixSave}.success-update.description`), 'success');
				const { id, items, business_name_id, business_name, segment, segment_id, description } = fetch.requisition;
				setData(prevState => prevState.map(element => {
					if (id === element.id) {
						element.items = items;
						element.business_name_id = business_name_id;
						element.business_name = business_name;
						element.segment = segment;
						element.segment_id = segment_id;
						element.description = description;
					}
					return element;
				}));
				onClose();
			} else {
				Swal.fire(t(`${prefixSave}.fail.title`), t(`${prefixSave}.fail.description`), 'warning');
			}
		}
	}
	// Deshabilitar botón para guardar
	const isButtonDisabled = () => {
		let is_disabled = false;
		const { id, provider_items } = requisition;
		// Si la requisición no ha sido enviada a los proveedores
		// return !(id && provider_items.length === 0) || !id;
		if (id && provider_items.length > 0) {
			is_disabled = true;
		}
		return is_disabled;
	}
	return (
		<ModalComp
			onClose={onClose}
			title={title}
			size={'xl'}
			body={<Fragment>
				{/* Headers */}
				<HeadersComp
					handleChange={handleChange}
					requisition={requisition}
					form={form}
					type={type}
					hookBusinessNames={hookBusinessNames}
					hookSegments={hookSegments}
				/>
				<Divider orientation='left'>
					<Heading size='sm'>{t(`${prefix}.assigned-items`)}</Heading>
				</Divider>
				{/* Si se crea una requisición - El identificador no existe*/}
				{
					!requisition?.id && <Fragment>
						{/* Partidas */}
						<AddItems
							prefix={prefix}
							item={item}
							changeItem={changeItem}
							addItem={addItem}
							fibra_id={fibra_id}
							showErrorItem={showErrorItem}
						/>
						{/* Lista de partidas */}
						<ItemsList
							items={hookItems.data}
							type={type}
							loading={hookItems.loading}
							removeItem={removeItem}
						/>
					</Fragment>
				}
				{/* Si se edita una requisición - Mostrar partidas asignados */}
				{
					requisition?.id && <>
						{
							isButtonDisabled() ?
								<Alert status="info" className='mt-4'><AlertIcon />{t(`${prefix}.alert-edit`)}</Alert>
								:
								<AddItems
									prefix={prefix}
									item={item}
									changeItem={changeItem}
									addItem={addItem}
									fibra_id={fibra_id}
									showErrorItem={showErrorItem}
								/>
						}
						<AssignedProvider
							items={hookItems.data}
							loading={hookItems.loading}
							removeItem={removeItem}
							disabled={isButtonDisabled()}
						/>
					</>
				}
			</Fragment>}
			footer={<>
				<ButtonIcon
					icon={faTimes}
					name={t(`${prefix}.close`)}
					variant='outline-secondary'
					onClick={onClose}
				/>
				{
					showSaveButton &&
					<ButtonIcon
						icon={faSave}
						name={t(`${prefix}.save`)}
						variant='outline-primary'
						onClick={onSaveSearch}
						disabled={isButtonDisabled()}
					/>
				}
			</>
			}
		/>
	)
}

DetailsAdmin.propTypes = {
	onClose: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	requisition: PropTypes.shape({
		id: PropTypes.number.isRequired,
		provider_items: PropTypes.array.isRequired
	}).isRequired,
	type: PropTypes.string.isRequired,
	setData: PropTypes.func.isRequired,
	headers: PropTypes.string.isRequired,
	fibra_id: PropTypes.number.isRequired,
	addElement: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	business_name_ids: PropTypes.array,
	segment_ids: PropTypes.array
}

export default DetailsAdmin