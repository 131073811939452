import downloadFile from "../libs/DownloadFile";
import { fetcherCancelToken } from "../libs/Petition";

const url = '/payment_orders';
const CREATED = 201;
const STATUS_OK = 200;

export const indexPaymentOrders = async ({ headers, purchase_order_id, page, pageSize }) => {

    const response = { data: [], totalItems: 0 };

    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            params: { purchase_order_id, page, pageSize },
            headers
        });
        // console.log("🚀 ~ file: PaymentOrders.js:19 ~ indexPaymentOrders ~ fetch:", fetch)

        if (fetch.status === STATUS_OK) {
            response.data = fetch.data.data;
            response.totalItems = fetch.data.totalItems;
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const storePaymentOrders = async ({ headers, data }) => {
    let response = { res: false, data: {}, status: 500 };
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        // console.log("🚀 ~ file:Role PaymentOrders.js:40 ~ storePaymentOrders ~ fetch:", fetch)
        response.status = fetch.status;
        if (fetch.status === CREATED) {
            response.data = fetch.data;
            response.res = true;
        }else if(fetch.status === 422){
            response.data = fetch.data
        }
        return response;
    } catch (error) {
        return response;
    }

}

export const updatePaymentOrders = async ({ headers, data, payment_order_id }) => {
    let response = { res: false, data: {}, status: 500 };
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}/${payment_order_id}`,
            method: 'PUT',
            data,
            headers
        });
        // console.log("🚀 ~ file: PaymentOrders.js:60 ~ updatePaymentOrders ~ fetch:", fetch)

        response.status = fetch.status;
        if (fetch.status === STATUS_OK) {
            response.data = fetch.data;
            response.res = true;
        }else if(fetch.status === 400){
            response.data = fetch.data
        }
        return response;
    } catch (error) {
        return response;
    }
}

export const getBlobPaymentOrders = async ({ headers, payment_order_id }) => {
    let response = { blob: null, cancel: () => { } };
    try {
        const { fetch, cancel } = await fetcherCancelToken({
            url: `${url}/${payment_order_id}`,
            method: 'GET',
            responseType: "blob",
            headers
        });
        // console.log("🚀 ~ file: PaymentOrders.js:80 ~ getBlobPaymentOrders ~ fetch:", fetch)

        if (fetch.status === STATUS_OK) {
            response.blob = new Blob([fetch.data], {
                type: fetch.data.type
            });
            response.cancel = cancel;
        }
        return response;;
    } catch (error) {
        return response;
    }
}

export const downloadPaymentOrders = async ({ headers, payment_order_id, name, toast }) => {
    try {
        await downloadFile({
            headers,
            url: `${url}/${payment_order_id}`,
            type: 'application/json',
            name,
            toast
        });
        return true;
    } catch (error) {
        return false;
    }
}