import { Fragment } from 'react'

const AdminHome = () => {
    const dashboard = 'https://metabase.victum-re.online/public/dashboard/8daee7ad-5210-4bb2-bee1-8bf01c79f9cd';

    return (
        <Fragment>
            <iframe
                src={dashboard}
                frameborder="0"
                width="100%"
                height="900"
                allowtransparency
            ></iframe>
        </Fragment>
    )
}

export default AdminHome;
