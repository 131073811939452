import { Fragment } from 'react'
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FormIcon from '../../../Layouts/Forms/FormIcon';

const CompPaymentData = (props) => {
    const {
        folio,
        uuid,
        rfc_issuing,
        name_issuing,
        rfc_receptor,
        name_receptor,
        previous_balance,
        outstanding_balance,
        amount_paid,
        emission_date,
        stamp_date
    } = props.data;

    const [t] = useTranslation('global');
    const prefix = 'mailbox.data';
    return (
        <Fragment>
            <Row className='mb-3'>
                <FormIcon title={t(`${prefix}.folio`)} value={folio} />
                <FormIcon title={t(`${prefix}.uuid`)}  value={uuid} />
            </Row>
            <Row className='mb-3'>
                <FormIcon title={t(`${prefix}.rfc-issuing`)} value={rfc_issuing} />
                <FormIcon title={t(`${prefix}.name-issuing`)} value={name_issuing} />
            </Row>
            <Row className='mb-3'>
                <FormIcon title={t(`${prefix}.rfc-receptor`)} value={rfc_receptor} />
                <FormIcon title={t(`${prefix}.name-receptor`)} value={name_receptor} />
            </Row>
            <Row className='mb-3'>
                <FormIcon title={t(`${prefix}.previous-balance`)} value={previous_balance} icon='$' />
                <FormIcon title={t(`${prefix}.amount-paid`)} value={amount_paid} icon='$' />
                <FormIcon title={t(`${prefix}.outstanding-balance`)} value={outstanding_balance} icon='$' />
            </Row>
            <Row className='mb-3'>
                <FormIcon title={t(`${prefix}.stamp-date`)} value={stamp_date} />
                <FormIcon title={t(`${prefix}.emission-date`)} value={emission_date} />
            </Row>
        </Fragment>
    )
}

export default CompPaymentData;
