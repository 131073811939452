import React from 'react'
import TabsComp from '../../Layouts/Tabs'
import { hookPaginationPropType } from '../../../data/propTypes'
import TreeBusinessNames from './TreeBusinessNames'

const TabsRoles = ({
	hookBusinessNames,
	hookSegments,
	hookUserBusinessNames,
	hookUserRoles
}) => {
	return (
		<TabsComp
			size='md'
			orientation={'vertical'}
			tablist={hookUserRoles.data.map(userRole => userRole.role.name)}
			tablistStyle={{ height: '70vh', overflowY: 'auto', padding: 'none' }}
			defaultIndex={0}
			tabpanels={hookUserRoles.data.map(userRole => {
				return <TreeBusinessNames
					key={userRole.role.id}
					hookBusinessNames={hookBusinessNames}
					hookSegments={hookSegments}
					hookUserBusinessNames={hookUserBusinessNames}
					userRole={userRole}
					hookUserRoles={hookUserRoles}
				/>
			})}
		/>
	)
}

TabsRoles.propTypes = {
	hookBusinessNames: hookPaginationPropType,
	hookSegments: hookPaginationPropType,
	hookUserBusinessNames: hookPaginationPropType,
	hookUserRoles: hookPaginationPropType
}

export default TabsRoles