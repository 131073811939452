import React, { useEffect, useRef, useState } from 'react'
import { Heading, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import TableComp from '../Layouts/Table'
import usePagination from '../../hooks/usePagination'
import PaginationComp from '../Layouts/Pagination'
import { downloadSegment, indexSegments, uploadSegment } from '../../api/Segments'
import { useSelector } from 'react-redux'
import FlexButton from '../Layouts/FlexButton'
import { faEdit, faFileDownload, faFileUpload, faPlus } from '@fortawesome/free-solid-svg-icons'
import ModalAddComp from './fragments/ModalAdd'
import Swal from 'sweetalert2'
import { indexBusinessName } from '../../api/Cfdi/BusinessNames'

const SegmentsComp = () => {
    const login = useSelector(state => state.login);
    const headers = { Authorization: `Bearer ${login.token}` };
    const fibra_id = login.fibra.id;
    const toast = useToast();
    const [t] = useTranslation('segments');
    const hookSegments = usePagination({ defaultPageSize: 10 });
    const hookBusinessNames = usePagination({ defaultPageSize: 100 });
    const { page, pageSize, totalItems } = hookSegments.pagination;
    const [modalAdd, setModalAdd] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const inputFile = useRef(null);
    // Obtener las razones sociales
    useEffect(() => {
        const getBusinessNames = async () => {
            hookBusinessNames.setLoading(true);
            const fetch = await indexBusinessName({ headers, fibra_id });
            hookBusinessNames.setData(fetch.data);
            hookBusinessNames.setTotalItems(fetch.totalItems);
            hookBusinessNames.setLoading(false);
        }
        getBusinessNames();
    }, []);
    // Obtener la lista de segmentos existentes
    useEffect(() => {
        const getSegments = async () => {
            hookSegments.setLoading(true);
            const fetch = await indexSegments({ headers, fibra_id, page, pageSize });
            hookSegments.setData(fetch.data);
            hookSegments.setTotalItems(fetch.totalItems);
            hookSegments.setLoading(false);
        }
        getSegments();
    }, [page, pageSize]);

    const editSegment = (item) => {
        hookSegments.setDataSel(item);
        setModalEdit(true);
    }

    const downloadContract = async (item) => {
        downloadSegment({
            headers,
            segment_id: item.id,
            name: `${item.code} - ${item.name}`,
            toast
        });
    }

    const uploadContract = (item) => {
        hookSegments.setDataSel(item);
        inputFile.current.click();
    }

    const handleChangeFile = async (e) => {
        let formData = new FormData();
        formData.append('contract_file', e.target.files[0]);
        const fetchUpload = await uploadSegment({ headers, data: formData, segment_id: hookSegments.dataSel.id });
        if (fetchUpload) {
            hookSegments.setData(prevState => prevState.map((element) => {
                if (element.id === fetchUpload.id) element = fetchUpload;
                return element;
            }))
            Swal.fire(t('sf.t-success'), t('sf.m-uploaded'), 'success');
        } else {
            Swal.fire(t('sf.t-fail'), t('sf.m-fail-server'), 'warning');
        }
    }

    return (<>
        <Heading>{t(`title`)}</Heading>
        <input
            ref={inputFile}
            type="file"
            onChange={handleChangeFile}
            style={{ display: "none" }}
        />
        <TableComp
            headers={[
                '#',
                t('table.code'),
                t('table.name'),
                t('table.business_name'),
                t('table.budget'),
                t('table.contract_code'),
                t('table.created-at'),
                t('table.actions')
            ]}
            keys={['#', 'code', 'name', 'business_name', 'budget', 'contract_code', 'created_at']}
            body={hookSegments.data}
            loading={hookSegments.loading}
            loadingItems={pageSize}
            page={page}
            moment_dates={[{ index: 6, format: 'LLLL' }]}
            currency={[{ index: 4 }]}
            actions={[
                {
                    icon: faEdit, tooltip: t('table.tt-edit'), variant: 'outline-success', handleClick: editSegment
                }, {
                    icon: faFileUpload, tooltip: t('table.tt-upload'), variant: 'primary', handleClick: uploadContract
                }, {
                    icon: faFileDownload, tooltip: t('table.tt-download'), variant: 'outline-primary',
                    showWhenNotNull: ['contract_file'], handleClick: downloadContract
                }
            ]}
        />
        <PaginationComp
            onChange={hookSegments.changePage}
            totalItems={totalItems}
            current={page}
            defaultPageSize={pageSize}
        />
        {/* Sección para agregar un segmento */}
        <FlexButton style={{ bottom: 20, right: 20 }} icon={faPlus} onClick={() => setModalAdd(true)} />
        {
            modalAdd && <ModalAddComp
                onClose={() => setModalAdd(false)}
                headers={headers}
                fibra_id={fibra_id}
                hookSegments={hookSegments}
                hookBusinessNames={hookBusinessNames}
            />
        }
        {/* Sección para editar un segmento */}
        {
            modalEdit && <ModalAddComp
                edit
                onClose={() => setModalEdit(false)}
                headers={headers}
                fibra_id={fibra_id}
                hookSegments={hookSegments}
                hookBusinessNames={hookBusinessNames}
            />
        }
    </>)
}

export default SegmentsComp