import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import DrawerComp from '../../Layouts/Drawer'
import { Box, FormLabel, Input, Select } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { indexSegments } from '../../../api/Segments'
import { indexBusinessName } from '../../../api/Cfdi/BusinessNames'
import usePagination from '../../../hooks/usePagination'
import { SET_PURCHASE_ORDERS_FILTERS } from '../../../redux/actions/main'
import { useDispatch } from 'react-redux'

const FiltersComp = ({ isAdmin, hookForm, fibra_id, headers, loading, business_name_ids, segment_ids, hookPurchaseOrders }) => {
    const [t] = useTranslation('purchase_orders');
    const dispatch = useDispatch();
    const { form, handleChange } = hookForm;
    const hookSegments = usePagination({});
    const hookBusinessNames = usePagination({});

    useEffect(() => {
        const getSegments = async () => {
            hookSegments.setLoading(true);
            const fetchSegments = await indexSegments({ headers, fibra_id });
            const allowedSegments = fetchSegments.data.filter(segment => segment_ids.includes(segment.id));
            hookSegments.setData(allowedSegments);
            hookSegments.setLoading(false);
        }
        if (!loading) getSegments();
    }, [loading]);

    useEffect(() => {
        const getBusinessNames = async () => {
            hookBusinessNames.setLoading(true);
            const fetchBusinessNames = await indexBusinessName({ headers, fibra_id });
            const allowedBusinessNames = fetchBusinessNames.data.filter(business_name => business_name_ids.includes(business_name.id));
            hookBusinessNames.setData(allowedBusinessNames);
            hookBusinessNames.setLoading(false);
        }
        if (!loading) getBusinessNames();
    }, [loading]);

    // Ejecutar al buscar resultados
    const handleClickSearch = () => {
        hookForm.updateParams();
        dispatch({ type: SET_PURCHASE_ORDERS_FILTERS, filters: hookForm.form });
        // Regresar a la página 1 después de aplicar los filtros
        hookPurchaseOrders.changePage(1, hookPurchaseOrders.pagination.pageSize);
    }

    return (
        <DrawerComp handleClickSearch={handleClickSearch} title={t('filters.title')} showFooter>
            <Box className='mb-3'>
                <FormLabel>{t(`filters.string`)}</FormLabel>
                <Input
                    name='string'
                    placeholder={t('filters.string-ph')}
                    onChange={hookForm.handleChange}
                    value={hookForm.form?.string}
                />
            </Box>
            {/* Razón social - sólo los administradores tienen acceso*/}
            {
                isAdmin &&
                <Box className='mb-3'>
                    <FormLabel>{t(`filters.business_name`)}</FormLabel>
                    <Select onChange={handleChange} defaultValue={-1} name='business_name_id' value={form?.business_name_id}>
                        <option value={-1}>{t(`filters.all`)}</option>
                        {
                            !hookBusinessNames.loading && hookBusinessNames.data.map(business_name =>
                                <option key={business_name.id} value={business_name.id}>
                                    {business_name.name}
                                </option>
                            )
                        }
                    </Select>
                </Box>
            }
            {/* Segmento - sólo los administradores tienen acceso*/}
            {
                isAdmin &&
                <Box className='mb-3'>
                    <FormLabel>{t(`filters.segment`)}</FormLabel>
                    <Select onChange={handleChange} defaultValue={-1} name='segment_id' value={form?.segment_id}>
                        <option value={-1}>{t(`filters.all`)}</option>
                        {
                            !hookSegments.loading && hookSegments.data.map(segment =>
                                <option key={segment.id} value={segment.id}>
                                    {segment.code} - {segment.name}
                                </option>
                            )
                        }
                    </Select>
                </Box>
            }
            {/* Comprobante de pago */}
            <Box className='mb-3'>
                <FormLabel>{t(`filters.voucher`)}</FormLabel>
                <Select onChange={handleChange} defaultValue={-1} name='voucher' value={form?.voucher}>
                    <option value={-1}>{t(`filters.all`)}</option>
                    <option value={'sent'}>{t(`filters.sent`)}</option>
                    <option value={'pending'}>{t(`filters.pending`)}</option>
                </Select>
            </Box>
            {/* Orden de pago */}
            <Box className='mb-3'>
                <FormLabel>{t(`filters.payment-order-status`)}</FormLabel>
                <Select onChange={handleChange} defaultValue={-1} name='payment_order_status' value={form?.payment_order_status}>
                    <option value={-1}>{t(`filters.all`)}</option>
                    <option value={'complete'}>{t(`filters.complete`)}</option>
                    <option value={'partial'}>{t(`filters.partial`)}</option>
                    <option value={'empty'}>{t(`filters.empty`)}</option>
                </Select>
            </Box>
            {/* Estado */}
            <Box className='mb-3'>
                <FormLabel>{t(`filters.status`)}</FormLabel>
                <Select onChange={handleChange} defaultValue={-1} name='removed' value={form?.removed}>
                    <option value={-1}>{t(`filters.all`)}</option>
                    <option value={0}>{t(`filters.active`)}</option>
                    <option value={1}>{t(`filters.canceled`)}</option>
                </Select>
            </Box>
            {/* Fecha mínima */}
            <Box className='mb-3'>
                <FormLabel>{t(`filters.start_date`)}</FormLabel>
                <Input type='date' name='start_date' value={form?.start_date} onChange={handleChange} />
            </Box>
            {/* Fecha maxima */}
            <Box className='mb-3'>
                <FormLabel>{t(`filters.end_date`)}</FormLabel>
                <Input type='date' name='end_date' value={form?.end_date} onChange={handleChange} />
            </Box>
        </DrawerComp>
    )
}

FiltersComp.propTypes = {
    isAdmin: PropTypes.bool,
    hookForm: PropTypes.shape({
        form: PropTypes.shape({
            segment_id: PropTypes.number,
            business_name_id: PropTypes.number,
            string: PropTypes.string,
            start_date: PropTypes.string,
            end_date: PropTypes.string,
            removed: PropTypes.string,
            voucher: PropTypes.string,
            payment_order_status: PropTypes.string,
        }).isRequired,
        handleChange: PropTypes.func.isRequired,
        updateParams: PropTypes.func.isRequired
    }).isRequired,
    fibra_id: PropTypes.number.isRequired,
    headers: PropTypes.shape({
        Authorization: PropTypes.string.isRequired
    }).isRequired,
    loading: PropTypes.bool,
    business_name_ids: PropTypes.array,
    segment_ids: PropTypes.array
}

export default FiltersComp