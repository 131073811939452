import { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import * as Yup from 'yup';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { Skeleton } from 'antd';
import { createAppRequest } from '../../../api/apps';
import ModalComp from '../../Layouts/Modal';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import { useSelector } from 'react-redux';

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Correo electrónico inválido').required('El correo electrónico es obligatorio'),
    full_name: Yup.string().max(100).required('El nombre completo es obligatorio')
});

const ModalAccess = ({ onClose }) => {
    const login = useSelector(state => state.login);
    const headers = { Authorization: `Bearer ${login.token}` };
    const fibra_id = login.fibra.id;

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values, actions) => {
        setLoading(true);
        const response = await createAppRequest({ headers, data: { ...values, fibra_id } });
        if (response) {
            Swal.fire('¡Operación exitosa!', 'Se ha recibido su solicitud y pronto se le brindará el acceso a la app.', 'success');
            onClose();
        } else {
            Swal.fire('¡Operación fallida!', 'Hubo un error al tratar de realizar la solicitud.', 'warning');
        }
        setLoading(false);
    }

    return (
        <ModalComp
            title={'Solicitar Acceso'}
            onClose={onClose}
            size='md'
            body={<>
                {
                    loading ? <Skeleton active /> :

                        <Formik
                            initialValues={{ email: '', full_name: '' }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {(props) => (
                                <Form className='d-flex flex-column'>
                                    <Field name='full_name' className='mt-4'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.full_name && form.touched.full_name}>
                                                <FormLabel htmlFor='full_name'>Nombre completo</FormLabel>
                                                <Input {...field} id='full_name' placeholder='Nombre completo' />
                                                <FormErrorMessage>{form.errors.full_name}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='email' className='mt-4'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.email && form.touched.email}>
                                                <FormLabel htmlFor='email'>Correo electrónico (Apple ID)</FormLabel>
                                                <Input {...field} id='email' placeholder='Correo electrónico' />
                                                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    {/* <a className='mt-4'
                                        href='https://www.canva.com/design/DAFtg2G_VKs/QC8ihgySy-Be72TvNEgrtA/view?website#2'
                                        target="_blank"
                                        rel="noopener noreferrer">Ver tutorial
                                    </a> */}
                                    <ButtonIcon className='mt-4 align-self-end' type='submit' icon={faSignInAlt} name='Solicitar acceso' variant='outline-success' onClick={() => { }} />
                                </Form>
                            )}
                        </Formik>
                }
            </>}
        />
    )
}

ModalAccess.propTypes = {
    onClose: PropTypes.func.isRequired
}

export default ModalAccess