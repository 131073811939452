import Fetcher from "../../libs/Petition"

const url = '/complementary_payment';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param invoice_id Se obtienen los complementos de pago de una factura
 * @returns ComplementaryPayment::collection() Retorna una colección de objetos 
 */
export const indexComplementaryPayment = async ({ headers, invoice_id }) => {
    let response = {data:[], totalItems: 0};
    try {
        let params = { invoice_id };

        let fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = {data:[], totalItems: 0};
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param complemetary_payment Identificador del complemento de pago sobre el que se hará la consulta
 * @returns Role Retorna un objeto tipo CfdiStatuses
 */
export const showComplementaryPayment = async ({ headers, complemetary_payment }) => {
    let response = {};
    try {
        let fetch = await Fetcher({
            url: `${url}/${complemetary_payment}`,
            method: 'GET',
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = {};
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storeComplementaryPayment = async ({ headers, data , params}) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data, params,
            headers
        });
        // console.log("Fetch store complementary payment: ", fetch);
        if (fetch.status === STATUS_OK || fetch.status === CREATED)
            response = fetch.data;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param complemetary_payment Identificador del complemento de pago que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updateComplementaryPayment = async ({ headers, data, complemetary_payment }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${complemetary_payment}`,
            method: 'PUT',
            data,
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param complemetary_payment_id Identificador del complemento de pago que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyComplementaryPayment = async ({ headers, complemetary_payment }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${complemetary_payment}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}

export const uploadComplementaryPayment = async ({headers, data, params}) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}/upload`,
            method: 'POST',
            data, headers, params
        });
        // console.log("Fetch upload complementary: ", fetch);
        if(fetch.status === CREATED){
                response = fetch;
        }
    } catch (error) {
        response = error;
    }finally{
        return response;
    }
}