import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';

const PrivateRoute = ({ component: Component, role, code, ...rest }) => {
    const { roles, role_selected } = useSelector(state => state.login);

    return (
        <Route
            {...rest}
            render={props => {
                // Buscar si el usuario actual tiene acceso al Rol solicitado
                const roleExists = roles.find(role => role.id === role_selected.id);
                // Verificar si además tiene permiso para acceder al componente
                // Default TRUE por si no se especifica el código del componente
                let hasComponentPermission = true;
                if (roleExists && code)
                    hasComponentPermission = roleExists.modules.find(module => module.code === code);
                return roleExists && hasComponentPermission ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/permission-denied",
                            state: { from: props.location }
                        }}
                    />
                );
            }}
        />
    );
}

PrivateRoute.propTypes = {
    component: PropTypes.any.isRequired,
    role: PropTypes.string,
    code: PropTypes.string.isRequired,
    rest: PropTypes.any,
    location: PropTypes.shape({
        pathname: PropTypes.string,
        state: PropTypes.object,
        search: PropTypes.string,
        hash: PropTypes.string,
    })
}

export default PrivateRoute;