import { fetcherCancelToken } from "../../libs/Petition";

const url = '/user_business_names';
const CREATED = 201;
const STATUS_OK = 200;

export const indexUserBusinessNames = async ({ headers, user_id, page, pageSize, updated_at_start }) => {

    let response = { data: [], totalItems: 0 };

    try {
        // Parámetros requeridos
        let params = { user_id };
        // Parámetros opcionales
        if (page) params.page = page;
        if (pageSize) params.pageSize = pageSize;
        if (updated_at_start) params.updated_at_start = updated_at_start;
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });

        // console.log("🚀 ~ file: UserBusinessNames.js:31 ~ indexUserBusinessNames ~ fetch:", fetch)

        if (fetch.status === STATUS_OK)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}

export const storeUserBusinessNames = async ({ headers, data }) => {
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });

        // console.log("🚀 ~ file: UserBusinessNames.js:52 ~ storeUserBusinessNames ~ fetch:", fetch)

        if (fetch.status === CREATED)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }

}