import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import ModalComp from "../../Layouts/Modal";

const Info = (props) => {
    const { onClose } = props;
    const [t] = useTranslation('global');
    return (
        <ModalComp
            onClose={onClose}
            size='lg'
            title={t('rents.modal-info.title')}
            body={<Fragment>

                </Fragment>}
                footer={<Fragment>
    
                </Fragment>}
        />
    );
}

export default Info