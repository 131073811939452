import { Fragment, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { faBoxes, faEdit, faFileExcel, faKey, faProjectDiagram, faRedoAlt, faSpinner, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { indexRoles } from "../../../api/Roles";
import { downloadProvidersXlsx, indexUser, updateUser } from "../../../api/Users";
import PaginationComp from "../../Layouts/Pagination";
import TableComp from "../../Layouts/Table";
import ModalUserEdit from "../fragments/ModalUserEdit";
import ModalUserRoles from "../fragments/ModalUsersRoles";
import usePagination from "../../../hooks/usePagination";
import Swal from "sweetalert2";
import { resetPassword } from "../../../api/Authentification";
import FlexButton from "../../Layouts/FlexButton";
import { useTranslation } from "react-i18next";
import useConfirmation from "../../../hooks/useConfirm";
import FilterUsersComp from "../fragments/FilterUsers";
import { Row } from "antd";
import useForm from "../../../hooks/useForm";
import ModalUserBusinessNameSegments from "../fragments/ModalUserBusinessNamesSegments";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import useLoading from "../../../hooks/useLoading";
import { useToast } from "@chakra-ui/react";

const Users = ({ fibra_id, headers }) => {
    const [t] = useTranslation('settings');
    const p = 'users.table';
    const toast = useToast();
    const hookUsers = usePagination({ defaultPageSize: 10, page: 1 });
    const hookRoles = usePagination({ defaultPageSize: 50, page: 1 });
    const hookForm = useForm();
    const { confirmAction } = useConfirmation();

    const { page, pageSize } = hookUsers.pagination;

    const [modalAdd, setModalAdd] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [modalRoles, setModalRoles] = useState(false);
    const [modalUserBn, setModalUserBn] = useState(false);
    const hookLoadingXlsx = useLoading();

    // Obtener todos los usuarios pertenecientes al paquete (fibra)
    useEffect(() => {
        const getUsers = async () => {
            hookUsers.setLoading(true);
            const response = await indexUser({ headers, fibra_id, page, pageSize, ...hookForm.params });
            hookUsers.setData(response.data);
            hookUsers.setTotalItems(response.totalItems);
            hookUsers.setLoading(false);
        }
        getUsers();
    }, [page, pageSize, hookForm.params]);
    // Obtener los roles disponibles
    useEffect(() => {
        const getRoles = async () => {
            hookRoles.setLoading(true);
            const response = await indexRoles({ headers, fibra_id });
            hookRoles.setData(response.data);
            hookRoles.setTotalItems(response.totalItems);
            hookRoles.setLoading(false);
        }
        getRoles();
    }, []);

    const updateRoles = (item) => {
        hookUsers.setDataSel(item);
        setModalRoles(true);
    }

    const editUser = (item) => {
        hookUsers.setDataSel(item);
        setModalEdit(true);
    }
    // Editar relación de razones sociales
    const updateBusinessNames = (item) => {
        hookUsers.setDataSel(item);
        setModalUserBn(true);
    }
    // Restaurar contraseña de usuario, esta llega al correo electrónico del mismo
    const resetPass = (item) => {
        const { email } = item;
        const title = t('users.sf.t-confirm');
        const html = `${t('users.sf.html-t')} <br>${t('users.sf.html-m')} ${email}.`;
        const confirmButtonText = t('users.sf.b-confirm');

        confirmAction(title, html, confirmButtonText, async () => {
            const { res } = await resetPassword({ data: { email } });
            if (res) {
                Swal.fire(t('users.sf.t-success'), `${t('users.sf.m-send-mail')} ${email}.`, 'success');
            } else {
                Swal.fire(t('users.sf.t-fail'), t('users.sf.m-fail-server'), 'info');
            }
        });
    }
    // Deshabilitar usuario
    const dropUser = async (item) => {
        const title = t('users.sf.drop-confirm');
        const html = `${t('users.sf.html-drop')}.`;
        const confirmButtonText = t('users.sf.b-drop-confirm');

        confirmAction(title, html, confirmButtonText, async () => {
            const { res } = await updateUser({ headers, data: { active: false }, user_id: item.id });
            if (res) {
                hookUsers.updateElement({ ...item, active: 'INACTIVO' });
                Swal.fire(t('users.sf.t-success'), `${t('users.sf.m-drop')}`, 'success');
            } else {
                Swal.fire(t('users.sf.t-fail'), t('users.sf.m-fail-server'), 'info');
            }
        });
    }
    // Habilitar usuario
    const restoreUser = async (item) => {
        const title = t('users.sf.restore-confirm');
        const html = `${t('users.sf.html-restore')}.`;
        const confirmButtonText = t('users.sf.b-restore-confirm');

        confirmAction(title, html, confirmButtonText, async () => {
            const { res } = await updateUser({ headers, data: { active: true }, user_id: item.id });
            if (res) {
                hookUsers.updateElement({ ...item, active: 'ACTIVO' });
                Swal.fire(t('users.sf.t-success'), `${t('users.sf.m-restore')}`, 'success');
            } else {
                Swal.fire(t('users.sf.t-fail'), t('users.sf.m-fail-server'), 'info');
            }
        });
    }
    // Ejecutar al buscar resultados
    const handleClickSearch = () => hookForm.updateParams();

    // Descargar concentrado de proveedores
    const downloadXlsx = async () => {
        const now = new Date().getTime();
        hookLoadingXlsx.showLoading();
        await downloadProvidersXlsx({ headers, fibra_id, name: `business_partners_${now}`, toast });
        hookLoadingXlsx.hideLoading();
    }

    return (
        <Fragment>
            {/* Mostrar estado de carga */}
            <hookLoadingXlsx.LoadingModal />
            <Row justify='end' className='mb-2'>
                <ButtonIcon
                    key='database_providers'
                    name={t('users.table.btn-providers')}
                    tooltip={t('users.table.btn-providers-tt')}
                    icon={hookLoadingXlsx.isOpen ? faSpinner : faFileExcel}
                    variant='outline-success'
                    onClick={downloadXlsx}
                    disabled={hookLoadingXlsx.isOpen}
                />
                <FilterUsersComp
                    handleClickSearch={handleClickSearch}
                    hookForm={hookForm}
                />
            </Row>
            <TableComp
                headers={['#', t(`${p}.name`), t(`${p}.email`), t(`${p}.phone-number`), t(`${p}.active`), t(`${p}.created-at`), t(`${p}.actions`)]}
                keys={['#', 'first_name++last_name', 'email', 'phone_number', 'active', 'created_at']}
                actions={[
                    { handleClick: editUser, icon: faEdit, tooltip: t(`${p}.tt-edit`), variant: 'outline-success' },
                    { handleClick: updateRoles, icon: faBoxes, tooltip: t(`${p}.tt-roles`), variant: 'outline-secondary' },
                    { handleClick: updateBusinessNames, icon: faProjectDiagram, tooltip: t(`${p}.tt-user-bn`), variant: 'outline-secondary' },
                    { handleClick: resetPass, icon: faKey, tooltip: t(`${p}.tt-reset`), variant: 'outline-primary' },
                    { handleClick: dropUser, icon: faTrashAlt, tooltip: t(`${p}.tt-drop`), variant: 'outline-danger', dinamic: [{ key: 'active', value: 'ACTIVO' }] },
                    { handleClick: restoreUser, icon: faRedoAlt, tooltip: t(`${p}.tt-restore`), variant: 'outline-success', dinamic: [{ key: 'active', value: 'INACTIVO' }] }
                ]}
                body={hookUsers.data}
                loading={hookUsers.loading}
                loadingItems={pageSize}
                moment_dates={[{ index: 5, format: 'LLLL' }]}
                badges={[{ index: 4 }]}
                badges_values={[{
                    col: 'active',
                    values: [
                        { value: 'ACTIVO', name: t(`${p}.color-active`), color: 'green' },
                        { value: 'INACTIVO', name: t(`${p}.color-inactive`), color: 'red' }
                    ]
                }]}
            />
            {/* Componente de paginación */}
            <PaginationComp
                current={page}
                onChange={hookUsers.changePage}
                totalItems={hookUsers.pagination.totalItems}
            />
            {/* Sección para agregar un usuario */}
            <FlexButton style={{ bottom: 20, right: 20 }} onClick={() => setModalAdd(true)} />
            {/* Modal para editar datos de usuario */}
            <ModalUserEdit
                onClose={() => setModalAdd(false)}
                hidden={!modalAdd}
                headers={headers}
                fibra_id={fibra_id}
                addElement={hookUsers.addElement}
            />
            {/* Modal para editar datos de usuario */}
            <ModalUserEdit
                isEdit
                onClose={() => setModalEdit(false)}
                hidden={!modalEdit}
                user={hookUsers.dataSel}
                headers={headers}
                updateElement={hookUsers.updateElement}
            />
            {/* Modal para asignar permisos a los roles */}
            <ModalUserRoles
                onClose={() => setModalRoles(false)}
                hidden={!modalRoles}
                roles={hookRoles.data}
                headers={headers}
                user_id={hookUsers.dataSel?.id}
            />
            {/* Modal para editar acceso a razones sociales */}
            <ModalUserBusinessNameSegments
                onClose={() => setModalUserBn(false)}
                hidden={!modalUserBn}
                headers={headers}
                fibra_id={fibra_id}
                user_id={hookUsers.dataSel?.id}
            />
        </Fragment>
    );
}

Users.propTypes = {
    fibra_id: PropTypes.number.isRequired,
    headers: PropTypes.number.isRequired
}

export default Users;