import { faEnvelopeOpen, faEye, faInbox } from '@fortawesome/free-solid-svg-icons';
import { Row } from 'antd';
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { indexCompPagoPendProv } from '../../../api/ERP/SAP/ComPagoPendProv';
import { indexPedPendProv } from '../../../api/ERP/SAP/PedPendProv';
import Filter from '../../Layouts/Filters';
import FlexButton from '../../Layouts/FlexButton';
import SearchComp from '../../Layouts/Search';
import TableComp from '../../Layouts/Table'
import AuthCxpCompPago from '../AuthCxpCompPago/Index';
import RecCompPagoProv from '../RecComPagoProv/Index';
import Details from './Details';

const CompPagoPendProv = (props) => {
    const { headers } = props;
    const [compPagoPendProv, setCompPagoPendProv] = useState([]);
    const [ILifnr, setILifnr] = useState('0300003369');
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState();                           // Modal para ver los detalles
    const [modalAuth, setModalAuth] = useState();                           // Modal para la autorización
    const [modalRec, setModalRec] = useState();                           // Modal para la recepción
    const [itemSel, setItemSel] = useState({});
    const [t] = useTranslation('erp');
    const p = ['CompPagoPendProv.headers'];

    useEffect(() => {
        getCompPagoPendProv();
    }, []);

    const getCompPagoPendProv = async () => {
        setLoading(true);
        let response = await indexCompPagoPendProv({ headers, ILifnr });
        setCompPagoPendProv(response.data);
        setLoading(false);
    }
    const openOrClose = () => setModal(!modal);
    const openOrCloseAuth = () => setModalAuth(!modalAuth);
    const openOrCloseRec = () => setModalRec(!modalRec);

    const viewDetails = (item) => {
        setItemSel(item);
        openOrClose();
    }

    const searchCompPagoPendProv = () => getCompPagoPendProv();

    return (
        <Fragment>
            <Row justify='end' className='mb-2'>
                <Filter items={[
                    <SearchComp
                        tooltip={t(`${p[0]}.tt-1`)}
                        onClick={searchCompPagoPendProv}
                        placeholder={t(`${p[0]}.ph-1`)}
                        setString={setILifnr}
                        string={ILifnr}
                    />
                ]}
                />
            </Row>
            <TableComp
                headers={[
                    '#',
                    t(`${p[0]}.Bukrs`),
                    t(`${p[0]}.Gjahr`),
                    t(`${p[0]}.Augbl`),
                    t(`${p[0]}.Waers`),
                    t(`${p[0]}.Wrbtr`),
                    t(`${p[0]}.Augdt`),
                    t(`${p[0]}.Zlsch`),
                    t(`${p[0]}.actions`)
                ]}
                keys={['#', 'Bukrs', 'Gjahr', 'Augbl', 'Waers', 'Wrbtr', 'Augdt', 'Zlsch']}
                body={compPagoPendProv}
                loading={loading}
                actions={[
                    { icon: faEye, variant: 'outline-primary', tooltip: t(`${p[0]}.tt-2`), handleClick: viewDetails }
                ]}
                currency={[{ index: 5 }]}
                moment_dates={[{ index: 6, format: 'LL' }]}
            />
            {/* Modal para ver los detalles */}
            {
                modal && <Details
                    onClose={openOrClose}
                    item={itemSel}
                />
            }
            {/* Autorización de Complementos de pago cuentas por pagar */}
            <FlexButton style={{ bottom: 20, right: 20 }} onClick={openOrCloseAuth} icon={faEnvelopeOpen} />
            {
                modalAuth && <AuthCxpCompPago
                    onClose={openOrCloseAuth}
                    headers={headers}
                />
            }
            {/* Recepción de complementos de pago de proveedores */}
            <FlexButton style={{ bottom: 80, right: 20 }} onClick={openOrCloseRec} icon={faInbox} />
            {
                modalRec && <RecCompPagoProv
                    onClose={openOrCloseRec}
                    headers={headers}
                />
            }
        </Fragment>
    )
}

export default CompPagoPendProv