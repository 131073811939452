import { useState } from 'react';
import Swal from 'sweetalert2';

const useConfirmation = () => {
    const [confirmationResult, setConfirmationResult] = useState(null);

    const confirmAction = async (title, html, confirmButtonText, actionCallback) => {
        const result = await Swal.fire({
            title: title,
            html: html,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: confirmButtonText
        });

        setConfirmationResult(result);

        if (result.isConfirmed) {
            actionCallback();
        }
    }

    const clearConfirmationResult = () => {
        setConfirmationResult(null);
    }

    return { confirmAction, confirmationResult, clearConfirmationResult };
}

export default useConfirmation;
