import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useEffect } from 'react';
import Login from './components/Login/index';
import App from './components/App';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { OPEN_SESSION } from './redux/actions/main';

const IndexComponent = () => {
	const [cookie, setCookie] = useCookies(['user']);
	const dispatch = useDispatch();
	const session = useSelector(state => state.login.session);

	useEffect(() => {
		try {
			if (!session && cookie.user) {
				let { token, apps, roles, user, fibra } = cookie.user.data;
				setCookie('user', cookie.user, { path: "/", maxAge: 3600, sameSite: true, });
				dispatch({
					type: OPEN_SESSION,
					session: 'active',
					token,
					apps,
					roles,
					user,
					role_selected: roles[0],
					modules: roles[0].modules,
					fibra
				});
			} else {
				setCookie('user', null, { path: "/", maxAge: 0, sameSite: true, });
			}
		} catch (error) {
			console.log('Error al leer las credenciales almacenadas', error);
		}
	}, []);

	return (<Fragment>
		<Helmet>
			<title>Victum Inmobiliario</title>
		</Helmet>
		{
			session ?
				<App /> :
				<Login />
		}
	</Fragment>);
}

export default IndexComponent;
