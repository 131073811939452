import { Fragment, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import TableComp from "../../Layouts/Table";
import { faBoxes, faEdit, faEye, faProjectDiagram } from "@fortawesome/free-solid-svg-icons";
import FlexButton from "../../Layouts/FlexButton";
import ModalRoles from "../fragments/ModalRoles";
import ModalStatuses from "../fragments/ModalStatuses";
import { useDispatch } from "react-redux";
import { getCfdiStatuses } from "../../../api/Cfdi/CfdiStatuses";
import { useTranslation } from "react-i18next";
import { indexRoles } from "../../../api/Roles";
import usePagination from "../../../hooks/usePagination";
import PaginationComp from "../../Layouts/Pagination";
import ModalRoleBusinessNamesSegments from "../fragments/ModalRoleBusinessNamesSegments";

const Roles = ({ headers, fibra }) => {
    const [t] = useTranslation('settings');
    const p = 'roles.table';
    const dispatch = useDispatch();
    const hookRoles = usePagination({ defaultPageSize: 10 });
    const { page, pageSize, totalItems } = hookRoles.pagination;
    const [modalTitle, setModalTitle] = useState('');
    const [type, setType] = useState('add');
    const [modal, setModal] = useState(false);
    const [modalStatuses, setModalStatuses] = useState(false);
    const [modalBusinessNames, setModalBusinessNames] = useState(false);

    // Obtener todos los roles
    useEffect(() => {
        const getRoles = async () => {
            hookRoles.setLoading(true);
            const fetch = await indexRoles({ headers, fibra_id: fibra.id, page, pageSize });
            hookRoles.setData(fetch.data);
            hookRoles.setTotalItems(fetch.totalItems);
            hookRoles.setLoading(false);
        }
        getRoles();
    }, [page, pageSize]);

    const showDetails = (item) => {
        setModalTitle(t(`${p}.mt-details`));
        setType('details');
        hookRoles.setDataSel(item);
        setModal(true);
    }

    const updateRol = (item) => {
        setModalTitle(t(`${p}.mt-update`));
        setType('update');
        hookRoles.setDataSel(item);
        setModal(true);
    }

    const addRole = () => {
        setModalTitle(t(`${p}.mt-add`));
        setType('add');
        hookRoles.setDataSel({ name: '', code: '', description: '' });
        setModal(true);
    }

    const showStatuses = (item) => {
        hookRoles.setDataSel(item);
        openOrCloseStatuses();
    }

    const openOrCloseStatuses = () => {
        setModalStatuses(!modalStatuses);
        if (modalStatuses) {
            getStatuses(hookRoles.dataSel);
        }
    }

    // Se obtienen los cfdi_statuses en base al rol seleccionado
    const getStatuses = async (role) => {
        let response = await getCfdiStatuses({ headers, fibra_id: fibra.id, role_id: role.id });
        dispatch({ type: 'SET_CFDI_STATUSES', cfdi_statuses: response });
    }

    const updateBusinessNamesSegments = (item) => {
        hookRoles.setDataSel(item);
        setModalBusinessNames(true);
    }

    return (
        <Fragment>
            {/* Tabla para mostrar los roles de usuarios */}
            <TableComp
                headers={[
                    '#',
                    t(`${p}.name`),
                    t(`${p}.description`),
                    t(`${p}.created-at`),
                    t(`${p}.actions`),
                ]}
                keys={['#', 'name', 'description', 'created_at']}
                body={hookRoles.data}
                actions={[
                    { handleClick: showDetails, icon: faEye, tooltip: t(`${p}.tt-details`), variant: 'outline-primary' },
                    { handleClick: updateRol, icon: faEdit, tooltip: t(`${p}.tt-update`), variant: 'outline-success' },
                    { handleClick: updateBusinessNamesSegments, icon: faProjectDiagram, tooltip: t(`${p}.tt-bn`), variant: 'outline-secondary' },
                    { handleClick: showStatuses, icon: faBoxes, tooltip: t(`${p}.tt-cfdi`), variant: 'outline-secondary' },
                ]}
                loading={hookRoles.loading}
                page={page}
                loadingItems={pageSize}
                moment_dates={[{ index: 3, format: 'LLLL' }]}
            />
            <PaginationComp
                current={page}
                onChange={hookRoles.changePage}
                totalItems={totalItems}
                defaultPageSize={pageSize}
            />
            {/* FAB para agregar un nuevo rol */}
            <FlexButton style={{ bottom: 20, right: 20 }} onClick={addRole} />

            {/* Modal para actualizar y agregar rol */}
            {
                modal && <ModalRoles
                    onClose={() => setModal(false)}
                    title={modalTitle}
                    role={hookRoles.dataSel}
                    type={type}
                    headers={headers}
                    addElement={hookRoles.addElement}
                    updateElement={hookRoles.updateElement}
                    fibra={fibra}
                />
            }
            {/* Modal para los status CFDi a los que pueden acceder los roles */}
            {
                modalStatuses && <ModalStatuses
                    onClose={openOrCloseStatuses}
                    role={hookRoles.dataSel}
                    headers={headers}
                    fibra_id={fibra.id}
                />
            }
            {/* Modal para hacer la asignación de reazones sociales y segmentos */}
            {
                modalBusinessNames && <ModalRoleBusinessNamesSegments
                    onClose={() => setModalBusinessNames(false)}
                    headers={headers}
                    fibra_id={fibra.id}
                    role={hookRoles.dataSel}
                />
            }
        </Fragment>
    );
}

Roles.propTypes = {
    headers: PropTypes.shape({
        Authorization: PropTypes.string.isRequired
    }).isRequired,
    fibra: PropTypes.shape({
        id: PropTypes.number.isRequired
    }).isRequired,
}

export default Roles;