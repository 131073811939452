import { useEffect, useState } from "react";
import { useStore } from "react-redux";
import { Container } from "react-bootstrap";
import { useToast, Heading } from "@chakra-ui/react";
import downloadFile from "../../../libs/DownloadFile";
import '../../../styles/Contract.Component.css';
import { indexRent } from "../../../api/Malls/Rent";
import TableComp from "../../Layouts/Table";
import PaginationComp from "../../Layouts/Pagination";
import { faEye, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import ContractsView from "./Modal";
import { useTranslation } from "react-i18next";

const TableRents = (props) => {
    const { params, theaders, keys, currency, moment_dates } = props;

    const store = useStore();
    const { token } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };
    const [rent_id, setRentId] = useState(null);

    const [rents, setRents] = useState([]);
    const [modalView, setModalView] = useState(false);
    const toast = useToast();
    const [loading, setLoading] = useState(true);
    const [totalItems, setTotalItems] = useState(0);        // Cantidad de registros de la consulta
    const [page, setPage] = useState(1);                    // Página actual que se está consultando
    const [pageSize, setPageSize] = useState(10);           // Cantidad de registros por página

    const [t] = useTranslation('global');
    const prefix_tooltips = 'contract.table.tooltips';

    useEffect(() => {
        getContracts();
    }, [page, pageSize]);

    const getContracts = async () => {
        setLoading(true);
        let response = await indexRent({ headers, page, pageSize, ...params });
        setRents(response.data);
        setTotalItems(response.totalItems);
        setLoading(false);
    }

    const downloadContract = async (contract) => {
        downloadFile({
            url: `/rents/download/${contract.id}`,
            type: 'application/json',
            name: contract.name,
            toast,
            headers
        });
    }

    const openOrCloseModal = (item) => {
        if (!modalView) setRentId(item.id)
        setModalView(!modalView);
    }

    // Evento lanzado al cambiar de página
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }

    return (
        <Container id='client-contracts-component' fluid>
            <Heading>{t(`contracts.title`)}</Heading>

            {/* Table donde se listan los contratos */}
            <TableComp
                headers={theaders}
                keys={keys}
                body={rents}
                loading={loading}
                loadingItems={pageSize}
                actions={[
                    { icon: faEye, handleClick: openOrCloseModal, tooltip: t(`${prefix_tooltips}.details`) },
                    { icon: faFilePdf, handleClick: downloadContract, tooltip: t(`${prefix_tooltips}.download`), variant: 'outline-danger' },
                ]}
                currency={currency}
                moment_dates={moment_dates}
            />
            {/* Paginación */}
            <PaginationComp
                totalItems={totalItems}
                onChange={changePage}
                current={page}
            />

            {/* Modal de vista detallada de contratos */}
            {
                modalView ?
                    <ContractsView
                        rent_id={rent_id}
                        headers={headers}
                        onClose={openOrCloseModal}
                    /> : null
            }
        </Container>
    );
}

export default TableRents;