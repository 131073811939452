import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import TableComp from '../../../Layouts/Table'
import { useTranslation } from 'react-i18next'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

const ItemsList = ({ items, type, loading, removeItem }) => {
    const [t] = useTranslation('requisition');
    const prefix_list = `modal-details.items-list`
    return (
        <Row className='mb-3'>
            <Col>
                <TableComp
                    headers={[
                        '#',
                        t(`${prefix_list}.description`),
                        t(`${prefix_list}.detailed`),
                        t(`${prefix_list}.unit`),
                        t(`${prefix_list}.quantity`),
                        t(`${prefix_list}.actions`)
                    ]}
                    keys={['#', 'description', 'detailed', 'unit', 'quantity']}
                    body={items}
                    actions={[{
                        icon: faTrashAlt, variant: 'outline-danger',
                        tooltip: t(`${prefix_list}.remove-item`), handleClick: removeItem,
                        disabled: type === 'provider'
                    }]}
                    loading={loading}
                />
            </Col>
        </Row>
    )
}

ItemsList.propTypes = {
    items: PropTypes.array.isRequired,
    type: PropTypes.oneOf(['admin', 'approval', 'provider']).isRequired,
    loading: PropTypes,
    removeItem: PropTypes.func.isRequired
}

export default ItemsList