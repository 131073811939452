import React from 'react'
import PropTypes from 'prop-types';
import ModalComp from '../Layouts/Modal'
import ButtonIcon from '../Layouts/Forms/ButtonIcon'
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Calendar, Skeleton } from 'antd';
import usePurchaseOrders from '../../hooks/usePurchaseOrders';

const DetailsModal = ({ headers, fibra_id, onClose, hidden, business_name_id, segment_id }) => {
    const hookPurchaseOrders = usePurchaseOrders({ headers, fibra_id, business_name_id, segment_id, defaultPageSize: 1000 });
    return (
        <ModalComp
            size='lg'
            onClose={onClose}
            hidden={hidden}
            title={hookPurchaseOrders.loading ? 'Obteniendo datos...':'Planificación mensual'}
            body={<>
                {
                    hookPurchaseOrders.loading ? <Skeleton active /> :
                        <Calendar mode='year' />
                }
            </>}
            footer={<>
                <ButtonIcon icon={faTimes} name='Cerrar' onClick={onClose} variant='outline-secondary' />
                <ButtonIcon icon={faSave} name='Guardar' onClick={onClose} variant='outline-success' />
            </>}
        />
    )
}

DetailsModal.propTypes = {
    headers: PropTypes.shape({
        Authorization: PropTypes.string.isRequired
    }).isRequired,
    fibra_id: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    hidden: PropTypes.bool.isRequired,
    segment_id: PropTypes.number.isRequired,
    business_name_id: PropTypes.number.isRequired
}

export default DetailsModal