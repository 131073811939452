import { Text } from '@chakra-ui/react';
import { faArrowLeft, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import ModalComp from '../../Layouts/Modal';

const TwoFactorModal = (props) => {
    const { onClose, type, confirm } = props;

    const [t] = useTranslation('global');
    const prefix = 'settings.security';
    return (
        <ModalComp
            onClose={onClose}
            title={t(`${prefix}.modal-title`)}
            body={<Fragment>
                {
                    type === 'enable' ?
                        <Fragment>
                            {/* Mensaje para habilitar Autentificación en dos pasos */}
                            <Text fontSize='lg' fontWeight='bold'>
                                {t(`${prefix}.modal-ena-title`)}
                            </Text>
                            <Text>
                                {t(`${prefix}.modal-ena-text`)}
                            </Text>
                        </Fragment> :
                        <Fragment>
                            {/* Mensaje para deshabilitar Autentificación en dos pasos */}
                            <Text fontSize='lg' fontWeight='bold'>
                                {t(`${prefix}.modal-dis-title`)}
                            </Text>
                            <Text>
                                {t(`${prefix}.modal-dis-text`)}
                            </Text>
                        </Fragment>
                }
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name={t(`${prefix}.button-close`)} icon={faArrowLeft} onClick={onClose} variant='outline-secondary' />
                <ButtonIcon
                    name={type === 'enable' ? t(`${prefix}.button-active`) : t(`${prefix}.button-inactive`)}
                    icon={type === 'enable' ? faCheck : faTimes} onClick={confirm}
                    variant={type === 'enable' ? 'outline-success' : 'outline-danger'}
                />
            </Fragment>}
        />
    );
};

export default TwoFactorModal;
