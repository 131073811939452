import downloadFile from "../libs/DownloadFile";
import getBlobFile from "../libs/getBlobFile";
import Fetcher from "../libs/Petition"

const url = '/releases';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param fibra_id Se utiliza el identificador de la fibra para obtener los roles que le corresponden
 * @param page [Opcional] Se utiliza para conocer la página en la que se encuentra
 * @param pageSize [Opcional] Se utiliza para especificar el tamaño de la página, es decir, la cantidad de registros que se obtendrán.
 * @param user_id [Opcional] Se usa para obtener únicamente los registros de un cliente
 * @param role_id [Opcional] Se usa para obtener únicamente los registros de un role
 * @returns Object {"data" => ComplaintSuggestionBox::collection(),"totalItems" => Cantidad de registros existentes}
 */
export const indexRelease = async (props) => {
    const {
        headers,
        fibra_id,
        page,
        pageSize,
        role_id,
        user_id,
        active } = props;
    let response = { data: [], totalItems: 0 };
    try {
        // Parámetros requeridos
        let params = { fibra_id };
        // Parámetros opcionales
        if (user_id) params.user_id = user_id;
        if (role_id) params.role_id = role_id;
        if (active) params.active = active;
        if (page) params.page = page;
        if (pageSize) params.pageSize = pageSize;
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        // console.log("🚀 ~ indexRelease ~ fetch:", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param release_id Identificador del comunicado sobre el que se hará la consulta
 * @returns Incident Retorna un objeto tipo Incident
 */
export const showRelease = async ({ headers, release_id }) => {
    let response = {};
    try {
        const fetch = await Fetcher({
            url: `${url}/${release_id}`,
            method: 'GET',
            headers
        });
        // console.log("🚀 ~ file: Releases.js:67 ~ showRelease ~ fetch:", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
        return response;
    } catch (error) {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storeRelease = async ({ headers, data }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        // console.log("🚀 ~ file: Releases.js:92 ~ storeRelease ~ fetch", fetch)
        if (fetch.status === CREATED)
            response = fetch.data.data;
        return response;
    } catch (error) {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param release_id Identificador del comunicado que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updateRelease = async ({ headers, data, release_id }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}/${release_id}`,
            method: 'PUT',
            data,
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param release_id Identificador del comunicado que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyRelease = async ({ headers, release_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${release_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
        return response;
    } catch (error) {
        return response;
    }
}

export const uploadRelease = async ({ headers, release_id, data }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}/upload/${release_id}`,
            method: 'POST',
            data, headers
        });
        // console.log("🚀 ~ file: Releases.js:148 ~ uploadRelease ~ fetch", fetch)
        if (fetch.status === STATUS_OK)
            response = true;
        return response;
    } catch (error) {
        return response;
    }
}

export const downloadRelease = async ({ headers, release_id, name, toast }) => {
    let response = false;
    try {
        downloadFile({
            headers,
            url: `${url}/download/${release_id}`,
            type: 'application/json',
            name,
            toast
        });
        response = true;
        return response;
    } catch (error) {
        return response;
    }
}

export const getBlobRelease = async ({ headers, release_id }) => {
    let response = null;
    try {
        return await getBlobFile({ headers, url: `${url}/download/${release_id}` });
    } catch (error) {
        return response;
    }
}