import { SET_REQUISITIONS, SET_REQUISITIONS_FILTERS } from '../actions/main';
import { stateRequisitions as initialState } from '../initialState/main';

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_REQUISITIONS:
            return {
                ...state,
                data: action.requisitions.data,
                totalItems: action.requisitions.totalItems,
                page: action.requisitions.page,
                pageSize: action.requisitions.pageSize,
            };
        case SET_REQUISITIONS_FILTERS:
            return {
                ...state,
                filters: action.filters
            }
        default: return state;
    }
}

export default reducer;
