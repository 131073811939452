import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Upload } from 'antd'
import { Select } from '@chakra-ui/react'
import ViewImageOrPDF from '../../Layouts/ViewImageOrPDF'
import { downloadBusinessPartners, uploadBusinessPartners } from '../../../api/business_partners'
import { useSelector } from 'react-redux'
import ButtonIcon from '../../Layouts/Forms/ButtonIcon'
import { useTranslation } from 'react-i18next'
import { faFileUpload, faSave, faStepBackward } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';

const UserFiles = ({ currentUser, setShowFiles, updateElement }) => {
    const [t] = useTranslation('settings');
    const token = useSelector(state => state.login.token);
    const headers = { Authorization: `Bearer ${token}` };

    const [currentFile, setCurrentFile] = useState('certificate_tax_status');   // Archivo que se está visualizando
    const [blobFile, setBlobFile] = useState(null);     // Archivo blob actual
    const [files, setFiles] = useState({
        certificate_tax_status: null,
        compliance_opinion: null,
        statement: null,
    });

    const setFile = (type, file) => {
        setFiles(prev => ({ ...prev, [type]: file }));
    };

    // Obtener archivos actuales si existen
    useEffect(() => {
        // Método para obtener un archivo blob
        const getBlobFile = async (business_partner_id, type) => {
            const blobFile = await downloadBusinessPartners({ headers, type, business_partner_id });
            // Si lo obtiene lo asigna al que le corresponde
            if (blobFile) setFile(type, blobFile);
        };
        // Obtener los archivos, si los tiene almacenados
        if (currentUser?.business_partner_provider) {
            const { id: business_partner_id, certificate_tax_status, compliance_opinion, statement } = currentUser.business_partner_provider;
            if (certificate_tax_status) getBlobFile(business_partner_id, 'certificate_tax_status');
            if (compliance_opinion) getBlobFile(business_partner_id, 'compliance_opinion');
            if (statement) getBlobFile(business_partner_id, 'statement');
        }
    }, [currentUser]);

    // Mostrar archivo seleccionado
    useEffect(() => {
        setBlobFile(files[currentFile]);
    }, [currentFile, files]);

    // Manejador de archivo actual
    const handleChangeSelectFile = (event) => {
        const { value } = event.target;
        setCurrentFile(value);
    };

    // Actualizar documento, si existe lo reemplaza
    const updateDocument = async () => {
        const { id: business_partner_id } = currentUser.business_partner_provider;
        const data = new FormData();
        data.append('file', blobFile);
        data.append('business_partner_id', business_partner_id);
        data.append('type', currentFile);
        const fetch = await uploadBusinessPartners({ headers, data });
        if (fetch) {
            // Actualizar las rutas de los archivos cargados
            updateElement({ ...currentUser, business_partner_provider: fetch });
            Swal.fire(t('users.sf.t-success'), t('users.sf.m-files-success'), 'success');
        } else {
            Swal.fire(t('users.sf.t-fail'), t('users.sf.m-files-failed'), 'warning');
        }
    };
    // Propiedades del componente para cargar archivos
    const uploadProps = {
        onRemove: () => {
            setFile(currentFile, null);
        },
        beforeUpload: (file) => {
            setFile(currentFile, file);
            return false;
        },
        fileList: blobFile ? [blobFile] : [],
    };

    return (
        <>
            <Row>
                {/* Encabezado apra seleccionar archivo y ver detalles */}
                <Col span={8}>
                    {/* Elegir tipo de documento que se va a visualizar o modificar */}
                    <Select onChange={handleChangeSelectFile}>
                        <option value="certificate_tax_status">{t('users.modal.certificate_tax_status')}</option>
                        <option value="compliance_opinion">{t('users.modal.compliance_opinion')}</option>
                        <option value="statement">{t('users.modal.statement')}</option>
                    </Select>
                    {/* Componente para cargar los archivos */}
                    <Row className='mt-4'>
                        <Upload {...uploadProps}>
                            <ButtonIcon name={t('users.modal.upload-doc')} icon={faFileUpload} variant='outline-primary' tooltipDisabled />
                        </Upload>
                    </Row>
                    <Row className='mt-4' style={{ zIndex: -100 }}>
                        {/* Regresar a la vista anterior */}
                        <ButtonIcon name={t('users.modal.back')} icon={faStepBackward} variant='outline-secondary' onClick={() => setShowFiles(false)} />
                        {/* Botón que se habilita cuando detecta que un archivo ha sido cargado */}
                        <ButtonIcon
                            name={t('users.modal.save-doc')}
                            icon={faSave}
                            variant='outline-success'
                            onClick={updateDocument}
                            disabled={!blobFile}
                        />
                    </Row>
                </Col>
                {/* Visualizar archivo si existe, se pueden visualizar PDF e imágenes */}
                <Col span={16}>
                    {
                        blobFile &&
                        <ViewImageOrPDF file={blobFile} loading={false} />
                    }
                </Col>
            </Row>
        </>
    )
}

UserFiles.propTypes = {
    updateElement: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
    setShowFiles: PropTypes.func.isRequired
}

export default UserFiles