import React from 'react'
import PropTypes from 'prop-types'
import { Heading } from '@chakra-ui/react'
import { Divider } from 'antd'
import { Col, Form, Row } from 'react-bootstrap'

const UploadInvoice = ({ t, p_data, handleLoadFile }) => {
    return (
        <Row>
            <Divider orientation='left'>
                <Heading size='sm'>{t(`${p_data}.add-invoice`)}</Heading>
            </Divider>
            <Form.Group className="mb-3" as={Col}>
                <Form.Label>{t(`${p_data}.load-xml`)}</Form.Label>
                <Form.Control type="file" name="xml" onChange={handleLoadFile} accept="text/xml" />
            </Form.Group>
            <Form.Group className="mb-3" as={Col}>
                <Form.Label>{t(`${p_data}.load-pdf`)}</Form.Label>
                <Form.Control type="file" name="pdf" onChange={handleLoadFile} accept="application/pdf" />
            </Form.Group>
        </Row>
    )
}

UploadInvoice.propTypes = {
    t: PropTypes.object,
    p_data: PropTypes.string.isRequired,
    handleLoadFile: PropTypes.func.isRequired
}

export default UploadInvoice