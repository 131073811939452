import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { destroyRequisition, indexRequisitions } from '../../../api/Budget/requisitions'
import TableComp from '../../Layouts/Table'
import PaginationComp from '../../Layouts/Pagination'
import usePagination from '../../../hooks/usePagination'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import FlexButton from '../../Layouts/FlexButton'
import useConfirmation from '../../../hooks/useConfirm'
import Swal from 'sweetalert2'
import DetailsProvider from './DetailsProvider'
import DetailsApproval from './DetailsApproval'
import DetailsAdmin from './DetailsAdmin'
import { Row } from 'antd'
import FiltersReqComp from './FiltersReqComp'
import useForm from '../../../hooks/useForm'
import useRequisitions from '../../../hooks/useRequisitions'
import { SET_REQUISITIONS_FILTERS } from '../../../redux/actions/main'

const MainRequisition = forwardRef(({ type, params, tKeys, tHeaders, tMomentDates, badges, badges_values, tactions, loading, business_name_ids, segment_ids }, ref) => {
    const [t] = useTranslation('requisition');
    const { confirmAction } = useConfirmation();
    const prefix = 'table.headers';
    const dispatch = useDispatch();
    const login = useSelector(state => state.login);
    const requisitionsStored = useSelector(state => state.requisitions);
    const headers = { Authorization: `Bearer ${login.token}` };
    const fibra_id = login.fibra.id;

    const hookForm = useForm();
    const hookRequisitions = useRequisitions({ fibra_id, headers, type, loading, business_name_ids, segment_ids, filters: hookForm.params, ...params });
    const { data, pagination, changePage, setData, addElement } = hookRequisitions;

    const [modalDetailsProvider, setModalDetailsProvider] = useState(false);
    const [modalDetailsAdmin, setModalDetailsAdmin] = useState(false);
    const [modalDetailsApproval, setModalDetailsApproval] = useState(false);
    const [requisitionSel, setRequisitionSel] = useState({})
    const [title, setTitle] = useState('');

    useEffect(() => {
        hookForm.setForm(requisitionsStored.filters);
    }, [requisitionsStored.filters]);

    useEffect(() => {
        if (!modalDetailsAdmin) setRequisitionSel({});
    }, [modalDetailsAdmin]);
    useEffect(() => {
        if (!modalDetailsProvider) setRequisitionSel({});
    }, [modalDetailsProvider]);
    useEffect(() => {
        if (!modalDetailsApproval) setRequisitionSel({});
    }, [modalDetailsApproval]);

    const showRequisitionDetails = (item) => {
        setTitle(t(`modal-details.title-show`));
        setRequisitionSel(item);
        switch (type) {
            case 'provider':
                setModalDetailsProvider(true); break;
            case 'admin':
                setModalDetailsAdmin(true); break;
            case 'approval':
                setModalDetailsApproval(true); break;
            default: break;
        }

    }
    const createRequisition = () => {
        setTitle(t(`modal-details.title-create`));
        setModalDetailsAdmin(true);
    }
    // Eliminar requisición
    const dropItem = (item) => {
        const title = t(`table.sf.drop-confirm`);
        const html = t(`table.sf.html-drop`);
        const confirmButtonText = t(`table.sf.b-drop-confirm`);

        confirmAction(title, html, confirmButtonText, async () => {
            const res = await destroyRequisition({ headers, requisition_id: item.id });
            if (res) {
                hookRequisitions.setData(prevState => prevState.filter(requisition => requisition.id !== item.id));
                hookRequisitions.setTotalItems(pagination.totalItems - 1);
                Swal.fire(t(`table.sf.t-success`), t(`table.sf.m-drop`), 'success');
            } else {
                Swal.fire(t(`table.sf.t-fail`), t(`table.sf.m-fail-server`), 'info');
            }
        });
    }
    // Asignar la función al ref proporcionado por el componente padre
    useImperativeHandle(ref, () => ({
        dropItem: (item) => dropItem(item),
    }));

    // Ejecutar al buscar resultados
    const handleClickSearch = () => {
        hookForm.updateParams();
        dispatch({ type: SET_REQUISITIONS_FILTERS, filters: hookForm.form });
        // Regresar a la página 1 después de aplicar los filtros
        hookRequisitions.changePage(1, pagination.pageSize);
    }

    return (
        <Fragment>
            <Row justify='end' className='mb-2'>
                <FiltersReqComp
                    type={type}
                    handleClickSearch={handleClickSearch}
                    hookForm={hookForm}
                    headers={headers}
                    fibra_id={fibra_id}
                    loading={loading}
                    business_name_ids={business_name_ids}
                    segment_ids={segment_ids}
                />
            </Row>
            <TableComp
                headers={tHeaders}
                keys={tKeys}
                body={data}
                loading={hookRequisitions.loading}
                loadingItems={pagination.pageSize}
                page={pagination.page}
                moment_dates={tMomentDates}
                badges={badges}
                badges_values={badges_values}
                actions={[
                    {
                        icon: faEdit,
                        tooltip: t(`${prefix}.actions-tooltip`),
                        handleClick: showRequisitionDetails,
                        variant: 'outline-success'
                    },
                    ...tactions
                ]}
            />
            <PaginationComp
                current={pagination.page}
                totalItems={pagination.totalItems}
                defaultPageSize={pagination.pageSize}
                onChange={changePage}
            />
            {/* Modal  para ver los detalles */}
            {/* Sección para crear una requisición - Disponible para admin */}
            {
                type === 'admin' && <FlexButton style={{ bottom: 20, right: 20 }} onClick={createRequisition} />
            }
            {/* Modal para ver detalles del proveedor */}
            {
                modalDetailsProvider && <DetailsProvider
                    onClose={() => setModalDetailsProvider(false)}
                    title={title}
                    requisition={requisitionSel}
                    type={type}
                    setData={setData}
                    fibra_id={fibra_id}
                    headers={headers}
                    provider_id={login.user.id}
                />
            }
            {/* Modal para ver detalles del área de aprobación */}
            {
                modalDetailsApproval && <DetailsApproval
                    onClose={() => setModalDetailsApproval(false)}
                    title={title}
                    requisition={requisitionSel}
                    type={type}
                    setData={setData}
                    fibra_id={fibra_id}
                    headers={headers}
                    loading={loading}
                    business_name_ids={business_name_ids}
                    segment_ids={segment_ids}
                />
            }
            {/* Modal para ver detalles del área de ingeniería */}
            {
                modalDetailsAdmin && <DetailsAdmin
                    onClose={() => setModalDetailsAdmin(false)}
                    title={title}
                    requisition={requisitionSel}
                    type={type}
                    setData={setData}
                    fibra_id={fibra_id}
                    headers={headers}
                    addElement={addElement}
                    loading={loading}
                    business_name_ids={business_name_ids}
                    segment_ids={segment_ids}
                />
            }
        </Fragment>
    )
})

MainRequisition.propTypes = {
    type: PropTypes.oneOf(['admin', 'approval', 'provider']).isRequired,
    params: PropTypes.shape({
        provider_id: PropTypes.number
    }),
    tKeys: PropTypes.array.isRequired,
    tHeaders: PropTypes.array.isRequired,
    tMomentDates: PropTypes.array.isRequired,
    badges: PropTypes.arrayOf(
        PropTypes.shape({
            index: PropTypes.number.isRequired
        })
    ).isRequired,
    badges_values: PropTypes.arrayOf(
        PropTypes.shape({
            col: PropTypes.string.isRequired,
            values: PropTypes.arrayOf(
                PropTypes.shape({
                    value: PropTypes.string.isRequired,
                    color: PropTypes.string.isRequired,
                    name: PropTypes.string
                })
            )
        })
    ).isRequired,
    tactions: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    business_name_ids: PropTypes.array,
    segment_ids: PropTypes.array
}

export default MainRequisition