import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap';
import SkeletonTable from '../../../Layouts/Skeletons/Table';
import { getCurrency } from '../../../../libs/functions';

const UnitPriceTable = (props) => {
    const {
        tHeaders,
        loading,
        items,
        getDefaultValue,
        handleChange,
        unitPrices,
        getTag,
        handleChangeFiles,
        provider_id
    } = props;

    return (
        <Table
            className='mt-4'
            striped
            bordered
            hover
        >
            <thead>
                <tr>
                    {
                        tHeaders.map((header) =>
                            <th key={header}>{header}</th>
                        )
                    }
                </tr>
            </thead>
            <tbody>
                {
                    loading ? <SkeletonTable tr={5} td={tHeaders.length} /> :
                        items
                            .filter((item) => item.providers.find(provider => provider.id === provider_id))
                            .map((item, item_index) => {
                                // Ya fue asignado el precio unitario
                                const exists = getDefaultValue(item);
                                // La partida ya fue asignada a una orden de compra
                                const assigned = item.provider_id;
                                return <tr key={item.id}>
                                    <td align='center'>{item_index + 1}</td>
                                    <td >{item.description}</td>
                                    <td >{item.detailed}</td>
                                    <td align='center'>{item.unit}</td>
                                    <td align='center'>{item.quantity}</td>
                                    {/* Celda para insertar el precio unitario */}
                                    <td>
                                        <input
                                            type="number"
                                            className='form-control form-control-sm'
                                            defaultValue={exists.unit_price}
                                            onChange={(event) => handleChange(event, item_index)}
                                            disabled={exists && assigned}
                                        />
                                    </td>
                                    {/* Celda para mostrar el total */}
                                    <td>
                                        <input
                                            type="text"
                                            value={getCurrency(unitPrices[item_index] * item.quantity)}
                                            className='form-control form-control-sm'
                                            readOnly
                                        />
                                    </td>
                                    {/* Agregar archivos - sólo si es necesario */}
                                    <td align='center'>
                                        {
                                            exists && assigned && getTag(exists)
                                        }
                                        {
                                            exists && !assigned && <>
                                                {getTag(exists)}
                                                <input
                                                    type="file"
                                                    className='form-control form-control-sm'
                                                    onChange={(event) => handleChangeFiles(event, item_index)}
                                                />
                                            </>
                                        }
                                        {
                                            !exists && !assigned &&
                                            <input
                                                type="file"
                                                className='form-control form-control-sm'
                                                onChange={(event) => handleChangeFiles(event, item_index)}
                                            />
                                        }

                                    </td>
                                </tr>
                            })
                }
            </tbody>
        </Table>
    )
}

UnitPriceTable.propTypes = {
    tHeaders: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    getDefaultValue: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    unitPrices: PropTypes.array.isRequired,
    getTag: PropTypes.func.isRequired,
    handleChangeFiles: PropTypes.func.isRequired,
    provider_id: PropTypes.number.isRequired
}

export default UnitPriceTable