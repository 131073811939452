import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useState, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Fetcher from '../../../libs/Petition';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import SkeletonCards from '../../Layouts/Skeletons/Cards';
import FibraDanhosLogo from '../../../images/danhos-logo.jpeg';
import { useTranslation } from 'react-i18next';

const Clients = (props) => {
    const { showConfig } = props;

    const [t] = useTranslation('global');
    const prefix = 'super-admin.tooltips';
    const token = useSelector(state => state.login.token);
    const headers = { Authorization: `Bearer ${token}` };
    const [fibras, setFibras] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getFibras = async () => {
            try {
                let response = await Fetcher({
                    method: 'GET',
                    url: `/fibras`,
                    headers
                });
                setFibras(response.data.data);
            } catch (error) {
                setFibras([]);
            }
            setLoading(false);
        }
        getFibras();
    }, []);

    return (
        <Fragment>
            {
                loading ?
                    <SkeletonCards items={4} cols={4} /> :
                    <Row xs={1} md={4} className="g-4 mt-2">
                        {
                            fibras?.map((fibra) => (
                                <Col key={fibra?.id}>
                                    <Card>
                                        <Card.Img variant="top" src={FibraDanhosLogo} />
                                        <Card.Body>
                                            <Card.Title>{fibra.code}</Card.Title>
                                            <Card.Text>{fibra.name}</Card.Text>
                                            <div id='footer-options'>
                                                <ButtonIcon icon={faEdit} variant='outline-success' tooltip={t(`${prefix}.edit-admin`)}
                                                    onClick={() => showConfig(fibra)} />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                        }
                    </Row>
            }
        </Fragment>

    );
}

export default Clients;