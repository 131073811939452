import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import ModalPurchaseOrder from "./Modal";
import TableComp from "../../Layouts/Table";
import PaginationComp from "../../Layouts/Pagination";
import { useStore } from "react-redux";
import { useTranslation } from "react-i18next";
import { hookPaginationPropType } from "../../../data/propTypes";

const PurchaseOrderTable = ({ type, title, hookPurchaseOrders, hookProviders, tactions }) => {
    const store = useStore();
    const { token, fibra } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };
    const { page, pageSize, totalItems } = hookPurchaseOrders.pagination;

    const [modalView, setModalView] = useState(false);
    const [t] = useTranslation('global');
    const prefix = 'purchase-order.data';

    // Limpiar búffer de la selección de orden de compra
    useEffect(() => {
        if (!modalView) hookPurchaseOrders.setDataSel(null);
    }, [modalView]);

    const view = (item) => {
        hookPurchaseOrders.setDataSel(item);
        setModalView(true);
    }

    return (
        <div style={{ width: '100%' }}>
            {/* Tabla de órdenes de compra */}
            <TableComp
                headers={[
                    '#',
                    t(`${prefix}.folio`),
                    t(`${prefix}.provider`),
                    t(`${prefix}.segment`),
                    t(`${prefix}.currency`),
                    t(`${prefix}.amount`),
                    t(`${prefix}.voucher`),
                    t(`${prefix}.percentage`),
                    t(`${prefix}.status`),
                    t(`${prefix}.actions`)
                ]}
                keys={['#', 'folio', 'user', 'segment', 'currency', 'amount', 'has_voucher', 'payment_order_percentage', 'canceled']}
                body={hookPurchaseOrders.data}
                loading={hookPurchaseOrders.loading}
                loadingItems={pageSize}
                currency={[{ index: 4 }, { index: 5 }]}
                badges={[{ index: 6 }, { index: 8 }]}
                percentage={[{ index: 7, accuracy: 2 }]}
                badges_values={[{
                    col: 'has_voucher',
                    values: [
                        { value: 'pending', color: 'gray', name: t(`${prefix}.bv-pending`) },
                        { value: 'sent', color: '#1399a7', name: t(`${prefix}.bv-sent`) },
                    ]
                }, {
                    col: 'canceled',
                    values: [
                        { value: 'ACTIVA', color: '#2e7c1d', name: t(`${prefix}.bv-active`) },
                        { value: 'CANCELADA', color: '#e6890b', name: t(`${prefix}.bv-canceled`) },
                    ]
                }]}
                actions={[
                    {
                        icon: type === 'admin' ? faEdit : faEye,
                        handleClick: view,
                        tooltip: `${t('purchase-order.tooltips.view')} ${type === 'admin' ? t('purchase-order.tooltips.and-edit') : ''} ${t('purchase-order.tooltips.purchase-order')}`,
                        variant: type === 'admin' ? 'outline-success' : 'outline-primary'
                    }, ...tactions
                ]}
            />
            {/* Paginación de órdenes de compra */}
            <PaginationComp totalItems={totalItems} onChange={hookPurchaseOrders.changePage} current={page} defaultPageSize={pageSize} />

            {/* Modal de vista */}
            {
                modalView && <ModalPurchaseOrder
                    title={title}
                    type={type}
                    headers={headers}
                    fibra_id={fibra.id}
                    hookPurchaseOrders={hookPurchaseOrders}
                    onClose={() => setModalView(false)}
                    providers={hookProviders.data}
                />
            }
        </div>
    );
}

PurchaseOrderTable.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    hookPurchaseOrders: PropTypes.shape(hookPaginationPropType).isRequired,
    hookProviders: PropTypes.shape(hookPaginationPropType).isRequired,
    tactions: PropTypes.array.isRequired
}

export default PurchaseOrderTable;