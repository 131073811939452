import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import TableComp from '../../../Layouts/Table'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

const ItemsList = ({ t, p_data, items, type, handleChangeCheck, getChecked, removeItem, loading }) => {
    const getDisabled = () => true;
    return (
        <Row className='mb-3'>
            <Col>
                <TableComp
                    headers={[
                        '#',
                        t(`${p_data}.item-description`),
                        t(`${p_data}.item-detailed`),
                        t(`${p_data}.item-unit`),
                        t(`${p_data}.item-quantity`),
                        t(`${p_data}.item-unit-price`),
                        t(`${p_data}.item-iva`),
                        t(`${p_data}.item-iva-withholding`),
                        t(`${p_data}.item-isr-withholding`),
                        t(`${p_data}.item-subtotal`),
                        t(`${p_data}.item-total`),
                        t(`${p_data}.item-actions`)
                    ]}
                    keys={['index', 'description', 'detailed', 'unit', 'quantity', 'unit_price', 'iva', 'iva_withholding_price', 'isr_withholding_price', 'subtotal', 'total']}
                    body={items}
                    currency={[{ index: 5 }, { index: 6 }, { index: 7 }, { index: 8 }, { index: 9 }, { index: 10 }]}
                    actions={
                        type === 'provider' ?
                            {
                                checkedChildren: t(`${p_data}.checked`), unCheckedChildren: t(`${p_data}.unchecked`),
                                onChange: handleChangeCheck,
                                getChecked, getDisabled
                            }
                            : [{
                                icon: faTrashAlt, variant: 'outline-danger',
                                tooltip: t(`purchase-order.tooltips.item-del`), handleClick: removeItem,
                                disabled: type === 'provider'
                            }]
                    }
                    type={type === 'provider' ? 'switch' : ''}
                    loading={loading}
                />
            </Col>
        </Row>
    )
}

ItemsList.propTypes = {
    t: PropTypes.string.isRequired,
    p_data: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    type: PropTypes.oneOf(['admin', 'provider']).isRequired,
    handleChangeCheck: PropTypes.func.isRequired,
    getChecked: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
}
export default ItemsList