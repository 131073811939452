import React, { useState } from 'react'
import FormIcon from '../../Layouts/Forms/FormIcon'
import { useTranslation } from 'react-i18next'
import { Card, Row } from 'react-bootstrap';
import { Stack, Text } from '@chakra-ui/react';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import { resetPassword } from '../../../api/Authentification';
import Swal from 'sweetalert2';

const ResetPassword = ({ setResetPass }) => {
    const [t] = useTranslation('global');

    const [data, setData] = useState({});

    const send = async () => {
        const { res } = await resetPassword({ data });
        if (res) {
            Swal.fire(
                t('login.reset-password.done-title'),
                t('login.reset-password.done-desc'),
                'success'
            );
            setResetPass(false);
        } else {
            Swal.fire(
                t('login.reset-password.undone-title'),
                t('login.reset-password.undone-desc'),
                'warning'
            );
        }
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData(prevState => ({ ...prevState, [name]: value }));
    }
    return (
        <Row>
            <Card id='reset-form' style={{ borderRadius: '0.375rem', padding: '5%' }}>
                <Card.Title>{t('login.reset-password.title')}</Card.Title>
                <Stack spacing={4}>
                    <FormIcon
                        title={t('login.reset-password.email')}
                        styleInput={{ backgroundColor: '#FFF' }}
                        name='email'
                        value={data?.email}
                        handleChange={handleChange}
                    />
                    <Text color='whiteAlpha.800' > {t('login.reset-password.text')}</Text>
                    {/* Button para iniciar sesión */}
                    <div className="d-grid gap-2">
                        <ButtonIcon variant='primary' icon={faUnlockAlt} name={t('login.reset-password.btn-reset')} onClick={send} />
                    </div>
                </Stack>
            </Card>
        </Row>
    )
}

export default ResetPassword