import { useToast } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { faArrowLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { storeBusinessName } from '../../../api/Cfdi/BusinessNames';
import setToast from '../../../libs/SetToast';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import FormIcon from '../../Layouts/Forms/FormIcon';
import ModalComp from '../../Layouts/Modal'

const UpdateBusinessName = ({ onClose, headers, business_name_id, setData }) => {
    const [form, setForm] = useState({});
    const [t] = useTranslation('bulk_download');
    const prefix = 'update-business-name';
    const p_t = `${prefix}.toasts`;
    const toast = useToast();

    const handleChangeFiles = (e) => {
        setForm({ ...form, [e.target.name]: e.target.files[0] });
    }
    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    }
    const updateFIEL = async () => {
        let data = new FormData();
        // Se manda el "business_name_id" para indicar que es una actualización
        data.append('business_name_id', business_name_id)
        if (form.name) data.append('name', form.name);
        if (form.rfc) data.append('rfc', form.rfc);
        if (form.pass_phrase) data.append('pass_phrase', form.pass_phrase);
        if (form.cer) data.append('cer', form.cer);
        if (form.key) data.append('key', form.key);
        const response = await storeBusinessName({ headers, data });
        if (response) {
            setToast({
                toast,
                title: t(`${p_t}.success.title`),
                description: t(`${p_t}.success.description`),
                status: 'success'
            });
            // Actualizar el registro de la tabla
            setData(prevState => prevState.map((business_name) => {
                if (business_name.id === business_name_id) {
                    business_name = response;
                }
                return business_name;
            }));
            onClose();
        } else {
            setToast({
                toast,
                title: t(`${p_t}.failure.title`),
                description: t(`${p_t}.failure.description`),
                status: 'error'
            });
        }
    }

    return (
        <ModalComp
            onClose={onClose}
            title={t(`${prefix}.title`)}
            body={<Fragment>
                <Row className='mb-3'>
                    <FormIcon title={t(`${prefix}.business-name`)} type='text' placeholder={t(`${prefix}.business-name-ph`)} name='name' handleChange={handleChange} />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title={t(`${prefix}.tax-id`)} type='text' placeholder={t(`${prefix}.tax-id-ph`)} name='rfc' handleChange={handleChange} />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title={t(`${prefix}.cer-file`)} type='file' name='cer' handleChange={handleChangeFiles} accept='.cer' />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title={t(`${prefix}.key-file`)} type='file' name='key' handleChange={handleChangeFiles} accept='.key' />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title={t(`${prefix}.pass-phrase`)} type='password' name='pass_phrase' handleChange={handleChange} />
                </Row>
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name={t(`${prefix}.btn-close`)} icon={faArrowLeft} variant='outline-secondary' onClick={onClose} />
                <ButtonIcon name={t(`${prefix}.btn-save`)} icon={faSave} variant='outline-success' onClick={updateFIEL} />
            </Fragment>}
        />
    )
}

UpdateBusinessName.propTypes = {
    onClose: PropTypes.func.isRequired,
    headers: PropTypes.shape({
        Authorization: PropTypes.string.isRequired
    }).isRequired,
    business_name_id: PropTypes.number.isRequired,
    setData: PropTypes.func.isRequired
}

export default UpdateBusinessName