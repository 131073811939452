import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import TableComp from '../../../Layouts/Table';
import { Divider, InputNumber, Space, Switch } from 'antd';
import { Heading } from '@chakra-ui/react';
import useForm from '../../../../hooks/useForm';

const ItemList = ({ setForms, items, provider_id, disabled }) => {
	const [t] = useTranslation('requisition');
	const prefix_list = `modal-details.items-list`

	const hookForm = useForm();
	// Inicializar retenciones
	useEffect(() => {
		hookForm.setForm(items.map(item => {
			return {
				item_id: item.id,
				has_iva_withholding: false,
				iva_withholding: 4,
				has_isr_withholding: false,
				isr_withholding: 1.25,
			}
		}));
	}, []);
	// Actualizar retenciones, efecto que detecta cambios en los switches de las retenciones
	useEffect(() => {
		if (Array.isArray(hookForm.form)) {
			for (const purchaseOrderItem of hookForm.form) {
				setForms(prevState => prevState.map(form => {
					// Se busca el formulario del proveedor actual, ya que pueden haber
					// múltiples ordenes de compra
					if (form.provider_id === provider_id) {
						// Se modifican los valores de las partidas
						const items = getItemsRet(form, purchaseOrderItem);
						form.data.items = items;
					}
					return form;
				}));
			}
		}
	}, [hookForm.form]);
	// Por default el switch está desactivado
	const getChecked = () => {
		return false
	}
	// Implementar método para determinadar cuando el switch de iva permanece deshabilitado
	const getDisabled = () => {
		return disabled
	}
	// Manejador de cambios del switch IVA
	const handleChange = (checked, item_id) => {
		setForms(prevState => prevState.map(form => {
			// Se busca el formulario del proveedor actual, ya que pueden haber
			// múltiples ordenes de compra
			if (form.provider_id === provider_id) {
				// Se modifican los valores de las partidas
				const items = getItems(form, item_id, checked);
				form.data.items = items;
			}
			return form;
		}));
	}
	// Modificación de las partidas, en base a los switches de las retenciones
	const getItemsRet = (form, purchaseOrderItem) => {
		return form.data.items.map(item => {
			if (item.id === purchaseOrderItem.item_id) {
				// Calcular el total con IVA
				const totalWithIva = item.subtotal + item.iva;
				let iva_withholding_price = 0;
				let isr_withholding_price = 0;
				// Calcular la retención de IVA
				if (purchaseOrderItem.has_iva_withholding) {
					iva_withholding_price = item.subtotal * (purchaseOrderItem.iva_withholding / 100);
					item.iva_withholding = purchaseOrderItem.iva_withholding;
				} else {
					item.iva_withholding = 0;
				}
				// Calcular la retención de ISR
				if (purchaseOrderItem.has_isr_withholding) {
					isr_withholding_price = item.subtotal * (purchaseOrderItem.isr_withholding / 100);
					item.isr_withholding = purchaseOrderItem.isr_withholding;
				} else {
					item.isr_withholding = 0;
				}
				item.iva_withholding_price = iva_withholding_price;
				item.isr_withholding_price = isr_withholding_price;
				item.total = totalWithIva - iva_withholding_price - isr_withholding_price;
			}
			return item;
		});
	}
	// Modificación de las partidas, en base a switch IVA
	const getItems = (form, item_id, checked) => {
		return form.data.items.map(item => {
			if (item.id === item_id) {
				if (checked) {
					// Calcular el total con IVA
					const totalWithIva = item.subtotal * (1 + process.env.REACT_APP_IVA / 100);
					// Calcular el IVA
					const iva = totalWithIva - item.subtotal;
					item.iva = iva;
					item.total = totalWithIva;
				} else {
					item.iva = 0
					item.total = item.subtotal;
				}
			}
			return item;
		})
	}
	// Manejar el cambio del interruptor para IVA y ISR
	const handleSwitchChange = (item, field, checked) => {
		hookForm.setForm(prevState =>
			prevState.map(element => {
				if (element.item_id === item.id) {
					element[field] = checked;
				}
				return element;
			})
		);
	}

	// Manejar el cambio del valor del input para IVA y ISR
	const handleInputChange = (item, field, value) => {
		hookForm.setForm(prevState =>
			prevState.map(element => {
				if (element.item_id === item.id) {
					element[field] = value;
				}
				return element;
			})
		);
	}

	// Renderizar el interruptor con el campo de entrada para IVA y ISR
	const renderSwitchWithInput = (item, formItem, switchField, inputField, switchCheckedLabel, switchUncheckedLabel, inputDefaultValue) => (
		<Space>
			<Switch
				checkedChildren={t(switchCheckedLabel)}
				unCheckedChildren={t(switchUncheckedLabel)}
				checked={formItem?.[switchField] || false}
				onChange={(checked) => handleSwitchChange(item, switchField, checked)}
				disabled={getDisabled()}
			/>
			<InputNumber
				width={80}
				size='small'
				value={formItem?.[inputField] || inputDefaultValue}
				min={0}
				max={100}
				formatter={(value) => `${value}%`}
				parser={(value) => value.replace('%', '')}
				onChange={(value) => handleInputChange(item, inputField, value)}
				disabled={!formItem?.[switchField]}
			/>
		</Space>
	)
	// Renderizar las acciones para cada ítem
	const renderActions = (item) => {
		if (!Array.isArray(hookForm.form)) return null;
		const formItem = hookForm.form.find(element => element.item_id === item.id);
		return (
			<>
				<Switch
					checkedChildren={t(`${prefix_list}.switch-enabled`)}
					unCheckedChildren={t(`${prefix_list}.switch-disabled`)}
					onChange={(checked) => handleChange(checked, item.id)}
					defaultChecked={() => getChecked()}
					disabled={getDisabled()}
				/>
				<br />
				{renderSwitchWithInput(item, formItem, 'has_iva_withholding', 'iva_withholding', t(`${prefix_list}.iva-wh`), t(`${prefix_list}.iva-wh`), 4)}
				{renderSwitchWithInput(item, formItem, 'has_isr_withholding', 'isr_withholding', t(`${prefix_list}.isr-wh`), t(`${prefix_list}.isr-wh`), 1.25)}
			</>
		)
	}

	return (
		<Row className='mb-3'>
			<Divider orientation='left'>
				<Heading size='sm'>Partidas</Heading>
			</Divider>
			<Col>
				<TableComp
					type="custom"
					headers={[
						'#',
						t(`${prefix_list}.description`),
						t(`${prefix_list}.detailed`),
						t(`${prefix_list}.unit`),
						t(`${prefix_list}.quantity`),
						t(`${prefix_list}.unit-price`),
						t(`${prefix_list}.iva`),
						t(`${prefix_list}.iva-withholding`),
						t(`${prefix_list}.isr-withholding`),
						t(`${prefix_list}.subtotal`),
						t(`${prefix_list}.total`),
						t(`${prefix_list}.actions`)
					]}
					keys={['#', 'item.description', 'item.detailed', 'item.unit', 'item.quantity', 'unit_price', 'iva', 'iva_withholding_price', 'isr_withholding_price', 'subtotal', 'total']}
					body={items}
					currency={[{ index: 5 }, { index: 6 }, { index: 7 }, { index: 8 }, { index: 9 }, { index: 10 }]}
					actions={{ renderActions }}
					loading={false}
				/>
			</Col>
		</Row>
	)
}

ItemList.propTypes = {
	setForms: PropTypes.func.isRequired,
	items: PropTypes.array.isRequired,
	provider_id: PropTypes.number.isRequired,
	disabled: PropTypes.bool.isRequired
}

export default ItemList