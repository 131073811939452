import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { SET_ACTIVE_TAB } from "../../redux/actions/main";
import ComplaintSuggestionsBox from "./fragments/Table";

const ComplaintsSuggestionsAdmin = () => {
    const [t] = useTranslation('global');
    const t_prefix = 'complaint-suggestions.table.headers';// Referencia al objeto headers de global.json
    
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/complaints-suggestions' });
    }, []);

    return (
        <ComplaintSuggestionsBox
            is_admin
            params={{}}
            theaders={[
                '#',
                t(`${t_prefix}.user`),
                t(`${t_prefix}.title`),
                t(`${t_prefix}.type`),
                t(`${t_prefix}.status`),
                t(`${t_prefix}.created-at`),
                t(`${t_prefix}.actions`)
            ]}
            tkeys={['id', 'user', 'title', 'type', 'status', 'created_at']}
            tbadges={[{ index: 3, color: 'type_color' }, { index: 4, color: 'status_color' }]}
            moment_dates={[{ index: 5 , format: 'LLLL'}]}
        />
    );
}

export default ComplaintsSuggestionsAdmin;