import React, { useState } from 'react'
import PropTypes from 'prop-types';
import DrawerComp from '../../Layouts/Drawer'
import { useTranslation } from 'react-i18next';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import { faFileExcel, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { downloadPurchaseOrderXlsx } from '../../../api/PurchaseOrders';
import { Box, FormLabel, useToast } from '@chakra-ui/react';
import FormIcon from '../../Layouts/Forms/FormIcon';
import useForm from '../../../hooks/useForm';
import setToast from '../../../libs/SetToast';

const Downloads = ({ headers, fibra_id }) => {
    const toast = useToast();
    const [t] = useTranslation('purchase_orders');
    const hookForm = useForm();
    const [loadingXlsxPO, setLoadingXlsxPO] = useState(false);
    const [loadingXlsxPV, setLoadingXlsxPV] = useState(false);
    const [loadingXlsxIT, setLoadingXlsxIT] = useState(false);
    const [loadingXlsxWPV, setLoadingXlsxWPV] = useState(false);

    // Descargar reporte xlsx
    const downloadXlsx = async (type) => {
        const now = new Date().getTime();
        const { start_date, end_date } = hookForm.form;
        // Validar atributos de rango de búsqueda
        if (start_date && end_date && start_date >= end_date) {
            setToast({
                title: "Error de fechas", status: "warning", toast,
                description: "El rango de las fechas es incorrecto. Corriga las fechas!"
            });
            return;
        }
        if (type === 'payment_order') {
            const name = 'purchase_order_without_payment_order_' + now;
            setLoadingXlsxPO(true);
            await downloadPurchaseOrderXlsx({ headers, name, toast, fibra_id, type, start_date, end_date });
            setLoadingXlsxPO(false);
        } else if (type === 'with_vouchers') {
            const name = 'purchase_order_with_vouchers' + now;
            setLoadingXlsxWPV(true);
            await downloadPurchaseOrderXlsx({ headers, name, toast, fibra_id, type, start_date, end_date });
            setLoadingXlsxWPV(false);
        } else if (type === 'purchase_order_items') {
            const name = 'purchase_order_items' + now;
            setLoadingXlsxIT(true);
            await downloadPurchaseOrderXlsx({ headers, name, toast, fibra_id, type, start_date, end_date });
            setLoadingXlsxIT(false);
        } else {
            const name = 'purchase_order_without_vouchers' + now;
            setLoadingXlsxPV(true);
            await downloadPurchaseOrderXlsx({ headers, name, toast, fibra_id, type, start_date, end_date });
            setLoadingXlsxPV(false);
        }

    }

    return (
        <DrawerComp
            title={t('filters.title-downloads')}
            mainIcon={faFileExcel}
            mainName={t('filters.main-name')}
            mainTooltip={t('filters.main-name-tt')}
            mainVariant='outline-success'
        >
            <Box className='mb-3'>
                <FormLabel>{t(`filters.remesa-sin-comprobante-pago`)}</FormLabel>
                <ButtonIcon
                    key='remesa-sin-comprobante-pago'
                    name={loadingXlsxPV ? t('filters.download-xlsx-loading') : t('filters.download-xlsx-v')}
                    icon={loadingXlsxPV ? faSpinner : faFileExcel}
                    variant='outline-success'
                    onClick={() => downloadXlsx('vouchers')}
                    disabled={loadingXlsxPV}
                />
            </Box>
            <Box className='mb-3'>
                <FormLabel>{t(`filters.remesa-sin-orden-pago`)}</FormLabel>
                <ButtonIcon
                    key='remesa-sin-orden-pago'
                    name={loadingXlsxPO ? t('filters.download-xlsx-loading') : t('filters.download-xlsx-po')}
                    icon={loadingXlsxPO ? faSpinner : faFileExcel}
                    variant='outline-success'
                    onClick={() => downloadXlsx('payment_order')}
                    disabled={loadingXlsxPO}
                />
            </Box>
            <Box className='mb-3'>
                <FormLabel>{t(`filters.remesa-con-comprobante-pago`)}</FormLabel>
                <ButtonIcon
                    key='remesa-con-comprobante-pago'
                    name={loadingXlsxWPV ? t('filters.download-xlsx-loading') : t('filters.download-xlsx-cv')}
                    icon={loadingXlsxWPV ? faSpinner : faFileExcel}
                    variant='outline-success'
                    onClick={() => downloadXlsx('with_vouchers')}
                    disabled={loadingXlsxWPV}
                />
            </Box>
            <Box className='mb-3'>
                <FormLabel>{t(`filters.remesa-partidas-pagadas`)}</FormLabel>
                <ButtonIcon
                    key='remesa-partidas-pagadas'
                    name={loadingXlsxIT ? t('filters.download-xlsx-loading') : t('filters.download-xlsx-it')}
                    icon={loadingXlsxIT ? faSpinner : faFileExcel}
                    variant='outline-success'
                    onClick={() => downloadXlsx('purchase_order_items')}
                    disabled={loadingXlsxIT}
                />
            </Box>
            <Box className='mb-3'>
                <FormLabel>{t(`Buscar desde (opcional)`)}</FormLabel>
                <FormIcon type='date' name='start_date' value={hookForm.form.start_date} handleChange={hookForm.handleChange} />
            </Box>
            <Box className='mb-3'>
                <FormLabel>{t(`Buscar hasta (opcional)`)}</FormLabel>
                <FormIcon type='date' name='end_date' value={hookForm.form.end_date} handleChange={hookForm.handleChange} />
            </Box>
        </DrawerComp>
    )
}

Downloads.propTypes = {
    headers: PropTypes.shape({
        Authorization: PropTypes.string.isRequired
    }).isRequired,
    fibra_id: PropTypes.number.isRequired
}

export default Downloads