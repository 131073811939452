import { fetcherCancelToken } from "../../libs/Petition";

const url = '/role_business_names';
const CREATED = 201;
const STATUS_OK = 200;

export const indexRoleBusinessNames = async ({ headers, role_id, page, pageSize, updated_at_start }) => {

    let response = { business_names: [], segments: [] };

    try {
        // Parámetros requeridos
        let params = { role_id };
        // Parámetros opcionales
        if (page) params.page = page;
        if (pageSize) params.pageSize = pageSize;
        if (updated_at_start) params.updated_at_start = updated_at_start;
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });

        // console.log("🚀 ~ file:Role BusinessNames.js:31 ~ indeRolexBusinessNames ~ fetch:", fetch)

        if (fetch.status === STATUS_OK)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}

export const storeRoleBusinessNames = async ({ headers, data }) => {
    let response = false;
    try {
        const { fetch } = await fetcherCancelToken({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });

        // console.log("🚀 ~ file:Role BusinessNames.js:52 ~ storRoleeBusinessNames ~ fetch:", fetch)

        if (fetch.status === CREATED)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }

}