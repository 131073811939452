import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';
import TableComp from '../../../Layouts/Table';
import PaginationComp from '../../../Layouts/Pagination';
import ModalView from './Modal';
import MailboxFilters from './MailboxFilters';

const Invoices = ({ hookInvoices, setFilters, filters, tactions, is_admin }) => {
    const { changePage, pagination } = hookInvoices;
    const token = useSelector(state => state.login.token);
    const cfdi_statuses = useSelector(state => state.cfdi.state);
    const headers = { Authorization: `Bearer ${token}` };
    // Hooks locales
    const [modal, setModal] = useState(false);              // Modal incativo por default
    const [t] = useTranslation('global');
    const prefix = 'mailbox.data';

    const openOrClose = () => setModal(!modal);

    const viewInvoice = (item) => {
        hookInvoices.setDataSel(item);
        openOrClose();
    }

    return (
        <div>
            {/* Filtro: Para realizar consultas específicas */}
            <Row justify='end' className='mb-2'>
                <MailboxFilters
                    cfdi_statuses={cfdi_statuses}
                    filters={filters}
                    setFilters={setFilters}
                />
            </Row>
            {/* Table: Lista de facturas consultadas */}
            {
                tactions && <TableComp
                    headers={[
                        t(`${prefix}.folio`),
                        t(`${prefix}.uuid`),
                        t(`${prefix}.receptor`),
                        t(`${prefix}.transmitter`),
                        t(`${prefix}.payment_method`),
                        t(`${prefix}.currency`),
                        t(`${prefix}.total`),
                        t(`${prefix}.emission_date`),
                        t(`${prefix}.status`),
                        t(`${prefix}.actions`)
                    ]}
                    keys={['folio', 'uuid', 'nombre_receptor', 'nombre_emisor', 'payment_method', 'currency', 'total', 'emission_date', 'cfdi_status']}
                    body={hookInvoices.data}
                    actions={[
                        { handleClick: viewInvoice, tooltip: t('mailbox.table.tooltips.details') },
                        ...tactions
                    ]}
                    loading={hookInvoices.loading}
                    loadingItems={pagination.pageSize}
                    badges={[{ index: 8, color: 'color' }]}
                    currency={[{ index: 6 }]}
                    moment_dates={[{ index: 7, format: 'LL' }]}
                />
            }
            {/* Paginación */}
            <PaginationComp totalItems={pagination.totalItems} onChange={changePage} current={pagination.page} />
            {/* Modal: Detalles de factura */}
            {
                modal && <ModalView
                    onClose={openOrClose}
                    invoice={hookInvoices.dataSel}
                    headers={headers}
                    is_admin={is_admin}
                />
            }
        </div>
    )
}

Invoices.propTypes = {
    hookInvoices: PropTypes.shape({
        data: PropTypes.array.isRequired,
        dataSel: PropTypes.object.isRequired,
        pagination: PropTypes.shape({
            page: PropTypes.number.isRequired,
            pageSize: PropTypes.number.isRequired,
            totalItems: PropTypes.number.isRequired
        }),
        loading: PropTypes.bool.isRequired,
        setDataSel: PropTypes.func.isRequired,
        changePage: PropTypes.func.isRequired
    }).isRequired,
    setFilters: PropTypes.func.isRequired,
    filters: PropTypes,
    tactions: PropTypes.array.isRequired,
    is_admin: PropTypes.bool,
}

export default Invoices