import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from "react";
import PaginationComp from "../../Layouts/Pagination";
import TableComp from "../../Layouts/Table";
import { Row, Select } from 'antd';
import ModalHistory from "../modals/History";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const History = (props) => {
    const { rent_requests, totalItems, page, pageSize, loading, changePage, type, changeStatus } = props;
    const [headers, setHeaders] = useState([]);
    const [keys, setKeys] = useState([]);
    const [modalView, setModalView] = useState(false);      // Modal de detalles de la solicitud
    const [request_id, setRequestID] = useState(null);
    const [t] = useTranslation('global');
    const prefix = 'rents.filters';

    useEffect(() => {
        if (type === 'admin') {
            setHeaders([
                '#',
                t('rents.data.user'),
                t('rents.data.local'),
                t('rents.data.date'),
                t('rents.data.status'),
                t('rents.data.actions')
            ]);
            setKeys(['id', 'user', 'local_mall', 'created_at', 'status']);
        } else {
            setHeaders([
                '#',
                t('rents.data.local'),
                t('rents.data.date'),
                t('rents.data.status'),
                t('rents.data.actions')
            ]);
            setKeys(['id', 'local_mall', 'created_at', 'status']);
        }
    }, []);

    const openOrCloseModalView = () => { setModalView(!modalView); };

    const viewDetails = (item) => {
        setRequestID(item.id);
        openOrCloseModalView();
    }
    return (
        <Fragment>
            {type === 'client' &&
                <Row justify='end' className='mb-2'>
                    <Select placeholder={t(`${prefix}.status`)} name='status' onChange={changeStatus} style={{ width: 180 }}>
                        <Option value={null}>{t(`${prefix}.all`)}</Option>
                        <Option value='pendiente'>{t(`${prefix}.pending`)}</Option>
                        <Option value='atendiendo'>{t(`${prefix}.attending`)}</Option>
                        <Option value='cancelado'>{t(`${prefix}.cancel`)}</Option>
                        <Option value='resuelto'>{t(`${prefix}.solve`)}</Option>
                    </Select>
                </Row>
            }
            <TableComp
                headers={headers}
                keys={keys}
                body={rent_requests}
                loading={loading}
                loadingItems={pageSize}
                actions={[
                    { icon: faEye, handleClick: viewDetails, tooltip: t(`${prefix}.view-details`) }
                ]}
                badges={[{ index: type === 'admin' ? 4 : 3, color: 'status_color' }]}
                moment_dates={[{ index: type === 'admin' ? 3 : 2, format: 'LLLL' }]}
            />
            <PaginationComp
                totalItems={totalItems}
                onChange={changePage}
                current={page}
            />
            {
                modalView &&
                <ModalHistory
                    onClose={openOrCloseModalView}
                    rent_request_id={request_id}
                />
            }
        </Fragment>
    );
}

export default History