import Fetcher from "../libs/Petition"
import downloadFile from "../libs/DownloadFile";

const url = '/users';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param fibra_id Se utiliza el identificador de la fibra para obtener los usuarios que le corresponden
 * @param role_code [Opcional] Obtiene los registros de un role segúnsu código
 * @returns User::collection() Retorna una colección de objetos de tipo User
 */
export const indexUser = async ({ headers, fibra_id, role_code, page, pageSize, ...anotherParams }) => {
    const { first_name, email, active, start_date, end_date } = anotherParams;
    let response = { data: [], totalItems: 0 };
    try {
        let params = { fibra_id };
        if (role_code) params.role_code = role_code;
        if (page) params.page = page;
        if (pageSize) params.pageSize = pageSize;
        if (first_name) params.first_name = first_name;
        if (email) params.email = email;
        if (active !== '-1') params.active = active;
        if (start_date) params.start_date = start_date;
        if (end_date) params.end_date = end_date;
        const fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        // console.log("🚀 ~ file: Users.js:27 ~ indexUser ~ fetch:", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = { data: [], totalItems: 0 }
    }

    return response;
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param user_id Identificador del usuario sobre el que se hará la consulta
 * @returns User Retorna un objeto tipo User
 */
export const showUser = async ({ headers, user_id }) => {
    let response = [];
    try {
        let fetch = await Fetcher({
            url: `${url}/${user_id}`,
            method: 'GET',
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = [];
    }

    return response;
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storeUser = async ({ headers, data }) => {
    let response = true;
    try {
        return await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
    } catch (error) {
        return response
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param user_id Identificador del usuario que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updateUser = async ({ headers, data, user_id }) => {
    let response = { res: false, data: {} };
    try {
        const fetch = await Fetcher({
            url: `${url}/${user_id}`,
            method: 'PUT',
            data,
            headers
        });
        // console.log("🚀 ~ file: Users.js:97 ~ updateUser ~ fetch:", fetch)

        if (fetch.status === STATUS_OK) {
            response.res = true;
            response.data = fetch.data.data;
        } else if (fetch.status === 422) {
            response.data = fetch.data.errors;
        }
        return response;
    } catch (error) {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param user_id Identificador del usuario que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyUser = async ({ headers, user_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${user_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    }

    return response;
}

/**
 * 
 * @param headers  Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param fibra_id Identificador del cliente
 * @param name Nombre que se le asigna al archivo al descargarlo
 * @param toast Instancia del hook useToast de Chakra UI, para mostrar notificaciones
 * @returns Retorna la descarga del archivo blob que retorna la petición
 */
export const downloadProvidersXlsx = async ({ headers, fibra_id, name, toast }) => {
    try {
        await downloadFile({
            headers,
            url: `${url}/download-providers/xlsx`,
            type: 'application/json',
            name,
            toast,
            params: { fibra_id }
        });
        return true;
    } catch (error) {
        return false;
    }
}