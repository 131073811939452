import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Container } from 'react-bootstrap'
import TableComp from '../../../Layouts/Table'
import usePagination from '../../../../hooks/usePagination'
import PaginationComp from '../../../Layouts/Pagination'
import { downloadInvoicePaymentsPdf, downloadInvoicePaymentsXml, getBlobInvoicePaymentsPdf, indexInvoicePayments } from '../../../../api/Cfdi/InvoicePayments'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { faEye, faFileCode, faFilePdf, faInfo } from '@fortawesome/free-solid-svg-icons'
import ModalDetails from './ModalDetails'
import { useToast } from '@chakra-ui/react'
import useLoading from '../../../../hooks/useLoading'
import usePreview from '../../../../hooks/usePreview'
import ShowFile from './ShowFile'

const InvoicePayments = ({ is_admin }) => {
    const toast = useToast();
    const login = useSelector(state => state.login);
    const [t] = useTranslation('invoice_payments');
    const fibra_id = login.fibra.id;
    const headers = { Authorization: `Bearer ${login.token}` };
    const { LoadingModal, hideLoading, showLoading } = useLoading();
    const hookPreview = usePreview();
    const hookPayments = usePagination({ defaultPageSize: 10 });
    const { page, pageSize, totalItems } = hookPayments.pagination;
    const [modalDetails, setModalDetails] = useState(false);

    useEffect(() => {
        const getInvoicePayments = async () => {
            hookPayments.setLoading(true);
            const anotherParams = {};
            if (!is_admin) anotherParams.provider_id = login?.user?.id;
            const { data, totalItems } = await indexInvoicePayments({ headers, fibra_id, page, pageSize, ...anotherParams });
            hookPayments.setData(data);
            hookPayments.setTotalItems(totalItems);
            hookPayments.setLoading(false);
        }
        getInvoicePayments();
    }, [page, pageSize]);

    const viewDetails = (item) => {
        hookPayments.setDataSel(item);
        setModalDetails(true);
    }
    const getNamefile = (item) => {
        const { folio, rfc_receptor, rfc_issuing, } = item;
        return `${folio}_${rfc_issuing}_${rfc_receptor}`
    }
    // Método para visualizar archivo
    const handleViewFile = async (item) => {
        hookPayments.setDataSel(item);
        hookPreview.setLoading(true);
        const fetch = await getBlobInvoicePaymentsPdf({ headers, invoice_payment_id: item.id });
        hookPreview.onOpen();
        if (fetch.blob) {
            hookPreview.setBlobFile(fetch.blob);
        }
        hookPreview.setLoading(false);
    }
    // Descargar archivo XML
    const downloadXML = async (item) => {
        showLoading();
        const invoice_payment_id = item.id;
        const name = getNamefile(item);
        await downloadInvoicePaymentsXml({ headers, invoice_payment_id, name, toast });
        hideLoading();
    }
    // Descargar archivo PDF
    const downloadPDF = async (item) => {
        showLoading();
        const invoice_payment_id = item.id;
        const name = getNamefile(item);
        await downloadInvoicePaymentsPdf({ headers, invoice_payment_id, name, toast });
        hideLoading();
    }
    return (
        <Container fluid>
            <TableComp
                headers={[
                    '#',
                    t('table.folio'),
                    t('table.folio-related'),
                    t('table.emission'),
                    t('table.no-payment'),
                    t('table.previous-balance'),
                    t('table.amount-paid'),
                    t('table.outstanding-balance'),
                    t('table.actions')
                ]}
                keys={['#', 'folio', 'invoice.folio', 'emission_date', 'payment_number', 'previous_balance', 'amount_paid', 'outstanding_balance']}
                body={hookPayments.data}
                loading={hookPayments.loading}
                loadingItems={pageSize}
                page={page}
                moment_dates={[{ index: 3, format: 'LLL' }]}
                currency={[{ index: 5 }, { index: 6 }, { index: 7 }]}
                actions={[
                    { icon: faInfo, variant: 'outline-secondary', handleClick: viewDetails, tooltip: t('table.tt-details') },
                    { icon: faEye, variant: 'outline-primary', handleClick: handleViewFile, tooltip: t('table.tt-show') },
                    { icon: faFileCode, variant: 'outline-success', handleClick: downloadXML, tooltip: t('table.tt-download-xml') },
                    { icon: faFilePdf, variant: 'outline-danger', handleClick: downloadPDF, tooltip: t('table.tt-download-pdf') },
                ]}
            />
            <PaginationComp
                current={page}
                onChange={hookPayments.changePage}
                totalItems={totalItems}
                defaultPageSize={pageSize}
            />
            {/* Modal para ver detalles del pago */}
            {
                modalDetails && <ModalDetails
                    onClose={() => setModalDetails(false)}
                    payment={hookPayments.dataSel}
                />
            }
            {/* Mostrar estado de carga */}
            <LoadingModal />
            {/* Vista previa del archivo */}
            {
                hookPreview.showPreview && <ShowFile
                    onClose={() => hookPreview.onClose()}
                    hookPreview={hookPreview}
                    hookPayments={hookPayments}
                />
            }
        </Container>
    )
}

InvoicePayments.propTypes = {
    is_admin: PropTypes.bool
}

export default InvoicePayments