import { Container } from "react-bootstrap";
import '../../styles/About.Component.css';
import { Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const About = () => {
    const [t] = useTranslation('global');

    return (
        <Container id='about-component' fluid>
            <Heading>{t('about.title')}</Heading>
        </Container>
    );
}

export default About;