import '../../styles/Home.Component.css';
import { Fragment, useState } from "react";
import { Box, Heading, Tag, Text, Grid } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ButtonIcon from '../Layouts/Forms/ButtonIcon';
import { faFileDownload, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import ModalAccess from '../Apps/fragments/ModalAccess';

const Home = () => {
    const [t] = useTranslation("global");
    const [modalAccess, setModalAccess] = useState(false);
    const getApk = () => {
        const link = document.createElement('a');
        link.href = 'https://drive.google.com/file/d/1vketwpiRJYmvqg2Q4WgP6QpZzsdIk5wH/view?usp=sharing';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <Fragment>
            <Heading>{t("home.title")}</Heading>
            <Text className="mb-3 mt-3">
                {t("home.description")}
            </Text>
            <Heading className='tituloSub'>Aplicaciones de Victum RE</Heading>
            <Grid
                templateColumns={{
                    sm: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(3, 1fr)",
                    xl: "repeat(5, 1fr)"
                }}
                gap={4}
                p={4}
            // bgColor={'#FFF'}
            >
                <Box maxW='sm' borderWidth='1px' borderRadius='lg' overflow='hidden' bgColor={'#FFF'}>
                    <Box p='6'>
                        <Heading size='md'>Descargar aplicación</Heading>
                    </Box>
                    <Box p='6'>
                        <ButtonIcon icon={faFileDownload} name='Descargar aplicación' variant={'link'} onClick={getApk} />
                    </Box>
                    <Box p='6'>
                        <Box display='flex' alignItems='baseline' gap={2}>
                            <Box>
                                <Tag color={'teal'} >Victum RE Mobile</Tag>
                            </Box>
                            <Box>
                                <Tag color={'default'} >V1.0</Tag>
                            </Box>
                            <Box>
                                <Tag
                                    color='#87d068'>
                                    Android
                                </Tag>
                            </Box>
                        </Box>

                    </Box>
                </Box>
                <Box maxW='sm' borderWidth='1px' borderRadius='lg' overflow='hidden' bgColor={'#FFF'}>
                    <Box p='6'>
                        <Heading size='md'>Solicitar acceso</Heading>
                    </Box>
                    <Box p='6'>
                        <ButtonIcon icon={faPaperPlane} name='Enviar solicitud' variant={'link'} onClick={() => setModalAccess(true)} />
                    </Box>
                    <Box p='6'>
                        <Box display='flex' alignItems='baseline' gap={2}>
                            <Box>
                                <Tag color={'teal'} >Victum RE Mobile</Tag>
                            </Box>
                            <Box>
                                <Tag color={'default'} >V1.0</Tag>
                            </Box>
                            <Box>
                                <Tag
                                    color='#108ee9'>
                                    iOS
                                </Tag>
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </Grid>
            {/* Modal para solicitar acceso a iOS */}
            {
                modalAccess && <ModalAccess
                    onClose={() => setModalAccess(false)}
                />
            }
        </Fragment>
    );
}

export default Home;