import React, { useEffect, useState } from 'react';
import TableComp from '../Layouts/Table';
import usePagination from '../../hooks/usePagination';
import { indexCedisDeliveries } from '../../api/Cedis/Deliveries';
import { useSelector } from 'react-redux';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import ModalEntrada from './ModalEntrada'

const TodasLasEntregas = () => {
    const login = useSelector(state => state.login);
    const headers = { Authorization: `Bearer ${login.token}` };
    const hookEntregas = usePagination({ defaultPageSize: 10 });
    const [showModal, setShowModal] = useState(false);


    useEffect(() => {
        const getEntregas = async () => {
            hookEntregas.setLoading(true);
            const { data, totalItems } = await indexCedisDeliveries({ headers, cedis_id: 10 });
            console.log("🚀 ~ getEntregas ~ data:", data);
            hookEntregas.setData(data);
            hookEntregas.setTotalItems(totalItems);
        };
        getEntregas();
    }, []);

    const agregarEntrada = (fila) => {
        console.log("🚀 ~ agregarEntrada ~ fila:", fila);
        hookEntregas.setDataSel(fila)
        setShowModal(true);
    };

    return (
        <>
            {showModal && <ModalEntrada
                handleCloseModal={() => setShowModal(false)}
                cedis_delivery_id={hookEntregas.dataSel.id}
                headers={headers}
            />}
            <TableComp
                headers={['#', 'Código de entrega', 'Codigo rf', 'Codigo rm', 'Fecha limite', 'Estado', 'Creacion', 'Acciones']}
                keys={['#', 'title', 'code_rf', 'code_rm', 'end', 'status', 'created_at']}
                body={hookEntregas.data}
                moment_dates={[{ index: 4, format: 'LLLL' }, { index: 6, format: 'LL' }]}
                badges={[{ index: 5 }]}
                badges_values={[{ col: 'status', values: [{ color: '#2e7c1d', name: 'entregado', value: 'delivered' }, { color: '#FF5733', name: 'rechazado', value: 'rejected' }, { color: 'blue', name: 'pendiente', value: 'pending' }] }]}
                actions={[
                    { icon: faDatabase, variant: 'success', tooltip: 'Agregar al inventario', handleClick: agregarEntrada, dinamics: [{ key: 'status', values: ['delivered'] }] }
                ]}
            />
        </>
    );
}

export default TodasLasEntregas;

