import React, { useState } from "react";
import PropTypes from 'prop-types';
import '../../styles/Carousel.css';
import { Alert, Form } from "react-bootstrap";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Collapse, Row, Skeleton } from "antd";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import ButtonIcon from "./Forms/ButtonIcon";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

const CarouselComponent = ({
    evidences,
    loading,
    enableDel,
    enableUpl,
    loadingUpl,
    loadingDel,
    handleLoadFile,
    handleUploadClick,
    handleDeleteThumbnail,
    showThumbnails,
    showFullscreenButton
}) => {
    const [t] = useTranslation('layouts');
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };

    const renderImageGallery = () => {
        const images = evidences.map((evidence) => ({
            original: evidence.image,
            thumbnail: evidence.image,
            originalAlt: t('Carousel.originalAlt'),
            thumbnailAlt: t('Carousel.thumbnailAlt'),
            originalTitle: evidence.created_at,
        }));

        return (
            <ImageGallery
                items={images}
                showFullscreenButton={showFullscreenButton}
                useBrowserFullscreen={false}
                onScreenChange={toggleFullScreen}
                showPlayButton={false}
                showIndex={true}
                showBullets={true}
                renderItem={renderItem}
                startIndex={currentIndex}
                onSlide={(currentIndex) => setCurrentIndex(currentIndex)}
                showThumbnails={showThumbnails}
            />
        );
    };

    const renderItem = (item) => {
        return (
            <div className="image-gallery-image">
                <img
                    src={item.original}
                    alt={item.originalAlt}
                    srcSet={item.srcSet}
                    sizes={item.sizes}
                    title={item.originalTitle}
                />
                <span className="image-gallery-description">{item.description}</span>
            </div>
        );
    };

    return (
        <>
            {
                !loading && evidences.length === 0 && (
                    <Alert variant="info">{t('Carousel.no-data')}</Alert>
                )
            }
            {
                loading ? (
                    <Skeleton active />
                ) : (
                    renderImageGallery()
                )
            }

            {/* Editar las fotografías del carousel */}
            <Collapse>
                {/* Agregar fotografía */}
                {
                    enableUpl &&
                    <Panel header={t('Carousel.add-pic')} key="1">
                        {
                            loadingUpl ? <Skeleton active /> :
                                <Row align='middle'>
                                    <Col span={20}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('Carousel.upload')}</Form.Label>
                                            <Form.Control type="file" size="sm" accept='image/*' onChange={handleLoadFile} multiple />
                                        </Form.Group>
                                    </Col>
                                    <Col span={4}>
                                        <ButtonIcon name={t('Carousel.add')} icon={faPlus} variant='success' tooltip={t('Carousel.tt-add')} onClick={handleUploadClick} />
                                    </Col>
                                </Row>
                        }
                    </Panel>
                }
                {/* Eliminar fotografías */}
                {
                    enableDel &&
                    <Panel header={t('Carousel.drop')} key="2">
                        {
                            loadingDel ? <Skeleton active /> :
                                <Row>
                                    {
                                        evidences.map((thumbnail, index) => (
                                            <Col sm={6} key={index}>
                                                <div className="thumbnail-container">
                                                    <img src={thumbnail.image} alt="Thumbnail" />
                                                    <div className="delete-button-container">
                                                        <ButtonIcon
                                                            size='sm'
                                                            tooltip={t('Carousel.tt-drop')}
                                                            icon={faTrashAlt}
                                                            variant='outline-danger'
                                                            onClick={() => handleDeleteThumbnail(thumbnail, index)}
                                                            className="delete-button"
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        ))
                                    }
                                </Row>
                        }
                    </Panel>
                }
            </Collapse>
        </>
    );
};

CarouselComponent.propTypes = {
    evidences: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.object.isRequired,
            created_at: PropTypes.string.isRequired
        })
    ).isRequired,
    loading: PropTypes.bool.isRequired,
    enableDel: PropTypes.bool,
    enableUpl: PropTypes.bool,
    loadingUpl: PropTypes.bool,
    loadingDel: PropTypes.bool,
    handleLoadFile: PropTypes.func,
    handleUploadClick: PropTypes.func,
    handleDeleteThumbnail: PropTypes.func,
    showThumbnails: PropTypes.bool,
    showFullscreenButton: PropTypes.bool,
};

export default CarouselComponent;
