import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react'
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import FormIcon from '../../Layouts/Forms/FormIcon';
import ModalComp from '../../Layouts/Modal'

const Details = (props) => {
    const { onClose, item } = props;
    const [t] = useTranslation('erp');
    const p = ['PedPendProv.details'];
    return (
        <ModalComp
            onClose={onClose}
            title={t(`${p[0]}.title`)}
            size='lg'
            body={<Fragment>
                <Row className='mb-3'>
                    <FormIcon title={t(`${p[0]}.CentroDesc`)} name='CentroDesc' value={item?.CentroDesc} />
                    <FormIcon title={t(`${p[0]}.Eknam`)} name='Eknam' value={item?.Eknam} />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title={t(`${p[0]}.Lgobe`)} name='Lgobe' value={item?.Lgobe} />
                    <FormIcon title={t(`${p[0]}.Ebeln`)} name='Ebeln' value={item?.Ebeln} />
                    <FormIcon title={t(`${p[0]}.Bedat`)} name='Bedat' value={item?.Bedat} />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title={t(`${p[0]}.Ebelp`)} name='Ebelp' value={item?.Ebelp} />
                    <FormIcon title={t(`${p[0]}.Txz01`)} name='Txz01' value={item?.Txz01} />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title={t(`${p[0]}.Menge`)} name='Menge' value={item?.Menge} />
                    <FormIcon title={t(`${p[0]}.Meins`)} name='Meins' value={item?.Meins} />
                    <FormIcon title={t(`${p[0]}.Netpr`)} name='Netpr' value={item?.Netpr} icon='$' />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title={t(`${p[0]}.Waers`)} name='Waers' value={item?.Waers} />
                    <FormIcon title={t(`${p[0]}.ImpTotalPed`)} name='ImpTotalPed' value={item?.ImpTotalPed} icon='$' />
                    <FormIcon title={t(`${p[0]}.CanXEntregar`)} name='CanXEntregar' value={item?.CanXEntregar} />
                    <FormIcon title={t(`${p[0]}.ImpXEntregar`)} name='ImpXEntregar' value={item?.ImpXEntregar} icon='$' />
                </Row>
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name={t(`${p[0]}.btn-close`)} icon={faArrowLeft} variant='outline-secondary' onClick={onClose} />
            </Fragment>}
        />
    )
}

export default Details