import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ModalComp from '../../Layouts/Modal'
import { useTranslation } from 'react-i18next'
import usePagination from '../../../hooks/usePagination'
import { indexBusinessName } from '../../../api/Cfdi/BusinessNames'
import { indexUserBusinessNames } from '../../../api/Cfdi/UserBusinessNames'
import { indexSegments } from '../../../api/Segments'
import TabsRoles from './TabsRoles'
import { indexUserRoles } from '../../../api/UserRoles'

const ModalUserBusinessNameSegments = ({ onClose, hidden, headers, fibra_id, user_id }) => {
    const [t] = useTranslation('settings');
    const hookUserBusinessNames = usePagination({});
    const hookBusinessNames = usePagination({});
    const hookSegments = usePagination({});
    const hookUserRoles = usePagination({});
    // Obtener relacion de razones sociales del usuario
    useEffect(() => {
        const getUserBusinessNames = async () => {
            hookUserBusinessNames.setLoading(true);
            const fetch = await indexUserBusinessNames({ headers, user_id });
            hookUserBusinessNames.setData(fetch);
            hookUserBusinessNames.setLoading(false);
        }
        if (!hidden) getUserBusinessNames();
    }, [hidden]);
    // Obtener lista de razones sociales
    useEffect(() => {
        const getBusinessNames = async () => {
            hookBusinessNames.setLoading(true);
            const fetch = await indexBusinessName({ fibra_id, headers });
            hookBusinessNames.setData(fetch.data);
            hookBusinessNames.setTotalItems(fetch.totalItems);
            hookBusinessNames.setLoading(false);
        }
        if (!hidden) getBusinessNames();
    }, [hidden]);
    // Obtener lista de segmentos
    useEffect(() => {
        const getSegments = async () => {
            hookSegments.setLoading(true);
            const fetch = await indexSegments({ fibra_id, headers });
            hookSegments.setData(fetch.data);
            hookSegments.setTotalItems(fetch.totalItems);
            hookSegments.setLoading(false);
        }
        if (!hidden) getSegments();
    }, [hidden]);
    // Obtener roles a los que tiene acceso el usuario seleccionado
    useEffect(() => {
        const getUserRoles = async () => {
            hookUserRoles.setLoading(true);
            const fetch = await indexUserRoles({ headers, user_id });
            hookUserRoles.setData(fetch.data);
            hookUserRoles.setLoading(false);
        }
        if (!hidden) getUserRoles();
    }, [hidden]);

    return (
        <ModalComp
            onClose={onClose}
            hidden={hidden}
            size='lg'
            title={t('users.modal-user-bn.title')}
            body={
                <TabsRoles
                    hookBusinessNames={hookBusinessNames}
                    hookSegments={hookSegments}
                    hookUserBusinessNames={hookUserBusinessNames}
                    hookUserRoles={hookUserRoles}
                />
            }
        />
    )
}

ModalUserBusinessNameSegments.propTypes = {
    onClose: PropTypes.func.isRequired,
    hidden: PropTypes.bool.isRequired,
    headers: PropTypes.shape({
        Authorization: PropTypes.string.isRequired
    }).isRequired,
    fibra_id: PropTypes.number.isRequired,
    user_id: PropTypes.number.isRequired
}

export default ModalUserBusinessNameSegments