import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react"
import PropTypes from 'prop-types';

const TabsComp = ({ tablist, tabpanels, defaultIndex, onChange, size, orientation, tablistStyle }) => {

    const onChangeLocal = () => { }

    return (
        <Tabs
            variant="soft-rounded"
            colorScheme="blue"
            size={size || 'md'}
            defaultIndex={defaultIndex || 0}
            onChange={onChange || onChangeLocal}
            orientation={orientation || 'horizontal'}
        >
            <div style={tablistStyle}>
                <TabList>
                    {
                        tablist.map((tab) =>
                            <Tab key={`tab_${tab}`}>
                                {tab}
                            </Tab>
                        )
                    }
                </TabList>
            </div>
            <TabPanels>
                {
                    tabpanels.map((panel, index) =>
                        <TabPanel key={`tab_panel_${tablist[index]}`}>
                            {panel}
                        </TabPanel>
                    )
                }
            </TabPanels>
        </Tabs>
    );
}

TabsComp.propTypes = {
    tablist: PropTypes.array.isRequired,
    tabpanels: PropTypes.array.isRequired,
    defaultIndex: PropTypes.number,
    onChange: PropTypes.func,
    size: PropTypes.string,
    orientation: PropTypes.oneOf(['vertical', 'horizontal', null])
}

export default TabsComp;