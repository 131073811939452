import React from 'react'
import PropTypes from 'prop-types';
import ModalComp from '../Layouts/Modal'
import ButtonIcon from '../Layouts/Forms/ButtonIcon'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import usePurchaseOrders from '../../hooks/usePurchaseOrders';
import TableComp from '../Layouts/Table';
import PaginationComp from '../Layouts/Pagination';
import { Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const HistoryModal = ({ headers, fibra_id, onClose, hidden, business_name_id, segment }) => {
    const [t] = useTranslation('global');
    const hookPurchaseOrders = usePurchaseOrders({ headers, fibra_id, business_name_id, segment_id: segment?.id, defaultPageSize: 10 });
    return (
        <ModalComp
            size='xl'
            onClose={onClose}
            hidden={hidden}
            title={`${segment?.name}`}
            body={<>
                <TableComp
                    headers={['#', 'Folio', 'Total', 'Comprobante de pago', 'Órdenes de pago', 'Acciones']}
                    keys={['#', 'folio', 'amount', 'has_voucher', 'payment_order_percentage']}
                    body={hookPurchaseOrders.data}
                    loading={hookPurchaseOrders.loading}
                    loadingItems={hookPurchaseOrders.pagination.pageSize}
                    page={hookPurchaseOrders.pagination.page}
                    currency={[{ index: 2 }]}
                    percentage={[{ index: 4 }]}
                    badges={[{ index: 3 }]}
                    badges_values={[{
                        col: 'has_voucher',
                        values: [
                            { value: 'pending', color: 'gray', name: t(`purchase-order.data.bv-pending`) },
                            { value: 'sent', color: '#1399a7', name: t(`purchase-order.data.bv-sent`) },
                        ]
                    }]}
                />
                <PaginationComp
                    current={hookPurchaseOrders.pagination.page}
                    onChange={hookPurchaseOrders.changePage}
                    totalItems={hookPurchaseOrders.pagination.totalItems}
                    defaultPageSize={hookPurchaseOrders.pagination.pageSize}
                />
            </>}
            footer={<ButtonIcon icon={faTimes} name='Cerrar' onClick={onClose} variant='outline-secondary' />}
        />
    )
}

HistoryModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    hidden: PropTypes.bool.isRequired
}

export default HistoryModal