import { Fragment, useEffect, useState } from "react";
import { showRent } from "../../../api/Malls/Rent";
import ModalComp from "../../Layouts/Modal";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import TabsComp from "../../Layouts/Tabs";
import General from "../forms/General";
import LocalData from "../forms/LocalData";
import CarouselComponent from "../../Layouts/Carousel";
import Fetcher from "../../../libs/Petition";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const ContractsView = (props) => {
    const { onClose, rent_id, headers } = props;
    const [rent, setRent] = useState({});
    const [photos, setPhotos] = useState([]);
    const [t] = useTranslation('global');
    const prefix = 'contracts.modal-details';

    useEffect(() => {
        getRent();
    }, []);

    const getRent = async () => {
        let response = await showRent({ headers, rent_id });
        setRent(response);
        try {
            let fetch = await Fetcher({
                method: 'GET', url: `/local_malls/download/${response.local_mall_id}`,
                responseType: 'blob', headers
            });
            let image = new Blob([fetch.data], { type: fetch.data.type });
            let url = window.URL || window.webkitURL;
            image = url.createObjectURL(image);
            setPhotos([image]);
        } catch (error) {

        }
    }

    return (
        <ModalComp
            onClose={onClose}
            title={rent?.name || t(`${prefix}.loading`)}
            size='xl'
            body={<Fragment>
                <TabsComp
                    tablist={[
                        t(`${prefix}.tabs.general`),
                        t(`${prefix}.tabs.local-data`),
                        t(`${prefix}.tabs.local-photos`)
                    ]}
                    tabpanels={[
                        <General rent={rent} />,
                        <LocalData local_mall={rent?.local_mall} />,
                        <CarouselComponent evidences={photos} />
                    ]}
                />
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon icon={faArrowLeft} name={t(`${prefix}.button-close`)} onClick={onClose} variant='outline-secondary' />
            </Fragment>}
        />
    );
}

export default ContractsView;