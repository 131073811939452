import { List, ListIcon, ListItem } from "@chakra-ui/layout";
import { faFileUpload, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useRef, useState } from "react";

import TableComp from "../../Layouts/Table";
import { MdCheckCircle } from 'react-icons/md';
import { Col, Row } from "react-bootstrap";
import { Popconfirm } from "antd";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import { useTranslation } from "react-i18next";

const _ = require('underscore');

const Files = (props) => {
    const { files, loading, userFiles, setUserFiles } = props;
    const [fileSel, setFileSel] = useState(null);  // Archivo seleccionado para cargar archivo
    const inputFile = useRef(null);
    const [t] = useTranslation('global');
    const prefix = 'login.signup.data';

    const upload = (item) => {
        setFileSel(item.id);
        inputFile.current.click();
    }

    const handleChangeFile = (e) => {
        // Si el archivo ha sido cargado previamente se sobreescribe
        let { files } = e.target;
        let arr = userFiles.filter(item => item.index !== fileSel);
        // Se actualiza el estado
        if (files.length > 0) {
            setUserFiles([
                ...arr, {
                    index: fileSel,             // ID de Files (Archivos solicitados)
                    file: e.target.files[0]     // Archivo
                }
            ]);
        }

    }

    const confirm = (userFile) => {
        let arr = userFiles.filter(item => item.index !== userFile.index);
        setUserFiles(arr);
    }

    return (
        <Fragment>
            <input
                ref={inputFile}
                type="file"
                onChange={handleChangeFile}
                style={{ display: "none" }}
            />
            <TableComp
                headers={[
                    '#',
                    t(`${prefix}.name`),
                    t(`${prefix}.description`),
                    t(`${prefix}.created-at`),
                    t(`${prefix}.upload`)
                ]}
                keys={['#', 'name', 'description', 'created_at']}
                body={files}
                actions={[
                    { icon: faFileUpload, name: t(`${prefix}.action-upload`), tooltip: t(`${prefix}.action-tooltip`), handleClick: upload }
                ]}
                loading={loading}
            />
            <List>
                {
                    userFiles.map((userFile, index) =>
                        <ListItem key=''>
                            <Row>
                                <Col>
                                    <ListIcon key={index} as={MdCheckCircle} color='green.500' />
                                    {userFile?.file?.name}
                                </Col>
                                <Col className='col-1 d-flex align-items-start'>
                                    <Popconfirm
                                        title={t(`${prefix}.popconfirm-title`)}
                                        onConfirm={() => confirm(userFile)}
                                        okText={t(`${prefix}.popconfirm-yes`)}
                                        cancelText={t(`${prefix}.popconfirm-no`)}
                                    >
                                        <ButtonIcon icon={faTrashAlt} variant='outline-danger' tooltip={t(`${prefix}.button-del-tooltip`)} />
                                    </Popconfirm>
                                </Col>
                            </Row>
                        </ListItem>
                    )
                }

            </List>
        </Fragment>
    );
}

export default Files