import Fetcher from "../libs/Petition"

const url = '/blacklist';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param page [Opcional] Se utiliza para conocer la página en la que se encuentra
 * @param pageSize [Opcional] Se utiliza para especificar el tamaño de la página, es decir, la cantidad de registros que se obtendrán.
 * @returns Object {"data" => AccessControlUser::collection(),"totalItems" => Cantidad de registros existentes}
 */
export const indexBlacklist = async (props) => {
    const {
        headers,
        page,
        pageSize } = props;
    let response = [];
    try {
        // Parámetros requeridos
        let params = {};
        // Parámetros opcionales
        if (page) params.page = page;
        if (pageSize) params.pageSize = pageSize;
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        // console.log("Fetcher blacklist [index]: ", fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = {
            data: [],
            totalItems: 0
        };
    } finally {
        return response;
    }
}
/**
 * 
 * @param rfc RFC que se va a buscar en la lista negra del SAT
 * @returns Blacklist Retorna un objeto tipo Blacklist
 */
export const showBlacklist = async ({ rfc }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}/${rfc}`,
            method: 'GET'
        });
        // console.log("Fetch blacklist [show]: ", fetch);
        if (fetch.status === STATUS_OK){
            if(!fetch.data.res){
                response = fetch.data;
            }
        }
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param data Contiene el objeto que se va a almacenar { blacklist_file } 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storeBlacklist = async ({ data }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data
        });
        response = fetch;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param blacklist_id Identificador del control de acceso que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updateBlacklist = async ({ headers, data, blacklist_id }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}/${blacklist_id}`,
            method: 'PUT',
            data,
            headers
        });
        if (fetch.status === STATUS_OK)
            response = true;
        // console.log("Fetch complain suggestion: ", fetch);
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param blacklist_id Identificador del control de acceso que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyBlacklist = async ({ headers, blacklist_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${blacklist_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}