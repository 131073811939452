import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, useToast } from '@chakra-ui/react';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Form, Row } from 'react-bootstrap';
import { destroyCedisUsers, indexCedisUsers, storeCedisUsers } from '../../../api/Cedis/CedisUsers';
import { indexUser } from '../../../api/Users';
import setToast from '../../../libs/SetToast';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import TableComp from '../../Layouts/Table';

const UsersAssigned = (props) => {
    const { headers, fibra_id, cedis_id } = props;
    const toast = useToast();
    const [users, setUsers] = useState([]);
    const [usersAvailable, setUsersAvailable] = useState([]);
    const [user_id, setUser_id] = useState(null);
    const [userCedis, setUserCedis] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getAdmins()
        getCedisUsers();
    }, []);

    useEffect(() => {
        getUsersAvailable();
    }, [userCedis, users]);
    // Usuarios que ya han sido asignados
    const getCedisUsers = async () => {
        setLoading(true);
        const { data } = await indexCedisUsers({ headers, cedis_id });
        setUserCedis(data);
        setLoading(false);
    }
    // Administradores que tiene la fibra
    const getAdmins = async () => {
        const { data } = await indexUser({ headers, fibra_id, role_code: 'ADMINISTRADOR' });
        setUsers(data);
    }
    // Usuarios disponibles
    const getUsersAvailable = async () => {
        const usersWithoutCedis = users.filter((user) => !userCedis.some((item) => item.user_id === user.id));
        setUsersAvailable(usersWithoutCedis)
    }
    const handleChangeAdmin = (e) => setUser_id(e.target.value);

    const assignUser = async () => {
        const response = await storeCedisUsers({
            headers, data: {
                cedis_id, user_id
            }
        });
        if (response) {
            getCedisUsers();
            setUser_id(null);
            setToast({
                title: '¡Nuevo usuario!', status: 'success', toast,
                description: 'Se han asignado permisos para que el usuario acceda a la interfaz "Entregas".'
            });
        }
    }

    const removePermission = async (item) => {
        let response = await destroyCedisUsers({ headers, cedis_user_id: item.id });
        if (response) {
            getCedisUsers();
            setToast({
                title: '¡Permiso revocado!', status: 'error', toast,
                description: 'Se han revocado los permisos para acceder a la interfaz "Entregas".'
            });
        }
    }
    return <Fragment>
        <Row className='mb-3'>
            <Col>
                <Form.Label>Administrador</Form.Label>
                <Select placeholder='Seleccionar administrador' name='user_id' defaultValue={user_id} value={user_id} onChange={handleChangeAdmin}>
                    {
                        usersAvailable.map((user) =>
                            <option key={user.id} value={user.id}>{user?.name} | {user?.first_name} {user?.last_name}</option>
                        )
                    }
                </Select>
            </Col>
            <Col className='d-flex justify-content-start align-items-end'>
                <ButtonIcon name='Asignar administrador' icon={faPlus} variant='outline-success'
                    onClick={assignUser} disabled={!user_id} />
            </Col>
        </Row>
        <TableComp
            headers={['#', 'Administrador', 'Correo electrónico', 'Asignado desde', 'Acciones']}
            keys={['#', 'user_name', 'user_email', 'created_at']}
            body={userCedis}
            loading={loading}
            actions={[
                { icon: faTrashAlt, tooltip: 'Eliminar del CEDIS', handleClick: removePermission, variant: 'outline-danger' }
            ]}
        />
    </Fragment>;
};

UsersAssigned.propTypes = {
    headers: PropTypes.string.isRequired,
    fibra_id: PropTypes.number.isRequired,
    cedis_id: PropTypes.number.isRequired
}

export default UsersAssigned;
