import React from 'react'
import PropTypes from 'prop-types'
import ModalComp from '../../Layouts/Modal'
import ViewImageOrPDF from '../../Layouts/ViewImageOrPDF'
import { hookPaginationPropType, hookPreviewType } from '../../../data/propTypes'

const ShowFile = ({ onClose, hookPreview, hookPaymentOrders }) => {
    const { folio } = hookPaymentOrders.dataSel;
    return (
        <ModalComp
            onClose={onClose}
            keyboard
            title={folio}
            size='lg'
            body={
                <div style={{ width: '100%' }}>
                    {
                        hookPreview.blobFile &&
                        <ViewImageOrPDF
                            file={hookPreview.blobFile}
                            loading={hookPreview.loading}
                        />
                    }
                </div>
            }
        />
    )
}

ShowFile.propTypes = {
    onClose: PropTypes.func.isRequired,
    hookPreview: hookPreviewType,
    hookPaymentOrders: hookPaginationPropType
}

export default ShowFile