import React from 'react'
import PropTypes from 'prop-types'
import { Heading } from '@chakra-ui/react';
import { Divider, Skeleton } from 'antd';
import { Col, FloatingLabel, Form, FormSelect, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { currencyTypes } from '../../../../data/currencyTypes';
import { getCurrency } from '../../../../libs/functions';

const Header = ({ folio, handleChange, form, businessNames, providers, purchase_order, hookSegments }) => {
    const [t] = useTranslation('global');
    const p_data = 'purchase-order.data';
    return (
        <>
            <Divider orientation='left'>
                <Heading size='sm'>{t(`${p_data}.general-data`)}</Heading>
            </Divider>
            <Row className='mb-3'>
                <Col>
                    <FloatingLabel label={t(`${p_data}.folio`)}>
                        <Form.Control type="text" placeholder={t(`${p_data}.folio`)} name='folio' value={folio} readOnly />
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel label={t(`${p_data}.business-name`)}>
                        <FormSelect name='business_name_id' value={purchase_order?.business_name_id}
                            disabled={true} required>
                            <option value={-1}>{t(`${p_data}.select-business-name`)}</option>
                            {
                                businessNames.map((item) =>
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                )
                            }
                        </FormSelect>
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel label={t(`${p_data}.provider`)}>
                        <FormSelect name='user_id' value={purchase_order?.provider_id}
                            disabled={true} required>
                            <option value={-1}>{t(`${p_data}.select-provider`)}</option>
                            {
                                providers.map((provider) =>
                                    <option key={provider.id} value={provider.id}>{provider.name}</option>
                                )
                            }
                        </FormSelect>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col>
                    {
                        hookSegments.loading ? <Skeleton active /> :
                            <FloatingLabel label={t(`${p_data}.segment`)}>
                                <FormSelect name='segment_id' onChange={handleChange} defaultValue={purchase_order?.segment_id} value={form?.segment_id}
                                    disabled={true} required>
                                    <option value={-1}>{t(`${p_data}.select-segment`)}</option>
                                    {
                                        hookSegments.data.map((item) =>
                                            <option key={item.id} value={item.id}>{item.code} - {item.name}</option>
                                        )
                                    }
                                </FormSelect>
                            </FloatingLabel>
                    }
                </Col>
                <Col>
                    <FloatingLabel label={t(`${p_data}.currency`)}>
                        <FormSelect name='currency' value={purchase_order?.currency}
                            disabled={true} required>
                            <option value={-1}>{t(`${p_data}.select-currency`)}</option>
                            {
                                currencyTypes.map((item) =>
                                    <option key={item.id} value={item.code}>{item.code} - {item.name}</option>
                                )
                            }
                        </FormSelect>
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel label={t(`${p_data}.ended-date`)}>
                        <Form.Control type="date" name='ended_date' id={purchase_order.provider_id} onChange={handleChange} value={form?.ended_date} required />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col>
                    <FloatingLabel label={t(`${p_data}.subtotal`)}>
                        <Form.Control type="text" name='subtotal' value={getCurrency(purchase_order?.subtotal)} disabled={true} required />
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel label={t(`${p_data}.iva`)}>
                        <Form.Control type="text" name='iva' value={getCurrency(purchase_order?.iva)} disabled={true} required />
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel label={t(`${p_data}.iva-withholding`)}>
                        <Form.Control type="text" name='iva_withholding' value={getCurrency(purchase_order?.iva_withholding)} disabled={true} required />
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel label={t(`${p_data}.isr-withholding`)}>
                        <Form.Control type="text" name='isr_withholding' value={getCurrency(purchase_order?.isr_withholding)} disabled={true} required />
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel label={t(`${p_data}.amount`)}>
                        <Form.Control type="text" name='amount' value={getCurrency(purchase_order?.total)}
                            disabled={true} required />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FloatingLabel label={t(`${p_data}.description`)}>
                        <Form.Control as='textarea' style={{ height: '5em' }} name='description' id={purchase_order.provider_id} value={form?.description} onChange={handleChange} required />
                    </FloatingLabel>
                </Col>
            </Row>
        </>
    )
}

Header.propTypes = {
    folio: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    form: PropTypes.object,
    businessNames: PropTypes.array,
    providers: PropTypes.array,
    purchase_order: PropTypes.shape({
        provider_id: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
        ended_date: PropTypes.string,
        business_name_id: PropTypes.number.isRequired,
        segment_id: PropTypes.number,
        currency: PropTypes.string,
        iva: PropTypes.number,
        iva_withholding: PropTypes.number,
        isr_withholding: PropTypes.number,
        subtotal: PropTypes.number
    }).isRequired,
    hookSegments: PropTypes.object
}

export default Header