import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import TabsComp from '../../Layouts/Tabs'
import { useTranslation } from 'react-i18next';
import PageHeaderComp from '../../Layouts/PageHeader';
import { indexUser } from '../../../api/Users';
import usePagination from '../../../hooks/usePagination';
import { useSelector } from 'react-redux';
import useGet from '../../../hooks/useGet';
import { showPurchaseOrders } from '../../../api/PurchaseOrders';
import PaymentOrders from '../payment_orders/Index';
import PaymentVouchers from '../payment_vouchers/Index';
import { indexPurchaseOrderVouchers } from '../../../api/PurchaseOrderVouchers';

const PurchaseOrderMain = ({ match }) => {
	const { purchase_order_id } = match.params;
	const { token, fibra, } = useSelector(prevState => prevState.login);
	const headers = { Authorization: `Bearer ${token}` };
	const [t] = useTranslation('purchase_orders');

	const hookProviders = usePagination({ defaultPageSize: 100 });
	const hookPurchaseOrder = useGet();
	const hookVouchers = usePagination({ defaultPageSize: 10 });
	// Obtener los comprobantes de pago
	useEffect(() => {
		const getVouchers = async () => {
			hookVouchers.setLoading(true);
			const { page, pageSize } = hookVouchers.pagination;
			const { data, totalItems } = await indexPurchaseOrderVouchers({ headers, purchase_order_id, page, pageSize });
			hookVouchers.setData(data);
			hookVouchers.setTotalItems(totalItems);
			hookVouchers.setLoading(false);
		}
		getVouchers();
	}, [hookVouchers.pagination.page, hookVouchers.pagination.pageSize]);

	useEffect(() => {
		const getPurchaseOrderDetails = async () => {
			hookPurchaseOrder.setLoading(true);
			const response = await showPurchaseOrders({ headers, purchase_order_id });
			hookPurchaseOrder.setData(response);
			hookPurchaseOrder.setLoading(false);
		}
		getPurchaseOrderDetails();
	}, []);

	useEffect(() => {
		const getProviders = async () => {
			hookProviders.setLoading(true);
			const response = await indexUser({ headers, fibra_id: fibra.id, role_code: 'PROVEEDOR' });
			hookProviders.setData(response.data);
			hookProviders.setTotalItems(response.totalItems);
			hookProviders.setLoading(false);
		}
		getProviders();
	}, []);

	return (
		<div>
			<PageHeaderComp
				title={'Detalles de la Orden de Compra'}
				subtitle={purchase_order_id}
			/>
			<TabsComp
				tablist={[
					// t('main.purchase-order'),
					t('main.payment-orders'),
					t('main.payment-vouchers'),
					// t('main.deliveries'),
				]}
				tabpanels={[
					// <PurchaseOrder
					// 	key='purchase-order-key'
					// />,
					<PaymentOrders
						key='payment-orders-key'
						headers={headers}
						purchase_order_id={purchase_order_id}
						hookVouchers={hookVouchers}
						purchaseOrder={hookPurchaseOrder.data}
					/>,
					<PaymentVouchers
						key='payment-vouchers-key'
						headers={headers}
						hookVouchers={hookVouchers}
					/>,
					// <Deliveries
					// 	key='deliveries-key'
					// />,
				]}
			/>
		</div>
	)
}

PurchaseOrderMain.propTypes = {
	match: PropTypes.object
}

export default PurchaseOrderMain