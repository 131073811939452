import { faArrowLeft, faCommentAlt } from '@fortawesome/free-solid-svg-icons';
import React, { Fragment, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { storeChatRooms } from '../../../api/Chat/Room';
import { indexUser } from '../../../api/Users';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import FormIcon from '../../Layouts/Forms/FormIcon';
import ModalComp from '../../Layouts/Modal';
import TableComp from '../../Layouts/Table';

const NewRoom = (props) => {
    const { onClose, update } = props;

    const login = useSelector(state => state.login);
    const headers = { Authorization: `Bearer ${login.token}` };
    const fibra_id = login.fibra.id;

    const [users, setUsers] = useState([]);             // Usuarios a los que se puede agregar a una sala
    const [loading, setLoading] = useState(true);       // Indicador de carga de los usuarios
    const [members, setMembers] = useState([]);         // integrantes de la nueva sala
    const [roomName, setRoomName] = useState('');       // Nombre de la sala
    // const [roomTypes, setRoomTypes] = useState([]);

    useEffect(() => {
        getUsers();
        // getRoomTypes()
    }, []);

    const getUsers = async () => {
        let response = await indexUser({ headers, fibra_id });  // Obtener todos los usuarios
        // console.log("response: ", response);
        setUsers(response.data.filter((usr) => usr.id !== login.user.id)); // Eliminar el usuario actual
        setLoading(false);
    }

    // const getRoomTypes = async () => {
    //     let response = await indexChatRoomTypes({ headers });
    //     setRoomTypes(response.data);
    // }

    const handleChange = (checked, user_id) => {
        if (checked)
            setMembers([...members, { id: user_id }]);      // Agregar miembro a la lista
        else
            setMembers(members.filter((member) => member.id !== user_id));  // ELiminar miembro de la lista
    }
    // Método para marcar por default una tupla
    const getChecked = (user_id) => false;
    // Método para deshabilidar la columna Acciones de alguna tupla
    const getDisabled = (user_id) => false;

    const createRoom = async (data) => {
        return await storeChatRooms({ headers, data });
    }
    const addRoom = () => {
        let valid = true;
        if (members.length >= 2) {
            if (roomName === '') {
                valid = false;
            }
        }
        let data = {
            name: roomName,
            users: [...members, { id: login.user.id }]
        }

        if (valid) createRoom(data);
        update();
        onClose();
    }

    const changeRoomName = (e) => {
        setRoomName(e.target.value);
    }
    return (
        <ModalComp
            onClose={onClose}
            title='Configurar nueva sala'
            size='lg'
            body={<Fragment>
                {/* Si la sala es grupal */}
                {
                    members.length >= 2 &&
                    <Row className='mb-3'>
                        <Col md={{ span: 6 }}>
                            <FormIcon
                                title='Nombre de la sala'
                                placeholder='Escribe el nombre de la sala'
                                value={roomName}
                                handleChange={changeRoomName}
                            />
                        </Col>
                    </Row>
                }
                <TableComp
                    headers={['#', 'Usuario', 'Correo electrónico', 'Acciones']}
                    keys={['#', 'name', 'email']}
                    body={users}
                    loading={loading}
                    actions={{
                        checkedChildren: 'Agregar',
                        unCheckedChildren: 'No agregar',
                        onChange: handleChange,
                        getChecked: getChecked,
                        getDisabled: getDisabled
                    }}
                    type='switch'
                />
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name='Cerrar' icon={faArrowLeft} onClick={onClose} variant='outline-secondary' />
                {
                    members.length > 0 &&
                    <ButtonIcon name='Crear sala' variant='outline-success' icon={faCommentAlt} onClick={addRoom} />
                }
            </Fragment>}
        />
    )
}

export default NewRoom;
