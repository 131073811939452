import Fetcher from "./Petition";

const getBlobFile = async ({ url, headers, params = {} }) => {
    let file = null;
    try {
        const response = await Fetcher({
            method: 'GET', url,
            responseType: 'blob', headers, params
        });
        if (response.status === 200) file = response.data;
    } catch (error) {
        file = null;
    }
    return file;
}

export default getBlobFile;