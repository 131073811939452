import React from 'react'
import PropTypes from 'prop-types'
import DrawerComp from '../../Layouts/Drawer'
import { useTranslation } from 'react-i18next'
import { Box, FormLabel, Input, Select } from '@chakra-ui/react'

const FilterUsersComp = ({ hookForm, handleClickSearch }) => {
    const [t] = useTranslation('settings');
    const p = 'users.filters';

    const { form, handleChange } = hookForm;

    return (
        <DrawerComp title={t(`${p}.title`)} handleClickSearch={handleClickSearch} showFooter>
            {/* Primer nombre */}
            <Box className='mb-3'>
                <FormLabel>{t(`${p}.first_name`)}</FormLabel>
                <Input placeholder={t(`${p}.first_name_ph`)} name='first_name' value={form?.first_name} onChange={handleChange} />
            </Box>
            {/* Correo electrónico */}
            <Box className='mb-3'>
                <FormLabel>{t(`${p}.email`)}</FormLabel>
                <Input placeholder={t(`${p}.email_ph`)} name='email' value={form?.email} onChange={handleChange} />
            </Box>
            {/* Estado */}
            <Box className='mb-3'>
                <FormLabel>{t(`${p}.active`)}</FormLabel>
                <Select onChange={handleChange} defaultValue={-1} name='active' value={form?.active}>
                    <option value={-1}>{t(`${p}.all`)}</option>
                    <option value={'ACTIVE'}>{t(`${p}.status_active`)}</option>
                    <option value={'INACTIVE'}>{t(`${p}.status_inactive`)}</option>
                </Select>
            </Box>
            {/* Fecha mínima */}
            <Box className='mb-3'>
                <FormLabel>{t(`${p}.start_date`)}</FormLabel>
                <Input type='date' name='start_date' value={form?.start_date} onChange={handleChange} />
            </Box>
            {/* Fecha maxima */}
            <Box className='mb-3'>
                <FormLabel>{t(`${p}.end_date`)}</FormLabel>
                <Input type='date' name='end_date' value={form?.end_date} onChange={handleChange} />
            </Box>
        </DrawerComp>
    )
}

FilterUsersComp.propTypes = {
    hookForm: PropTypes.shape({
        form: PropTypes.shape({
            first_name: PropTypes.string,
            active: PropTypes.string,
            start_date: PropTypes.string,
            end_date: PropTypes.string,
            email: PropTypes.string,
        }).isRequired,
        handleChange: PropTypes.func.isRequired
    }).isRequired,
    handleClickSearch: PropTypes.func.isRequired
}

export default FilterUsersComp