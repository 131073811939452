import { Container } from "react-bootstrap";
import '../../styles/PurchaseOrder.Component.css';
import PurchaseOrderTable from "./fragments/Table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import ModalPurchaseOrder from "./fragments/Modal";
import { Heading, useToast } from "@chakra-ui/react";
import FlexButton from "../Layouts/FlexButton";
import { downloadPurchaseOrderPdf, downloadPurchaseOrderXlsx, indexPurchaseOrders, updatePurchaseOrder } from "../../api/PurchaseOrders";
import { indexUser } from "../../api/Users";
import { useTranslation } from "react-i18next";
import { faCalendarAlt, faCogs, faFileExcel, faFilePdf, faRedoAlt, faSpinner, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Schedule from "./fragments/Schedule";
import { PageHeader } from "antd";
import { SET_ACTIVE_TAB } from "../../redux/actions/main";
import Swal from "sweetalert2";
import usePagination from "../../hooks/usePagination";
import useConfirmation from "../../hooks/useConfirm";
import useForm from "../../hooks/useForm";
import ButtonIcon from "../Layouts/Forms/ButtonIcon";
import FiltersComp from "./fragments/Filters";
import { indexUserBusinessNames } from "../../api/Cfdi/UserBusinessNames";
import { indexRoleBusinessNames } from "../../api/Cfdi/RoleBusinessNames";
import { useHistory } from "react-router-dom";
import Downloads from "./fragments/Downloads";

const PurchaseOrderDanhos = () => {
    const toast = useToast();
    const store = useStore();
    const history = useHistory();
    const purchaseOrdersStored = useSelector((state) => state.purchase_orders);
    const { token, fibra, role_selected, user } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };
    const [modalAdd, setModalAdd] = useState(false);            // Modal para agregar orden de compra
    const [modalSchedule, setModalSchedule] = useState(false);  // Modal para programar entrega de elementos de una orden de compra
    const [t] = useTranslation('global');
    const dispatch = useDispatch();
    const hookForm = useForm();
    const hookProviders = usePagination({ defaultPageSize: 100 });
    const hookPurchaseOrders = usePagination({ defaultPageSize: 10, page: 1 });
    const { page, pageSize } = hookPurchaseOrders.pagination;
    const { confirmAction } = useConfirmation();
    const [loading, setLoading] = useState(true);
    const [form, setForm] = useState({ segment_ids: [], business_name_ids: [] });
    const { segment_ids, business_name_ids } = form;
    // Obtener filtros existentes
    useEffect(() => {
        hookForm.setForm(purchaseOrdersStored.filters);
    }, [purchaseOrdersStored.filters]);
    // Obtener permisos del rol actual
    useEffect(() => {
        const getDefaultConfigs = async () => {
            setLoading(true);
            if (role_selected.custom_conf) {
                const fetch = await indexUserBusinessNames({ headers, user_id: user.id });
                if (Array.isArray(fetch)) {
                    const { segments, business_names } = fetch.find(element => element.role.id === role_selected.id);
                    // Almacenar los identificadores de los segmentos y razones sociales a las que se tiene acceso
                    setForm({
                        business_name_ids: business_names.map(business_name => business_name.business_name_id),
                        segment_ids: segments.map(segment => segment.segment_id)
                    });
                }
                setLoading(false);
            } else {
                const { segments, business_names } = await indexRoleBusinessNames({ headers, role_id: role_selected.id });
                // Almacenar los identificadores de los segmentos y razones sociales a las que se tiene acceso
                setForm({
                    business_name_ids: business_names.map(business_name => business_name.business_name_id),
                    segment_ids: segments.map(segment => segment.segment_id)
                });
                setLoading(false);
            }
        }
        // Obtener permisos personalizados o por default
        getDefaultConfigs();
    }, [role_selected]);
    useEffect(() => {
        if (!modalSchedule) hookPurchaseOrders.setDataSel(null);
    }, [modalSchedule]);

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/purchase-order' });
        const getProviders = async () => {
            hookProviders.setLoading(true);
            const response = await indexUser({ headers, fibra_id: fibra.id, role_code: 'PROVEEDOR' });
            hookProviders.setData(response.data);
            hookProviders.setTotalItems(response.totalItems);
            hookProviders.setLoading(false);
        }
        getProviders();
    }, []);
    // Método para obtener las órdenes de compra
    useEffect(() => {
        const getPurchaseOrders = async () => {
            hookPurchaseOrders.setLoading(true);
            const response = await indexPurchaseOrders({ headers, fibra_id: fibra.id, page, pageSize, ...purchaseOrdersStored?.filters, ...form });
            hookPurchaseOrders.setData(response.data);
            hookPurchaseOrders.setTotalItems(response.totalItems);
            hookPurchaseOrders.setLoading(false);
        }
        if (!loading) getPurchaseOrders();
    }, [page, pageSize, purchaseOrdersStored?.filters, loading]);
    // Abrir modal para programar una entrega
    const scheduleDelivery = (item) => {
        hookPurchaseOrders.setDataSel(item);
        setModalSchedule(true);
    }
    // Descargar orden de compra
    const downloadPdfReport = (item) => {
        downloadPurchaseOrderPdf({ headers, purchase_order_id: item.id, name: item.folio, toast });
    }
    // Actualizar lista de ordenes de compra
    const updateRecords = (data) => {
        hookPurchaseOrders.setData(prevState => prevState.filter(purchaseOrder => purchaseOrder.id !== data.id));
    }
    // Eliminar orden de compra
    const dropItem = (item) => {
        const p = 'purchase-order.sf'
        const title = t(`${p}.drop-confirm`);
        const html = t(`${p}.html-drop`);
        const confirmButtonText = t(`${p}.b-drop-confirm`);

        confirmAction(title, html, confirmButtonText, async () => {
            const { res, data } = await updatePurchaseOrder({ headers, data: { removed: true }, purchase_order_id: item.id });
            if (res) {
                updateRecords(data);
                Swal.fire(t(`${p}.t-success`), t(`${p}.m-drop`), 'success');
            } else {
                Swal.fire(t(`${p}.t-fail`), t(`${p}.m-fail-server`), 'info');
            }
        });
    }
    // Restaurar orden de compra
    const restoreItem = (item) => {
        const p = 'purchase-order.sf'
        const title = t(`${p}.restore-confirm`);
        const html = t(`${p}.html-restore`);
        const confirmButtonText = t(`${p}.b-restore-confirm`);

        confirmAction(title, html, confirmButtonText, async () => {
            const { res, data } = await updatePurchaseOrder({ headers, data: { removed: false }, purchase_order_id: item.id });
            if (res) {
                updateRecords(data);
                Swal.fire(t(`${p}.t-success`), t(`${p}.m-restore`), 'success');
            } else {
                Swal.fire(t(`${p}.t-fail`), t(`${p}.m-fail-server`), 'info');
            }
        });
    }

    const getDisabled = (item) => item.canceled === 'CANCELADA'
    const showMain = (item) => {
        history.push(`/purchase-order/${item.id}`);
    }

    return (
        <Container id='purchase-order-component' fluid>
            <PageHeader
                ghost={true}
                onBack={() => window.history.back()}
                title={<Heading>{t('purchase-order.title')}</Heading>}
                subTitle=""
                extra={[
                    <Downloads
                        fibra_id={fibra.id}
                        headers={headers}
                    />,
                    <FiltersComp
                        key='3'
                        isAdmin
                        hookForm={hookForm}
                        fibra_id={fibra.id}
                        headers={headers}
                        loading={loading}
                        business_name_ids={business_name_ids}
                        segment_ids={segment_ids}
                        hookPurchaseOrders={hookPurchaseOrders}
                    />
                ]
                }
            >
            </PageHeader>

            {/* Table */}
            <PurchaseOrderTable
                type='admin'
                title={t('purchase-order.modal-edit.title')}
                hookPurchaseOrders={hookPurchaseOrders}
                hookProviders={hookProviders}
                tactions={[{
                    icon: faCogs, handleClick: showMain,
                    tooltip: t('purchase-order.tooltips.show'),
                    variant: 'outline-primary', getDisabled
                }, {
                    icon: faCalendarAlt, handleClick: scheduleDelivery,
                    tooltip: t('purchase-order.tooltips.delivery-date'),
                    variant: 'outline-primary', getDisabled
                }, {
                    icon: faFilePdf, handleClick: downloadPdfReport,
                    tooltip: t('purchase-order.tooltips.download-pdf'),
                    variant: 'outline-danger', getDisabled
                }, {
                    icon: faTrashAlt, handleClick: dropItem,
                    tooltip: t('purchase-order.tooltips.drop-item'),
                    variant: 'outline-danger', dinamics: [{ key: 'canceled', values: ['ACTIVA'] }]
                }, {
                    icon: faRedoAlt, handleClick: restoreItem,
                    tooltip: t('purchase-order.tooltips.restore-item'),
                    variant: 'outline-success', dinamics: [{ key: 'canceled', values: ['CANCELADA'] }]
                }]}
            />
            {/* Button para agregar órdenes de compra */}
            <FlexButton style={{ bottom: 20, right: 20 }} onClick={() => setModalAdd(true)} />

            {/* Modal para agregar nueva orden de pago */}
            {
                modalAdd && <ModalPurchaseOrder
                    title={t(`purchase-order.modal-add.title`)}
                    type='add'
                    headers={headers}
                    fibra_id={fibra.id}
                    onClose={() => setModalAdd(false)}
                    hookPurchaseOrders={hookPurchaseOrders}
                    providers={hookProviders.data}
                />
            }
            {/* Modal para programar la entrega en CEDIS */}
            {
                modalSchedule && <Schedule
                    onClose={() => setModalSchedule(false)}
                    orderSel={hookPurchaseOrders.dataSel}
                    headers={headers}
                    fibra_id={fibra.id}
                />
            }
        </Container>
    );
}

export default PurchaseOrderDanhos;