import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { destroyAppModules, indexAppModules, storeAppModules } from "../../../api/AppModules";
import { indexModules } from "../../../api/Modules";
import PaginationComp from "../../Layouts/Pagination";
import TableComp from "../../Layouts/Table";


const Modules = (props) => {
    const { fibra_id, app_id } = props;

    const [t] = useTranslation('global');
    const prefix = 'super-admin.data';

    const login = useSelector(state => state.login);
    const headers = { Authorization: `Bearer ${login.token}` };

    const [modules, setModules] = useState([]);
    const [appModules, setAppModules] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);


    useEffect(() => {
        getAppModules();
    }, []);

    useEffect(() => {
        getModules();
    }, [page, pageSize]);

    // Obtiene todos los módulos disponibles
    const getModules = async () => {
        setLoading(true);
        let response = await indexModules({ headers, page, pageSize });
        setModules(response.data);
        setTotalItems(response.totalItems);
        setLoading(false);
    }

    // Obtiene arreglo de módulos a los que tiene acceso la fibra seleccionada
    const getAppModules = async () => {
        let response = await indexAppModules({ headers, fibra_id, app_id });
        setAppModules(response.data);
    }

    // Evento lanzado al cambiar de página
    const changePage = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    }

    // Evento para asignar módulo a la fibra
    const handleChange = async (checked, module_id) => {
        if (checked) { // Se agrega la relación
            await storeAppModules({ headers, data: { fibra_id, module_id, app_id } });
        } else { // Se elimina la relación  
            const appModule = appModules.find(item => item.module_id === module_id);
            if (appModule) // Si el registro se encuentra se elimina
                await destroyAppModules({ headers, app_module_id: appModule.id });
        }
    }
    // Método para obtener si el registro existe o no
    const getChecked = (module_id) => {
        return appModules.find(item => item.module_id === module_id);
    }
    // Método para indicar si el switch está habilitado
    const getDisabled = () => false;

    return (
        <Fragment>
            <TableComp
                headers={[
                    '#',
                    t(`${prefix}.code`),
                    t(`${prefix}.name`),
                    t(`${prefix}.created-at`),
                    t(`${prefix}.actions`)
                ]}
                keys={['#', 'code', 'name', 'created_at']}
                body={modules}
                loading={loading}
                type='switch'
                actions={{
                    checkedChildren: t(`${prefix}.checked`),
                    unCheckedChildren: t(`${prefix}.unchecked`),
                    onChange: handleChange,
                    getChecked: getChecked,
                    getDisabled: getDisabled
                }}
                moment_dates={[{ index: 3, format: 'LLLL' }]}
            />
            <PaginationComp
                totalItems={totalItems}
                current={page}
                onChange={changePage}
                defaultPageSize={pageSize}
            />
        </Fragment>
    );
}

export default Modules