import downloadFile from "../libs/DownloadFile";
import Fetcher from "../libs/Petition"

const url = '/repse/user_docs';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param page [Opcional] Se utiliza para conocer la página en la que se encuentra
 * @param pageSize [Opcional] Se utiliza para especificar el tamaño de la página, es decir, la cantidad de registros que se obtendrán.
 * @param user_id [Opcional] Se usa para obtener únicamente los registros de un cliente
 * @param repse_doc_id [Opcional] Identificador del documento del que se quiere obtener el historial
 * @returns Object {"data" => ComplaintSuggestionBox::collection(),"totalItems" => Cantidad de registros existentes}
 */
export const indexRepseUserDocs = async (props) => {
    const {
        headers,
        page,
        pageSize,
        user_id,
        repse_doc_id
    } = props;
    let response = { data: [], totalItems: 0 };
    try {
        // Parámetros requeridos
        let params = { user_id };
        // Parámetros opcionales
        if (repse_doc_id) params.repse_doc_id = repse_doc_id;
        if (page) params.page = page;
        if (pageSize) params.pageSize = pageSize;
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        // console.log("REPSE User Docs: ", fetch.data);
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = {
            data: [],
            totalItems: 0
        };
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param user_doc_id Identificador del comunicado sobre el que se hará la consulta
 * @returns Incident Retorna un objeto tipo Incident
 */
export const showRepseUserDocs = async ({ headers, user_doc_id }) => {
    let response = [];
    try {
        let fetch = await Fetcher({
            url: `${url}/${user_doc_id}`,
            method: 'GET',
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = {};
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storeRepseUserDocs = async ({ headers, data }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        if (fetch.status === CREATED)
            response = fetch.data.data;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param user_doc_id Identificador del comunicado que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updateRepseUserDocs = async ({ headers, data, user_doc_id }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}/${user_doc_id}`,
            method: 'PUT',
            data,
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param user_doc_id Identificador del comunicado que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyRepseUserDocs = async ({ headers, user_doc_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${user_doc_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}

export const uploadRepseUserDocs = async ({ headers, user_doc_id, data }) => {
    let response = false;
    // console.log("DATA TYPE: ", data);
    try {
        let fetch = await Fetcher({
            url: `${url}/upload/${user_doc_id}`,
            method: 'POST',
            data, headers
        });
        // console.log("Fetch Repse users [upload]: ", fetch);
        if (fetch.status === STATUS_OK)
            response = true;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}

export const downloadRepseUserDocs = async ({ headers, user_doc_id, name, toast }) => {
    let response = false;
    try {
        downloadFile({
            headers,
            url: `${url}/download/${user_doc_id}`,
            type: 'application/json',
            name,
            toast
        });
        response = true;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}