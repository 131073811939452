import React from 'react'
import PropTypes from 'prop-types';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import ButtonIcon from './Forms/ButtonIcon'

const SearchComp = (props) => {
    const { tooltip, placeholder, hookForm } = props;

    return (
        <InputGroup size='md'>
            <Input
                name='string'
                pr='4.5rem'
                type='text'
                placeholder={placeholder}
                onChange={hookForm?.handleChange}
                value={hookForm?.form?.string}
                backgroundColor='#fff'
            />
            <InputRightElement width='4.5rem'>
                <ButtonIcon
                    icon={faSearch}
                    tooltip={tooltip}
                    onClick={hookForm?.updateParams}
                />
            </InputRightElement>
        </InputGroup>
    )
}

SearchComp.propTypes = {
    tooltip: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    hookForm: PropTypes.shape({
        handleChange: PropTypes.func.isRequired,
        updateParams: PropTypes.func.isRequired,
        form: PropTypes.shape({
            string: PropTypes.string.isRequired
        }).isRequired
    }).isRequired
}

export default SearchComp;