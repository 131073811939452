import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FlexButton from "../Layouts/FlexButton";
import Mailbox from "./fragments/Mailbox"
import _ from "underscore";
import UploadInvoice from "./fragments/invoices/Upload";
import { getInvoices } from "../../api/Cfdi/Mailbox";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import CompPaymentModal from "./fragments/invoices/CompPaymentModal";
import { useTranslation } from "react-i18next";
import { SET_ACTIVE_TAB } from "../../redux/actions/main";
import usePagination from "../../hooks/usePagination";

const MailboxProvider = () => {
    const login = useSelector(state => state.login);
    const { token, fibra, user, role_selected } = login;
    const headers = { Authorization: `Bearer ${token}` };
    const [modalAdd, setModalAdd] = useState(false);
    const [modalComp, setModalComp] = useState(false);      // Modal para los complementos de pago
    const [showTab, setShowTab] = useState(false);
    // Paginación
    const hookInvoices = usePagination({ defaultPageSize: 10 });
    const [t] = useTranslation('global');
    const [filters, setFilters] = useState({ cfdi_status_id: null });
    const dispatch = useDispatch();
    const { page, pageSize } = hookInvoices.pagination;

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/mailbox/provider' });
    }, []);

    useEffect(() => {
        const getMailbox = async () => {
            hookInvoices.setLoading(true);
            const params = { headers, page, pageSize, ...filters };
            const response = await getInvoices({
                ...params, fibra_id: fibra.id,
                role_id: role_selected.id, user_id: user.id
            });
            hookInvoices.setData(response.data);
            hookInvoices.setTotalItems(response.totalItems);
            hookInvoices.setLoading(false);
        }
        getMailbox();
    }, [page, pageSize, filters]);

    const complementaryPayment = (item) => {
        hookInvoices.setDataSel(item);
        setModalComp(true);
    }

    return (
        <Fragment>
            <Mailbox
                hookInvoices={hookInvoices}
                tactions={[                          // Botones que son visibles para el proveedor
                    {
                        icon: faFileUpload, variant: 'outline-success',
                        tooltip: t('mailbox.table.tooltips.add-comp'),
                        handleClick: complementaryPayment,
                        dinamic: [
                            { key: 'payment_method', value: 'PPD' },
                            // { key: 'cfdi_status', value: 'Pagados' }
                        ]
                    }
                ]}
                filters={filters}
                setFilters={setFilters}
                setShowTab={setShowTab}
            />
            {
                showTab &&
                <FlexButton style={{ bottom: 10, right: 10 }} onClick={() => setModalAdd(true)} />
            }
            {/* Modal para agregar nueva factura */}
            {
                modalAdd ? <UploadInvoice
                    onClose={() => setModalAdd(false)}
                    headers={headers}
                /> : null
            }
            {/* Modal para mostrar los complementos de pago */}
            {
                modalComp && <CompPaymentModal
                    onClose={() => setModalComp(false)}
                    headers={headers}
                    invoice={hookInvoices.dataSel}
                    user_id={user.id}
                />
            }
        </Fragment>
    );
}

export default MailboxProvider;