import { useState, useEffect, Fragment } from "react";
import PropTypes from 'prop-types';
import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import Swal from 'sweetalert2';
import { showPurchaseOrders, storePurchaseOrder, updatePurchaseOrder } from "../../../api/PurchaseOrders";
import { destroyPurchaseOrderItem, storePurchaseOrderItem } from "../../../api/PurchaseOrderItems";
import ModalComp from "../../Layouts/Modal";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import { useToast } from "@chakra-ui/toast";
import setToast from "../../../libs/SetToast";
import { useTranslation } from "react-i18next";
import ItemsList from "./Modal/ItemsList";
import Header from "./Modal/Header";
import AddItems from "./Modal/AddItems";
import { indexBusinessName } from "../../../api/Cfdi/BusinessNames";
import usePagination from "../../../hooks/usePagination";
import { indexSegments } from "../../../api/Segments";
import { hookPaginationPropType } from "../../../data/propTypes";
import { getCurrency } from "../../../libs/functions";
import { Alert, AlertIcon } from "@chakra-ui/react";
import useLoading from "../../../hooks/useLoading";

const ModalPurchaseOrder = (props) => {
    const { headers, fibra_id, onClose, title, providers, type, hookPurchaseOrders } = props;
    const purchase_order_id = hookPurchaseOrders.dataSel?.id;
    const po = hookPurchaseOrders.dataSel;
    const toast = useToast();
    const [folio, setFolio] = useState('');                         // Folio de la orden de compra
    const [form, setForm] = useState({});                           // Data de la orden de compra
    const [amount, setAmount] = useState(null);
    const [subtotal, setSubtotal] = useState(null);
    const [purchaseOrder, setPurchaseOrder] = useState({});
    const [loading, setLoading] = useState(true);
    // Partidas 
    const [item, setItem] = useState({});                           // Datos de nueva partida para ser agregada
    const [items, setItems] = useState([]);                         // Partidas de la orden de compra seleccionada
    const [itemsDestroy, setItemsDestroy] = useState([]);          // Array de items para ser eliminados
    const [itemsSel, setItemsSel] = useState([]);                  // Array de items para facturar
    const [t] = useTranslation('global');
    const prefix = 'purchase-order.toasts';
    const p_data = 'purchase-order.data';

    const [businessNames, setBusinessNames] = useState([]);
    const [loadingBN, setLoadingBN] = useState(true);
    const storeLoading = useLoading();
    const hookSegments = usePagination({ defaultPageSize: 50 });

    // Obtener los segmentos
    useEffect(() => {
        const getSegments = async () => {
            hookSegments.setLoading(true);
            const { page, pageSize } = hookSegments.pagination;
            const fetch = await indexSegments({ headers, fibra_id, page, pageSize });
            hookSegments.setData(fetch.data);
            hookSegments.setTotalItems(fetch.totalItems);
            hookSegments.setLoading(false);
        }
        getSegments();
    }, []);
    // Obtener razones sociales de la empresa
    useEffect(() => {
        const getBusinessNames = async () => {
            const { data } = await indexBusinessName({ headers, fibra_id });
            setBusinessNames(data);
            setLoadingBN(false);
        }
        getBusinessNames();
    }, []);
    // Si es una orden de compra existente se obtienes los datos, en caso contrario se obtiene un folio
    useEffect(() => {
        if (purchase_order_id) { getPurchaseOrder(); } else { getFolio(); setLoading(false); }
    }, []);
    // Actualiza el total de la orden de compra cada vez que las partidas se actualizan
    useEffect(() => {
        updateTotal();
    }, [items, itemsDestroy]);
    // Obtiene la orden de compra que corresponde al purchase_order_id del props 
    const getPurchaseOrder = async () => {
        let response = await showPurchaseOrders({ headers, purchase_order_id });
        let new_items = [];
        // Se agrega el atributo index a cada objeto, comenzando por 1
        response.items.map((_item, _index) => { new_items.push({ ..._item, index: _index + 1 }); });
        setItems(new_items);                // Se actualizan las partidas de la orden de compra
        setFolio(response.folio);           // Se obtiene el folio de la orden de compra
        setPurchaseOrder(response);         // Se guarda el objeto de la orden de compra
        // console.log("Purchase order: ", response);
        setLoading(false);                  // Se indica que la carga de datos ha concluido
    }
    // Se actualizar la lista de status cfdi a la que tiene acceso el rol seleccionado
    const handleChangeCheck = async (checked, item_id) => {
        if (checked) {
            setItemsSel([...itemsSel, item_id]);
        } else {
            setItemsSel(itemsSel.filter(element => element !== item_id));
        }
    }
    // Obtiene si la partida ha sido facturada para deshabilitarla
    const getChecked = (item_id) => {
        const item = items.find(item => item.id === item_id);
        // Si existe el item y tiene un invoice_id, se considera facturado
        return Boolean(item?.invoice_id);
    }
    // Método para analizar los cambios en los campos de la orden de compra
    const handleChange = (event) => {
        let { name, value } = event.target;
        setForm({ ...form, [name]: value });
    }
    // Se ejecuta el método según el tipo de operación, es decir, actualización o creación
    const handleSubmit = () => {
        if (items.length > 0) {
            props.type === 'add' ? addPurchaseOrder() : updateOrder();
        } else {
            setToast({ toast, title: t(`${prefix}.warning-sp.title`), description: t(`${prefix}.warning-sp.description`), status: 'warning' });
        }

    }
    // Método para agregar una nueva orden de compra
    const addPurchaseOrder = async () => {
        storeLoading.showLoading();
        const purchaseOrderItems = items.map(element => {
            return {
                description: element.description,
                detailed: element.detailed,
                quantity: element.quantity,
                unit: element.unit,
                unit_price: element.unit_price,
                iva: element.iva,
            }
        });
        const response = await storePurchaseOrder({ headers, data: { ...form, fibra_id, folio, items: purchaseOrderItems } });
        if (response) {
            Swal.fire(t(`${prefix}.swal-succ-cre.title`), t(`${prefix}.swal-succ-cre.description`), 'success');
            // Agregar registro usando el hook de paginación
            const { user, segment } = response.data;
            response.data.user = `${user.first_name} ${user?.last_name}`;
            response.data.segment = `${segment.code} ${segment.name}`;
            hookPurchaseOrders.addElement(response.data);
            onClose();
        } else {
            setToast({ toast, title: t(`${prefix}.warning-di.title`), description: t(`${prefix}.warning-di.description`), status: 'warning' });
        }
        storeLoading.hideLoading();
    }
    // Actualizar orden de compra
    const updateOrder = async () => {
        const { res, data } = await updatePurchaseOrder({ headers, purchase_order_id, data: form });
        if (res) {
            createOrUpdateItems(purchase_order_id); // Se actualizan las partidas
            Swal.fire(t(`${prefix}.swal-succ-upd.title`), t(`${prefix}.swal-succ-upd.description`), 'success');
            // Modificar registro del hook de paginación
            hookPurchaseOrders.setData(prevState => prevState.map(purchaseOrder => {
                if (purchaseOrder.id === purchase_order_id) {
                    const { user, segment } = data;
                    purchaseOrder = {
                        ...data,
                        user: `${user?.first_name} ${user?.last_name}`,
                        segment: `${segment?.code} ${segment?.name}`
                    }
                }
                return purchaseOrder;
            }));
            onClose();
        }
    }
    // Método para actualizar las partidas
    const createOrUpdateItems = (id) => {
        itemsDestroy.forEach(async (_item) => {    // Elimina las partidas del array de itemsDestroy
            await destroyPurchaseOrderItem({ headers, item_id: _item });
        })
        items.forEach(async (_item, _index) => {    // Agrega las partidas nuevas
            if (!_item.id) {    // Si la partida tiene ID significa que ya existe en la base de datos
                const { description, total, unit_price, quantity, unit, detailed, subtotal, iva } = _item;
                await storePurchaseOrderItem({ headers, data: { description, unit_price, quantity, total, purchase_order_id: id, unit, detailed, subtotal, iva } });
            }
        });
    }
    // Obtiene el folio de las órdenes de compra nuevas 
    const getFolio = () => {
        setFolio(``);
    }
    // Listener para obtener los cambios del formulario de nueva partida
    const changeItem = (e) => {
        let { name, value } = e.target;
        setItem({ ...item, [name]: value });
    }
    // Agregar nueva partida al array items y se limpian los datos en memoria
    const addItem = () => {
        let { description, unit_price, quantity, unit, detailed, iva } = item;
        const subtotal = unit_price * quantity;
        // Calcula si se debe aplicar IVA
        const VAT = subtotal * (process.env.REACT_APP_IVA / 100);
        const total = iva ? VAT + subtotal : subtotal;
        if (description && unit_price && quantity) {
            setItems([...items, { index: items.length + 1, description, quantity, unit_price, subtotal, total, unit, detailed, iva: VAT }]);
            setItem({ description: '', total: '', quantity: '', unit_price: '', unit: '', detailed: '', iva: false });
        }
    }
    // Eliminar partida de la orden de compra
    const removeItem = (itemToRemove) => {
        // Si tiene un Id, se agrega al array de items a eliminar
        if (itemToRemove.id) setItemsDestroy([...itemsDestroy, itemToRemove.id]);
        // Se actualiza el estado, eliminando el item y actualizando los index
        setItems(prevItems =>
            prevItems
                .filter(item => item.index !== itemToRemove.index)
                .map((item, index) => ({ ...item, index: index + 1 }))
        );
    }
    // Método para actualizar el total de la partida
    const updateTotal = () => {
        const subtotal = items.reduce((count, currentValue) => parseFloat(currentValue.subtotal) + count, 0);
        const total = items.reduce((count, currentValue) => parseFloat(currentValue.total) + count, 0);
        setAmount(getCurrency(total));
        setSubtotal(getCurrency(subtotal))
        setForm({ ...form, amount: total, subtotal });
    }

    return (
        <ModalComp
            title={`${title}`}
            size='xl'
            onClose={onClose}
            body={
                <Fragment>
                    {/* Mostrar leyenda de orden de compra cancelada */}
                    {
                        po?.canceled === 'CANCELADA' &&
                        <Alert status="warning"><AlertIcon />{t(`${p_data}.alert-canceled`)}</Alert>
                    }
                    {/* Mostrar datos de la orden de compra */}

                    {/* Datos principales */}
                    <Header
                        handleChange={handleChange}
                        providers={providers}
                        folio={folio}
                        po={po}
                        type={type}
                        purchase_order={purchaseOrder}
                        amount={amount}
                        form={form}
                        businessNames={businessNames}
                        loadingBN={loadingBN}
                        hookSegments={hookSegments}
                        subtotal={subtotal}
                    />
                    {/* Agregar partidas si es administrador */}
                    <AddItems t={t} p_data={p_data} type={type} item={item} changeItem={changeItem} addItem={addItem} fibra_id={fibra_id} />

                    {/* Lista de partidas */}
                    <ItemsList t={t} p_data={p_data} items={items} type={type} handleChangeCheck={handleChangeCheck}
                        getChecked={getChecked} removeItem={removeItem} loading={loading} />
                    {
                        type === 'provider' &&
                        <Alert status="warning"><AlertIcon />{t(`${p_data}.alert-invoice-disabled`)}</Alert>
                    }
                </Fragment>}
            footer={<Fragment>
                <ButtonIcon variant="outline-secondary" onClick={onClose} name={t(`${p_data}.button-close`)} icon={faArrowLeft} />
                {
                    type !== 'provider' &&
                    <ButtonIcon variant="outline-primary" name={t(`${p_data}.button-save`)} onClick={handleSubmit} icon={faSave} disabled={po?.canceled === 'CANCELADA' || storeLoading.isOpen} />
                }
            </Fragment>}
        />
    );
}

ModalPurchaseOrder.propTypes = {
    headers: PropTypes.string.isRequired,
    fibra_id: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    providers: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    hookPurchaseOrders: PropTypes.shape(hookPaginationPropType).isRequired
}

export default ModalPurchaseOrder;