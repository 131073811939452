import downloadFile from "../libs/DownloadFile";
import Fetcher from "../libs/Petition"

const url = '/purchase_orders';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param fibra_id Se utiliza el identificador de la fibra para obtener los roles que le corresponden
 * @param page Se utiliza para conocer la página en la que se encuentra
 * @param pageSize Se utiliza para especificar el tamaño de la página, es decir, la cantidad de registros que se obtendrán.
 * @param user_id [Opcional] Se usa para obtener los registros de un proveedor
 * @returns Object {"data" => PurchaseOrder::collection(),"totalItems" => Cantidad de registros existentes}
 */
export const indexPurchaseOrders = async ({ headers, fibra_id, page, pageSize, user_id, ...anotherParams }) => {
    let response = { data: [], totalItems: 0 };
    const { segment_id, business_name_id, string, start_date, end_date, segment_ids, business_name_ids, removed, voucher, payment_order_status } = anotherParams;
    try {
        let params = { fibra_id, page, pageSize, string };
        if (user_id) params.user_id = user_id;
        if (string) params.string = string;
        if (segment_id !== '-1') params.segment_id = segment_id;
        if (business_name_id !== '-1') params.business_name_id = business_name_id;
        if (start_date) params.start_date = start_date;
        if (end_date) params.end_date = end_date;
        if (segment_ids) params.segment_ids = segment_ids;
        if (business_name_ids) params.business_name_ids = business_name_ids;
        if (removed !== '-1') params.removed = removed;
        if (voucher !== '-1') params.voucher = voucher;
        if (payment_order_status !== '-1') params.payment_order_status = payment_order_status;
        const fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        // console.log("🚀 ~ file: PurchaseOrders.js:29 ~ indexPurchaseOrders ~ fetch:", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data;
        return response
    } catch (error) {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param purchase_order_id Identificador de la orden de compra sobre el que se hará la consulta
 * @returns PurchaseOrder Retorna un objeto tipo PurchaseOrder
 */
export const showPurchaseOrders = async ({ headers, purchase_order_id }) => {
    let response = {};
    try {
        let fetch = await Fetcher({
            url: `${url}/${purchase_order_id}`,
            method: 'GET',
            headers
        });
        // console.log("Purchase order [show fetch]: ", fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = {};
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storePurchaseOrder = async ({ headers, data }) => {
    let response = false;
    try {
        const fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        // console.log("🚀 ~ file: PurchaseOrders.js:75 ~ storePurchaseOrder ~ fetch:", fetch)
        if (fetch.status === CREATED)
            response = fetch.data;
    } catch (error) {
        response = false;
    }
    return response;

}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param purchase_order_id Identificador de la orden de compra que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updatePurchaseOrder = async ({ headers, data, purchase_order_id }) => {
    let response = { res: false, data: {} };
    try {
        const fetch = await Fetcher({
            url: `${url}/${purchase_order_id}`,
            method: 'PUT',
            data,
            headers
        });
        // console.log("🚀 ~ file: PurchaseOrders.js:99 ~ updatePurchaseOrder ~ fetch:", fetch)
        if (fetch.status === STATUS_OK) {
            response.data = fetch.data.data;
            response.res = true;
        }
        return response
    } catch (error) {
        return response
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param purchase_order_id Identificador de la orden de compra que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyPurchaseOrder = async ({ headers, purchase_order_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${purchase_order_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    }
    return response;
}

export const uploadPurchaseOrder = async ({ headers, data, purchase_order_id }) => {
    let response = false;
    try {
        const fetch = await Fetcher({
            url: `${url}/upload/${purchase_order_id}`,
            method: 'POST',
            data, headers
        });
        // console.log("🚀 ~ file: PurchaseOrders.js:140 ~ uploadPurchaseOrder ~ fetch:", fetch)

        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
        return response;
    } catch (error) {
        return response;
    }
}

export const downloadPurchaseOrder = async ({ headers, purchase_order_id, name, toast }) => {
    try {
        await downloadFile({
            headers,
            url: `${url}/download/${purchase_order_id}`,
            type: 'application/json',
            name,
            toast
        });
        return true;
    } catch (error) {
        return false;
    }
}

export const downloadPurchaseOrderPdf = async ({ headers, purchase_order_id, name, toast }) => {
    try {
        await downloadFile({
            headers,
            url: `${url}/download/pdf/${purchase_order_id}`,
            type: 'application/json',
            name,
            toast
        });
        return true;
    } catch (error) {
        return false;
    }
}

export const downloadPaymentOrderPdf = async ({ headers, purchase_order_id, name, toast }) => {
    try {
        await downloadFile({
            headers,
            url: `/payment_orders/download/pdf/${purchase_order_id}`,
            type: 'application/json',
            name,
            toast
        });
        return true;
    } catch (error) {
        return false;
    }
}

export const downloadPurchaseOrderXlsx = async ({ headers, fibra_id, name, toast, type, start_date, end_date }) => {
    try {
        await downloadFile({
            headers,
            url: `purchase_order/items/download/xlsx`,
            type: 'application/json',
            name,
            toast,
            params: { fibra_id, type, start_date, end_date }
        });
        return true;
    } catch (error) {
        return false;
    }
}