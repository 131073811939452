// Función para importar dinámicamente todos los archivos de idioma
export const importAll = (context) => {
    // Objeto que almacenará todas las traducciones organizadas por idioma y módulo
    const langModules = {};

    // Iterar sobre cada archivo de idioma encontrado por require.context
    context.keys().forEach((key) => {
        try {
            // Obtener el idioma desde la ruta del archivo
            const lang = key.split('/')[1];
            // Obtener el nombre del módulo desde la ruta del archivo sin la extensión .json
            const moduleName = key.split('/')[2].replace('.json', '');

            // Inicializar el objeto de idioma si aún no existe
            if (!langModules[lang]) {
                langModules[lang] = {};
            }

            // Almacenar el contenido del archivo de idioma en el objeto correspondiente
            langModules[lang][moduleName] = context(key);
        } catch (error) {
            // Manejar errores en caso de problemas al cargar un archivo de idioma
            console.error(`Error al cargar módulo de idioma desde ${key}`, error);
        }
    });

    // Devolver el objeto con todas las traducciones organizadas por idioma y módulo
    return langModules;
};