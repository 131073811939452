import PropTypes from 'prop-types';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import ModalComp from '../../Layouts/Modal';
import TabsComp from '../../Layouts/Tabs';
import FormData from './FormData';
import UsersAssigned from './Users';

const ModalView = (props) => {
    const { onClose, cedis, data, headers, fibra_id } = props;
    return (
        <ModalComp
            onClose={onClose}
            title='Vista general'
            size='lg'
            body={
                <TabsComp
                    tablist={['Datos generales', 'Usuarios asignados']}
                    tabpanels={[
                        <FormData
                            key='key-1'
                            cedis={cedis}
                            handleChange={() => { }}
                            data={data}
                        />,
                        <UsersAssigned
                            key='key-2'
                            fibra_id={fibra_id}
                            headers={headers}
                            cedis_id={cedis.id}
                        />
                    ]}
                />
            }
            footer={
                <ButtonIcon icon={faArrowLeft} name='Cerrar' variant='outline-secondary' onClick={onClose} />
            }
        />
    );
};

ModalView.propTypes = {
    onClose: PropTypes.func.isRequired,
    cedis: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    headers: PropTypes.string.isRequired,
    fibra_id: PropTypes.number.isRequired,
}

export default ModalView;
