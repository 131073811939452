import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Divider } from 'antd';
import { Heading } from '@chakra-ui/react';
import { Table } from 'react-bootstrap';
import SkeletonTable from '../../../Layouts/Skeletons/Table';
import { useTranslation } from 'react-i18next';
import Select from 'react-select'
import AutoCompleteComp from '../../../Layouts/Forms/Autocomplete';
import useForm from '../../../../hooks/useForm';
import { indexBudgetCodes } from '../../../../api/Budget/codes';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import ButtonIcon from '../../../Layouts/Forms/ButtonIcon';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { updateRequisitionItem } from '../../../../api/Budget/requisition_items';
import Swal from 'sweetalert2';

const AssignProvidersComp = ({ loading, items, providers, handleChangeProviders, defaultData, fibra_id }) => {
    const token = useSelector(prevState => prevState.login.token);
    const headers = { Authorization: `Bearer ${token}` };
    const currentLanguage = i18next.language;
    const [t] = useTranslation('requisition');
    const prefix = 'modal-details.items-list';
    const prefix2 = 'modal-details'

    const [options, setOptions] = useState([]);
    const hookForm = useForm();
    const [currentItem, setCurrentItem] = useState('');
    // const [next, setNext] = useState(false);

    const tHeaders = ['#', t(`${prefix}.description`), t(`${prefix}.detailed`), t(`${prefix}.unit`), t(`${prefix}.quantity`), t(`${prefix}.providers`), t(`${prefix}.update`)];

    // Llenar autocomplete con los valores iniciales
    useEffect(() => {
        const defaultForm = {};
        for (let index = 0; index < items.length; index++) {
            const item = items[index];
            defaultForm['description-' + item.id] = item.description;
            defaultForm['description-' + item.id + '-changed'] = false;
        }
        hookForm.setForm(defaultForm)
    }, [items]);
    // Obtener partidas presupuestarias
    useEffect(() => {
        const getBudgetCodes = async () => {
            const { data } = await indexBudgetCodes({ headers, fibra_id });
            setOptions(data.map((item) => {
                const { id, code, translations } = item;
                const currentTranslation = translations.find((translation) => translation.locale === currentLanguage);
                return {
                    id,
                    label: `${code} - ${currentTranslation?.name}`
                }
            }));
        }
        getBudgetCodes();
    }, []);
    // Obtener la lista de proveedores por default
    const getDefaultProviders = (item_id) => {
        const { provider_items } = defaultData;
        if (provider_items) {
            return provider_items
                .filter(providerItem => providerItem.requisition_item_id === item_id)
                .map((providerItem) => ({ label: `${providerItem.provider.name} (${providerItem.provider.email})`, value: providerItem.provider.id }))
        }
        return [];
    }
    // Evento onSelect del autocomplete: Establecer valor de la lista de sugerencias
    const verifiqueBudgetCodes = (item) => {
        const exists = options.find(option => option.label === item.label);
        if (exists) hookForm.setForm(prevState => ({ ...prevState, [currentItem + '-changed']: true }));
        else hookForm.setForm(prevState => ({ ...prevState, [currentItem + '-changed']: false }));
    }
    // Evento onChange del autcomplete: Buscar coincidencias
    const changeAutocomplete = (e) => {
        const { name } = e.target;
        setCurrentItem(name);
        hookForm.handleChange(e);
        verifiqueBudgetCodes({ label: e.target.value });
    }
    // Método para actualizar partida presupuestaria
    const onUpdateItem = async (item) => {
        const data = { description: hookForm.form[`description-${item.id}`] }
        const fetch = await updateRequisitionItem({ headers, data, requisition_item_id: item.id });
        if (fetch) {
            Swal.fire(t(`${prefix2}.onSave.success-update.title`), t(`${prefix2}.onSave.success-update.description`), 'success');
            hookForm.setForm(prevState => ({ ...prevState, [currentItem + '-changed']: false }));
        } else {
            Swal.fire(t(`${prefix2}.onSave.fail.title`), t(`${prefix2}.onSave.fail.description`), 'warning');
        }
    }

    return (
        <>
            <Divider orientation='left'>
                <Heading size='sm'>{t(`${prefix}.assigned`)}</Heading>
            </Divider>
            <Table
                className='mt-4'
                striped
                bordered
                hover
            >
                <thead>
                    <tr>
                        {
                            tHeaders.map((header) =>
                                <th key={header}>{header}</th>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        loading ? <SkeletonTable tr={5} td={tHeaders.length} /> :
                            items.map((item, item_index) => {
                                return <tr key={item.id}>
                                    <td align='center'>{item_index + 1}</td>
                                    {
                                        defaultData.provider_items.length > 0 ?
                                            <td >{item.description}</td> :
                                            <td>
                                                <AutoCompleteComp
                                                    autoComplete='off'
                                                    name={`description-${item.id}`}
                                                    placeholder={t(`${prefix2}.item-description-ph`)}
                                                    options={options}
                                                    handleChange={changeAutocomplete}
                                                    onSelect={verifiqueBudgetCodes}
                                                    value={hookForm.form[`description-${item.id}`]}
                                                    error={Object.values(item).length > 0 && !hookForm.form[`description-${item.id}`]}
                                                />
                                            </td>
                                    }
                                    <td>{item?.detailed}</td>
                                    <td align='center'>{item.unit}</td>
                                    <td align='center'>{item.quantity}</td>
                                    {/* Celda para asignar los proveedores */}
                                    <td>
                                        <Select
                                            isMulti
                                            className='form-control'
                                            closeMenuOnSelect={false}
                                            defaultValue={getDefaultProviders(item.id)}
                                            onChange={(newValue) => handleChangeProviders(newValue, item.id)}
                                            options={providers.map(provider => ({ label: `${provider?.business_partner_provider?.business_name} (${provider?.email})`, value: provider.id }))}
                                        />
                                    </td>
                                    {/* Mostrar botón para guardar únicamente cuando se detecta cambio en la partida y la partida es válida*/}
                                    <td>
                                        <ButtonIcon
                                            icon={faSave}
                                            variant='outline-success'
                                            onClick={() => onUpdateItem(item)}
                                            tooltip={t(`${prefix}.update-tt`)}
                                            disabled={!(hookForm.form[`description-${item.id}`] !== item.description && hookForm.form[`description-${item.id}-changed`])}
                                        />
                                    </td>
                                </tr>
                            })
                    }
                </tbody>
            </Table>
        </>
    )
}

AssignProvidersComp.propTypes = {
    loading: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    providers: PropTypes.array,
    handleChangeProviders: PropTypes.func,
    defaultData: PropTypes.shape({
        provider_items: PropTypes.array.isRequired
    }).isRequired,
    fibra_id: PropTypes.number.isRequired
}

export default AssignProvidersComp