import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ModalComp from '../../Layouts/Modal'
import ButtonIcon from '../../Layouts/Forms/ButtonIcon'
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { Tree } from 'antd'
import usePagination from '../../../hooks/usePagination'
import { indexBusinessName } from '../../../api/Cfdi/BusinessNames'
import { indexSegments } from '../../../api/Segments'
import { Alert, AlertIcon } from '@chakra-ui/react'
import { indexRoleBusinessNames, storeRoleBusinessNames } from '../../../api/Cfdi/RoleBusinessNames'
import Swal from 'sweetalert2'

const ModalRoleBusinessNamesSegments = ({ onClose, headers, fibra_id, role }) => {
    const [t] = useTranslation('settings');
    const p = 'roles.modal-business-names-segments';
    const hookBusinessNames = usePagination({});
    const hookSegments = usePagination({});

    const [disabledTree, setDisabledTree] = useState(false); 			// Permisos editables
    const [checkedKeys, setCheckedKeys] = useState([]);					// Permisos seleccionados (editable)
    const [halfCheckedKeys, setHalfCheckedKeys] = useState([]);         // Permisos parcialmente seleccionados
    const [expandedKeys, setExpandedKeys] = useState([]);               // Permisos con hijos que están expandidos
    const [treeData, setTreeData] = useState([]);
    const [currentData, setCurrentData] = useState({ segments: [], business_names: [] });

    // En esto efecto se puede decidir si el usuario tiene permisos para modificar esta sección
    useEffect(() => { setDisabledTree(false); }, []);
    // Obtenemos lista de razones sociales
    useEffect(() => {
        const getBusinessNames = async () => {
            hookBusinessNames.setLoading(true);
            const fetch = await indexBusinessName({ fibra_id, headers });
            hookBusinessNames.setData(fetch.data);
            hookBusinessNames.setTotalItems(fetch.totalItems);
            hookBusinessNames.setLoading(false);
        }
        getBusinessNames();
    }, []);
    // Obtener lista de segmentos
    useEffect(() => {
        const getSegments = async () => {
            hookSegments.setLoading(true);
            const fetch = await indexSegments({ fibra_id, headers });
            hookSegments.setData(fetch.data);
            hookSegments.setTotalItems(fetch.totalItems);
            hookSegments.setLoading(false);
        }
        getSegments();
    }, []);
    // Obtener el árbol de las razones sociales con sus segmentos
    useEffect(() => {
        if (!hookBusinessNames.loading && !hookSegments.loading) {
            const businessNames = hookBusinessNames.data;
            const segments = hookSegments.data;
            setTreeData(
                // Mostrar razones sociales
                businessNames.map(businessName => {
                    return {
                        title: `${businessName.name} - ${businessName.rfc}`,
                        key: businessName.id,
                        // Se muestran como hijos los segmentos
                        children: segments
                            // Obtener los segmentos relacionados
                            .filter(segment => segment.business_name_id === businessName.id)
                            .map(segment => {
                                return {
                                    title: `${segment.code} - ${segment.name}`,
                                    // Es importante seguir la nomenclatura del key `${businessName.id}-${segment.id}`
                                    // para que se puedan mostrar de forma anidada
                                    key: `${businessName.id}-${segment.id}`
                                }
                            })
                    }
                })
            );
        }
    }, [hookBusinessNames.loading, hookSegments.loading]);
    // Mostrar desglosadas las opciones
    useEffect(() => {
        if (!hookBusinessNames.loading) {
            setExpandedKeys(hookBusinessNames.data.map(businessName => businessName.id));
        }
    }, [hookBusinessNames.loading]);
    // Obtener las configuraciones actuales
    useEffect(() => {
        const getCurrentConfigs = async () => {
            const fetch = await indexRoleBusinessNames({ headers, role_id: role.id });
            setCurrentData({
                segments: fetch.segments,
                business_names: fetch.business_names
            });
            setCheckedKeys(fetch.segments.map(segment => {
                return `${segment.business_name_id}-${segment.segment_id}`
            }))
        }
        getCurrentConfigs();
    }, []);
    // Evento que se lanza al seleccionar algun checkbox
    const onCheck = (checkedKeysValue, e) => {
        setHalfCheckedKeys(e.halfCheckedKeys);
        setCheckedKeys(checkedKeysValue);
    };
    // Expandir las opciones del árbol
    const onExpand = (expandedKeys) => setExpandedKeys(expandedKeys);
    // Obtenemos el arreglo de identificadores de las opciones seleccionadas
    const getBusinessNamesSegments = () => {
        const checkedBusinessNamesSegments = [...halfCheckedKeys, ...checkedKeys];
        const checkedSegments = checkedBusinessNamesSegments
            .filter(item => item.toString().includes('-'))
            .map(item => {
                const arrSplt = item.toString().split('-');
                return arrSplt[1]
            });
        const checkedBusinessNames = checkedBusinessNamesSegments
            .filter(item => !item.toString().includes('-'))
            .map(business_name_id => business_name_id);
        return {
            business_names: checkedBusinessNames,
            segments: checkedSegments
        }
    }
    // Se guarda la nueva configuración
    const onSave = async () => {
        if (hasChange()) {
            const business_names_segments = getBusinessNamesSegments();
            const data = { role_id: role.id, ...business_names_segments };
            const fetch = await storeRoleBusinessNames({ headers, data });
            if (fetch) {
                if (fetch.role) updateCurrentData(fetch.role);
                Swal.fire(t(`${p}.sf-success.title`), t(`${p}.sf-success.msg`), 'success');
            } else {
                Swal.fire(t(`${p}.sf-fail.title`), t(`${p}.sf-fail.msg`), 'info');
            }
        }
    }
    // Método que se encarga de verificar si la configuración ha cambiado
    const hasChange = () => {
        const treeData = getBusinessNamesSegments();
        if (treeData.segments.length !== currentData.segments.length) {
            return true;
        }
        // Verificamos si los segment_id seleccionados son diferentes de los actuales
        const currentIds = currentData.segments.map(item => item.segment_id);
        for (const id of treeData.segments) {
            // Convertimos a entero, ya que el componente maneja las keys como cadena
            if (!currentIds.includes(parseInt(id))) {
                return true;
            }
        }
        return false
    };
    // Actualizar datos actuales
    const updateCurrentData = ({ segments, business_names }) => {
        setCurrentData({
            segments,
            business_names
        });
    }

    return (
        <ModalComp
            onClose={onClose}
            title={t(`${p}.title`)}
            size={'md'}
            body={
                <div style={{ height: '60vh', overflowY: 'auto' }}>
                    <Alert status='info' className='mb-3' fontSize='sm'>
                        <AlertIcon /> {t(`${p}.alert-icon`)}
                    </Alert>
                    <Tree
                        checkable
                        onCheck={onCheck}
                        checkedKeys={checkedKeys}
                        treeData={treeData}
                        expandedKeys={expandedKeys}
                        onExpand={onExpand}
                        disabled={disabledTree}
                    />
                </div>
            }
            footer={<>
                <ButtonIcon name={t(`${p}.close`)} icon={faTimes} variant='outline-secondary' onClick={onClose} />
                <ButtonIcon name={t(`${p}.save`)} icon={faSave} variant='outline-success' onClick={onSave} disabled={!hasChange()} />
            </>}
        />
    )
}

ModalRoleBusinessNamesSegments.propTypes = {
    onClose: PropTypes.func.isRequired,
    headers: PropTypes.shape({
        Authorization: PropTypes.string.isRequired
    }).isRequired,
    fibra_id: PropTypes.number.isRequired,
    role: PropTypes.shape({
        id: PropTypes.number.isRequired
    }).isRequired,
}

export default ModalRoleBusinessNamesSegments