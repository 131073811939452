import Fetcher from "../../../libs/Petition";

const url = 'rec/fac/ped/prov';
const CREATED = 201;
const STATUS_OK = 200;

export const storeRecFacPedProv = async ({ headers, data }) => {
    let response = { data: [], totalItems: 0 };
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            headers,
            data
        });
        // console.log("Rec Fac Ped Prov: ", fetch);
        // if (fetch.status === CREATED)
            response = fetch.data
    } catch (error) {
        response = { data: [], totalItems: 0 };
    } finally {
        return response;
    }
}