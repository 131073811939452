import React from 'react'
import PropTypes from 'prop-types'
import { Heading, useToast } from '@chakra-ui/react'
import { Divider } from 'antd'
import { Table } from 'react-bootstrap'
import { getCurrency } from '../../../../libs/functions'
import ButtonIcon from '../../../Layouts/Forms/ButtonIcon'
import { faFileDownload, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { destroyRequisitionProviderItem, downloadRequisitionProviderItem } from '../../../../api/Budget/requisition_item_providers'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import useConfirmation from '../../../../hooks/useConfirm'

const ItemsProviders = ({ items, provider_items, setOrderItems, onClose }) => {
    const [t] = useTranslation('requisition');
    const { confirmAction } = useConfirmation();
    const prefix = 'modal-details.items-list';
    const login = useSelector(state => state.login);
    const toast = useToast();
    const headers = { Authorization: `Bearer ${login.token}` };
    // Encabezados de la tabla
    const tHeaders = ['#', t(`${prefix}.description`), t(`${prefix}.detailed`), t(`${prefix}.unit`), t(`${prefix}.quantity`), t(`${prefix}.provider`),
        t(`${prefix}.unit-price`), t(`${prefix}.total`), t(`${prefix}.file`), t(`${prefix}.assign`), t(`${prefix}.drop`)];

    // Buscar si el proveedor ya subió el precio unitario de la partida
    const getUnitPrice = (item, providerItems) => {
        let unit_price = '---';
        const exists = providerItems.find(element => element.requisition_item_id === item.id)
        if (exists?.unit_price) unit_price = exists.unit_price;
        return getCurrency(unit_price);
    }
    // Obtener el total de la partida - por proveedor
    const getTotal = (item, providerItems) => {
        let total = '---'
        const exists = providerItems.find(element => element.requisition_item_id === item.id)
        if (exists?.unit_price) total = exists.unit_price * item.quantity;
        return getCurrency(total, 2)
    }
    // Método para comprobar si el proveedor adjuntó un archivo
    const getFile = (item, providerItems) => {
        let button = null;
        const exists = providerItems.find(element => element.requisition_item_id === item.id)
        if (exists) {
            if (exists.file) {
                button = <ButtonIcon icon={faFileDownload} tooltip={t(`${prefix}.download`)} onClick={() => downloadDoc(exists)} />;
            }
        }
        return button;
    }
    // Método para descargar el documento adjunto
    const downloadDoc = async (requisition_item_provider) => {
        downloadRequisitionProviderItem({
            toast, headers,
            requisition_item_provider_id: requisition_item_provider.id,
            name: requisition_item_provider.file
        });
    }
    // Obtener el radio button - deshabilitar cuando la partida ya haya sido asignada o no se hayan cargado los precios unitarios
    const getRadio = (item, provider, providerItems) => {
        const currentItem = providerItems.filter(providerItem => providerItem.requisition_item_id === item.id);
        return <input type="radio" name={item.id} value={provider?.id} onChange={handleChangeRadio}
            disabled={item.provider_id || !currentItem[0]?.unit_price} />
    }
    // Manejador de los estados de radio button
    const handleChangeRadio = (event) => {
        const { name, value } = event.target;
        setOrderItems(prevState => [
            ...prevState.filter((orderItem) => orderItem.requisition_item_id !== name),
            { requisition_item_id: name, provider_id: value }
        ]);
    }
    // Método para borrar una asignación 
    const onDeleteItem = (item, providerItems) => {
        const itemSelected = providerItems.find(providerItem => providerItem.requisition_item_id === item.id);
        const title = t(`${prefix}.del-title`);
        const html = t(`${prefix}.del-desc`);
        const confirmButtonText = t(`${prefix}.del-confirm`);

        confirmAction(title, html, confirmButtonText, async () => {
            const { res } = await destroyRequisitionProviderItem({ headers, requisition_item_provider_id: itemSelected.id });
            if (res) {
                onClose();
                Swal.fire(t(`${prefix}.del-succ-title`), t(`${prefix}.del-succ-desc`), 'success');
            } else {
                Swal.fire(t(`${prefix}.del-err-title`), t(`${prefix}.del-err-desc`), 'info');
            }
        });
    }
    const isDisabledDelete = (item) => {
        let isDisabled = true;
        const { providers, provider_id } = item;
        // Si sólo tiene una asignación no permitir borrar hasta arreglar el error
        if (providers.length > 1) isDisabled = false;
        // Si el elemento ya fue asignado a una orden de compra
        if (provider_id) isDisabled = true;
        return isDisabled;
    }

    return (
        <>
            <Divider orientation='left'>
                <Heading size='sm'>{t(`${prefix}.heading`)}</Heading>
            </Divider>
            <Table
                className='mt-4'
                striped
                bordered
                hover
            >
                <thead>
                    <tr>
                        {
                            tHeaders.map((header) =>
                                <th key={header}>{header}</th>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        Array.isArray(provider_items) && Array.isArray(items) && items.map((item, item_index) => {
                            const rowSpan = item.providers.length;
                            const firstProvider = item.providers[0];
                            let firstProviderItems = [];
                            if (firstProvider)
                                firstProviderItems = provider_items.filter(provider_item => provider_item.provider.id === firstProvider.id);
                            const restProviders = item.providers.slice(1);
                            return <>
                                <tr>
                                    <td rowSpan={rowSpan} align='center'>{item_index + 1}</td>
                                    <td rowSpan={rowSpan}>{item.description}</td>
                                    <td rowSpan={rowSpan}>{item.detailed}</td>
                                    <td rowSpan={rowSpan}>{item.unit}</td>
                                    <td rowSpan={rowSpan} align='center'>{item.quantity}</td>
                                    <td>{`${firstProvider?.business_partner_provider?.business_name}`}</td>
                                    <td align='right'>{getUnitPrice(item, firstProviderItems)}</td>
                                    <td align='right'>{getTotal(item, firstProviderItems)}</td>
                                    {/* Sección de acciones seleccionar para orden de comprar y visualizar documento */}
                                    <td align='center'>
                                        {
                                            getFile(item, firstProviderItems)
                                        }
                                    </td>
                                    <td align='center'>
                                        {
                                            getRadio(item, firstProvider, firstProviderItems)
                                        }
                                    </td>
                                    <td align='center'>
                                        <ButtonIcon
                                            icon={faTrashAlt}
                                            variant='outline-danger'
                                            onClick={() => onDeleteItem(item, firstProviderItems)}
                                            tooltipDisabled={true}
                                            disabled={isDisabledDelete(item)}
                                        />
                                    </td>
                                </tr>
                                {
                                    restProviders.map((provider) => {
                                        const currentProviderItems = provider_items.filter(provider_item => provider_item.provider.id === provider.id);
                                        return <tr key={provider.id}>
                                            <td>{provider?.business_partner_provider?.business_name}</td>
                                            <td align='right'>{getUnitPrice(item, currentProviderItems)}</td>
                                            <td align='right'>{getTotal(item, currentProviderItems)}</td>
                                            <td align='center'>
                                                {getFile(item, currentProviderItems)}
                                            </td>
                                            <td align='center'>
                                                {
                                                    getRadio(item, provider, currentProviderItems)
                                                }
                                            </td>
                                            <td align='center'>
                                                <ButtonIcon
                                                    icon={faTrashAlt}
                                                    variant='outline-danger'
                                                    onClick={() => onDeleteItem(item, currentProviderItems)}
                                                    disabled={isDisabledDelete(item)}
                                                />
                                            </td>
                                        </tr>
                                    })
                                }
                            </>
                        })
                    }
                </tbody>
            </Table>
        </>
    )
}

ItemsProviders.propTypes = {
    items: PropTypes.array.isRequired,
    provider_items: PropTypes.array.isRequired,
    setOrderItems: PropTypes.func,
    onClose: PropTypes.func.isRequired
}
export default ItemsProviders