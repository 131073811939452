import Fetcher from "../libs/Petition"

const url = '/access_control_users';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param fibra_id Se utiliza el identificador de la fibra para obtener los roles que le corresponden
 * @param page [Opcional] Se utiliza para conocer la página en la que se encuentra
 * @param pageSize [Opcional] Se utiliza para especificar el tamaño de la página, es decir, la cantidad de registros que se obtendrán.
 * @returns Object {"data" => AccessControlUser::collection(),"totalItems" => Cantidad de registros existentes}
 */
export const indexAccessControlUser = async (props) => {
    const {
        headers,
        fibra_id,
        page,
        pageSize } = props;
    let response = [];
    try {
        // Parámetros requeridos
        let params = { fibra_id };
        // Parámetros opcionales
        if (page) params.page = page;
        if (pageSize) params.pageSize = pageSize;
        const fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = {
            data: [],
            totalItems: 0
        };
    }
    return response;
}

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param page [Opcional] Se utiliza para conocer la página en la que se encuentra
 * @param pageSize [Opcional] Se utiliza para especificar el tamaño de la página, es decir, la cantidad de registros que se obtendrán.
 * @returns Object {"data" => Fibra::collection(),"totalItems" => Cantidad de registros existentes}
 */
export const indexFibras = async (props) => {
    const { page, pageSize } = props;

    let response = { data: [], totalItems: 0 };

    try {
        // Parámetros requeridos
        let params = {};
        // Parámetros opcionales
        if (page) params.page = page;
        if (pageSize) params.pageSize = pageSize;
        const fetch = await Fetcher({
            url: `${url}/fibras`,
            method: 'GET',
            params
        });
        // console.log("🚀 ~ file: AccessControlUsers.js:70 ~ indexFibras ~ fetch:", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = {
            data: [],
            totalItems: 0
        };
    }
    return response;
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param access_control_user_id Identificador del control de acceso sobre el que se hará la consulta
 * @returns AccessControlUser Retorna un objeto tipo AccessControlUser
 */
export const showAccessControlUser = async ({ headers, access_control_user_id }) => {
    let response = [];
    try {
        const fetch = await Fetcher({
            url: `${url}/${access_control_user_id}`,
            method: 'GET',
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = {};
    }
    return response;
}
/**
 * 
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storeAccessControlUser = async ({ data }) => {
    let response = true;
    try {
        const fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data
        });
        // console.log("🚀 ~ file: AccessControlUsers.js:116 ~ storeAccessControlUser ~ fetch:", fetch)
        if (fetch.status !== CREATED)
            response = {
                status: false,
                ...fetch.data
            };
        else
            response = {
                status: true,
                ...fetch.data
            };
    } catch (error) {
        response = false;
    }
    return response;
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param access_control_user_id Identificador del control de acceso que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updateAccessControlUser = async ({ headers, data, access_control_user_id }) => {
    let response = false;
    try {
        const fetch = await Fetcher({
            url: `${url}/${access_control_user_id}`,
            method: 'PUT',
            data,
            headers
        });
        // console.log("🚀 ~ file: AccessControlUsers.js:149 ~ updateAccessControlUser ~ fetch:", fetch)
        if (fetch.status === STATUS_OK)
            response = true;
    } catch (error) {
        response = false;
    }
    return response;
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param access_control_user_id Identificador del control de acceso que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyAccessControlUser = async ({ headers, access_control_user_id }) => {
    let response = true;
    try {
        const fetch = await Fetcher({
            url: `${url}/${access_control_user_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    }
    return response;
}