import Fetcher from "./Petition";
import setToast from "./SetToast";
import PropTypes from 'prop-types';

const downloadFile = async (props) => {
    const { url, headers, type, name, toast, params } = props;
    try {
        let response = await Fetcher({
            method: 'GET',
            url,
            responseType: 'blob',
            headers, params
        });
        // console.log("🚀 ~ file: DownloadFile.js:17 ~ downloadFile ~ response:", response)
        if (response.data.type === type) {
            setToast({
                title: "Descarga fallida", status: "error", toast,
                description: "El archivo que está tratando de descargar está dañado o no existe"
            });
        } else {
            const file = new Blob([response.data], {
                type: response.data.type
            });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(file);
            link.setAttribute('download', name)
            document.body.appendChild(link)
            link.click();
        }
    } catch (error) {
        setToast({
            title: "Conexión fallida",
            description: "Hubo un error al tratar de hacer la conexión",
            status: "error",
            toast
        });
    }
}

downloadFile.propTypes = {
    url: PropTypes.string,
    headers: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    toast: PropTypes.object
}

export default downloadFile;