import Fetcher from "../../libs/Petition"

const url = '/requisition_purchase_orders';
const CREATED = 201;
const STATUS_OK = 200;

export const storeRequisitionPurchaseOrder = async ({ headers, data }) => {
    let response = false;
    try {
        const fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        // console.log("🚀 ~ file: requisition_purchase_orders.js:16 ~ storeRequisitionPurchaseOrder ~ fetch:", fetch)
        if (fetch.status === STATUS_OK)
            response = true;
    } catch (error) {
        response = false;
    }
    return response;

}