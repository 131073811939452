import { Result, Button } from 'antd';
import { useEffect } from 'react';
import { Container } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { SET_ACTIVE_TAB } from '../../redux/actions/main';
import '../../styles/PermissionDenied.Component.css';

const PermissionDenied = () => {
    const history = useHistory();
    const [t] = useTranslation('global');
    const prefix = 'permission-denied';
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: prefix });
    }, []);

    const handleBack = () => {
        history.push('/');
    }

    return (
        <Container fluid id='permission-denied-component'>
            <Result
                status="403"
                title={t(`${prefix}.title`)}
                subTitle={t(`${prefix}.subtitle`)}
                extra={<Button type="primary" onClick={handleBack}>{t(`${prefix}.extra`)}</Button>}
            />
        </Container>
    );
}

export default PermissionDenied;