import PropTypes from 'prop-types';

export const hookPaginationPropType = {
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    pagination: PropTypes.shape({
        page: PropTypes.number.isRequired,
        pageSize: PropTypes.number.isRequired,
        totalItems: PropTypes.number.isRequired,
    }).isRequired,
    dataSel: PropTypes.shape({
        id: PropTypes.number.isRequired
    }).isRequired,
    changePage: PropTypes.func.isRequired,
    setDataSel: PropTypes.func.isRequired
}

export const hookPreviewType = {
    blobFile: PropTypes.object,
    showPreview: PropTypes.bool,
    loading: PropTypes.bool,
    setBlobFile: PropTypes.func,
    setShowPreview: PropTypes.func,
    setLoading: PropTypes.func,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
}