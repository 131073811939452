import { faBoxes, faFileCode, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCfdiStatuses } from "../../api/Cfdi/CfdiStatuses";
import { downloadInvoicePdf, downloadInvoiceXml, getInvoices } from "../../api/Cfdi/Mailbox";
import { SET_ACTIVE_TAB } from "../../redux/actions/main";
import Mailbox from "./fragments/Mailbox"
import ModalCfdi from "./fragments/invoices/ModalCfdi";
import usePagination from "../../hooks/usePagination";
import { useToast } from "@chakra-ui/react";

const MailboxAdmin = () => {
    const login = useSelector(state => state.login);
    const { fibra, role_selected, roles, token } = login;
    const headers = { Authorization: `Bearer ${token}` };
    const toast = useToast();

    const [modalCfdi, setModalCfdi] = useState(false);      // Modal para cambiar permisos CFDI
    // Paginación
    const hookInvoices = usePagination({ defaultPageSize: 10 });
    const hookCfdiStatuses = usePagination({ defaultPageSize: 50 });
    const [t] = useTranslation('global');
    const [filters, setFilters] = useState({ cfdi_status_id: null });
    const dispatch = useDispatch();
    const { page, pageSize } = hookInvoices.pagination;
    const [loadingPdf, setLoadingPdf] = useState([]);
    const [loadingXml, setLoadingXml] = useState([]);

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/mailbox/admin' });
    }, []);

    // Obtener estados cfdi
    useEffect(() => {
        const getCfdis = async () => {
            const response = await getCfdiStatuses({ headers, fibra_id: fibra.id });
            hookCfdiStatuses.setData(response);
            hookCfdiStatuses.setLoading(false);
        }
        getCfdis();
    }, []);
    // Obtener las facturas
    useEffect(() => {
        const getMailbox = async () => {
            hookInvoices.setLoading(true);
            let role_id = role_selected.id;
            if (!role_id) {
                if (roles.length > 0) role_id = roles[0].id
            }

            const params = { headers, page, pageSize, ...filters };
            const response = await getInvoices({
                ...params, fibra_id: fibra.id,
                role_id
            });
            hookInvoices.setData(response.data);
            hookInvoices.setTotalItems(response.totalItems);
            hookInvoices.setLoading(false);
        }
        getMailbox();
    }, [page, pageSize, filters]);

    const changeStatus = (item) => {
        hookInvoices.setDataSel(item);
        setModalCfdi(true);
    }
    // Obtener nombre del archivo a descargar
    const getNamefile = (item) => {
        const { cfdi_status, rfc_receptor, rfc_issuing, } = item;
        return `${cfdi_status}_${rfc_issuing}_${rfc_receptor}`
    }
    // Descargar factura en pdf
    const downloadPdf = async (item) => {
        const name = getNamefile(item);
        setLoadingPdf(prevState => [...new Set([...prevState, item.id])]);
        await downloadInvoicePdf({ headers, invoice_id: item.id, name, toast });
        setLoadingPdf(prevState => prevState.filter(id => id !== item.id));
    }
    // Descargar factura en xml
    const downloadXml = async (item) => {
        const name = getNamefile(item);
        setLoadingXml(prevState => [...new Set([...prevState, item.id])]);
        await downloadInvoiceXml({ headers, invoice_id: item.id, name, toast });
        setLoadingXml(prevState => prevState.filter(id => id !== item.id));
    }
    // Comprobar si un botón está cargando el pdf
    const getLoadingPdf = (item) => loadingPdf.includes(item.id)
    // Comprobar si un botón está cargando el xml
    const getLoadingXml = (item) => loadingXml.includes(item.id)

    return (
        <Fragment>
            <Mailbox
                is_admin
                hookInvoices={hookInvoices}
                tactions={[{
                    handleClick: changeStatus,
                    icon: faBoxes,
                    tooltip: t('mailbox.table.tooltips.change-cfdi'),
                    variant: 'outline-secondary',
                }, {
                    handleClick: downloadPdf,
                    icon: faFilePdf,
                    tooltip: t('mailbox.table.tooltips.pdf-cfdi'),
                    variant: 'outline-danger',
                    getDisabled: getLoadingPdf
                }, {
                    handleClick: downloadXml,
                    icon: faFileCode,
                    tooltip: t('mailbox.table.tooltips.xml-cfdi'),
                    variant: 'outline-success',
                    getDisabled: getLoadingXml
                }]}
                filters={filters}
                setFilters={setFilters}
            />

            {/* Modal para cambiar permisos CFDI */}
            {
                modalCfdi && <ModalCfdi
                    onClose={() => setModalCfdi(false)}
                    invoice={hookInvoices.dataSel}
                    cfdiStatuses={hookCfdiStatuses.data}
                    headers={headers}
                    setData={hookInvoices.setData}
                />
            }
        </Fragment>
    );
}

export default MailboxAdmin;