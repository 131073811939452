import { Alert, AlertIcon, AlertDescription, useToast } from '@chakra-ui/react'
import { faSave, faSignInAlt, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { storeAccessControlUser } from "../../api/AccessControlUsers";
import { showBlacklist } from "../../api/Blacklist";
import { indexProviderDocuments } from "../../api/ProviderDocuments";
import { storeProviderDocumentReceiveds } from "../../api/ProvidersDocumentsReceiveds";
import setToast from "../../libs/SetToast";
import ButtonIcon from "../Layouts/Forms/ButtonIcon";
import StatusResponse from "../Layouts/StatusResponse";
import TabsComp from "../Layouts/Tabs";
import Data from "./fragments/Data";
import Files from "./fragments/Files";
import PropTypes from 'prop-types';

const _ = require('underscore');

const Signup = (props) => {
    const { fibra_id, goBack } = props;

    const toast = useToast();
    const [t] = useTranslation('global');
    const prefix = 'signup.tabs';
    const p_toasts = 'login.signup.toasts';
    // Datos de usuario
    const [data, setData] = useState({});
    // Archivos del proveedor
    const [files, setFiles] = useState({});         // Archivos que se solicitan
    const [userFiles, setUserFiles] = useState([]); // Documentos que el usuario carga
    const [loading, setLoading] = useState(true);   // Cambia a false cuando se obtienen los documentos solicitados
    const [loadingButton, setLoadingButton] = useState(false);

    const [statusOperation, setStatusOperation] = useState(false);
    const [validRfc, setValidRfc] = useState(true);
    const [dataValidRfc, setDataValidRfc] = useState({});

    useEffect(() => {
        cleanData();
    }, []);

    useEffect(() => {
        getFiles();
    }, [fibra_id]);

    const getFiles = async () => {
        let response = await indexProviderDocuments({ fibra_id });
        setFiles(response.data);
        setLoading(false);
    }

    const handleRfc = async (e) => {
        let { name, value } = e.target;
        setData({ ...data, [name]: value });
        setValidRfc(true);
        if (value.length === 12 || value.length === 13) {
            let response = await showBlacklist({ rfc: value });
            if (response) {
                setDataValidRfc(response);
                setValidRfc(false);
            }
        }
    }

    const handleChange = (e) => {
        let { name, value } = e.target;
        setData({ ...data, [name]: value });
    }
    // Validación de datos de proveedor
    const isValidData = () => {
        let response = true;
        if (
            data.first_name === '' || data.first_name === undefined
            || data.last_name === '' || data.last_name === undefined
            || data.tax_id === '' || data.tax_id === undefined
            || data.business_name === '' || data.business_name === undefined
            || data.alias === '' || data.alias === undefined
            || data.person_type === '' || data.person_type === undefined
            || data.email === '' || data.email === undefined
            || data.phone_number === '' || data.phone_number === undefined
        ) {
            response = false;
        }
        return response;
    }
    // Validación de documentos de proveedor
    const isValidDocuments = () => {
        let response = true;
        files.map(file => {
            let pos = _.findIndex(userFiles, { index: file.id });
            if (pos === -1) {
                response = false;
            }
        });
        return response;
    }
    // Limpiar datos del formulario
    const cleanData = () => {
        setUserFiles([]);
        setData({
            person_type: 1,
            fibra_id
        });
    }
    // Método para almacenar la solicitud de acceso de proveedores no registrados
    const save = async () => {
        // Verificar que los datos está completos
        setLoadingButton(true);
        if (isValidData()) {
            // Verificar que se subieron todos los archivos
            if (isValidDocuments()) {
                // Hacer la carga en el servidor de datos y documentos
                let transactionId;
                try {
                    let responseData = await storeAccessControlUser({ data: { ...data, fibra_id } });
                    if (responseData.status) {
                        transactionId = responseData.data.id;           // Identificador de los datos almacenados
                        userFiles.map(async (userFile) => {
                            let form_data = new FormData();                             // Petición de tipo form data
                            form_data.append('file', userFile.file);                    // Concatenamos el archivo
                            form_data.append('provider_document_id', userFile.index);   // Identificador del documento guardado
                            form_data.append('access_control_user_id', transactionId);  // Identificador de la peteción a la que se asigna el documento
                            await storeProviderDocumentReceiveds({ data: form_data });

                        });
                        setToast({      // Notificación de estado satisfactorio
                            title: t(`${p_toasts}.successfully.title`), toast, status: 'success',
                            description: t(`${p_toasts}.successfully.description`)
                        });
                        cleanData();
                        setLoadingButton(false);
                        setStatusOperation(true);
                        // Limpiar formulario
                    }
                } catch (error) {
                    setToast({
                        title: t(`${p_toasts}.error.title`), toast, status: 'error',
                        description: t(`${p_toasts}.error.description`)
                    });
                    setLoadingButton(false);
                }
            } else { // Los documentos no son válidos o están incompletos
                setToast({
                    title: t(`${p_toasts}.docs-warning.title`), toast, status: 'warning',
                    description: t(`${p_toasts}.docs-warning.description`)
                });
                setLoadingButton(false);
            }
        } else { // Los datos no son válidos o están incompletos
            setToast({
                title: t(`${p_toasts}.data-warning.title`), toast, status: 'warning',
                description: t(`${p_toasts}.data-warning.description`)
            });
            setLoadingButton(false);
        }
    }

    const redirectTo = () => goBack();

    return (
        <Fragment>
            {
                statusOperation ?
                    <StatusResponse
                        title={t('signup.data.response-title')}
                        subTitle={t('signup.data.response-subtitle')}
                        extra={[
                            <ButtonIcon key='' icon={faSignInAlt} name={t('signup.data.button-login')}
                                variant='outline-success' onClick={redirectTo} />
                        ]}
                    />
                    : <Fragment>
                        <TabsComp
                            tablist={[t(`${prefix}.data`), t(`${prefix}.documents`)]}
                            tabpanels={[
                                // Formulario para obtener datos del proveedor
                                <Data
                                    key=''
                                    data={data}
                                    handleChange={handleChange}
                                    handleRfc={handleRfc}
                                />,
                                // Lista de documentos solicitados para su aprobación
                                <Files
                                    key=''
                                    files={files}
                                    loading={loading}
                                    userFiles={userFiles}
                                    setUserFiles={setUserFiles}
                                />
                            ]}
                        />
                        {
                            !validRfc ?
                                <Alert status='error'>
                                    <AlertIcon />
                                    <AlertDescription>
                                        {t(`signup.data.the-taxpayer`)}{' '}<strong>{dataValidRfc?.taxpayer_name}</strong>{' '}{t(`signup.data.taxpayer-status`)} <strong>{dataValidRfc.taxpayer_status}</strong>.
                                        {t(`signup.data.taxpayer-text`)}.
                                    </AlertDescription>
                                </Alert> : null
                        }
                        <div className='d-flex justify-content-end mt-3'>
                            <ButtonIcon
                                icon={loadingButton ? faSpinner : faSave}
                                name={loadingButton ? t(`signup.data.button-load`) : t(`signup.data.button-save`)}
                                variant='outline-success'
                                onClick={save}
                                disabled={loadingButton || !validRfc}
                            />
                        </div>
                    </Fragment>
            }
        </Fragment>
    );
}

Signup.propTypes = {
    fibra_id: PropTypes.number.isRequired,
    goBack: PropTypes.func.isRequired
}

export default Signup