import Fetcher from "../../libs/Petition"

const url = '/requisition_items';
const CREATED = 201;
const STATUS_OK = 200;

export const updateRequisitionItems = async ({ headers, data }) => {
    let response = false;
    try {
        const fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        // console.log("🚀 ~ file: requisition_items.js:16 ~ updateRequisitionItems ~ fetch:", fetch)
        if (fetch.status === STATUS_OK)
            response = true;
    } catch (error) {
        response = false;
    }
    return response;

}

// Método para actualizar la descripción de una partida presupestaría
export const updateRequisitionItem = async ({ headers, data, requisition_item_id }) => {
    let response = false;
    try {
        const fetch = await Fetcher({
            url: `${url}/${requisition_item_id}`,
            method: 'PUT',
            data,
            headers
        });
        // console.log("🚀 ~ file: requisition_items.js:16 ~ updateRequisitionItem ~ fetch:", fetch)
        if (fetch.status === STATUS_OK){
            response = fetch.data;
        }
        return response;
    } catch (error) {
        return response;
    }
}
