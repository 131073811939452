import Fetcher from "../../libs/Petition";

const url = '/quizzs';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param fibra_id Se utiliza el identificador de la fibra para obtener las encuestas que puede ver el administrador
 * @param role_id [Opcional] Se usa para obtener únicamente los registros de un role
 * @param user_id [Opcional] Se usa para obtener únicamente los registros de un usuario
 * @param page [Opcional] Se utiliza para conocer la página en la que se encuentra
 * @param pageSize [Opcional] Se utiliza para especificar el tamaño de la página, es decir, la cantidad de registros que se obtendrán.
 * @param active [Opcional] Indica las encuestas que se van a filtrar, activas o inactivas
 * @returns Object {"data" => Quizz::collection(),"totalItems" => Cantidad de registros existentes}
 */
export const indexQuizz = async (props) => {
    const {
        headers,
        fibra_id,
        role_id,
        page,
        pageSize,
        type,
        active } = props;
    let response = [];
    try {
        // Parámetros requeridos
        let params = { fibra_id };
        // Parámetros opcionales
        if (role_id) params.role_id = role_id;
        if (type) params.type = type;
        if (page) params.page = page;
        if (pageSize) params.pageSize = pageSize;
        if (active) params.active = active;
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        // console.log("index quizz: ", fetch);
        if (fetch.status === STATUS_OK)
            response = fetch.data;
    } catch (error) {
        response = {
            data: [],
            totalItems: 0
        };
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param quizz_id Identificador de la encuesta sobre el que se hará la consulta
 * @returns Incident Retorna un objeto tipo Incident
 */
export const showQuizz = async ({ headers, quizz_id }) => {
    let response = [];
    try {
        let fetch = await Fetcher({
            url: `${url}/${quizz_id}`,
            method: 'GET',
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data.data;
    } catch (error) {
        response = {};
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storeQuizz = async ({ headers, data }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        if (fetch.status !== CREATED)
            response = fetch.data;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param quizz_id Identificador de la encuesta que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updateQuizz = async ({ headers, data, quizz_id }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}/${quizz_id}`,
            method: 'PUT',
            data,
            headers
        });
        if (fetch.status === STATUS_OK)
            response = true;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param quizz_id Identificador de la encuesta que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyQuizz = async ({ headers, quizz_id }) => {
    let response = true;
    try {
        let fetch = await Fetcher({
            url: `${url}/${quizz_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status !== STATUS_OK)
            response = false;
    } catch (error) {
        response = false;
    } finally {
        return response;
    }
}