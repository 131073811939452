import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormIcon from "../../Layouts/Forms/FormIcon";

const LocalData = (props) => {
    const { local_mall } = props;
    const [t] = useTranslation('global');
    const prefix = 'contracts.modal-details.local-data';
    return (
        <Form>{/*Form de datos del local*/}
            <Row className='mb-3'>
                <FormIcon type='text' title={t(`${prefix}.local-code`)} name='local_code' value={local_mall?.code} />
                <FormIcon type='text' title={t(`${prefix}.local-name`)} name='loca_name' value={local_mall?.name} />
                <FormIcon type='text' title={t(`${prefix}.flat`)} name='local_piso' value={local_mall?.flat} />
            </Row>
            <Row className='mb-3'>
                <FormIcon type='text' title={t(`${prefix}.m2`)} name='m2' value={local_mall?.m2} />
                <FormIcon type='text' icon={'$'} title={t(`${prefix}.price-m2`)} name='price_m2' value={local_mall?.price_by_m2} />
                <FormIcon type='text' icon={'$'} title={t(`${prefix}.price-total`)} name='price_total' value={local_mall?.price_total} />
            </Row>
            <Row>
                <Col>
                    <label>{t(`${prefix}.additional-notes`)}</label>
                    <textarea className='form-control' value={local_mall?.aditional_notes}></textarea>
                </Col>
            </Row>
        </Form>
    );
}

export default LocalData