import {
    faBoxes,
    faBoxTissue,
    faBuilding,
    faBullhorn,
    faCheckDouble,
    faCloudDownloadAlt,
    faCogs,
    faCoins,
    faComments,
    faDolly,
    faExclamationTriangle,
    faFileContract,
    faFileInvoiceDollar,
    faFileSignature,
    faGavel,
    faMailBulk,
    faPoll,
    faProjectDiagram,
    faStoreAlt,
    faTasks,
    faTicketAlt,
    faToolbox,
    faUserCheck,
    faWallet,
    faWarehouse
} from "@fortawesome/free-solid-svg-icons";

const adminPaths = [
    { code: 'CFDI', name: 'mailbox', to: '/mailbox/admin', icon: faMailBulk },
    { code: 'X-ERP', name: 'erp', to: '/erp/sap', icon: faToolbox },
    { code: 'D-CFD', name: 'bulk-download', to: '/bulk-download', icon: faCloudDownloadAlt },
    { code: 'X-INV', name: 'invoicing', to: '/electronic-billing', icon: faFileInvoiceDollar },
    { code: 'C-INC', name: 'incidents-control', to: '/incidents-control', icon: faExclamationTriangle },
    { code: 'P-REQ', name: 'requisitions', to: '/requisitions', icon: faTasks },
    { code: 'A-REQ', name: 'requisitions-approval', to: '/requisitions-approval', icon: faCheckDouble },
    { code: 'X-SEG', name: 'segments', to: '/segments', icon: faProjectDiagram },
    { code: 'O-COM', name: 'purchase-orders', to: '/purchase-order', icon: faWallet },
    { code: 'C-DIS', name: 'cedis', to: '/cedis/admin', icon: faBoxes },
    { code: 'C-DEL', name: 'cedis-deliveries', to: '/cedis/deliveries', icon: faDolly },
    { code: 'I-INV', name: 'inventory', to: '/inventory', icon: faWarehouse },
    { code: 'X-MSG', name: 'messages', to: '/chat-admin', icon: faComments },
    { code: 'U-REP', name: 'repse', to: '/repse-admin', icon: faFileSignature },
    { code: 'C-COM', name: 'malls', to: '/malls', icon: faBuilding },
    { code: 'R-LOC', name: 'local-rent', to: '/mall/local/admin', icon: faStoreAlt },
    { code: 'X-CON', name: 'contracts', to: '/contracts', icon: faFileContract },
    { code: 'X-COM', name: 'releases', to: '/releases', icon: faBullhorn },
    { code: 'B-QSU', name: 'complaint-suggestions', to: '/complaints-suggestions', icon: faBoxTissue },
    { code: 'X-ENC', name: 'quizzs', to: '/polls', icon: faPoll },
    { code: 'X-LIC', name: 'tenders', to: '/tenders', icon: faGavel },
    { code: 'C-ACC', name: 'access-control', to: '/access-control', icon: faUserCheck },
    { code: 'X-CUP', name: 'coupons', to: '/coupons', icon: faTicketAlt },
    { code: 'F-PLA', name: 'financial-planning', to: '/financial-planning', icon: faCoins },
    { code: 'X-SET', name: 'settings', to: '/settings', icon: faCogs },
];

export default adminPaths;