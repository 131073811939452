import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SET_ACTIVE_TAB } from "../../redux/actions/main";
import FlexButton from "../Layouts/FlexButton";
import AddRelease from "./fragments/AddRelease";
import ReleasesTable from "./fragments/Table";
import useReleases from '../../hooks/useReleases';

const ReleasesAdmin = () => {
    const login = useSelector(state => state.login);
    const headers = { Authorization: `Bearer ${login.token}` };
    const fibra_id = login.fibra.id;

    const [t] = useTranslation('global');
    const prefix = 'releases.data';

    const hookReleases = useReleases({ headers, fibra_id });

    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();

    // Al cargar el componente, establece la pestaña activa
    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/releases' });
    }, [dispatch]);

    const addRelease = () => setShowModal(true);

    return (
        <Fragment>
            <ReleasesTable
                theaders={[
                    '#',
                    t(`${prefix}.title`),
                    t(`${prefix}.status`),
                    t(`${prefix}.created_at`),
                    t(`${prefix}.actions`)
                ]}
                tkeys={['#', 'title', 'active', 'created_at']}
                tbadges={[{ index: 2, color: 'color_active' }]}
                hookReleases={hookReleases}
                moment_dates={[{ index: 3, format: 'LLLL' }]}
            />
            {/* Botón flotante para agregar un comunicado */}
            <FlexButton style={{ bottom: 20, right: 20 }} onClick={addRelease} />

            {/* Modal para agregar comunicado */}
            {showModal && (
                <AddRelease
                    onClose={() => setShowModal(false)}
                    update={hookReleases.addElement} // Actualiza la tabla al agregar un nuevo release
                />
            )}
        </Fragment>
    );
};

export default ReleasesAdmin;
