import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from "react";
import { getCfdiStatuses } from "../../../api/Cfdi/CfdiStatuses";
import { destroyCfdiStatusRole, getCfdiStatusRoles, storeCfdiStatusRole } from "../../../api/Cfdi/CfdiStatusRoles";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import ModalComp from "../../Layouts/Modal";
import TableComp from "../../Layouts/Table";
import _ from 'underscore';
import { Alert, AlertIcon } from "@chakra-ui/alert";
import { Heading } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/react";
import setToast from "../../../libs/SetToast";

const ModalStatuses = (props) => {
    const { onClose, role, headers, fibra_id } = props;
    const [statuses, setStatuses] = useState([]);
    const [role_statuses, setRoleStatuses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingOperation, setLoadingOperation] = useState(false);
    const toast = useToast();

    useEffect(() => {
        getStatusesData();
    }, []);

    // Se obtienes los status cfdi de la fibra, y los status a los que tiene acceso el rol seleccionado
    const getStatusesData = async () => {
        let data = await getCfdiStatuses({ headers, fibra_id });
        setStatuses(data);
        data = await getCfdiStatusRoles({ headers, role_id: role.id });
        setRoleStatuses(data);
        setLoading(false); // Se puede mostrar la información actualizada
    }

    // Se actualizar la lista de status cfdi a la que tiene acceso el rol seleccionado
    const handleChange = async (checked, cfdi_status_id) => {
        setLoadingOperation(true);
        if (checked) { // Se agrega la relación
            await storeCfdiStatusRole({ headers, data: { role_id: role.id, cfdi_status_id } });
        } else { // Se elimina la relación
            let pos = _.findIndex(role_statuses, { cfdi_status_id });
            if (pos !== -1) // Si el registro se encuentra se elimina
                await destroyCfdiStatusRole({ headers, cfdi_status_role_id: role_statuses[pos].id });
        }
        setLoadingOperation(false);
    }
    // Evitar que se cierre el modal cuando una operación se está llevando a cabo
    const onExit = () => {
        if (loadingOperation) {
            setToast({ toast, status: 'warning', title: 'Hay operaciones pendientes',
            description: 'No se puede cerrar el modal debido a que no se han terminado de ejacutar algunas operaciones.'
        });
        } else {
            onClose();
        }
    }

    // Se obtiene cuales son los status cfdi que ya pertenecen al rol
    const getChecked = (cfdi_status_id) => {
        let pos = _.findIndex(role_statuses, { cfdi_status_id });
        return pos !== -1;
    }

    const getDisabled = () => {
        return false;
    }
    return (
        <ModalComp
            onClose={onClose}
            title={`Rol: ${role?.name}`}
            body={<Fragment>
                <Heading size='md' className='mb-3'>Estados CFDi</Heading>
                <TableComp
                    headers={['#', 'Estatus CFDi', 'Activar']}
                    keys={['id', 'name']}
                    body={statuses}
                    loading={loading}
                    actions={{
                        checkedChildren: 'Activado',
                        unCheckedChildren: 'Desactivado',
                        onChange: handleChange,
                        getChecked: getChecked,
                        getDisabled: getDisabled
                    }}
                    type='switch'
                />
                <Alert status="info">
                    <AlertIcon />Lista de estados CFDi a los que tiene acceso el "{role?.name}".
                </Alert>
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon icon={faArrowLeft} name='Cerrar' variant='outline-secondary' onClick={onClose} />
                {/* <ButtonIcon icon={faSave} name='Guardar y cerrar' variant='outline-primary' onClick={onClose} /> */}
            </Fragment>}
        />
    );
}

export default ModalStatuses;