import PropTypes from 'prop-types';
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { indexBusinessName } from "../../../api/Cfdi/BusinessNames";
import FlexButton from "../../Layouts/FlexButton";
import PaginationComp from "../../Layouts/Pagination";
import TableComp from "../../Layouts/Table"
import NewBusinessName from "./NewBusinessName";
import UpdateBusinessName from "./UpdateBusinessName";
import usePagination from '../../../hooks/usePagination';

const BusinessName = ({ headers, fibra_id }) => {
    const [modalAdd, setModalAdd] = useState(false);
    const [modalUpdate, setModalUpdate] = useState(false);
    const hookBusinessNames = usePagination({ defaultPageSize: 10 });
    const { page, pageSize, totalItems } = hookBusinessNames.pagination;
    const [t] = useTranslation('bulk_download');

    // Obtener las razones sociales
    useEffect(() => {
        const getBusinessNames = async () => {
            hookBusinessNames.setLoading(true);
            const response = await indexBusinessName({ headers, fibra_id, page, pageSize });
            hookBusinessNames.setData(response.data);
            hookBusinessNames.setTotalItems(response.totalItems);
            hookBusinessNames.setLoading(false);
        }
        getBusinessNames();
    }, [page, pageSize]);

    const updateFiel = (item) => {
        hookBusinessNames.setDataSel(item);
        setModalUpdate(true);
    }

    return (
        <Fragment>
            <TableComp
                headers={[
                    '#',
                    t('business-name-table.name'),
                    t('business-name-table.tax-id'),
                    t('business-name-table.has-fiel'),
                    t('business-name-table.created-at'),
                    t('business-name-table.updated-at'),
                    t('business-name-table.actions'),
                ]}
                keys={['#', 'name', 'rfc', 'has_fiel', 'created_at', 'updated_at']}
                body={hookBusinessNames.data}
                loading={hookBusinessNames.loading}
                loadingItems={pageSize}
                page={page}
                actions={[
                    { icon: faFileUpload, tooltip: t('business-name-table.tt-update'), variant: 'outline-success', handleClick: updateFiel }
                ]}

                moment_dates={[{ index: 4, format: 'LLLL' }, { index: 5, format: 'LLLL' }]}
                badges={[{ index: 3 }]}
                badges_values={[{
                    col: 'has_fiel',
                    values: [
                        { name: t('business-name-table.bv-complete'), value: 'Completa', color: "#1399a7" },
                        { name: t('business-name-table.bv-incomplete'), value: 'Incompleta', color: "#e6890b" }
                    ]
                }]}
            />
            <PaginationComp current={page} onChange={hookBusinessNames.changePage} totalItems={totalItems} />
            {/* Agregar nueva razón social */}
            <FlexButton style={{ bottom: 20, right: 20 }} onClick={() => setModalAdd(true)} />
            {
                modalAdd && <NewBusinessName
                    onClose={() => setModalAdd(false)}
                    headers={headers}
                    addElement={hookBusinessNames.addElement}
                    fibra_id={fibra_id}
                />
            }
            {/* Actualizar archivos CER y KEy de la FIEL */}
            {
                modalUpdate && <UpdateBusinessName
                    onClose={() => setModalUpdate(false)}
                    headers={headers}
                    business_name_id={hookBusinessNames.dataSel.id}
                    setData={hookBusinessNames.setData}
                />
            }
        </Fragment>
    )
}

BusinessName.propTypes = {
    headers: PropTypes.string.isRequired,
    fibra_id: PropTypes.number.isRequired
}

export default BusinessName