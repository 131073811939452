import { Heading } from "@chakra-ui/layout";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import TabsComp from "../../Layouts/Tabs";
import Guests from "./Guests";
import Users from "./Users";

const Main = () => {
    const [t] = useTranslation('global');

    return (
        <Fragment>
            <Heading>{t('access-control.title')}</Heading>
            <TabsComp
                tablist={[
                    t('access-control.tabs.users'),
                    t('access-control.tabs.guests'),
                ]}
                tabpanels={[
                    <Users key='ac-users' />,
                    <Guests key='ac-guests' />,
                ]}
            />
        </Fragment>
    );
}

export default Main