import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Tree } from 'antd';
import { hookPaginationPropType } from '../../../data/propTypes';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import { faCogs, faSave, faTrashRestore } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useConfirmation from '../../../hooks/useConfirm';
import { updateUserRoles } from '../../../api/UserRoles';
import Swal from 'sweetalert2';
import { storeUserBusinessNames } from '../../../api/Cfdi/UserBusinessNames';

const TreeBusinessNames = ({
	hookUserBusinessNames,
	hookSegments,
	hookBusinessNames,
	userRole,
	hookUserRoles
}) => {
	const [t] = useTranslation('settings');
	const p = 'users.modal-user-bn';
	const token = useSelector(state => state.login.token);
	const headers = { Authorization: `Bearer ${token}` };
	const { confirmAction } = useConfirmation();
	const { user_id, role_id } = userRole;
	const [disabledTree, setDisabledTree] = useState(true); 			// Permisos editables
	const [checkedKeys, setCheckedKeys] = useState([]);					// Permisos seleccionados (editable)
	const [halfCheckedKeys, setHalfCheckedKeys] = useState([]);         // Permisos parcialmente seleccionados
	const [expandedKeys, setExpandedKeys] = useState(['']);             // Permisos con hijos que están expandidos
	const [treeData, setTreeData] = useState([]);
	const [currentData, setCurrentData] = useState({ segments: [], business_names: [] });

	// En esto efecto se puede decidir si el usuario tiene permisos para modificar esta sección
	useEffect(() => {
		if (userRole.custom_conf) {
			setDisabledTree(false);
		} else {
			setDisabledTree(true);
		}
	}, [userRole.custom_conf]);
	// Obtener el árbol de las razones sociales con sus segmentos
	useEffect(() => {
		if (!hookBusinessNames.loading && !hookSegments.loading) {
			const businessNames = hookBusinessNames.data;
			const segments = hookSegments.data;
			setTreeData(
				// Mostrar razones sociales
				businessNames.map(businessName => {
					return {
						title: `${businessName.name} - ${businessName.rfc}`,
						key: businessName.id,
						// Se muestran como hijos los segmentos
						children: segments
							// Obtener los segmentos relacionados
							.filter(segment => segment.business_name_id === businessName.id)
							.map(segment => {
								return {
									title: `${segment.code} - ${segment.name}`,
									// Es importante seguir la nomenclatura del key `${businessName.id}-${segment.id}`
									// para que se puedan mostrar de forma anidada
									key: `${businessName.id}-${segment.id}`
								}
							})
					}
				})
			);
		}
	}, [hookBusinessNames.loading, hookSegments.loading]);
	// Mostrar desglosadas las opciones
	useEffect(() => {
		if (!hookBusinessNames.loading) {
			setExpandedKeys(hookBusinessNames.data.map(businessName => businessName.id));
		}
	}, [hookBusinessNames.loading]);
	// Obtener las configuraciones actuales
	useEffect(() => {
		const getDefaultConfigs = async () => {
			const { segments, business_names } = userRole;
			setCurrentData({ segments, business_names });
			const checked = segments.map(segment => {
				return `${segment.business_name_id}-${segment.id}`
			})
			setCheckedKeys(checked)
		}
		const getCustomConfigs = async () => {
			const currentConfigs = hookUserBusinessNames.data.find(element => element.role.id === role_id);
			if (currentConfigs.segments && currentConfigs.business_names) {
				setCurrentData({
					segments: currentConfigs.segments,
					business_names: currentConfigs.business_names
				});
				setCheckedKeys(currentConfigs.segments.map(segment => {
					return `${segment.business_name_id}-${segment.segment_id}`
				}))
			}
		}
		if (userRole.custom_conf) {
			// Obtener configuraciones personalizadas
			getCustomConfigs();
		} else {
			// Obtener configuraciones predeterminadas
			getDefaultConfigs();
		}
	}, [hookUserBusinessNames.data]);
	// Evento que se lanza al seleccionar algun checkbox
	const onCheck = (checkedKeysValue, e) => {
		setHalfCheckedKeys(e.halfCheckedKeys);
		setCheckedKeys(checkedKeysValue);
	};
	// Evento para expandir las opciones
	const onExpand = (expandedKeys) => setExpandedKeys(expandedKeys);
	// Se guarda la nueva configuración
	const onSave = async () => {
		if (hasChange()) {
			const business_names_segments = getBusinessNamesSegments();
			const data = { user_id, role_id, ...business_names_segments };
			const fetch = await storeUserBusinessNames({ headers, data });
			if (fetch) {
				updateHookUserBusinessNames(fetch?.user);
				Swal.fire(t(`${p}.sf-success-store.title`), t(`${p}.sf-success-store.msg`), 'success');
			} else {
				Swal.fire(t(`${p}.sf-fail.title`), t(`${p}.sf-fail.msg`), 'info');
			}
		}
	}
	// Obtenemos el arreglo de identificadores de las opciones seleccionadas
	const getBusinessNamesSegments = () => {
		const checkedBusinessNamesSegments = [...halfCheckedKeys, ...checkedKeys];
		const checkedSegments = checkedBusinessNamesSegments
			.filter(item => item.toString().includes('-'))
			.map(item => {
				const arrSplt = item.toString().split('-');
				return arrSplt[1]
			});
		const checkedBusinessNames = checkedBusinessNamesSegments
			.filter(item => !item.toString().includes('-'))
			.map(business_name_id => business_name_id);
		return {
			business_names: checkedBusinessNames,
			segments: checkedSegments
		}
	}
	// Método que se encarga de verificar si la configuración ha cambiado
	const hasChange = () => {
		const treeData = getBusinessNamesSegments();
		if (treeData.segments.length !== currentData.segments.length) {
			return true;
		}
		// Verificamos si los segment_id seleccionados son diferentes de los actuales
		const currentIds = currentData.segments.map(item => item.segment_id);
		for (const id of treeData.segments) {
			// Convertimos a entero, ya que el componente maneja las keys como cadena
			if (!currentIds.includes(parseInt(id))) {
				return true;
			}
		}
		return false
	};
	// Activar personalización de la configuración
	const onCustom = () => {
		const title = t(`${p}.confirm-custom.title`);
		const html = t(`${p}.confirm-custom.html`);
		const confirmButtonText = t(`${p}.confirm-custom.btn`);

		confirmAction(title, html, confirmButtonText, async () => {
			const fetch = await updateUserRoles({ headers, data: { custom_conf: true }, user_role_id: userRole.id });
			if (fetch) {
				// Actualizar a configuración personalizada
				updateHookUserRoles(true);
				Swal.fire(t(`${p}.sf-success-update.title`), t(`${p}.sf-success-update.msg`), 'success');
			} else {
				Swal.fire(t(`${p}.sf-fail.title`), t(`${p}.sf-fail.msg`), 'info');
			}
		});
	}
	// Restablecer las configuraciones por default
	const onDefault = () => {
		const title = t(`${p}.confirm-default.title`);
		const html = t(`${p}.confirm-default.html`);
		const confirmButtonText = t(`${p}.confirm-default.btn`);

		confirmAction(title, html, confirmButtonText, async () => {
			const fetch = await updateUserRoles({ headers, data: { custom_conf: false }, user_role_id: userRole.id });
			if (fetch) {
				// Actualizar a configuración por default
				updateHookUserRoles(false);
				Swal.fire(t(`${p}.sf-success-update-default.title`), t(`${p}.sf-success-update-default.msg`), 'success');
			} else {
				Swal.fire(t(`${p}.sf-fail.title`), t(`${p}.sf-fail.msg`), 'info');
			}
		});
	}
	// Actualizar userRoles
	const updateHookUserRoles = (isCustom) => {
		hookUserRoles.setData(prevState => prevState.map(element => {
			if (element.id === userRole.id) {
				element = { ...element, custom_conf: isCustom }
			}
			return element;
		}));
	}
	// Actualizar hook UserBusinessNames
	const updateHookUserBusinessNames = (updateResponse) => {
		hookUserBusinessNames.setData(prevState => prevState.map(element => {
			if (element.role.id === role_id) {
				element.segments = getHookSegmentStructure(updateResponse?.segments || []);
				element.business_names = updateResponse.business_names;
			}
			return element;
		}));
	}
	// Crear estructura de datos del hook Segments
	const getHookSegmentStructure = (segmentsUpdated) => {
		return segmentsUpdated
			.filter(segment => segment.pivot.role_id === role_id)
			.map(segment => {
				const { business_name_id, pivot } = segment;
				const { role_id, user_id, segment_id, created_at } = pivot;
				return {
					role_id,
					segment_id,
					user_id,
					business_name_id,
					created_at
				}
			});
	}

	return (
		<div>
			<div style={{ height: '65vh', overflowY: 'auto' }}>
				<Tree
					checkable
					onCheck={onCheck}
					checkedKeys={checkedKeys}
					treeData={treeData}
					expandedKeys={expandedKeys}
					onExpand={onExpand}
					disabled={disabledTree}
				/>
			</div>
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				{
					userRole.custom_conf ? <>
						<ButtonIcon name={t(`${p}.btn-default`)} icon={faTrashRestore} variant='outline-secondary' onClick={onDefault} />
						<ButtonIcon name={t(`${p}.btn-save`)} icon={faSave} variant='outline-success' onClick={onSave} disabled={!hasChange()} />
					</> :
						<ButtonIcon name={t(`${p}.btn-custom`)} icon={faCogs} variant='outline-primary' onClick={onCustom} />
				}
			</div>
		</div>
	)
}

TreeBusinessNames.propTypes = {
	hookUserBusinessNames: hookPaginationPropType,
	hookSegments: hookPaginationPropType,
	hookBusinessNames: hookPaginationPropType,
	userRole: PropTypes.shape({
		id: PropTypes.number.isRequired,
		user_id: PropTypes.number.isRequired,
		role_id: PropTypes.number.isRequired,
		custom_conf: PropTypes.bool.isRequired,
		business_names: PropTypes.array,
		segments: PropTypes.array,
	}).isRequired,
	hookUserRoles: hookPaginationPropType,
}

export default TreeBusinessNames