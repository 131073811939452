import React from 'react'
import ButtonIcon from '../Layouts/Forms/ButtonIcon'
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import ModalComp from '../Layouts/Modal';

const ModalRegistrarSalida = ({handleCloseModal}) => {
    return (
        <ModalComp
        size={'lg'}
            onClose={handleCloseModal} // Función para cerrar el modal
            title='Registrar Salida' // Título del modal tomado del estado modalContent
            body={<>

            </>} // Cuerpo del modal tomado del estado modalContent
            footer={
                <>
                <ButtonIcon icon={faTimes} name="Cerrar" variant="outline-secondary" onClick={handleCloseModal} />
                <ButtonIcon icon={faSave} name="Guardar" variant="outline-success" onClick={handleCloseModal} />
                </>
            } // Pie de página del modal con un botón para cerrar
        />
    )
}


export default ModalRegistrarSalida
