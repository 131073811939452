import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { destroyRoleModules, indexRoleModules, storeRoleModules } from '../../../api/RoleModules';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import ModalComp from '../../Layouts/Modal';
import TableComp from '../../Layouts/Table';

const _ = require('underscore');

const ModalRoleModules = (props) => {
    const { onClose, headers, modules, role_id } = props;

    const [t] = useTranslation('global');
    const prefix = 'super-admin.data';
    const role_selected = useSelector(state => state.login.role_selected);
    const dispatch = useDispatch();

    const [roleModules, setRoleModules] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getRoleModules();
    }, []);

    const getRoleModules = async () => {
        let response = await indexRoleModules({ headers, role_id });
        setRoleModules(response.data);
        setLoading(false);
        if (role_id === role_selected.id) {
            let modules = response.data.map((roleModule) => {
                let { id, module_code } = roleModule;
                return { id, code: module_code };
            });
            dispatch({ type: 'SET_MODULES', modules });
        }
    }
    // Evento para asignar módulo a la fibra
    const handleChange = async (checked, module_id) => {
        if (checked) {
            await storeRoleModules({ headers, data: { module_id, role_id } });
            getRoleModules();
        } else {
            let pos = _.findIndex(roleModules, { module_id });
            if (pos !== -1) { // Si el registro se encuentra se elimina
                await destroyRoleModules({ headers, role_module_id: roleModules[pos].id });
                getRoleModules();
            }
        }
    }
    // Método para obtener si el registro existe o no
    const getChecked = (module_id) => {
        let pos = _.findIndex(roleModules, { module_id });
        return pos !== -1;
    }
    // Método para indicar si el switch está habilitado
    const getDisabled = () => false;

    return (
        <ModalComp
            onClose={onClose}
            size='lg'
            title={t(`${prefix}.title`)}
            body={
                <TableComp
                    headers={[
                        '#',
                        t(`${prefix}.code`),
                        t(`${prefix}.name`),
                        t(`${prefix}.actions`)
                    ]}
                    keys={['id', 'code', 'name']}
                    body={modules}
                    type='switch'
                    actions={{
                        checkedChildren: t(`${prefix}.checked`),
                        unCheckedChildren: t(`${prefix}.unchecked`),
                        onChange: handleChange,
                        getChecked: getChecked,
                        getDisabled: getDisabled
                    }}
                    loading={loading}
                />
            }
            footer={<Fragment>
                <ButtonIcon name={t(`${prefix}.close-button`)} icon={faArrowLeft} variant='outline-secondary' onClick={onClose} />
            </Fragment>}
        />
    );
};

export default ModalRoleModules;
