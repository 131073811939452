import { useToast } from '@chakra-ui/react';
import { faArrowLeft, faCheck, faFileDownload, faHourglassHalf, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useEffect, useState } from 'react'
import { indexRepseUserDocs } from '../../../../api/RepseUsers';
import downloadFile from '../../../../libs/DownloadFile';
import ButtonIcon from '../../../Layouts/Forms/ButtonIcon';
import ModalComp from '../../../Layouts/Modal'
import TableComp from '../../../Layouts/Table';

const PeriodicDocs = (props) => {
    const { onClose, title, headers, user_id, repse_doc_id } = props;

    const toast = useToast();
    const [docs, setDocs] = useState([]);               // Historial de un documento
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getDocs();
    }, []);

    // Obtener el historial de documentos enviados por un proveedor de acuerdo al documento seleccionado
    const getDocs = async () => {
        let response = await indexRepseUserDocs({ headers, user_id, repse_doc_id });
        setDocs(response.data);
        setLoading(false);
    }

    const downloadDoc = (doc) => {
        downloadFile({
            url: `/repse/user_docs/download/${doc.id}`,
            type: 'application/json',
            name: doc.id,
            toast, headers
        });
    }

    // Valores de celda que se reemplazarán con un ícono
    const iconValues = [
        {
            value: 'validating', icon: <FontAwesomeIcon
                size='lg' icon={faHourglassHalf}
                style={{ color: 'orange' }}
            />
        }, {
            value: 'accepted', icon: <FontAwesomeIcon
                size='lg' icon={faCheck}
                style={{ color: '#00a884' }}
            />
        }, {
            value: 'rejected', icon: <FontAwesomeIcon
                size='lg' icon={faTimes}
                style={{ color: 'red' }}
            />
        }
    ];

    return (
        <ModalComp
            onClose={onClose}
            title={title}
            size='lg'
            body={<Fragment>
                <TableComp
                    headers={['#', 'Fecha', 'Estado', 'Acciones']}
                    keys={['#', 'created_at', 'status']}
                    body={docs}
                    loading={loading}
                    actions={[
                        { name: 'Decargar', icon: faFileDownload, tooltip: 'Descargar archivo', handleClick: downloadDoc }
                    ]}
                    icons={iconValues}
                />
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name='Cerrar' icon={faArrowLeft} variant='outline-secondary' />
            </Fragment>}
        />
    )
}

export default PeriodicDocs;
