import { Container } from "react-bootstrap";
import '../../styles/BillingReports.Component.css';
import { Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { SET_ACTIVE_TAB } from "../../redux/actions/main";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const BillingReports = () => {
    const [t] = useTranslation('global');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/billing-reports' });
    }, []);
    
    return(
        <Container id='billing-reports-component' fluid>
            <Heading>{t('billing-reports.title')}</Heading>
        </Container>
    );
}

export default BillingReports;