import { Fragment } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormGroupComponent from "../../Layouts/Forms/FormGroup";
import SkeletonForm from "../../Layouts/Skeletons/Form";

const FormComponent = (props) => {
    const { code, incident_type, status, description, user, created_at, sector, flat, local } = props.incident;
    const { loading } = props;
    const [t] = useTranslation('global');
    const prefix = 'incidents.data';

    return (
        <Fragment>
            {
                loading ? <SkeletonForm rows={3} cols={3} /> :
                    <Form>
                        <Row className="mb-3">
                            <FormGroupComponent type='text' title={t(`${prefix}.code`)} defaultValue={code} readOnly />
                            <FormGroupComponent type='text' title={t(`${prefix}.type`)} defaultValue={incident_type} readOnly />
                            <FormGroupComponent type='text' title={t(`${prefix}.status`)} defaultValue={status} readOnly />
                        </Row>
                        <Row className="mb-3">
                            <FormGroupComponent type='text' title={t(`${prefix}.user`)} defaultValue={user} readOnly />
                            <FormGroupComponent type='text' title={t(`${prefix}.created-at`)} defaultValue={created_at} readOnly />
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <label>{t(`${prefix}.description`)}</label>
                                <textarea className="form-control" value={description} readOnly />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <FormGroupComponent type='text' title={t(`${prefix}.sector`)} defaultValue={sector} readOnly />
                            <FormGroupComponent type='text' title={t(`${prefix}.flat`)} defaultValue={flat} readOnly />
                            <FormGroupComponent type='text' title={t(`${prefix}.local`)} defaultValue={local} readOnly />
                        </Row>
                    </Form>
            }
        </Fragment>
    );
}

export default FormComponent;