import React from 'react'
import PropTypes from 'prop-types'
import { PageHeader } from 'antd'
import { Heading } from '@chakra-ui/react'

const PageHeaderComp = ({
    title = '',
    subtitle = '',
    actions = <></>
}) => {
    return (
        <PageHeader
            className="site-page-header"
            onBack={() => window.history.back()}
            title={<Heading>{title}</Heading>}
            subTitle={<Heading size='md' color={'gray'}>{subtitle}</Heading>}
            extra={actions}
        />
    )
}

PageHeaderComp.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    actions: PropTypes.node
}

export default PageHeaderComp