import downloadFile from "../../libs/DownloadFile";
import Fetcher from "../../libs/Petition"
import getBlobFile from "../../libs/getBlobFile";

const url = '/requisition_item_providers';
const CREATED = 201;
const STATUS_OK = 200;

export const indexRequisitionProviderItems = async (props) => {
    const {
        headers,
        fibra_id,
        page,
        pageSize } = props;

    let response = { data: [], totalItems: 0 };

    try {
        // Parámetros requeridos
        let params = { fibra_id };
        // Parámetros opcionales
        if (page) params.page = page;
        if (pageSize) params.pageSize = pageSize;
        const fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        // console.log("🚀 ~ file: requisition_item_providers.js:28 ~ indexRequisitionProviderItems ~ fetch:", fetch)

        if (fetch.status === STATUS_OK)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}

export const storeRequisitionProviderItems = async ({ headers, data }) => {
    let response = false;
    try {
        const fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        // console.log("🚀 ~ file: requisition_item_providers.js:47 ~ storeRequisitionProviderItems ~ fetch:", fetch)

        if (fetch.status === CREATED)
            response = fetch.data;
    } catch (error) {
        response = false;
    }
    return response;

}

export const downloadRequisitionProviderItem = ({ headers, requisition_item_provider_id, name, toast }) => {
    try {
        downloadFile({
            headers,
            url: `${url}/download/${requisition_item_provider_id}`,
            type: 'application/json',
            name,
            toast
        });
        return true;
    } catch (error) {
        return false;
    }
}

export const getBlobRequisitionProviderItem = async ({ headers, requisition_item_provider_id }) => {
    let response = null;
    try {
        return await getBlobFile({ headers, url: `${url}/download/${requisition_item_provider_id}` });
    } catch (error) {
        return response;
    }

}

export const destroyRequisitionProviderItem = async ({ headers, requisition_item_provider_id }) => {
    let response = { res: false, data: {} };
    try {
        const fetch = await Fetcher({
            url: `${url}/${requisition_item_provider_id}`,
            method: 'DELETE',
            headers
        });
        // console.log("🚀 ~ file: requisition_item_providers.js:93 ~ destroyRequisitionProviderItem ~ response:", response)
        if (fetch.status === STATUS_OK) {
            response.data = fetch.data.data;
            response.res = true;
        }
        return response;
    } catch (error) {
        return response;
    }
}