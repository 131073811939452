import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ModalComp from '../../Layouts/Modal'
import ButtonIcon from '../../Layouts/Forms/ButtonIcon'
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { hookPaginationPropType } from '../../../data/propTypes'
import { getPercentaje } from '../../../libs/functions'
import useForm from '../../../hooks/useForm'
import FormIcon from '../../Layouts/Forms/FormIcon'
import { Col, Row } from 'antd'
import Swal from 'sweetalert2'
import { updatePaymentOrders } from '../../../api/PaymentOrders'

const EditPercentage = ({ headers, onClose, hookPaymentOrders, handleValidationErrors }) => {
    const [t] = useTranslation('purchase_orders');
    const p = 'payment-orders-modal';
    const hookForm = useForm();
    useEffect(() => {
        hookForm.setForm({ percentage: hookPaymentOrders.dataSel?.percentage * 100 });
    }, [])
    // Obtener el porcentaje actual de la orden de pago
    const getCurrentPercentage = () => {
        return getPercentaje(hookPaymentOrders.dataSel?.percentage, 2)
    }
    // Obtener porcentaje ocupado por las demás ordenes de pago
    const getEmployedPercentage = () => {
        const anotherPaymentOrders = hookPaymentOrders.data.filter(item => item.id !== hookPaymentOrders.dataSel.id);
        return getPercentaje(anotherPaymentOrders.reduce((total, item) => total + item.percentage, 0), 2)
    }
    // Obtener el porcentaje disponible que puede ser asignado
    const getAvailablePercentage = () => {
        const anotherPaymentOrders = hookPaymentOrders.data.filter(item => item.id !== hookPaymentOrders.dataSel.id);
        return getPercentaje(anotherPaymentOrders.reduce((total, item) => total - item.percentage, 1), 2)
    }
    // Actualizar porcentaje
    const onSave = async () => {
        if (validateData()) {
            const { percentage } = hookForm.form;
            const data = { percentage: percentage };
            const payment_order_id = hookPaymentOrders.dataSel.id;
            const fetch = await updatePaymentOrders({ headers, data, payment_order_id });
            if (fetch.res) {
                const updatedRecord = fetch.data?.row;
                hookPaymentOrders.updateElement({ ...updatedRecord, percentage: updatedRecord.percentage / 100 })
                Swal.fire(t(`${p}.sf.success.title`), t(`${p}.sf.success.desc`), 'success');
                onClose();
            } else if (fetch.status === 400) {
                handleValidationErrors(fetch.data?.errors);
            } else {
                Swal.fire(t(`${p}.sf.warning.title`), t(`${p}.sf.warning.desc`), 'warning');
            }
        }
    }
    // Validar formulario de envío
    const validateData = () => {
        const { percentage } = hookForm.form;
        const availablePercentage = hookPaymentOrders.data
            .filter(item => item.id !== hookPaymentOrders.dataSel.id)
            .reduce((total, item) => total - item.percentage, 1);
        const isValid = percentage > 0 && percentage <= availablePercentage * 100;
        if (!isValid) {
            Swal.fire(t(`${p}.sf.valid.title`), t(`${p}.sf.valid.desc`), 'info');
        }
        return isValid;
    }
    // Verificar si la cantidad ha cambiado
    const hasChange = () => {
        const { percentage } = hookForm.form;
        return percentage != hookPaymentOrders.dataSel?.percentage * 100;
    }
    return (
        <ModalComp
            onClose={onClose}
            title={t(`${p}.edit.title`)}
            body={<Row className='mb-4'>
                <Col xs={14}>
                    <p>Porcentaje actual: <strong>{getCurrentPercentage()}</strong></p>
                    <p>Porcentaje ocupado: <strong>{getEmployedPercentage()}</strong></p>
                    <p>Porcentaje disponible: <strong>{getAvailablePercentage()}</strong></p>
                </Col>
                <Col xs={10}>
                    <FormIcon
                        size={'sm'}
                        re_icon={'%'}
                        name='percentage'
                        defaultValue={hookPaymentOrders.dataSel?.percentage * 100}
                        value={hookForm.form?.percentage}
                        handleChange={hookForm.handleChange}
                    />
                </Col>
            </Row>}
            footer={<>
                <ButtonIcon icon={faTimes} name={t(`${p}.edit.close`)} onClick={onClose} variant='outline-secondary' />
                <ButtonIcon icon={faSave} name={t(`${p}.edit.save`)} onClick={onSave} variant='outline-success' disabled={!hasChange()} />
            </>}
        />
    )
}

EditPercentage.propTypes = {
    headers: PropTypes.shape({
        Authorization: PropTypes.string.isRequired
    }).isRequired,
    onClose: PropTypes.func.isRequired,
    hookPaymentOrders: hookPaginationPropType,
    handleValidationErrors: PropTypes.func
}

export default EditPercentage