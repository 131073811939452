import React from 'react';
import {
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    useDisclosure,
    Text,
    VStack
} from '@chakra-ui/react';

const useLoading = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const showLoading = () => onOpen();

    const hideLoading = () => onClose();

    const LoadingModal = () => (
        <Modal isOpen={isOpen} onClose={onClose} isCentered isClosable={false} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent
                backgroundColor="gray.700"
                boxShadow="none"
                display="flex"
                alignItems="center"
                justifyContent="center"
                padding={6}
                borderRadius="md"
            >
                <ModalBody display="flex" justifyContent="center" alignItems="center">
                    <VStack spacing={4}>
                        <Spinner size="xl" thickness="4px" speed="0.65s" color="blue.500" />
                        <Text color="white" fontSize="lg" fontWeight="bold">Cargando...</Text>
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );

    return { isOpen, showLoading, hideLoading, LoadingModal };
};

export default useLoading;
