import { Heading } from '@chakra-ui/react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TabsComp from '../Layouts/Tabs';
import BusinessName from './fragments/BusinessName';
import TableRequests from './fragments/Table';
import Echo from 'laravel-echo'
import { useEffect, useState } from 'react';
import { SET_ACTIVE_TAB } from '../../redux/actions/main';

const BulkDownloadAdmin = () => {
    const login = useSelector(state => state.login);
    const headers = { Authorization: `Bearer ${login.token}` };
    const fibra_id = login.fibra.id;
    const [t] = useTranslation('bulk_download');
    const [socket, setSocket] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/bulk-download' });
    }, []);

    useEffect(() => {
        // Agregar instancia de Pusher
        window.Pusher = require('pusher-js');
        // Establecer la comunicación a través de Laravel Echo
        window.Echo = new Echo({
            broadcaster: process.env.REACT_APP_WS_BROADCASTER,
            key: process.env.REACT_APP_WS_KEY,
            wssHost: process.env.REACT_APP_WS_HOST,
            wsHost: process.env.REACT_APP_WS_HOST,
            wssPort: process.env.REACT_APP_WS_PORT,
            wsPort: process.env.REACT_APP_WS_PORT,
            forceTLS: process.env.REACT_APP_WS_FORCE_TLS === 'false' ? false : true,
            disableStats: process.env.REACT_APP_WS_DISABLE_STATS === 'false' ? false : true,
            enabledTransports: ["ws", "wss"],
            transports: ['websocket'],
            auth: {
                headers: {
                    Authorization: `Bearer ${login.token}`,
                    Accept: 'application/json',
                },
            },
            apiOriginAllow: {
                "allowCors": true,
                "allowOrigin": "https://victum-re.online",
                "allowMethods": "GET, POST",
                "allowHeaders": "Origin, Content-Type, X-Auth-Token, X-Requested-With, Accept, Authorization, X-Socket-Id"
            }
        });

        window.Echo.channel(`BulkDownload.${fibra_id}`)
            .listen('BulkDownloadEvent', (e) => {
                setSocket(e);
            });

        return () => {
            window.Echo.leave(`BulkDownload.${fibra_id}`)
        }

    }, []);

    return (
        <Container fluid>
            <Heading>{t("title")}</Heading>
            {/* <Alert status='info' className='mb-3'>
                <AlertIcon />
                <AlertTitle mr={2}>En construcción!</AlertTitle>
                <AlertDescription>Actualmente este módulo se encuentra en construcción.</AlertDescription>
                <CloseButton position='absolute' right='8px' top='8px' />
            </Alert> */}
            <TabsComp
                tablist={[t("tabs.requests"), t("tabs.business-names")]}
                tabpanels={[
                    // Tabla para listar las peticiones realizadas
                    <TableRequests
                        key='req'
                        headers={headers}
                        fibra_id={fibra_id}
                        socket={socket}
                    />,
                    // Tabla para agregar razones sociales
                    <BusinessName
                        key='bna'
                        headers={headers}
                        fibra_id={fibra_id}
                    />
                ]}
            />


        </Container>
    )
}

export default BulkDownloadAdmin;
