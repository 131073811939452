import { Heading } from '@chakra-ui/react';
import { faArrowLeft, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ButtonIcon from '../../Layouts/Forms/ButtonIcon';
import FormIcon from '../../Layouts/Forms/FormIcon';
import ModalComp from '../../Layouts/Modal'
import TableComp from '../../Layouts/Table';

const Details = (props) => {
    const { onClose, item } = props;
    const [items, setItems] = useState([]);
    const [t] = useTranslation('erp');
    const p = ['CompPagoPendProv.form', 'CompPagoPendProv.item'];

    useEffect(() => {
        setItems(Object.values(item.ZmmTFacPagosProv));
    }, []);

    return (
        <ModalComp
            onClose={onClose}
            title={t('CompPagoPendProv.title')}
            size='xl'
            body={<Fragment>
                <Row className='mb-3'>
                    <FormIcon title={t(`${p[0]}.Bukrs`)} name='Bukrs' value={item?.Bukrs} />
                    <FormIcon title={t(`${p[0]}.Gjahr`)} name='Gjahr' value={item?.Gjahr} />
                    <FormIcon title={t(`${p[0]}.Augbl`)} name='Augbl' value={item?.Augbl} />
                </Row>
                <Row className='mb-4'>
                    <FormIcon title={t(`${p[0]}.Augdt`)} name='Augdt' value={item?.Augdt} />
                    <FormIcon title={t(`${p[0]}.Wrbtr`)} name='Wrbtr' value={item?.Wrbtr} icon={'$'} />
                    <FormIcon title={t(`${p[0]}.Waers`)} name='Waers' value={item?.Waers} />
                    <FormIcon title={t(`${p[0]}.Zlsch`)} name='Zlsch' value={item?.Zlsch} />
                </Row>
                <Heading size={'md'} className='mb-3'>{t(`${p[0]}.ht`)}</Heading>
                <Row className='mb-3'>
                    <Col>
                        <TableComp
                            headers={[
                                '#', 
                                t(`${p[1]}.Belnr`), 
                                t(`${p[1]}.Blart`), 
                                t(`${p[1]}.Gjahr`), 
                                t(`${p[1]}.HBldat`), 
                                t(`${p[1]}.Serie`), 
                                t(`${p[1]}.Folio`), 
                                t(`${p[1]}.ImporteFactura`), 
                                t(`${p[1]}.Waers`), 
                                t(`${p[1]}.MetodoPago`), 
                                t(`${p[1]}.Uuid`),
                                ''
                            ]}
                            keys={['#', 'Belnr','Blart', 'Gjahr', 'HBldat', 'Serie', 'Folio', 'ImporteFactura', 'Waers', 'MetodoPago', 'Uuid']}
                            body={items}
                            currency={[{ index: 6 }]}
                        />
                    </Col>
                </Row>
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name={t('CompPagoPendProv.btn-close')} icon={faArrowLeft} variant='outline-secondary' onClick={onClose} />
            </Fragment>}
        />
    )
}

export default Details