import { HStack, PinInput, PinInputField, Text, useToast } from "@chakra-ui/react";
import { faSpellCheck } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { verify } from "../../../api/Authentification";
import setToast from "../../../libs/SetToast";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";

const VerificationCode = (props) => {
    const { email, goHome } = props;
    const [code, setCode] = useState('BWzFjd');

    const stylePin = { backgroundColor: '#FFF' };
    const [t] = useTranslation('global');
    const prefix = 'login.two-factor';
    const toast = useToast();

    const isValidData = () => {
        return code.length === 6 ? true : false;
    }
    const verifyCode = async () => {
        if (isValidData()) {
            let response = await verify({ email, code });
            if (response.res) { // Código validado correctamente
                // Abrir sesión
                goHome(response.data);
                setToast({  // Mensaje de retroalimentación: success
                    title: t(`${prefix}.toasts.success.title`), toast, status: 'success',
                    description: t(`${prefix}.toasts.success.description`)
                });
            } else {
                setToast({  // Mensaje de retroalimentación: error
                    title: t(`${prefix}.toasts.error.title`), toast, status: 'error',
                    description: t(`${prefix}.toasts.error.description`)
                });
            }
        } else {
            setToast({  // Mensaje de retroalimentación: warning
                title: t(`${prefix}.toasts.warning.title`), toast, status: 'warning',
                description: t(`${prefix}.toasts.warning.description`)
            });
        }
    }

    const handleChange = (value) => { setCode(value) }

    return (
        <Fragment>
            <Text fontSize='lg' color='whiteAlpha.800'>
                {t(`${prefix}.title`)} <Text color='teal.500'>{email}</Text>
            </Text>
            <HStack className="mt-4">
                <PinInput
                    size='lg'
                    type='alphanumeric'
                    onChange={handleChange}
                >
                    <PinInputField style={stylePin} />
                    <PinInputField style={stylePin} />
                    <PinInputField style={stylePin} />
                    <PinInputField style={stylePin} />
                    <PinInputField style={stylePin} />
                    <PinInputField style={stylePin} />
                </PinInput>
            </HStack>
            <div className="d-grid gap-2 mt-3">
                <ButtonIcon variant='primary' icon={faSpellCheck} name={t(`${prefix}.button-verify`)} onClick={verifyCode} disabled={!isValidData()} />
            </div>
        </Fragment>
    );
};

export default VerificationCode;
