import Fetcher from "../../libs/Petition"

const url = '/budget_codes';
const CREATED = 201;
const STATUS_OK = 200;

export const indexBudgetCodes = async (props) => {
    const {
        headers,
        fibra_id,
        page,
        pageSize } = props;

    let response = { data: [], totalItems: 0 };

    try {
        // Parámetros requeridos
        let params = { fibra_id };
        // Parámetros opcionales
        if (page) params.page = page;
        if (pageSize) params.pageSize = pageSize;
        const fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        // console.log("🚀 ~ file: codes.js:28 ~ indexBudgetCodes ~ fetch:", fetch)

        if (fetch.status === STATUS_OK)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}