import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from "react";
import { Col, Form, FormSelect, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { showComplaintSuggestion } from "../../../api/ComplaintSuggestionBox";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import FormGroupComponent from "../../Layouts/Forms/FormGroup";
import ModalComp from "../../Layouts/Modal";
import SkeletonForm from "../../Layouts/Skeletons/Form";

const ModalDetails = (props) => {
    const { onClose, title, complaint_suggestion_id, headers, data, handleChange, update, is_admin } = props;
    const [complaint_suggestion, setComplaintSuggestion] = useState({});
    const [loading, setLoading] = useState(true);
    // Traducción
    const [t] = useTranslation('global');
    const prefix = 'complaint-suggestions';
    const prefix_button = `${prefix}.modal-details.button-`;
    const prefix_form = `${prefix}.table.headers`;

    useEffect(() => {
        getComplaintSuggestion();
    }, []);

    const getComplaintSuggestion = async () => {
        let response = await showComplaintSuggestion({ headers, complaint_suggestion_id });
        setComplaintSuggestion(response);
        setLoading(false);
    }
    return (
        <ModalComp
            title={title}
            size='lg'
            onClose={onClose}
            body={
                <Fragment>
                    {loading ? <SkeletonForm rows={3} cols={3} /> :
                        <Form>
                            <Row className='mb-3'>
                                <FormGroupComponent title={t(`${prefix_form}.title`)} defaultValue={complaint_suggestion?.title}
                                    value={complaint_suggestion?.title} />
                                <FormGroupComponent title={t(`${prefix_form}.type`)} defaultValue={complaint_suggestion?.type}
                                    value={complaint_suggestion?.type} />
                                <Col>
                                    <Form.Label>{t(`${prefix_form}.status`)}</Form.Label>
                                    <FormSelect name='status' defaultValue={complaint_suggestion?.status}
                                        value={data?.status} onChange={handleChange}>
                                        <option value={0}>{t(`${prefix}.filters.pending`)}</option>
                                        <option value={1}>{t(`${prefix}.filters.attending`)}</option>
                                    </FormSelect>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <FormGroupComponent title={t(`${prefix_form}.user`)}
                                    defaultValue={complaint_suggestion?.user?.first_name + ' ' + complaint_suggestion?.user?.last_name}
                                    value={complaint_suggestion?.user?.first_name + ' ' + complaint_suggestion?.user?.last_name} />
                                <FormGroupComponent title={t(`${prefix_form}.created-at`)} defaultValue={complaint_suggestion?.created_at}
                                    value={complaint_suggestion?.created_at} />
                                <FormGroupComponent title={t(`${prefix_form}.updated-at`)} defaultValue={complaint_suggestion?.updated_at}
                                    value={complaint_suggestion?.updated_at} />
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>{t(`${prefix}.modal-details.description`)}</Form.Label>
                                    <textarea className='form-control' defaultValue={complaint_suggestion?.body}
                                        value={complaint_suggestion?.body}></textarea>
                                </Col>
                            </Row>
                        </Form>
                    }
                </Fragment>}
            footer={<Fragment>
                <ButtonIcon icon={faArrowLeft} name={t(`${prefix_button}close`)} onClick={onClose} variant='outline-secondary' />
                {
                    is_admin &&
                    <ButtonIcon icon={faSave} name={t(`${prefix_button}save`)} onClick={update} variant='outline-primary' />
                }
            </Fragment>}
        />
    );
}

export default ModalDetails