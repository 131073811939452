import { faCheck, faCheckDouble, faEllipsisH, faHourglassHalf, faTimes, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect, Fragment } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { indexRepseDocs } from '../../../../api/Repse/RepseDocs';
import ButtonIcon from '../../../Layouts/Forms/ButtonIcon';
import PaginationComp from '../../../Layouts/Pagination';
import SkeletonTable from '../../../Layouts/Skeletons/Table';
import ChangeStatuses from './ChangeStatuses';
import '../../../../styles/Repse.Component.css';
import PeriodicDocs from './PeriodicDocs';

const _ = require('underscore');

const style = { textAlign: 'center', verticalAlign: 'middle' }; // Centrar una celda

const RepseMatrix = (props) => {
    const { headers, fibra_id, changePage, page, pageSize, totalItems, loading, providers, statuses, update } = props;
    const [fixedDocs, setFixedDocs] = useState([]);             // Documentación fija
    const [periodicDocs, setPeriodicDocs] = useState([]);       // Documentación periódica
    const [modalStatuses, setModalStatuses] = useState(false);  // Modal para cambiar status repse
    const [modalDocs, setModalDocs] = useState(false);          // Modal para visualizar la documentación periódica
    const [userSel, setUserSel] = useState(null);
    const [docSel, setDocSel] = useState(null);
    const [t] = useTranslation('global');
    const prefix = ['repse.matrix.statuses', 'repse.matrix.headers'];

    useEffect(() => {
        getDocumentation()
    }, []);

    const getDocumentation = async () => {
        let response = await indexRepseDocs({ headers, fibra_id });
        let docs = response.data;
        if (docs.length > 0) {
            setFixedDocs(
                docs.filter((doc) => doc.periodic_revision === null)
            );
            setPeriodicDocs(
                docs.filter((doc) => doc.periodic_revision !== null)
            );
        }
    }

    // Evento que se lanza al hacer onClick sobre el ícono de estado
    const clickIcon = (user_id, repse_doc_id) => {
        setUserSel(user_id);
        setDocSel(repse_doc_id);
        openOrCloseDocs();
    }
    // Retorna el icono del estado de documento REPSE fijo
    const existFile = (user_id, repse_doc_id) => {
        let response = { icon: faEllipsisH, color: 'gray' };
        let pos_prov = _.findIndex(providers, { id: user_id });
        if (pos_prov !== -1) {
            let pos = _.findIndex(providers[pos_prov].repse_docs, { repse_doc_id });
            if (pos !== -1)
                switch (providers[pos_prov].repse_docs[pos].status) {
                    case 'validating': response = { icon: faHourglassHalf, color: 'orange' }; break;
                    case 'accepted': response = { icon: faCheck, color: '#00a884' }; break;
                    case 'rejected': response = { icon: faTimes, color: 'red' }; break;
                    default: break;
                }
        }
        return <FontAwesomeIcon
            size='lg'
            icon={response.icon}
            style={{ color: response.color }}
        />;
    }
    // Retorna el icono del estado de documento REPSE periódico
    const getStatusDocs = (user_id, repse_doc_id) => {
        let response = { icon: faCheckDouble, color: '#00a884' };

        return <FontAwesomeIcon
            size='lg'
            icon={response.icon}
            style={{ color: response.color }}
            className='td-matrix'
            onClick={() => clickIcon(user_id, repse_doc_id)}
        />;
    }

    const openOrClose = () => setModalStatuses(!modalStatuses);
    const openOrCloseDocs = () => setModalDocs(!modalDocs);

    const changeStatuses = (user) => {
        setUserSel(user);
        openOrClose();
    }
    return (
        <Fragment>
            {/* Simbolización de los estados de documentación REPSE */}
            <Row className='mb-3'>
                <Col>
                    <FontAwesomeIcon icon={faCheckDouble} size='lg' style={{ marginLeft: '1%', color: '#00a884' }} /> {t(`${prefix[0]}.complete`)}
                    <FontAwesomeIcon icon={faCheck} size='lg' style={{ marginLeft: '1%', color: 'orange' }} /> {t(`${prefix[0]}.uncomplete`)}
                    <FontAwesomeIcon icon={faCheck} size='lg' style={{ marginLeft: '1%', color: '#00a884' }} /> {t(`${prefix[0]}.approved`)}
                    <FontAwesomeIcon icon={faHourglassHalf} size='lg' style={{ marginLeft: '1%', color: 'orange' }} /> {t(`${prefix[0]}.validating`)}
                    <FontAwesomeIcon icon={faTimes} size='lg' style={{ marginLeft: '1%', color: 'red' }} /> {t(`${prefix[0]}.rejected`)}
                    <FontAwesomeIcon icon={faEllipsisH} size='lg' style={{ marginLeft: '1%', color: 'gray' }} /> {t(`${prefix[0]}.not-received`)}
                </Col>
            </Row>
            {/* Matriz de usuarios REPSE */}
            <Table bordered size='sm' className='t-matrix'>
                <thead>
                    <tr>
                        <th style={style} rowSpan={2}>{t(`${prefix[1]}.providers`)}</th>
                        <th style={style} colSpan={fixedDocs.length}>{t(`${prefix[1]}.fixed-doc`)}</th>
                        <th style={style} colSpan={periodicDocs.length}>{t(`${prefix[1]}.periodic-doc`)}</th>
                        <th style={style} rowSpan={2}>{t(`${prefix[1]}.actions`)}</th>
                    </tr>
                    <tr>
                        {/* Documentos que se cargan una sola vez */}
                        {
                            fixedDocs.map((doc, index) =>
                                <td style={style} key={'f-' + index}>{doc.name}</td>
                            )
                        }
                        {/* Documentos que se tienen que estar subiendo periódicamente */}
                        {
                            periodicDocs.map((doc, index) =>
                                <td style={style} key={'p-' + index}>{doc.name}</td>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        loading ? <SkeletonTable tr={10} td={10} /> :
                            providers.map((provider, index) =>
                                <tr key={index}>
                                    <td style={{ verticalAlign: 'middle' }}>{provider.name}</td>
                                    {/* Documentos que se cargan una sola vez ppor cada usuario */}
                                    {
                                        fixedDocs.map((doc, index) =>
                                            <td style={style} key={'f-' + index}>
                                                {existFile(provider.id, doc.id)}
                                            </td>
                                        )
                                    }
                                    {/* Documentos que se tienen que estar subiendo periódicamente por cada usuario */}
                                    {
                                        periodicDocs.map((doc, index) =>
                                            <td style={style} key={'p-' + index}>
                                                {getStatusDocs(provider.id, doc.id)}
                                            </td>
                                        )
                                    }
                                    {/* Acciones */}
                                    <td style={style}>
                                        <ButtonIcon icon={faUserEdit} variant='outline-primary' tooltip={t(`${prefix[1]}.change-status`)} onClick={() => changeStatuses(provider)} />
                                    </td>
                                </tr>
                            )

                    }
                </tbody>
            </Table>
            {/* Componente de paginación */}
            <PaginationComp
                totalItems={totalItems}
                current={page}
                onChange={changePage}
                defaultPageSize={pageSize}
            />
            {/* Modal para cambiar estados REPSE */}
            {
                modalStatuses && <ChangeStatuses
                    onClose={openOrClose}
                    userSel={userSel}
                    statuses={statuses}
                    headers={headers}
                    update={update}
                />
            }
            {/* Modal para ver la documentación periódica */}
            {
                modalDocs && <PeriodicDocs
                    onClose={openOrCloseDocs}
                    title='Documentación'
                    user_id={userSel}
                    repse_doc_id={docSel}
                    headers={headers}
                />
            }
        </Fragment>
    );
};

export default RepseMatrix;