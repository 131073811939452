import { Fragment, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import ButtonIcon from "../../../Layouts/Forms/ButtonIcon";
import ModalComp from "../../../Layouts/Modal";
import { Select, useToast } from '@chakra-ui/react';
import { updateInvoice } from "../../../../api/Cfdi/Mailbox";
import setToast from "../../../../libs/SetToast";
import { useTranslation } from 'react-i18next';

const ModalCfdi = ({ onClose, invoice, headers, cfdiStatuses, setData }) => {
    const toast = useToast();
    const [statusSequences, setStatusSequences] = useState([]);
    const [newStatus, setNewStatus] = useState(null);
    const [t] = useTranslation('global');
    const prefix = 'mailbox.files-upload.toasts';

    useEffect(() => {
        const cfdiStatus = cfdiStatuses.find(element => element.id === invoice.cfdi_status_id);
        if (cfdiStatus) {
            setStatusSequences(cfdiStatus.status_sequences);
        }
    }, []);

    const onSave = async () => {
        if (newStatus?.cfdi_status_id) {
            const response = await updateInvoice({ headers, data: { ...newStatus }, invoice_id: invoice.id });
            if (response) {
                // Actualizar estado cfdi
                setData(prevState => prevState.map(item => {
                    if (item.id === invoice.id) {
                        item.cfdi_status_id = response.cfdi_status_id;
                        item.cfdi_status = response.cfdi_status;
                        item.color = response.color;
                    }
                    return item;
                }));
                setToast({
                    title: t(`${prefix}.cfdi-updated.title`), status: "success", toast, description: t(`${prefix}.cfdi-updated.description`),
                });
            } else {
                setToast({
                    title: t(`${prefix}.cfdi-failed.title`), status: "warning", toast, description: t(`${prefix}.cfdi-failed.description`)
                });
            }
        }
        onClose();  // Cerrar modal
    }

    const handleChange = (e) => {
        let { name, value } = e.target;
        setNewStatus({ [name]: value })
    }

    return (
        <ModalComp
            onClose={onClose}
            title={t('mailbox.modal-cfdi.title')}
            body={
                <Select placeholder={t('mailbox.modal-cfdi.select')} name='cfdi_status_id' onChange={handleChange}>
                    {
                        statusSequences.map((status_seq) =>
                            <option key={status_seq.id} value={status_seq.id}>{status_seq.name}</option>
                        )
                    }
                </Select>
            }
            footer={<Fragment>
                <ButtonIcon name={t('mailbox.modal-cfdi.button-close')} icon={faArrowLeft} variant="outline-secondary" onClick={onClose} />
                <ButtonIcon name={t('mailbox.modal-cfdi.button-save')} icon={faSave} variant="outline-success" onClick={onSave} />
            </Fragment>}
        />
    );
}

ModalCfdi.propTypes = {
    onClose: PropTypes.func.isRequired,
    invoice: PropTypes.shape({
        id: PropTypes.number.isRequired,
        cfdi_status_id: PropTypes.number.isRequired
    }).isRequired,
    headers: PropTypes.string.isRequired,
    cfdiStatuses: PropTypes.array.isRequired,
    setData: PropTypes.func.isRequired,
}

export default ModalCfdi;