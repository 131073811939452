import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Heading, InputGroup } from '@chakra-ui/react'
import { Divider, Switch } from 'antd'
import { Col, Form, Row } from 'react-bootstrap'
import FormIcon from '../../../Layouts/Forms/FormIcon'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import ButtonIcon from '../../../Layouts/Forms/ButtonIcon'
import AutocompleteComp from '../../../Layouts/Forms/Autocomplete'
import { indexBudgetCodes } from '../../../../api/Budget/codes'
import { useSelector } from 'react-redux'
import i18next from 'i18next'

const AddItems = ({ t, p_data, type, item, changeItem, addItem, fibra_id }) => {
    const currentLanguage = i18next.language;
    const token = useSelector(prevState => prevState.login.token);
    const headers = { Authorization: `Bearer ${token}` };
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const getBudgetCodes = async () => {
            const { data } = await indexBudgetCodes({ headers, fibra_id });
            setOptions(data.map((item) => {
                const { id, code, translations } = item;
                const currentTranslation = translations.find((translation) => translation.locale === currentLanguage);
                return {
                    id,
                    label: `${code} - ${currentTranslation?.name}`
                }
            }));
        }
        getBudgetCodes();
    }, []);

    const handleChangeSwitch = (checked) => {
        changeItem({ target: { name: 'iva', value: checked } });
    }

    const getSubtotal = ({ quantity, unit_price }) => {
        return quantity * unit_price;
    }
    
    const getTotal = ({ quantity, unit_price, iva }) => {
        const subtotal = getSubtotal({ quantity, unit_price });
        const VAT = subtotal * (process.env.REACT_APP_IVA / 100);
        const total = iva ? subtotal + VAT : subtotal;
        return total.toFixed(6);
    }

    return (
        <>
            <Divider orientation='left'>
                <Heading size='sm'>{t(`${p_data}.items`)}</Heading>
            </Divider>
            {
                type !== 'provider' && <>
                    <Row className='mb-3'>
                        <AutocompleteComp title={t(`${p_data}.item-description`)} name='description' defaultValue={item.description}
                            placeholder={t(`${p_data}.item-description-ph`)} value={item.description} options={options} onSelect={() => { }} handleChange={changeItem} />
                        <FormIcon type='text' name='detailed' title={t(`${p_data}.detailed`)} defaultValue={item.detailed} value={item.detailed} handleChange={changeItem} />
                        <FormIcon type='text' name='unit' title={t(`${p_data}.item-unit`)} defaultValue={item.unit} value={item.unit} handleChange={changeItem} />
                        <Form.Group as={Col}>
                            <Form.Label>{t(`${p_data}.item-apply-iva`)}</Form.Label>
                            <InputGroup>
                                <Switch
                                    checkedChildren='16%'
                                    unCheckedChildren='0%'
                                    onChange={handleChangeSwitch}
                                    checked={item.iva}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row className='mb-3'>
                        <FormIcon type='number' name='quantity' title={t(`${p_data}.item-quantity`)} defaultValue={item.quantity} value={item.quantity} handleChange={changeItem} />
                        <FormIcon type='number' name='unit_price' title={t(`${p_data}.item-unit-price`)} icon='$' defaultValue={item.unit_price} value={item.unit_price} handleChange={changeItem} />
                        <FormIcon type='number' name='subtotal' title={t(`${p_data}.item-subtotal`)} icon='$' defaultValue={item.subtotal} value={getSubtotal(item)} handleChange={changeItem} />
                        <FormIcon type='number' name='total' title={t(`${p_data}.item-total`)} icon='$' defaultValue={item.total} value={getTotal(item)} handleChange={changeItem} />
                        <Col className='d-flex justify-content-center align-items-center'>
                            <ButtonIcon name={t(`${p_data}.button-add-item`)} icon={faPlus} variant='outline-success' onClick={addItem} />
                        </Col>
                    </Row>
                </>
            }
        </>
    )
}

AddItems.propTypes = {
    t: PropTypes.func.isRequired,
    p_data: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    changeItem: PropTypes.func.isRequired,
    addItem: PropTypes.func.isRequired,
    fibra_id: PropTypes.number.isRequired
}

export default AddItems