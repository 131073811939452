import { useToast } from '@chakra-ui/react'
import { faArrowLeft, faSave } from '@fortawesome/free-solid-svg-icons'
import { Fragment, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { storeRecFacPedProv } from '../../../api/ERP/SAP/RecFacPedProv'
import setToast from '../../../libs/SetToast'
import ButtonIcon from '../../Layouts/Forms/ButtonIcon'
import FormIcon from '../../Layouts/Forms/FormIcon'
import ModalComp from '../../Layouts/Modal'
import { data_validate } from '../validate/RecFacPedProv'

const RecFacPedProv = (props) => {
    const { onClose, headers } = props;
    const [data, setData] = useState({});
    const toast = useToast();
    const [t] = useTranslation('erp');
    const p = 'RecFacPedProv.form';
    const pt = 'RecFacPedProv.toasts';

    const handleChange = (e) => {
        let { name, value } = e.target;
        setData({ ...data, [name]: value });
    }

    const validate = () => {
        let response = true;
        for (const element of data_validate) {
            const { key, name } = element;
            if (!data.hasOwnProperty(key)) {
                response = false;
                setToast({ toast, title: t(`${pt}.${name}.title`), description: t(`${pt}.${name}.description`), status: 'warning' });
                break;
            }
        }
        return response;
    }
    const saveData = async () => {
        if (validate()) {
            try {
                let response = await storeRecFacPedProv({ headers, data });
                setToast({ toast, title: 'Respuesta de la petición: ', description: Object.values(response.data[0])[0], status: 'warning' });
            } catch (error) {
                setToast({ toast, title: '¡Error!', description: 'Error al procesar la solicitud', status: 'error' });
            }
        }
    }
    return (
        <ModalComp
            title={t('RecFacPedProv.title')}
            size='xl'
            onClose={onClose}
            body={<Fragment>
                <Row className='mb-3'>
                    <FormIcon title={t(`${p}.CentroDesc`)} name='CentroDesc' handleChange={handleChange} />
                    <FormIcon title={t(`${p}.Eknam`)} name='Eknam' handleChange={handleChange} />
                    <FormIcon title={t(`${p}.Lgobe`)} name='Lgobe' handleChange={handleChange} />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title={t(`${p}.Ebeln`)} name='Ebeln' handleChange={handleChange} />
                    <FormIcon title={t(`${p}.Bedat`)} type='date' name='Bedat' handleChange={handleChange} />
                    <FormIcon title={t(`${p}.Ebelp`)} name='Ebelp' handleChange={handleChange} />
                    <FormIcon title={t(`${p}.Menge`)} type='number' name='Menge' handleChange={handleChange} />
                </Row>
                <Row className='mb-3'>
                    <Col>
                        <Form.Label>{t(`${p}.Txz01`)}</Form.Label>
                        <textarea className='form-control' name='Txz01' onChange={handleChange}></textarea>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <FormIcon title={t(`${p}.Meins`)} name='Meins' handleChange={handleChange} />
                    <FormIcon title={t(`${p}.Waers`)} name='Waers' handleChange={handleChange} />
                    <FormIcon title={t(`${p}.Netpr`)} type='number' name='Netpr' handleChange={handleChange} />
                </Row>
                <Row className='mb-3'>
                    <FormIcon title={t(`${p}.ImpTotalPed`)} type='number' name='ImpTotalPed' handleChange={handleChange} />
                    <FormIcon title={t(`${p}.CanXEntregar`)} type='number' name='CanXEntregar' handleChange={handleChange} />
                    <FormIcon title={t(`${p}.ImpXEntregar`)} type='number' name='ImpXEntregar' handleChange={handleChange} />
                </Row>
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon name={t(`${p}.btn-close`)} icon={faArrowLeft} variant='outline-secondary' onClick={onClose} />
                <ButtonIcon name={t(`${p}.btn-save`)} icon={faSave} variant='outline-success' onClick={saveData} />
            </Fragment>}
        />
    )
}

export default RecFacPedProv