import Fetcher from "../../../libs/Petition";

const url = '/ped/pend/prov';
const CREATED = 201;
const STATUS_OK = 200;

export const indexPedPendProv = async ({ headers, ILifnr }) => {
    let response = { data: [], totalItems: 0 };
    try {
        let params = { ILifnr };
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            headers,
            params
        });
        // console.log("Ped pend prov: ", fetch);
        if (fetch.status === STATUS_OK) {
            response.data = fetch.data.data
            response.totalItems = fetch.data.data.length
        }
        return response;
    } catch (error) {
        return response;
    }
}