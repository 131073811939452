import PropTypes from 'prop-types';
import { faFilePdf, faSave, faStepBackward, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from "react";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import ModalComp from "../../Layouts/Modal";
import { Col, Form, Row, FormSelect } from "react-bootstrap";
import FormIcon from "../../Layouts/Forms/FormIcon";
import usePagination from '../../../hooks/usePagination';
import { storeUser, updateUser } from '../../../api/Users';
import Swal from 'sweetalert2';
import { Switch, Divider } from 'antd';
import { signup } from '../../../api/Authentification';
import setToast from '../../../libs/SetToast';
import { useToast, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Fetcher from '../../../libs/Petition';
import { updateBusinessPartners } from '../../../api/business_partners';
import { tax_system } from '../../../data/invoiceTypes';
import UserFiles from './UserFiles';

const ModalUserEdit = ({ onClose, hidden, user, headers, isEdit, fibra_id, addElement, updateElement }) => {
    const toast = useToast();
    const [t] = useTranslation('settings');
    const { data, setData, handleChange } = usePagination({});
    const [isBusinessPartner, setIsBusinessPartner] = useState(true);
    const [showFiles, setShowFiles] = useState(false);

    useEffect(() => {
        // Establecer por default persona física en caso de tratarse de la creación de un socio comercial
        if (!isEdit && isBusinessPartner)
            setData({ person_type: 0, tax_system: '601 - General de Ley Personas Morales' });
        // Obtener datos del socio comercial, si se trata de la edición de un usuario
        if (isEdit && !hidden) getDataProvider();
    }, [hidden, user]);

    useEffect(() => {
        if (data.person_type === 'Moral') setData(prevState => ({ ...prevState, person_type: 0 }));
        if (data.person_type === 'Física') setData(prevState => ({ ...prevState, person_type: 1 }));
    }, [data.person_type])

    // Obtener datos del proveedor (socio comercial)
    const getDataProvider = async () => {
        try {
            const response = await Fetcher({
                url: `/repse/users/${user?.id}`, method: 'GET', headers
            });
            if (response.status === 200 && response?.data.data?.business_partner) {
                setIsBusinessPartner(true);
                setData(prevState => ({
                    ...prevState,
                    ...response?.data.data?.business_partner,
                    tax_id: response?.data.data?.business_partner?.rfc
                }));
            } else setIsBusinessPartner(false)
        } catch (error) {
            console.log("🚀 ~ getDataProvider ~ error:", error)
        }
    }
    // Validar estructura de datos
    const isValidData = (dataKeys, toastKeys) => {
        let response = true;
        for (const element of dataKeys) {
            const { key, name } = element;
            if (!data.hasOwnProperty(key)) {
                response = false;
                setToast({
                    toast,
                    title: t(`users.toasts.${name}.title`),
                    description: t(`users.toasts.${name}.description`),
                    status: 'warning'
                });
                break;
            }
        }
        return response;
    };
    // Método para validar que los datos del socio comercial son correctos
    const isValidBusinessPartner = () => {
        // Datos requeridos
        const dataBusinessPartner = [
            { key: 'business_name', name: 'business-name' },
            { key: 'person_type', name: 'person-type' },
            { key: 'tax_id', name: 'tax-id' },
            { key: 'tax_system', name: 'tax-system' },
            { key: 'first_name', name: 'first-name' },
            { key: 'phone_number', name: 'phone-number' },
            { key: 'email', name: 'email' },
            { key: 'beneficiary', name: 'beneficiary' },
            { key: 'bank', name: 'bank' },
            { key: 'branch', name: 'branch' },
            { key: 'bank_account', name: 'bank-account' },
            { key: 'interbank_code', name: 'interbank-code' },
        ];

        return isValidData(dataBusinessPartner);
    };
    // Método para validar datos de administrador
    const isValidAdminData = () => {
        const dataAdmin = [
            { key: 'name', name: 'name' },
            { key: 'first_name', name: 'first-name' },
            { key: 'last_name', name: 'last-name' },
            { key: 'email', name: 'email' },
            { key: 'phone_number', name: 'phone-number' }
        ];

        return isValidData(dataAdmin);
    };
    // Método para agregar socio comercial
    const saveBusinessPartner = async () => {
        if (!isValidBusinessPartner()) {
            return;
        }

        try {
            const response = await signup({
                data: {
                    ...data, fibra_id,
                    name: data.email,
                    alias: data.business_name
                }
            });

            if (!response) {
                return;
            }

            const { status, data: responseData } = response;

            if (status === 200 || status === 201) {
                handleSuccessfulOperation(responseData.user);
            } else if (status === 422) {
                handleValidationErrors(responseData.errors);
            }
        } catch (error) {
            Swal.fire(t('users.sf.t-fail'), t('users.sf.m-fail-server'), 'info');
        }
    }
    // Creación exitosa de socio comercial
    const handleSuccessfulOperation = (user) => {
        Swal.fire(t('users.sf.t-success'), t('users.sf.m-user-add'), 'success');
        addElement(user);
        onClose();
    }
    // Creación de socio comercial fallida
    const handleValidationErrors = (errors) => {
        let errorMessageList = '';

        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                const errorMessages = errors[key];
                errorMessageList += errorMessages.map(errorMessage => `<li>${errorMessage}</li>`).join('');
            }
        }

        Swal.fire({ title: t('users.sf.t-fail'), html: `<ul>${errorMessageList}</ul>`, icon: 'warning' });
    }
    // Método para crear otro administrador
    const saveAdmin = async () => {
        if (!isValidAdminData()) {
            return;
        }
        try {
            // Registrar un administrador
            const response = await storeUser({
                headers,
                data: {
                    ...data, fibra_id,
                    last_name: ' ',
                    name: data.email,
                }
            });
            if (!response) {
                return;
            }

            const { status, data: responseData } = response;

            if (status === 200 || status === 201) {
                handleSuccessfulOperation(responseData.data);
            } else if (status === 422) {
                handleValidationErrors(responseData.errors);
            }

        } catch (error) {
            Swal.fire(t('users.sf.t-fail'), t('users.sf.m-fail-server'), 'info');
        }
    }
    // Guardar usuario: Socio comercial o administrador
    const onSave = async () => {
        if (isBusinessPartner) {
            saveBusinessPartner()
        } else {
            saveAdmin()
        }

    }
    // Actualizar datos bancarios de socio comercial
    const onUpdateBusinessPartner = async () => {
        const bankData = {
            business_name: data.business_name,
            alias: data.business_name,
            rfc: data.tax_id,
            person_type: data.person_type,
            beneficiary: data.beneficiary,
            bank: data.bank,
            tax_system: data.tax_system,
            branch: data.branch,
            bank_account: data.bank_account,
            interbank_code: data.interbank_code,
            reference: data.reference,
            agreement: data.agreement,
        }
        return await updateBusinessPartners({ headers, data: bankData, business_partner_id: data.id });
    }
    // Actualizar datos de usuario
    const onEdit = async () => {
        try {
            const fetch = await updateUser({ headers, data, user_id: user.id });
            let fetchBusinessPartner = { res: true, data: {} };
            // Si el usuario actual tiene esta propiedad es un socio comercial,
            let business_partner_provider = null;
            if (user.business_partner_provider) {
                const fetchUBD = await onUpdateBusinessPartner();
                if (fetchUBD.res) {
                    business_partner_provider = fetchUBD.data;
                } else {
                    fetchBusinessPartner.res = false;
                    fetchBusinessPartner.data = fetchUBD.data
                }
            }
            if (fetch.res && fetchBusinessPartner.res) {
                updateElement({ ...fetch.data, business_partner_provider });
                Swal.fire(t('users.sf.t-success'), t('users.sf.m-user-edit'), 'success');
                onClose();
            } else {
                let errors = {}
                if (!fetch.res) errors = fetch.data
                if (!fetchBusinessPartner.res) errors = { ...errors, ...fetchBusinessPartner.data }
                handleValidationErrors(errors);
            }
        } catch (error) {
            Swal.fire(t('users.sf.t-fail'), t('users.sf.m-fail-server'), 'info');
        }
    }

    return (
        <ModalComp
            onClose={onClose}
            title={t('users.modal.title')}
            hidden={hidden}
            size={showFiles ? 'xl' : 'xl'}
            body={
                <Fragment>
                    {
                        showFiles ? <UserFiles currentUser={user} setShowFiles={setShowFiles} updateElement={updateElement}/> :
                            <Row>
                                <Col>
                                    <Divider orientation='left'>
                                        <Heading size='sm'>{t(`users.modal.personal-data`)}</Heading>
                                    </Divider>
                                    {/* Switch para cambiar de socio comerciar a admin - Sólo si se agrega un registro */}
                                    {
                                        !isEdit &&
                                        <Row className="justify-content-end mb-4">
                                            <Col xs="auto">
                                                <Form.Label>{t('users.modal.signup-business-partner')}</Form.Label>
                                            </Col>
                                            <Col xs="auto">
                                                <Switch
                                                    checked={isBusinessPartner}
                                                    onChange={(checked) => setIsBusinessPartner(checked)}
                                                />
                                            </Col>
                                        </Row>
                                    }
                                    {/* Datos que se agregan cuando es un socio comercial y no es edición*/}
                                    {
                                        isBusinessPartner && <>
                                            <Row className="mb-3">
                                                <FormIcon title={t('users.modal.business-name')} name='business_name' defaultValue={user?.business_name}
                                                    value={data?.business_name} handleChange={handleChange} />
                                                <Col>
                                                    <Form.Label>{t('users.modal.person-type')}</Form.Label>
                                                    <FormSelect name='person_type' onChange={handleChange} value={data?.person_type}>
                                                        <option value={0}>{t('users.modal.person-moral')}</option>
                                                        <option value={1}>{t('users.modal.person-physical')}</option>
                                                    </FormSelect>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <FormIcon title={t('users.modal.tax-id')} name='tax_id' maxLength={13} defaultValue={data?.tax_id}
                                                    value={data?.tax_id} handleChange={handleChange} />
                                                <Col>
                                                    <Form.Label>{t('users.modal.tax-system')}</Form.Label>
                                                    <FormSelect name='tax_system' onChange={handleChange} value={data?.tax_system}>
                                                        {
                                                            tax_system.map((item) =>
                                                                <option key={item.code} value={`${item.code} - ${item.name}`}>{item.code} - {item.name}</option>
                                                            )
                                                        }
                                                    </FormSelect>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                    <Divider orientation='left'>
                                        <Heading size='sm'>{t(`users.modal.user-data`)}</Heading>
                                    </Divider>
                                    <Row className="mb-3">
                                        <FormIcon title={t('users.modal.full-name')} name='first_name' defaultValue={user?.first_name}
                                            value={data?.first_name} handleChange={handleChange} />
                                        <FormIcon title={t('users.modal.phone-number')} name='phone_number' defaultValue={user?.phone_number}
                                            value={data?.phone_number} handleChange={handleChange} />
                                    </Row>
                                    <Row className="mb-3">
                                        <FormIcon title={t('users.modal.email')} name='email' defaultValue={user?.email}
                                            value={data?.email} handleChange={handleChange} />
                                        <Col>
                                            <Form.Label>{t('users.modal.gender')}</Form.Label>
                                            <FormSelect name='gender' onChange={handleChange} value={data?.gender} >
                                                <option value={null}>{t('users.modal.gender-null')}</option>
                                                <option value={1}>{t('users.modal.gender-male')}</option>
                                                <option value={0}>{t('users.modal.gender-female')}</option>
                                            </FormSelect>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    {/* Datos bancarios de socio comercial */}
                                    {
                                        isBusinessPartner &&
                                        <>
                                            <Divider orientation='left'>
                                                <Heading size='sm'>{t(`users.modal.bank-data`)}</Heading>
                                            </Divider>
                                            <Row className="mb-3">
                                                <FormIcon title={t('users.modal.beneficiary')} name='beneficiary' defaultValue={data?.beneficiary}
                                                    value={data?.beneficiary} handleChange={handleChange} />
                                            </Row>
                                            <Row className="mb-3">
                                                <FormIcon title={t('users.modal.bank')} name='bank' defaultValue={data?.bank}
                                                    value={data?.bank} handleChange={handleChange} />
                                                <FormIcon title={t('users.modal.branch')} name='branch' defaultValue={data?.branch}
                                                    value={data?.branch} handleChange={handleChange} />
                                            </Row>
                                            <Row className="mb-3">
                                                <FormIcon title={t('users.modal.bank-account')} name='bank_account' type={'number'} maxLength={16} defaultValue={data?.bank_account}
                                                    value={data?.bank_account} handleChange={handleChange} />
                                                <FormIcon title={t('users.modal.interbank-code')} name='interbank_code' type={'number'} maxLength={18} defaultValue={data?.interbank_code}
                                                    value={data?.interbank_code} handleChange={handleChange} />
                                            </Row>
                                            <Row className="mb-3">
                                                <FormIcon title={t('users.modal.agreement')} name='agreement' defaultValue={data?.agreement}
                                                    value={data?.agreement} handleChange={handleChange} />
                                                <FormIcon title={t('users.modal.reference')} name='reference' defaultValue={data?.reference}
                                                    value={data?.reference} handleChange={handleChange} />
                                            </Row>
                                        </>
                                    }
                                </Col>
                            </Row>
                    }
                </Fragment>}
            footer={<Fragment>
                {
                    isEdit && !showFiles && <>
                    <ButtonIcon icon={faTimes} name={t('users.modal.close')} onClick={onClose} variant="outline-secondary" />
                    <ButtonIcon icon={faFilePdf} name={t('users.modal.pdf-files')} onClick={() => setShowFiles(true)} variant="outline-danger" />
                        <ButtonIcon icon={faSave} name={t('users.modal.edit')} onClick={onEdit} variant="outline-success" />
                    </>
                }
                {
                    !isEdit &&
                    <ButtonIcon icon={faSave} name={t('users.modal.save')} onClick={onSave} variant="outline-success" />
                }
            </Fragment>}
        />
    );
};

ModalUserEdit.propTypes = {
    onClose: PropTypes.func.isRequired,
    hidden: PropTypes.bool.isRequired,
    user: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        email: PropTypes.string,
        phone_number: PropTypes.string,
        business_name: PropTypes.string,
        alias: PropTypes.string,
        tax_id: PropTypes.string,
        business_partner_provider: PropTypes.object
    }),
    headers: PropTypes.string.isRequired,
    isEdit: PropTypes.bool,
    fibra_id: PropTypes.number,
    addElement: PropTypes.func,
    updateElement: PropTypes.func,
}

export default ModalUserEdit;
