import { Fragment, useState, useEffect } from "react";
import '../../styles/Login.Component.css';
import { connect } from "react-redux";
import { Card, Container, Row } from 'react-bootstrap';
import { Stack, Checkbox, Text, Link, Center } from '@chakra-ui/react';
import { useCookies } from "react-cookie";
import setToast from "../../libs/SetToast";
import { useToast } from "@chakra-ui/toast";
import ButtonIcon from "../Layouts/Forms/ButtonIcon";
import { faAt, faEye, faEyeSlash, faInfo, faKey, faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { login } from "../../api/Authentification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import SignupForm from "./fragments/SignupForm";
import FormIcon from "../Layouts/Forms/FormIcon";
import VerificationCode from "./fragments/VerificationCode";
import ResetPassword from "./fragments/ResetPassword";
import FlexButton from "../Layouts/FlexButton";

const _ = require('underscore');

const lang = localStorage.getItem('lang');

const Login = ({ openSession }) => {
    const [email, setEmail] = useState(process.env.REACT_APP_USERNAME);
    const [password, setPassword] = useState(process.env.REACT_APP_PASSWORD);
    const [sap_id, setSapID] = useState(process.env.REACT_APP_SAP_ID);
    const [cookie, setCookie] = useCookies(['user']);
    const [loading, setLoading] = useState(false);
    const toast = useToast();

    const [show, setShow] = useState(false);
    const [showSap, setShowSap] = useState(false);
    const [showVerCode, setShowVerCode] = useState(false);      // Mostrar interfaz para captura de código de verificación
    const [t] = useTranslation('global');

    const handleClick = () => setShow(!show);
    const handleClickSap = () => setShowSap(!showSap);

    const [sap_field, setSapField] = useState(true);            // Mostrar campo para SAP ID
    const [sign, setSign] = useState(false);
    const [resetPass, setResetPass] = useState(false);

    useEffect(() => {
        document.body.classList.add("setImage");
    }, []);

    const goBack = () => {
        document.body.classList.add("setImage");
        setSign(false);
    }
    const signupAs = () => {
        document.body.classList.remove("setImage");
        document.body.classList.add("setColor");
        setSign(true);
    }

    const goHome = (data) => {
        let { token, apps, roles, user, fibra } = data;
        let role_selected = roles.length > 0 ? roles[0].name : null;
        let modules = role_selected.modules;

        openSession(
            'active',
            token,
            apps,
            roles,
            user,
            role_selected,
            modules,
            fibra,
        );
    }

    const Authenticate = async () => {
        setLoading(true);
        let data = { email, password };
        if (sap_id) data.sap_id = sap_id;
        let response = await login({ ...data });
        if (response.status === 'SUCCESS') {
            let { apps, user } = response.data;
            let pos = _.findIndex(apps, { code: 'WEB' }); // Hallar el permiso de acceso a la aplicación web
            if (pos !== -1) {
                // Verificar si tiene habilitada la doble autentificación
                if (user.has_two_factor) {    // Mostrar vista para captura de código de verificación
                    setShowVerCode(true);
                } else {  // Sólo abrimos la sesión
                    // console.log("cookie: ", cookie.user);
                    if (cookie.user === undefined)
                        setCookie('user', response, {
                            path: "/",
                            maxAge: 3600, // Expires after 1hr
                            sameSite: true,
                        });
                    goHome(response.data);
                }
            } else // Mostrar mensaje de retroalimentación
                setToast({
                    title: t('login.toasts.warning.title'), status: "warning", toast,
                    description: t('login.toasts.warning.description'),
                });
        } else
            setToast({
                title: t('login.toasts.error.title'), description: response.message,
                status: "error", toast
            });
        setLoading(false);
    }

    const showSapField = (e) => { setSapField(!e.target.checked); }

    const changeLang = (e) => {
        let { value } = e.target;
        localStorage.setItem('lang', value);
        window.location.reload();
    }

    const forgotPassword = () => {
        setResetPass(true);
    }

    const windowHelp = () => {
        const nuevaPestanaURL = 'https://help-victum-repse.vercel.app/';

        // Abre una nueva pestaña con la URL proporcionada
        window.open(nuevaPestanaURL, '_blank');
    }

    return (
        <Container fluid id={'login-component'}>
            <Center height='40em'>
                {
                    sign && <SignupForm goBack={goBack} />
                }
                {
                    resetPass && <ResetPassword setResetPass={setResetPass} />
                }
                {
                    !sign && !resetPass &&
                    <Row>
                        {/* Card de formulario de inicio de sesión */}
                        <Card id='login-form' style={{ borderRadius: '0.375rem', padding: '5%' }}>
                            <Card.Body>
                                <Card.Title>{t('login.title')}</Card.Title>
                                {
                                    showVerCode ? <VerificationCode email={email} goHome={goHome} /> :
                                        <Stack spacing={4}>
                                            {/* Input para email */}
                                            <FormIcon
                                                type="email"
                                                handleChange={e => setEmail(e.target.value)}
                                                defaultValue={email}
                                                placeholder={t('login.form.email')}
                                                icon={<FontAwesomeIcon icon={faAt} />}
                                                styleInput={{ backgroundColor: '#FFF' }}
                                            />
                                            {/* Input para conntraseña */}
                                            <FormIcon
                                                type={show ? 'text' : 'password'}
                                                placeholder={t('login.form.password')}
                                                handleChange={e => setPassword(e.target.value)}
                                                defaultValue={password}
                                                styleInput={{ backgroundColor: '#FFF' }}
                                                icon={<FontAwesomeIcon icon={faKey} />}
                                                re_icon={<FontAwesomeIcon icon={show ? faEyeSlash : faEye} />}
                                                re_onClick={handleClick}
                                            />
                                            {/* Input oculto para ERP ID */}
                                            <FormIcon
                                                ig_hidden={sap_field}
                                                type={showSap ? 'text' : 'password'}
                                                placeholder={t('login.form.erp-id')}
                                                handleChange={e => setSapID(e.target.value)}
                                                defaultValue={sap_id}
                                                styleInput={{ backgroundColor: '#FFF' }}
                                                icon={<FontAwesomeIcon icon={faKey} />}
                                                re_icon={<FontAwesomeIcon icon={showSap ? faEyeSlash : faEye} />}
                                                re_onClick={handleClickSap}
                                            />
                                            {/* Mostrar u ocultar ERP ID */}
                                            <Checkbox style={{ color: '#FFF' }} onChange={showSapField}>
                                                {t('login.form.erp-hint')}
                                            </Checkbox>
                                            {/* Button para iniciar sesión */}
                                            <div className="d-grid gap-2">
                                                {
                                                    loading ?
                                                        <ButtonIcon variant='primary' icon={faSignInAlt} name={t('login.form.button-loading')} disabled /> :
                                                        <ButtonIcon variant='primary' icon={faSignInAlt} name={t('login.form.button')} onClick={Authenticate} size='md' />
                                                }
                                            </div>
                                            {/* Inciar captura de datos para registro de proveedor */}
                                            <Text color='whiteAlpha.800' > {t('login.form.signup')}{' '}
                                                <Link color='teal.500' onClick={signupAs}>{t('login.form.as')}</Link>
                                            </Text>
                                            {/* Recuperación de contraseña */}
                                            <Text color='whiteAlpha.800' > {' '}
                                                <Link color='teal.500' onClick={forgotPassword}>{t('login.form.forgot-password')}</Link>
                                            </Text>
                                            {/* Cambiar idioma de la aplicación */}
                                            <div className='d-flex flex-row-reverse'>
                                                <select onChange={changeLang} defaultValue={lang}
                                                    style={{ padding: '1%', width: '40%', borderRadius: '0.375rem' }}>
                                                    <option value="es">{t('login.form.es')}</option>
                                                    <option value="en">{t('login.form.en')}</option>
                                                </select>
                                            </div>
                                        </Stack>
                                }
                            </Card.Body>
                        </Card>
                    </Row>
                }
                {/* Ayuda */}
                <FlexButton style={{ bottom: 20, right: 20 }} icon={faInfo} onClick={() => windowHelp()} />
            </Center>
        </Container>
    );
}

const mapStateToProps = state => ({
    session: state.login.session
});

const mapDispatchToProps = dispatch => ({
    openSession(session, token, apps, roles, user, role_selected, modules, fibra) {
        dispatch({
            type: 'OPEN_SESSION',
            session,
            token,
            apps,
            roles,
            user,
            role_selected,
            modules,
            fibra
        })
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);