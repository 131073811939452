import Fetcher from "../libs/Petition"

const url = '/user_roles';
const CREATED = 201;
const STATUS_OK = 200;

/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param user_id Identificador del usuario sobre el que se hará la consulta de roles a los que tiene acceso
 * @returns UserRole::collection() Retorna una colección de objetos de tipo Role
 */
export const indexUserRoles = async ({ headers, user_id }) => {
    let response = { data: [] };
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params: { user_id },
            headers
        });
        // console.log("User Roles [index] ", fetch);
        if (fetch.status === STATUS_OK) {
            response = fetch.data;
        }
        return response;
    } catch (error) {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param user_role_id Identificador del rol sobre el que se hará la consulta
 * @returns UserRole Retorna un objeto tipo UserRole
 */
export const showUserRoles = async ({ headers, user_role_id }) => {
    let response = {};
    try {
        let fetch = await Fetcher({
            url: `${url}/${user_role_id}`,
            method: 'GET',
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a almacenar 
 * @returns Bool Retorna true si el objeto se almacena de manera satisfactoria, retorna FALSE si el objeto no se pudo almacenar 
 */
export const storeUserRoles = async ({ headers, data }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'POST',
            data,
            headers
        });
        if (fetch.status === CREATED)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param data Contiene el objeto que se va a actualizar
 * @param user_role_id Identificador del user_rol que se va a modificar
 * @returns Bool Retorna true si el objeto se actualiza de manera satisfactoria, retorna FALSE si el objeto no se pudo actualizar 
 */
export const updateUserRoles = async ({ headers, data, user_role_id }) => {
    let response = false;
    try {
        const fetch = await Fetcher({
            url: `${url}/${user_role_id}`,
            method: 'PUT',
            data,
            headers
        });
        // console.log("🚀 ~ file: UserRoles.js:93 ~ updateUserRoles ~ fetch:", fetch)
        if (fetch.status === STATUS_OK) {
            response = fetch.data;
        }
        return response;
    } catch (error) {
        return response;
    }
}
/**
 * 
 * @param headers Contiene la cabecera de autorización { Authorization: `Bearer ${token}` }
 * @param user_role_id Identificador del user_rol que se va a eliminar
 * @returns Bool Retorna true si el objeto se elimina de manera satisfactoria, retorna FALSE si el objeto no se pudo eliminar 
 */
export const destroyUserRoles = async ({ headers, user_role_id }) => {
    let response = false;
    try {
        let fetch = await Fetcher({
            url: `${url}/${user_role_id}`,
            method: 'DELETE',
            headers
        });
        if (fetch.status === STATUS_OK)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;
    }
}