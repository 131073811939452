import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Heading } from '@chakra-ui/react'
import { Divider, Skeleton } from 'antd'
import { Col, FloatingLabel, Form, FormSelect, Row } from 'react-bootstrap'
import { currencyTypes } from '../../../../data/currencyTypes'
import { useTranslation } from 'react-i18next'
import { getCurrency } from '../../../../libs/functions'

const Header = ({ handleChange, providers, folio, po, type, purchase_order, amount, subtotal, form, businessNames, loadingBN, hookSegments }) => {
    const [t] = useTranslation('global');
    const p_data = 'purchase-order.data';
    const [segments, setSegments] = useState([]);
    // Mostrar todos los segmentos por default
    useEffect(() => {
        if (!hookSegments.loading) {
            setSegments(hookSegments.data);
        }
    }, [hookSegments.loading]);
    // Mostrar los segmentos que pertenecen a la razón social seleccionada
    useEffect(() => {
        if (form.business_name_id) {
            const businessNameSegments = hookSegments.data.filter(segment => segment.business_name_id == form.business_name_id)
            setSegments(businessNameSegments);
        }
    }, [form.business_name_id]);

    return (
        <>
            {
                loadingBN ? <Skeleton active /> : <>
                    <Divider orientation='left'>
                        <Heading size='sm'>{t(`${p_data}.general-data`)}</Heading>
                    </Divider>
                    <Row className='mb-3'>
                        <Col>
                            <FloatingLabel label={t(`${p_data}.folio`)}>
                                <Form.Control type="text" placeholder={t(`${p_data}.folio`)} name='folio' defaultValue={folio} value={folio} readOnly />
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel label={t(`${p_data}.business-name`)}>
                                <FormSelect name='business_name_id' onChange={handleChange} defaultValue={po?.business_name_id} value={form?.business_name_id}
                                    disabled={type === 'provider'} required>
                                    <option value={-1}>{t(`${p_data}.select-business-name`)}</option>
                                    {
                                        businessNames.map((item) =>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )
                                    }
                                </FormSelect>
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel label={t(`${p_data}.provider`)}>
                                <FormSelect name='user_id' onChange={handleChange} defaultValue={po?.user_id} value={form?.user_id}
                                    disabled={type === 'provider'} required>
                                    <option value={-1}>{t(`${p_data}.select-provider`)}</option>
                                    {
                                        providers.map((provider) =>
                                            <option key={provider.id} value={provider.id}>{provider?.first_name} {provider?.last_name}</option>
                                        )
                                    }
                                </FormSelect>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col>
                            {
                                <FloatingLabel label={t(`${p_data}.segment`)}>
                                    <FormSelect name='segment_id' onChange={handleChange} defaultValue={po?.segment_id} value={form?.segment_id}
                                        disabled={type === 'provider'} required>
                                        <option value={-1}>{t(`${p_data}.select-segment`)}</option>
                                        {
                                            segments.map((item) =>
                                                <option key={item.id} value={item.id}>{item.code} - {item.name}</option>
                                            )
                                        }
                                    </FormSelect>
                                </FloatingLabel>
                            }
                        </Col>
                        <Col>
                            <FloatingLabel label={t(`${p_data}.currency`)}>
                                <FormSelect name='currency' onChange={handleChange} defaultValue={po?.currency} value={form?.currency}
                                    disabled={type === 'provider'} required>
                                    <option value={-1}>{t(`${p_data}.select-currency`)}</option>
                                    {
                                        currencyTypes.map((item) =>
                                            <option key={item.id} value={item.code}>{item.code} - {item.name}</option>
                                        )
                                    }
                                </FormSelect>
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel label={t(`${p_data}.ended-date`)}>
                                <Form.Control type="date" name='ended_date' onChange={handleChange} defaultValue={purchase_order?.ended_date}
                                    disabled={type === 'provider'} required />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col>
                            <FloatingLabel label={t(`${p_data}.subtotal`)}>
                                <Form.Control type="text" name='subtotal' defaultValue={purchase_order?.subtotal} value={subtotal}
                                    disabled={type === 'provider'} required />
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel label={t(`${p_data}.iva`)}>
                                <Form.Control type="text" name='iva' value={getCurrency(purchase_order?.iva)}
                                    disabled={type === 'provider'} required />
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel label={t(`${p_data}.iva-withholding`)}>
                                <Form.Control type="text" name='iva_withholding' value={getCurrency(purchase_order?.iva_withholding)}
                                    disabled={type === 'provider'} required />
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel label={t(`${p_data}.isr-withholding`)}>
                                <Form.Control type="text" name='isr_withholding' value={getCurrency(purchase_order?.isr_withholding)}
                                    disabled={type === 'provider'} required />
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel label={t(`${p_data}.amount`)}>
                                <Form.Control type="text" name='amount' defaultValue={purchase_order?.amount} value={amount}
                                    disabled={type === 'provider'} required />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FloatingLabel label={t(`${p_data}.description`)}>
                                <Form.Control as='textarea' style={{ height: '5em' }} name='description' defaultValue={purchase_order?.description} onChange={handleChange}
                                    disabled={type === 'provider'} required />
                            </FloatingLabel>
                        </Col>
                    </Row>
                </>
            }
        </>
    )
}

Header.propTypes = {
    handleChange: PropTypes.func.isRequired,
    providers: PropTypes.array.isRequired,
    folio: PropTypes.string.isRequired,
    po: PropTypes.object.isRequired,
    type: PropTypes.oneOf(['admin', 'provider']).isRequired,
    purchase_order: PropTypes.object.isRequired,
    amount: PropTypes.number.isRequired,
    subtotal: PropTypes.number.isRequired,
    form: PropTypes.object.isRequired,
    businessNames: PropTypes.array.isRequired,
    loadingBN: PropTypes.bool.isRequired,
    hookSegments: PropTypes.any,
}

export default Header