import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormIcon from "../../Layouts/Forms/FormIcon";

const General = (props) => {
    const { rent } = props;
    const [t] = useTranslation('global');
    const prefix = 'contracts.modal-details.general';
    return (
        <Form> {/*Form de datos generales*/}
            <Row className='mb-3'>
                <FormIcon type='text' title={t(`${prefix}.contract-name`)} name='name' value={rent?.name} />
                <FormIcon type='text' title={t(`${prefix}.client-name`)} name='client' value={rent?.user?.first_name + ' ' + rent?.user?.last_name} />
            </Row>
            <Row className='mb-3'>
                <FormIcon type='text' icon='$' title={t(`${prefix}.fixed-rent`)} name='renta_fija' value={rent?.renta_fija} />
                <FormIcon type='text' icon='%' title={t(`${prefix}.variable-rent`)} name='renta_variable' value={rent?.renta_variable} />
                <FormIcon type='text' title={t(`${prefix}.currency`)} name='tipo_moneda' value={rent?.tipo_moneda} />
            </Row>
            <Row className='mb-3'>
                <FormIcon type='text' title={t(`${prefix}.start-date`)} name='start_date' value={rent?.start_date} />
                <FormIcon type='text' title={t(`${prefix}.end-date`)} name='end_date' value={rent?.end_date} />
            </Row>
            <Row>
                <Col>
                    <label>{t(`${prefix}.description`)}</label>
                    <textarea className='form-control' value={rent?.descripcion}></textarea>
                </Col>
            </Row>

        </Form>
    );
}

export default General