import { faArrowLeft, faCheck, faStepBackward, faStepForward } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from "react";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import ModalComp from "../../Layouts/Modal";
import { Rate, Steps } from 'antd';
import { showQuizz } from "../../../api/Quizzs/Quizzs";
import SkeletonForm from "../../Layouts/Skeletons/Form";
import { Heading } from "@chakra-ui/layout";
import setToast from "../../../libs/SetToast";
import { useToast } from "@chakra-ui/toast";
import Swal from "sweetalert2";
import { storeQuizzAnswer } from "../../../api/Quizzs/QuizzAnswers";
import { storeQuizzQuestionAnswer } from "../../../api/Quizzs/QuizzQuestionAnswers";
import { useTranslation } from "react-i18next";

const { Step } = Steps;

const Answers = (props) => {
    const toast = useToast();
    const { onClose, headers, quizzSel, update, user_id, role_id } = props;
    const [current, setCurrent] = useState(0);
    const [quizz, setQuizz] = useState([]);
    const [quizzQuestions, setQuizzQuestions] = useState([]);
    const [quizzAnswers, setQuizzAnswers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [t] = useTranslation('global');
    const p_buttons = 'polls.modal-answers';
    const p_toasts = 'polls.toasts';

    useEffect(() => {
        getQuizz();
    }, []);

    const getQuizz = async () => {
        let response = await showQuizz({ headers, quizz_id: quizzSel.id });
        setQuizz(response);
        setQuizzQuestions(response?.quizz_questions);
        // console.log("Quizz questions: ", response?.quizz_questions);
        setQuizzAnswers(Array(response?.quizz_questions.length).fill(0));
        setLoading(false);
    }

    // Ir a la siguiente pregunta. Nota: Sólo si ha respondido la actual
    const next = () => {
        if (quizzAnswers[current] !== 0) {
            setCurrent(current + 1);
        } else {
            setToast({
                toast, title: t(`${p_toasts}.info.title`), status: "info",
                description: t(`${p_toasts}.info.description`)
            });
        }
    };

    // Ir a la pregunta anterior
    const prev = () => {
        setCurrent(current - 1);
    };

    // Asignar calificación a cada item de la encuesta
    const handleRate = (value) => {
        // console.log("handle rate: ", value);
        setQuizzAnswers(quizzAnswers.map((ans, index) => {
            if (current === index) {
                ans = value;
            }
            return ans;
        }));
    }
    // Finalizar encuesta
    const done = async () => {
        // Se indica que la encuesta se comienza a responder
        let response = await storeQuizzAnswer({
            headers,
            data: {
                quizz_id: quizzSel.id,
                user_id,
                role_id
            }
        });
        // console.log("Store quizz answer: ", response);
        // Se guardan las respuestas
        await quizzAnswers.map(async (ans, index) => {
            let { id } = quizzQuestions[index];
            await storeQuizzQuestionAnswer({
                headers,
                data: {
                    rate: ans,
                    quizz_answer_id: response.id,
                    quizz_question_id: id,
                }
            });
        });
        update();
        onClose();
        Swal.fire(t(`${p_toasts}.swal-success.title`), t(`${p_toasts}.swal-success.description`), 'success');
    }

    return (
        <ModalComp
            onClose={onClose}
            title={t(`${p_buttons}.title`)}
            size='xl'
            body={<Fragment>
                {
                    loading ? <SkeletonForm cols={3} rows={5} /> : <Fragment>
                        <Steps current={current}>
                            {quizzQuestions.map(item => (
                                <Step key={item.id} />
                            ))}
                        </Steps>
                        <div className="steps-content mt-4 mb-4">
                            <Heading>
                                {quizzQuestions[current].question}
                            </Heading>

                            <Rate allowHalf defaultValue={0} value={quizzAnswers[current]} style={{ fontSize: '80px' }} onChange={handleRate} />
                        </div>

                    </Fragment>
                }
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon icon={faArrowLeft} name={t(`${p_buttons}.button-close`)} onClick={onClose} variant='outline-secondary' />
                <div className="steps-action">
                    {current > 0 && (

                        <ButtonIcon icon={faStepBackward} onClick={prev} name={t(`${p_buttons}.button-backward`)} variant='outline-primary' />
                    )}
                    {current < quizzQuestions.length - 1 && (
                        <ButtonIcon icon={faStepForward} onClick={next} name={t(`${p_buttons}.button-forward`)} variant='outline-primary' />
                    )}
                    {current === quizzQuestions.length - 1 && quizzAnswers[current] !== 0 && (
                        <ButtonIcon icon={faCheck} onClick={done} name={t(`${p_buttons}.button-done`)} variant='outline-success' />
                    )}
                </div>
            </Fragment>}
        />
    );
}

export default Answers;