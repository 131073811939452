import React from 'react'
import TableComp from '../Layouts/Table'

const Inventario = () => {
    return (
        <TableComp
            headers={['id', 'partida', 'cantidad disponible', 'fecha de creación', 'fecha de actualización', 'acciones']} // Define las cabeceras de la tabla
            keys={['id', 'budget_code', 'stock', 'created_at', 'update_at']} // Define las llaves para acceder a los datos en la tabla
            body={[]} // Por ahora, el cuerpo de la tabla está vacío
        />
    )
}

export default Inventario