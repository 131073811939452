import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const EditMall = (props) => {
    const [show, setShow] = useState(true);
    const [form, setForm] = useState({});
    const [t] = useTranslation('global');
    const prefix = 'malls.edit-mall';

    useEffect(() => {
        if (props.mall) {
            const { name, code, latitude, longitude, } = props.mall;
            setForm({ name, code, latitude, longitude, });
        }
    }, [])

    const handleClose = () => {
        props.onClose();
        setShow(false)
    }

    const handleChange = (event) => {
        let { name, value } = event.target;
        setForm({
            ...form,
            [name]: value
        });
    }

    const handleSave = () => {
        if (props.mall.id) {
            updateMall();
        } else {
            createMall();
        }
    }

    const updateMall = () => {
        console.log("Updating mall");
    }

    const createMall = () => {
        console.log("Creating mall");
    }

    return (
        <Modal show={show} onHide={handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{t(`${prefix}.title`)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row className='mb-3'>
                        <Col>
                            <FloatingLabel label={t(`${prefix}.code`)}>
                                <Form.Control type="text" name='code' defaultValue={form.code} onChange={handleChange} />
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel label={t(`${prefix}.name`)}>
                                <Form.Control type="text" name='name' defaultValue={form.name} onChange={handleChange} />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col>
                            <FloatingLabel label={t(`${prefix}.latitude`)}>
                                <Form.Control type="number" name='latitude' defaultValue={form.latitude} onChange={handleChange} />
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel label={t(`${prefix}.longitude`)}>
                                <Form.Control type="number" name='longitude' defaultValue={form.longitude} onChange={handleChange} />
                            </FloatingLabel>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={handleClose}>{t(`${prefix}.button-close`)}</Button>
                <Button variant="outline-primary" onClick={handleSave}>{t(`${prefix}.button-save`)}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default EditMall;