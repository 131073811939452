import { Heading } from '@chakra-ui/react';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { SET_ACTIVE_TAB } from '../../redux/actions/main';
import TabsComp from '../Layouts/Tabs';
import Security from './Security/Security';

const ClientSettings = () => {
    const [t] = useTranslation('global');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/settings/client' });
    }, []);

    return (
        <Fragment>
            <Heading className='mb-3'>{t('settings.title')}</Heading>
            <TabsComp
                tablist={[
                    t('settings.tabs.security')
                ]}
                tabpanels={[
                    <Security key='' />
                ]}
            />
        </Fragment>
    );
};

export default ClientSettings;