import { useEffect } from 'react'
import usePagination from './usePagination';
import { indexPurchaseOrders } from '../api/PurchaseOrders';

const usePurchaseOrders = ({
    headers,
    fibra_id,
    defaultPageSize = 10,
    business_name_id = undefined,
    segment_id = undefined
}) => {
    const hookPurchaseOrders = usePagination({ defaultPageSize });
    const { page, pageSize } = hookPurchaseOrders.pagination;

    useEffect(() => {
        const getPurchaseOrders = async () => {
            hookPurchaseOrders.setLoading(true);
            const { data, totalItems } = await indexPurchaseOrders({
                headers,
                page,
                pageSize,
                fibra_id,
                business_name_id,
                segment_id
            });
            hookPurchaseOrders.setTotalItems(totalItems)
            hookPurchaseOrders.setData(data)
            hookPurchaseOrders.setLoading(false);
        }
        getPurchaseOrders();
    }, [page, pageSize, fibra_id, business_name_id, segment_id]);

    return hookPurchaseOrders;
}

export default usePurchaseOrders
