import React, { useEffect, useRef, useState } from 'react'
import MainRequisition from './fragments/Main'
import { useTranslation } from 'react-i18next';
import { Heading, useToast } from '@chakra-ui/react';
import { Container } from 'react-bootstrap';
import { faDownload, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { downloadRequisitionPdf } from '../../api/Budget/requisitions';
import { useSelector } from 'react-redux';
import { indexUserBusinessNames } from '../../api/Cfdi/UserBusinessNames';
import { indexRoleBusinessNames } from '../../api/Cfdi/RoleBusinessNames';

const RequisitionsAdmin = () => {
    const login = useSelector(state => state.login);
    const role_selected = login.role_selected;
    const user_id = login.user.id;
    const headers = { Authorization: `Bearer ${login.token}` };
    const mainTableRef = useRef(null);
    const [t] = useTranslation('requisition');
    const prefix = 'table.headers';
    const toast = useToast();
    // Manejar los permisos a las razones sociales y segmentos según el rol
    const [loading, setLoading] = useState(true);
    const [form, setForm] = useState({ segment_ids: [], business_name_ids: [] });
    const { segment_ids, business_name_ids } = form;

    // Obtener permisos del rol actual
    useEffect(() => {
        const getDefaultConfigs = async () => {
            setLoading(true);
            if (role_selected.custom_conf) {
                const fetch = await indexUserBusinessNames({ headers, user_id });
                if (Array.isArray(fetch)) {
                    const { segments, business_names } = fetch.find(element => element.role.id === role_selected.id);
                    // Almacenar los identificadores de los segmentos y razones sociales a las que se tiene acceso
                    setForm({
                        business_name_ids: business_names.map(business_name => business_name.business_name_id),
                        segment_ids: segments.map(segment => segment.segment_id)
                    });
                }
                setLoading(false);
            } else {
                const { segments, business_names } = await indexRoleBusinessNames({ headers, role_id: role_selected.id });
                // Almacenar los identificadores de los segmentos y razones sociales a las que se tiene acceso
                setForm({
                    business_name_ids: business_names.map(business_name => business_name.business_name_id),
                    segment_ids: segments.map(segment => segment.segment_id)
                });
                setLoading(false);
            }
        }
        // Obtener permisos personalizados o por default
        getDefaultConfigs();
    }, [role_selected]);

    const dropItem = (item) => {
        mainTableRef.current.dropItem(item);
    }

    const tKeysHeaders = [
        { key: '#', value: '#' },
        { key: 'folio', value: t(`${prefix}.folio`) },
        // { key: 'description', value: t(`${prefix}.description`) },
        { key: 'segment.code++segment.name', value: t(`${prefix}.segment`) },
        // { key: 'currency', value: t(`${prefix}.currency`) },
        { key: 'ended_date', value: t(`${prefix}.ended-date`), dateFormat: 'LL' },
        { key: 'created_at', value: t(`${prefix}.created_at`), dateFormat: 'LLL' },
        {
            key: 'status', value: 'Estado',
            badges: [
                { value: 'PEND', value2: t(`${prefix}.pending`), color: 'gray' },
                { value: 'CON_PRECIO', value2: t(`${prefix}.with_price`), color: '#e6890b' },
                { value: 'SIN_PRECIO', value2: t(`${prefix}.without_price`), color: '#B22222' },
                { value: 'OC_ASIGNADA', value2: t(`${prefix}.with_po`), color: '#008000' },
                { value: 'OC_PARCIAL', value2: t(`${prefix}.with_po`), color: 'teal' },
            ]
        },
        { key: null, value: t(`${prefix}.actions`) }
    ];

    const tMomentDates = tKeysHeaders
        .map((element, index) => ({ index, format: element.dateFormat }))
        .filter(element => element.format);

    const badges = tKeysHeaders
        .map((element, index) => ({ values: { col: element.key, values: element.badges }, index: { index } }))
        .filter(element => element.values.values);

    const downloadPdf = (item) => {
        downloadRequisitionPdf({ headers, toast, requisition_id: item.id, name: item.folio });
    }
    const tactions = [
        // Descargar el reporte en pdf
        {
            icon: faDownload, handleClick: downloadPdf,
            tooltip: t('table.tooltips.download-item'),
            variant: 'outline-primary'
        },
        // Eliminar un elemento
        {
            icon: faTrashAlt, handleClick: dropItem,
            tooltip: t('table.tooltips.drop-item'),
            variant: 'outline-danger'
        }
    ];

    return (
        <Container fluid>
            <Heading>{t('title-admin')}</Heading>
            <MainRequisition
                ref={mainTableRef}
                type='admin'
                tHeaders={tKeysHeaders.map(element => element.value)}
                tKeys={tKeysHeaders.filter(element => element !== null).map(element => element.key)}
                tMomentDates={tMomentDates}
                badges={badges.map(badge => badge.index)}
                badges_values={badges.map(badge => badge.values)}
                tactions={tactions}
                loading={loading}
                business_name_ids={business_name_ids}
                segment_ids={segment_ids}
            />
        </Container>

    )
}

export default RequisitionsAdmin