import Fetcher from "../libs/Petition"

const url = '/users_session_logs';
const CREATED = 201;
const STATUS_OK = 200;

export const indexUserSessionLogs = async ({ headers, fibra_id, page, pageSize }) => {
    let response = { data: [], totalItems: 0 };
    try {
        let params = { fibra_id };
        if (page) params.page = page;
        if (pageSize) params.pageSize = pageSize;
        let fetch = await Fetcher({
            url: `${url}`,
            method: 'GET',
            params,
            headers
        });
        console.log("🚀 ~ file: UserSessionLogs.js:19 ~ indexUserSessionLogs ~ fetch", fetch)
        if (fetch.status === STATUS_OK)
            response = fetch.data;
        return response;
    } catch (error) {
        return response;;
    }
}