import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInvoices } from "../../api/Cfdi/Mailbox";
import { SET_ACTIVE_TAB } from "../../redux/actions/main";
import Mailbox from "./fragments/Mailbox"
import usePagination from "../../hooks/usePagination";

const MailboxClient = () => {
    const login = useSelector(state => state.login);
    const { fibra, role_selected, user, token } = login;
    const headers = { Authorization: `Bearer ${token}` };

    const hookInvoices = usePagination({ defaultPageSize: 10 });
    const [filters, setFilters] = useState({ cfdi_status_id: null });
    const dispatch = useDispatch();
    const { page, pageSize } = hookInvoices.pagination;

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TAB, tab: '/mailbox/client' });
    }, []);

    useEffect(() => {
        const getMailbox = async () => {
            hookInvoices.setLoading(true);
            const params = { headers, page, pageSize, ...filters };
            const response = await getInvoices({
                ...params, fibra_id: fibra.id,
                role_id: role_selected.id, user_id: user.id
            });
            hookInvoices.setData(response.data);
            hookInvoices.setTotalItems(response.totalItems);
            hookInvoices.setLoading(false);
        }
        getMailbox();
    }, [page, pageSize, filters]);

    return (
        <Mailbox
            hookInvoices={hookInvoices}
            tactions={[
            ]}
            filters={filters}
            setFilters={setFilters}
        />
    );
}

export default MailboxClient;