import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "react-redux";
import { showRentRequest } from "../../../api/Malls/RentRequest";
import Fetcher from "../../../libs/Petition";
import CarouselComponent from "../../Layouts/Carousel";
import ButtonIcon from "../../Layouts/Forms/ButtonIcon";
import ModalComp from "../../Layouts/Modal";
import TabsComp from "../../Layouts/Tabs";
import ClientData from "../forms/ClientData";
import LocalData from "../forms/LocalData";

const ModalHistory = (props) => {
    const { onClose, rent_request_id } = props;

    const store = useStore();
    const { token } = store.getState().login;
    const headers = { Authorization: `Bearer ${token}` };
    const [rent_request, setRentRequest] = useState({});
    const [loading, setLoading] = useState(true);
    const [photos, setPhotos] = useState([]);
    const [t] = useTranslation('global');
    const prefix = 'rents.modal-history';

    useEffect(() => {
        getRentRequest();
    }, []);

    const getRentRequest = async () => {
        let response = await showRentRequest({ headers, rent_request_id });
        // console.log("response modal request: ", response);
        setRentRequest(response);
        try {
            let fetch = await Fetcher({
                method: 'GET', url: `/local_malls/download/${response.local_mall_id}`,
                responseType: 'blob', headers
            });
            let image = new Blob([fetch.data], { type: fetch.data.type });
            let url = window.URL || window.webkitURL;
            image = url.createObjectURL(image);
            setPhotos([image]);
        } catch (error) {

        }
        setLoading(false);
    }
    return (
        <ModalComp
            onClose={onClose}
            size='lg'
            title={t(`${prefix}.title`)}
            body={<Fragment>
                {
                    !loading &&
                    <TabsComp
                        tablist={[
                            t(`${prefix}.tabs.local`),
                            t(`${prefix}.tabs.client-data`),
                            t(`${prefix}.tabs.pics`)
                        ]}
                        tabpanels={[
                            <LocalData local_mall={rent_request?.local_mall} />,
                            <ClientData client={rent_request?.user} />,
                            <CarouselComponent evidences={photos} />,
                        ]}
                    />
                }
            </Fragment>}
            footer={<Fragment>
                <ButtonIcon icon={faArrowLeft} name={t(`${prefix}.button-close`)} variant='outline-secondary' onClick={onClose} />
            </Fragment>}
        />
    );
}

export default ModalHistory